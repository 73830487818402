import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { AppBar } from '@athonet/ui/components/Surfaces/AppBar';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { PageLayout } from '@athonet/ui/components/PageLayout';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { adminConfigLogoutUser } from 'store/actions/adminconfig/login';
import { Outlet, useNavigate } from 'react-router-dom';
import { getDataUser, hydrateAdminConfigUser } from 'store/actions/adminconfig/login';
import { useEffect } from 'react';
import { useAdminConfigUserSelector, useAdminConfigDataSelector } from 'store/selectors/adminConfig';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { useAppDispatch } from 'store';

export default function AdminConfigLayout() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const adminConfigUser = useAdminConfigUserSelector();
  const adminConfigData = useAdminConfigDataSelector();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminConfigUser.data && getDataUser()) {
      void dispatch(hydrateAdminConfigUser());
    } else if (!getDataUser()) {
      navigate('/adminconfig/login');
    }
  }, [adminConfigUser, dispatch, navigate]);

  if (!adminConfigUser.data) {
    return null;
  }

  return (
    <>
      <AppBar color="white">
        <Stack justify="space-between" direction="row" fullWidth nowrap spacing={4}>
          <Stack direction="row" align="center" divided spacing={2}>
            <Box sx={{ flex: '0 0 auto' }}>
              <Logo />
            </Box>
            <Box sx={{ flex: '0 0 auto' }}>
              <Text type="h5" fontWeight={'bold'}>
                {formatMessage({ id: 'adminConfig.appBar.title' })}
              </Text>
            </Box>
          </Stack>
          <Stack direction="row" align="center" spacing={2}>
            {adminConfigData.data?.reboot_required && (
              <Status status={STATUS.WARNING} label={formatMessage({ id: 'adminConfig.appBar.reboot' })} asChip />
            )}
            <Button
              onClick={() => {
                dispatch(adminConfigLogoutUser());
              }}
              variant="outlined"
              text={formatMessage({ id: 'adminConfig.appBar.logout' })}
              startIcon={'Logout'}
              data-testid="adminconfig-logout-button"
            />
          </Stack>
        </Stack>
      </AppBar>
      <PageLayout appBar fullWidth>
        <Outlet />
      </PageLayout>
    </>
  );
}
