import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { defer, json, LoaderFunctionArgs } from 'react-router-dom';
import {
  //FindNetworkByIdDocument,
  // FindNetworkByIdQueryResult,
  NetworkHardwareByIdDocument,
  NetworkHardwareByIdQueryResult,
  NetworkTopologyByIdDocument,
  NetworkTopologyByIdQueryResult,
} from '../../graphql/client/network';

export type NetworkTopologyLoaderData = {
  // findNetworkByIdQuery: FindNetworkByIdQueryResult;
  networkTopologyByIdQuery: NetworkTopologyByIdQueryResult;
  networkHardwareByIdQuery: NetworkHardwareByIdQueryResult;
};

export function networkTopologyLoader(client: ApolloClient<NormalizedCacheObject>) {
  return async ({ params }: LoaderFunctionArgs) => {
    // await client
    //   .query({
    //     query: FindNetworkByIdDocument,
    //     variables: { id: params.network_id },
    //   })
    //   .then((res) => {
    //     if (!res.data?.me?.networkSummary?.networks.length) {
    //       throw json(`No network was found for the id ${params.network_id}`, 404);
    //     }
    //   });

    return defer({
      networkTopologyByIdQuery: client.query({
        query: NetworkTopologyByIdDocument,
        variables: { id: params.network_id },
      }),
      //.catch((e) => console.error(e)),
      networkHardwareByIdQuery: client.query({
        query: NetworkHardwareByIdDocument,
        variables: { id: params.network_id },
      }),
      //.catch((e) => console.error(e)),
    });
  };
}
