import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useAppSelector } from 'store';
import { selectServiceProfileById } from 'store/selectors/serviceProfiles';
import { SlicesTable } from './slices/SlicesTable';
import { EditSliceDialog } from './slices/EditSliceDialog';
import { useParams } from 'react-router-dom';
import { NetworkPageParams } from 'Router';

interface SelectedServiceProfileProps {
  serviceProfileId: string;
}

export function SelectedServiceProfile({ serviceProfileId }: SelectedServiceProfileProps) {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const { network_id } = useParams<NetworkPageParams>();

  const serviceProfile = useAppSelector((state) => selectServiceProfileById(state, serviceProfileId));

  const handleOpenCreateSlice = useCallback(() => {
    if (!network_id) return;
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.slices.createSlice' }),
      content: () => <EditSliceDialog serviceProfileId={serviceProfileId} networkId={network_id} />,
    });
  }, [dialogOpen, formatMessage, serviceProfileId, network_id]);

  return (
    <Panel
      fullHeight
      folder
      title={formatMessage({ id: 'serviceProfiles.slices.table.title' }, { serviceProfile: serviceProfile?.name })}
      headerActionsComponent={
        <Button
          text={formatMessage({ id: 'serviceProfiles.slices.newSlice' })}
          variant="outlined"
          onClick={handleOpenCreateSlice}
        />
      }
      sx={{
        border: 'none',
      }}
    >
      <SlicesTable serviceProfileId={serviceProfileId} />
    </Panel>
  );
}
