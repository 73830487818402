import * as Types from './types';

import { gql } from '@apollo/client';
export type UserTrafficManagementAlertsFragmentFragment = { __typename?: 'UserTrafficManagementAlerts', error?: number | null, warning?: number | null };

export type AccessManagementAlertsFragmentFragment = { __typename?: 'AccessManagementAlerts', error?: number | null, warning?: number | null };

export type LocationFragmentFragment = { __typename?: 'Location', lat: string, long: string };

export type HardwareFragmentFragment = { __typename?: 'Hardware', id: string, name: string };

export type ThroughputFragmentFragment = { __typename?: 'Throughput', downlink?: number | null, uplink?: number | null, downlinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null, uplinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null };

export const UserTrafficManagementAlertsFragmentFragmentDoc = gql`
    fragment UserTrafficManagementAlertsFragment on UserTrafficManagementAlerts {
  error
  warning
}
    `;
export const AccessManagementAlertsFragmentFragmentDoc = gql`
    fragment AccessManagementAlertsFragment on AccessManagementAlerts {
  error
  warning
}
    `;
export const LocationFragmentFragmentDoc = gql`
    fragment LocationFragment on Location {
  lat
  long
}
    `;
export const HardwareFragmentFragmentDoc = gql`
    fragment HardwareFragment on Hardware {
  id
  name
}
    `;
export const ThroughputFragmentFragmentDoc = gql`
    fragment ThroughputFragment on Throughput {
  downlink
  uplink
  downlinkTimeseries {
    bytes
    datetime
  }
  uplinkTimeseries {
    bytes
    datetime
  }
}
    `;