import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SxProps, Theme, styled } from '@athonet/ui/theme';
import { Box } from '@athonet/ui/components/Surfaces/Box';

interface PwdStyledProps {
  rank: number;
  idx: number;
}

const getColor = (idx: number, level: number, theme: Theme) => {
  switch (level) {
    case 1:
      if (idx < 1) {
        return theme.palette.error.main;
      }
      break;

    case 2:
      if (idx < 2) {
        return theme.palette.warning.main;
      }
      break;

    case 3:
      if (idx < 3) {
        return theme.palette.success.main;
      }
      break;

    case 4:
      if (idx < 4) {
        return theme.palette.success.main;
      }
      break;

    default:
      return theme.palette.grey[300];
  }
  return theme.palette.grey[300];
};

const PwdIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'rank' && prop !== 'idx',
})<PwdStyledProps>(({ idx, rank, theme }) => ({
  margin: '0 2px',
  backgroundColor: getColor(idx, rank, theme),
  width: '25%',
}));

const PwdLabel = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'rank' && prop !== 'idx',
})<PwdStyledProps>(({ idx, rank, theme }) => ({
  textAlign: 'right',
  color: getColor(idx, rank, theme),
}));

interface PasswordStrengthBoxProps {
  password: string;
  sx?: SxProps<Theme>;
}

const PasswordStrengthBox: React.FC<PasswordStrengthBoxProps> = ({ password, sx }) => {
  const [pwdRank, setPwdRank] = useState(0);
  const { formatMessage } = useIntl();

  useEffect(() => {
    var rank = 0;
    // uppercase
    const upper = /(?=.*[A-Z].*[A-Z])/g;

    // special chars
    // eslint-disable-next-line no-useless-escape
    const spec = /(?=.*[!£$%)(\<\>\[\]@#$&*])/g;

    // digits
    const dig = /(?=.*[0-9].*[0-9])/g;

    // lenght > 8
    const leng = /.{8}/g;

    rank = password.match(upper) !== null ? rank + 1 : rank;
    rank = password.match(spec) !== null ? rank + 1 : rank;
    rank = password.match(dig) !== null ? rank + 1 : rank;
    rank = password.match(leng) !== null ? rank + 1 : rank;

    setPwdRank(rank);
  }, [password]);

  const label = useMemo(
    () => [
      formatMessage({ id: 'login.passwordStrenght.veryweak' }),
      formatMessage({ id: 'login.passwordStrenght.weak' }),
      formatMessage({ id: 'login.passwordStrenght.soso' }),
      formatMessage({ id: 'login.passwordStrenght.good' }),
      formatMessage({ id: 'login.passwordStrenght.great' }),
    ],
    [formatMessage]
  );

  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ width: '100%', height: '2px', display: 'flex', justifyContent: 'space-between' }}>
        <PwdIndicator idx={0} rank={pwdRank} />
        <PwdIndicator idx={1} rank={pwdRank} />
        <PwdIndicator idx={2} rank={pwdRank} />
        <PwdIndicator idx={3} rank={pwdRank} />
      </Box>
      {password ? (
        <PwdLabel idx={0} rank={pwdRank}>
          {label[pwdRank]}
        </PwdLabel>
      ) : (
        <>&nbsp;</>
      )}
    </Box>
  );
};

export default PasswordStrengthBox;
