import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import AccountDataPanel from 'components/UserProfile/AccountData';
import AuthenticationPanel from 'components/UserProfile/Authentication';
import LanguagePanel from 'components/UserProfile/Language';
import TenantsAndRolesPanel from 'components/UserProfile/TenantsAndRoles';

export default function Profile() {
  return (
    <GridContainer spacing={1}>
      <GridItem size={{ xs: 12 }}>
        <AccountDataPanel />
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <Stack spacing={1}>
          <AuthenticationPanel />
          <LanguagePanel />
        </Stack>
      </GridItem>
      <GridItem size={{ xs: 12, md: 6 }}>
        <TenantsAndRolesPanel />
      </GridItem>
    </GridContainer>
  );
}
