import { useIntl } from 'react-intl';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { useLoggableTenantsSelector } from 'store/selectors/user';
import { Fragment } from 'react';
import { BasePanel } from 'components/Panel/BasePanel';

export default function TenantsAndRolesPanel() {
  const { formatMessage } = useIntl();
  const tenants = useLoggableTenantsSelector();

  return (
    <BasePanel title={formatMessage({ id: 'profile.tenantsAndRoles' })}>
      <PanelContent>
        {tenants.map((item, i) => (
          <Fragment key={i}>
            <GridContainer sx={{ overflowX: 'auto' }}>
              {Object.entries(item).map(
                ([key, value]) =>
                  key !== 'status' && (
                    <KeyValueGridItem
                      sx={{}}
                      key={key}
                      label={formatMessage({ id: `profile.${key}` })}
                      value={Array.isArray(value) ? value.join(', ') : value}
                    />
                  )
              )}
            </GridContainer>
            {i < tenants.length - 1 && <Divider sx={{ my: 1.5 }} />}
          </Fragment>
        ))}
      </PanelContent>
    </BasePanel>
  );
}
