import React from 'react';
import { useIntl } from 'react-intl';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import ConfirmBox from 'components/ConfirmBox/ConfirmBox';

const ConfirmUserForm: React.FC<{ success?: boolean }> = ({ success }) => {
  const { formatMessage } = useIntl();

  return (
    <Box data-testid="confirmUserForm">
      <Panel>
        <ConfirmBox>
          <PanelContent>
            <Icon name={success ? 'Check-1' : 'Close'} color={success ? 'success' : 'error'} />
            {success ? (
              <div>
                <h1>{formatMessage({ id: 'users.confirm.success.title' })}</h1>
                <p>{formatMessage({ id: 'users.confirm.success.text' })}</p>
              </div>
            ) : (
              <div>
                <h1>{formatMessage({ id: 'users.confirm.failure.title' })}</h1>
                <p>{formatMessage({ id: 'users.confirm.failure.text' })}</p>
              </div>
            )}
          </PanelContent>
        </ConfirmBox>
      </Panel>
    </Box>
  );
};

export default ConfirmUserForm;
