import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { styled } from '@athonet/ui/theme';
import { selectEditingUserTenantsWithRoles, selectEditingUserTenantsWithRolesIds } from 'store/selectors/user';
import { List } from '@athonet/ui/components/Navigation/List';
import { ListItem } from '@athonet/ui/components/Navigation/List/ListItem';
import { useAppSelector } from 'store';

type ManageTenantsListProps = {
  onUnassignClick: (id: string) => void;
};

const StyledTenantsList = styled(List)(({ theme }) => ({
  height: '295px',
  overflowY: 'auto',
  border: '1px solid ' + theme.palette.grey[300],
  padding: 0,
}));

export default function ManageTenantsList({ onUnassignClick }: ManageTenantsListProps) {
  const { formatMessage } = useIntl();
  const tenants = useAppSelector((state) => selectEditingUserTenantsWithRoles(state));
  const tenantsIds = useAppSelector((state) => selectEditingUserTenantsWithRolesIds(state));

  const listItemActions = useMemo(
    () => [
      {
        label: formatMessage({ id: 'common.button.delete' }),
        onClick: (listItem: unknown, idx: number) => {
          onUnassignClick(tenantsIds[idx]);
        },
      },
    ],
    [formatMessage, onUnassignClick, tenantsIds]
  );

  return (
    <>
      <Box
        sx={{
          color: (theme) => theme.palette.secondary.main,
          fontSize: (theme) => theme.typography.caption,
        }}
      >
        {formatMessage(
          {
            id:
              tenants?.length === 1
                ? 'segments.form.qosProfile.manageTenants.total'
                : 'segments.form.qosProfile.manageTenants.total_plural',
          },
          { value: tenants?.length }
        )}
      </Box>
      <StyledTenantsList divided={true} listItemActions={listItemActions}>
        {tenants.map((item, index) => (
          <ListItem
            key={item.id}
            index={index}
            primaryText={item.name}
            secondaryText={item.roles ?? ''}
            selected={false}
          />
        ))}
      </StyledTenantsList>
    </>
  );
}
