import React from 'react';
import { Text, TextProps } from '@athonet/ui/components/Guidelines/Text';
import { Tooltip } from '@athonet/ui/components/Feedback/Tooltip';
import { SyncStatus, getSyncStatusColor } from './utils';
import { useIntl } from 'react-intl';

export interface RemoteSyncTextProps {
  syncStatus: SyncStatus;
  text?: string;
  tooltipText?: string;
}

const RemoteSyncText: React.FC<TextProps & RemoteSyncTextProps> = (props) => {
  const { text, tooltipText, syncStatus, ...textProps } = props;
  const { formatMessage } = useIntl();

  if (syncStatus === SyncStatus.None) return <Text {...textProps}>{text}</Text>;

  const content = (
    <Text {...textProps} sx={{ fontWeight: 'bolder' }} color={getSyncStatusColor(syncStatus)}>
      {text}
    </Text>
  );

  if (!tooltipText || syncStatus === SyncStatus.PendingCreation) return <>{content}</>;
  else
    return (
      <Tooltip title={`${formatMessage({ id: 'common.highlighting.oldValue' })}: ${tooltipText}`}>
        <div style={{ width: 'fit-content' }}>{content}</div>
      </Tooltip>
    );
};

export default RemoteSyncText;
