import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { Tenant, TENANT_TYPE } from 'store/models/tenant';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { getBootstrap } from './bootstrap';
//import { Node } from 'store/models/node';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { sentryLogError } from 'sentry';
import { GetTenantsRes } from './tenants/types';
import { getTenantsList } from './tenants/getTenantsList';
import { AppAxiosError, parseError } from 'utils/errorString';

export function getTenant(tenantId: string): Thunk<Promise<Tenant | null>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getTenant.replace('{id}', tenantId),
        method: 'GET',
      };
      const tenant = await dispatch(fetchData<Tenant>(options));
      return tenant;
    } catch (e) {
      dispatch(showErrorToast());
      sentryLogError(e);
      return null;
    }
  };
}

// export function getTenantsByNode(id: string): Thunk<Promise<Node['owners']>> {
//   return async (dispatch) => {
//     const options: AxiosRequestConfig = {
//       url: config.apis.getNodes
//         .replace('{sort}', '')
//         .replace('{limit}', `1`)
//         .replace('{page}', '0')
//         .replace('{filters}', `&id=${id}`)
//         .replace('{platform}', ''),
//       method: 'GET',
//     };

//     const nodes = await dispatch(
//       fetchData<{
//         items: Node[];
//       }>(options)
//     );
//     return nodes.items[0].owners;
//   };
// }

// export function assignTenantToNode(id: string, tenant_id: Tenant['id']): Thunk<Promise<void>> {
//   return async (dispatch) => {
//     const options: AxiosRequestConfig = {
//       url: config.apis.assignTenantNode.replace('{id}', id),
//       method: 'PUT',
//       data: {
//         tenant_id,
//       },
//     };
//     return dispatch(fetchData<void>(options));
//   };
// }

// export function deassignTenantToNode(id: string, tenant_id: Tenant['id']): Thunk<Promise<void>> {
//   return async (dispatch) => {
//     const options: AxiosRequestConfig = {
//       url: config.apis.deassignTenantNode.replace('{id}', id),
//       method: 'PUT',
//       data: {
//         tenant_id,
//       },
//     };
//     return dispatch(fetchData<void>(options));
//   };
// }

function fetchTenantsOptions(query?: string, onlyParents?: boolean): Thunk<Promise<Tenant[]>> {
  return async (dispatch) => {
    const api = onlyParents ? config.apis.getTenantParents : config.apis.getTenantWithSubtenants;

    const url = api
      .replace('{sort}', 'name')
      .replace('{limit}', '1000')
      .replace('{page}', '0')
      .replace('{filters}', query ? `?name=contains{{${query}}}` : '');

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };

    try {
      const res = await dispatch(fetchData<GetTenantsRes>(options));
      return res.tenants;
    } catch (e) {
      dispatch(showErrorToast());
      sentryLogError(e);
      return [];
    }
  };
}

export type TenantOption = { label: string; value: { id: string; type: string } };

export function getTenantsOptions(query?: string): Thunk<Promise<TenantOption[]>> {
  return async (dispatch) => {
    const tenants = await dispatch(fetchTenantsOptions(query));
    return tenants.map((tenant) => ({ label: tenant.name, value: { id: tenant.id, type: tenant.type } }));
  };
}

export function getParentTenants(query?: string): Thunk<Promise<Tenant[]>> {
  return async (dispatch) => {
    return dispatch(fetchTenantsOptions(query, true));
  };
}

export function getTenantsAutocompleteOptions(query?: string): Thunk<Promise<AutocompleteItemProps[]>> {
  return async (dispatch) => {
    const tenants = await dispatch(fetchTenantsOptions(query));
    return tenants.map((tenant) => ({ label: tenant.name, value: tenant.id }));
  };
}

export function updateTenantLogo(
  tenantId: string,
  file: Blob,
  encrypted?: string,
  key_id?: string | Blob
): Thunk<Promise<void>> {
  return async (dispatch) => {
    const uploadData = new FormData();
    uploadData.append('uploadfile', file);

    if (encrypted && key_id) uploadData.append('key_id', key_id);

    const options: AxiosRequestConfig = {
      url: config.apis.updateTenantLogo.replace('{id}', tenantId),
      method: 'PUT',
      transformRequest: (_, _1) => {
        return uploadData;
      },
      data: uploadData,
    };

    await dispatch(fetchData<void>(options));
    await dispatch(getBootstrap());
  };
}

export function deleteTenantLogo(tenantId: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteTenantLogo.replace('{id}', tenantId),
      method: 'DELETE',
    };
    await dispatch(fetchData<void>(options));
    await dispatch(getBootstrap());
  };
}

const getTenantErrorMessages = (error: unknown): Thunk<Promise<void>> => {
  return async (dispatch) => {
    const e = error as AppAxiosError;
    let errorString: string | undefined;

    switch (e.response?.data?.error) {
      case 'invalid tenant role':
        errorString = 'tenants.error.invalidRole';
        break;
      case 'user name already been taken':
        errorString = 'tenants.error.userTaken';
        break;
      case 'tenant name already been taken':
        errorString = 'tenants.error.tenantTaken';
        break;
      case 'cannot change tenant parent_id':
        errorString = 'tenants.error.cannotChangeParent';
        break;
      default:
        errorString = undefined;
        break;
    }

    void dispatch(
      showErrorToast(
        errorString
          ? {
              message: errorString,
              intlMessage: true,
            }
          : undefined
      )
    );
  };
};

export type CreateTenantPayload = Pick<Tenant, 'name' | 'address' | 'country' | 'type' | 'parent_id'>;

export function createTenant({ name, address, country, parent_id, type }: CreateTenantPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    let apiCall =
      type === TENANT_TYPE.CHANNEL_PARTNER
        ? config.apis.createChannelPartner
        : type === TENANT_TYPE.NETWORK_MANAGER
        ? config.apis.createNetworkManager
        : '';

    const options: AxiosRequestConfig = {
      url: apiCall,
      method: 'POST',
      data: { name, address, country, tenant_id: parent_id },
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList());
      dispatch(showSuccessToast());
    } catch (e) {
      getTenantErrorMessages(e);
    }
  };
}

export type EditTenantPayload = { values: CreateTenantPayload; tenantId: Tenant['id'] };

export function editTenant({
  values: { name, address, country, parent_id },
  tenantId,
}: EditTenantPayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.updateTenant.replace('{id}', tenantId),
      method: 'PATCH',
      data: { name, address, country, ...(parent_id && { parent_id }) },
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList());
      dispatch(showSuccessToast());
    } catch (e) {
      getTenantErrorMessages(e);
    }
  };
}

export function deleteTenant(tenantId: Tenant['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteTenants.replace('{id}', tenantId),
      method: 'DELETE',
    };

    try {
      await dispatch(fetchData<void>(options));
      await dispatch(getTenantsList());
      dispatch(showSuccessToast());
    } catch (e) {
      const error = e as AppAxiosError;
      if (error.response) {
        dispatch(
          showErrorToast({
            message: parseError(error),
            intlMessage: false,
          })
        );
      } else {
        dispatch(showErrorToast());
      }
    }
  };
}
