import { ApolloClient, ErrorPolicy, NormalizedCacheObject } from '@apollo/client';
import { defer } from 'react-router-dom';
import { NetworkSummaryDocument } from '../client/networkSummary';
import { NetworkSummaryQueryResult } from '../client/networkSummary';

export type NetworkSummaryData = {
  networkSummaryQuery: NetworkSummaryQueryResult;
};

export function networkSummaryLoader(client: ApolloClient<NormalizedCacheObject>) {
  return async () => {
    return defer({
      networkSummaryQuery: client.query({
        query: NetworkSummaryDocument,
      }),
    });
  };
}
