import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { Owner } from 'store/models/tenant';
import { fetchData } from '../fetchData';
import { AppAxiosError } from 'utils/errorString';

interface GetTenantsByUserRequest {
  userId: string;
}

export const getTenantsByUser = createAsyncThunk<
  Owner[],
  GetTenantsByUserRequest,
  {
    rejectValue: AppAxiosError;
    state: State;
  }
>('users/getTenants', async ({ userId }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getTenantsByUser.replace('{user_id}', userId)}`,
      method: 'GET',
    };

    const userTenants = await dispatch(fetchData<Owner[]>(options));
    return userTenants;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
