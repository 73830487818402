import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { defer, json, LoaderFunctionArgs } from 'react-router-dom';
import {
  //FindNetworkByIdDocument,
  NetworkAccessManagementByIdDocument,
  NetworkAccessManagementByIdQueryResult,
  NetworkOverviewByIdDocument,
  NetworkOverviewByIdQueryResult,
  NetworkRadiosByIdDocument,
  NetworkRadiosByIdQueryResult,
  NetworkThroughputByIdDocument,
  NetworkThroughputByIdQueryResult,
  NetworkUsersSummaryByIdDocument,
  NetworkUsersSummaryByIdQueryResult,
  NetworkUserTrafficManagementByIdDocument,
  NetworkUserTrafficManagementByIdQueryResult,
} from '../../graphql/client/network';

export type NetworkOverviewLoaderData = {
  networkOverviewIdQuery: NetworkOverviewByIdQueryResult;
  networkThroughputByIdQuery: NetworkThroughputByIdQueryResult;
  networkRadiosByIdQuery: NetworkRadiosByIdQueryResult;
  networkAccessManagementByIdQuery: NetworkAccessManagementByIdQueryResult;
  networkUserTrafficManagementByIdQuery: NetworkUserTrafficManagementByIdQueryResult;
  networkUsersSummaryByIdQuery: NetworkUsersSummaryByIdQueryResult;
};

export function networkOverviewLoader(client: ApolloClient<NormalizedCacheObject>) {
  return async ({ params }: LoaderFunctionArgs) => {
    // await client
    //   .query({
    //     query: FindNetworkByIdDocument,
    //     variables: { id: params.network_id },
    //   })
    //   .then((res) => {
    //     if (!res.data?.me?.networkSummary?.networks.length) {
    //       throw json(`No network was found for the id ${params.network_id}`, 404);
    //     }
    //   });

    return defer({
      networkOverviewIdQuery: client.query({
        query: NetworkOverviewByIdDocument,
        variables: { id: params.network_id },
      }),
      networkThroughputByIdQuery: client.query({
        query: NetworkThroughputByIdDocument,
        variables: { id: params.network_id },
      }),
      networkRadiosByIdQuery: client.query({
        query: NetworkRadiosByIdDocument,
        variables: { id: params.network_id },
      }),
      networkAccessManagementByIdQuery: client.query({
        query: NetworkAccessManagementByIdDocument,
        variables: { id: params.network_id },
      }),
      networkUserTrafficManagementByIdQuery: client.query({
        query: NetworkUserTrafficManagementByIdDocument,
        variables: { id: params.network_id },
      }),
      networkUsersSummaryByIdQuery: client.query({
        query: NetworkUsersSummaryByIdDocument,
        variables: { id: params.network_id },
      }),
    });
  };
}
