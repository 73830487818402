import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Button } from '@athonet/ui/components/Input/Button';
import { Accordion } from '@athonet/ui/components/Surfaces/Accordion';
import { AccordionGroup } from '@athonet/ui/components/Surfaces/AccordionGroup';
import { useCallback, useEffect, useMemo } from 'react';
import { AdminConfigItem } from 'store/models/adminConfig';
import { isEntityLoading } from 'store/reducers';
import { useAdminConfigCategoriesSelector, useAdminConfigDataSelector } from 'store/selectors/adminConfig';
import AdminConfigForm from './AdminConfigForm';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { ChipsArray } from '@athonet/ui/components/Data/ChipsArray';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Tooltip } from '@athonet/ui/components/Feedback/Tooltip';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { getAdminConfigData } from 'store/actions/adminconfig/adminConfig';
import { useAppDispatch } from 'store';

export default function AdminConfigView() {
  const adminConfigCategory = useAdminConfigCategoriesSelector();
  const adminConfigData = useAdminConfigDataSelector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getAdminConfigData());
  }, [dispatch]);

  const { drawerOpen } = useOverlay();
  const { formatMessage } = useIntl();

  const filterAdminConfigDetail = useCallback((data: AdminConfigItem[] | undefined, row: AdminConfigItem) => {
    return (
      data?.filter((item: { category: string }) => {
        return item.category.toLowerCase() === row.category.toLowerCase();
      }) || []
    );
  }, []);

  const adminConfigColumns = useMemo((): TableColumn<
    AdminConfigItem & {
      tools?: boolean;
    }
  >[] => {
    return [
      {
        key: 'category',
        label: formatMessage({ id: 'adminConfig.table.category' }),
        wordBreak: 'break-all',
        width: '140',
        minWidth: '140',
        maxWidth: '140',
      },
      {
        key: 'sub_category',
        label: formatMessage({ id: 'adminConfig.table.subCategory' }),
        wordBreak: 'break-all',
        width: '100',
        minWidth: '100',
        maxWidth: '100',
      },
      {
        key: 'key',
        label: formatMessage({ id: 'adminConfig.table.key' }),
        width: '140',
        minWidth: '140',
        maxWidth: '140',
        cellRender: (row: AdminConfigItem) => {
          return (
            <>
              <Text type="body2">{row.key}</Text>
              <Text type="body2" color="text.secondary">
                {row.description}
              </Text>
            </>
          );
        },
      },
      {
        key: 'access_level',
        label: formatMessage({ id: 'adminConfig.table.accessLevel' }),
        width: '100',
        minWidth: '100',
        maxWidth: '100',
        cellRender: (row: AdminConfigItem) => {
          if (row.access_level) {
            return <Chip id={row.access_level} size="small" label={row.access_level} />;
          }
          return <>{row.access_level}</>;
        },
      },
      {
        key: 'requires_reboot',
        label: formatMessage({ id: 'adminConfig.table.requiresReboot' }),
        width: '100',
        minWidth: '100',
        maxWidth: '100',
      },
      {
        key: 'value',
        label: formatMessage({ id: 'adminConfig.table.value' }),
        width: '140',
        minWidth: '140',
        maxWidth: '140',
        cellRender: (row: AdminConfigItem) => {
          if (row.type_value === 'array' && typeof row.value === 'object') {
            return (
              <ChipsArray
                items={row.value.map((s) => ({
                  id: s.toString(),
                  label: s.toString(),
                }))}
              />
            );
          }
          if (row.value && row.value.toString().length > 30 && typeof row.value === 'string') {
            return (
              <Tooltip title={row.value}>
                <div>
                  <Chip id={row.value} size="small" label={row.value} />
                </div>
              </Tooltip>
            );
          }
          return <>{row.value.toString()}</>;
        },
      },
      {
        key: 'tools',
        label: '',
        numeric: true,
        width: '50',
        minWidth: '50',
        maxWidth: '50',
        cellRender: (row: AdminConfigItem) => (
          <Button
            startIcon="Pencil"
            variant="outlined"
            size="small"
            onClick={() =>
              drawerOpen({
                content: () => <AdminConfigForm adminConfig={row} />,
                title: formatMessage({ id: 'adminConfig.form.title' }),
              })
            }
          />
        ),
      },
    ];
  }, [drawerOpen, formatMessage]);

  const adminCategoryDetail = useCallback(
    (rowAdminConfig: AdminConfigItem) => {
      const filterAdminConfigData = filterAdminConfigDetail(adminConfigData.data?.items, rowAdminConfig).sort(
        (a: AdminConfigItem, b: AdminConfigItem) => {
          return a.id.localeCompare(b.id);
        }
      );
      return (
        <Table
          data={filterAdminConfigData}
          columns={adminConfigColumns}
          rowsCount={filterAdminConfigData.length}
          rowsPerPage={filterAdminConfigData.length}
          orderBy={'category'}
          rowKey={'id'}
          page={0}
          loading={isEntityLoading(adminConfigData)}
          data-testid="adminconfig-rowdetail-table"
          hidePagination={true}
        />
      );
    },
    [adminConfigColumns, adminConfigData, filterAdminConfigDetail]
  );

  return (
    <AccordionGroup exclusive={false} spacing={2}>
      {adminConfigCategory?.map((row, i) => {
        return <Accordion title={row.category} content={adminCategoryDetail(row)} key={i} />;
      })}
    </AccordionGroup>
  );
}
