import { useState } from 'react';
import { loginUser, authUser } from 'store/actions/login';
import config from 'config';
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import LoginForm, { LoginFormDataType } from 'components/LoginForm';
import SendPasswordForm, { SendPasswordFormDataType } from 'components/SendPasswordForm';
import TenantsListForm from 'components/TenantsListForm';
import ChangePasswordForm, { ChangePasswordDataType } from 'components/ChangePasswordForm';
import { lsSet } from 'utils/localStorage';
import { showErrorToast, showSuccessToast, showWarningToast } from 'store/actions/toast';
import { getBootstrap } from 'store/actions/bootstrap';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useNavigate } from 'react-router-dom';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import TabPanel from 'components/TabPanel/TabPanel';
import { FormikHelpers } from 'formik';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Owner } from 'store/models/tenant';
import { useAppDispatch } from 'store';

export interface LoginTenantResponse {
  tenants: Owner[];
}

const Login = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loginFormData, setLoginFormData] = useState<LoginFormDataType>({
    user: '',
    password: '',
    auth_provider: AUTH_PROVIDER.ENTERPRISE,
  });
  const [tenantsList, setTenantsList] = useState<Owner[]>([]);
  const [tokens, setTokens] = useState<{ token?: string }>({});
  const dispatch = useAppDispatch();
  const bootstrap = useBootstrapSelector();
  const navigate = useNavigate();

  const manageTenants = (
    result: LoginTenantResponse,
    formData: LoginFormDataType,
    formikHelpers: FormikHelpers<LoginFormDataType>
  ) => {
    setLoginFormData(formData);
    if (result.tenants?.length > 1) {
      // choose tenants
      // switch to tenants list form
      setTenantsList(result.tenants);
      setTabIndex(2); // 2: tenants list
    } else if (result.tenants?.length === 1) {
      // if 1 tenant only -> auto login
      if (result.tenants[0]?.id) {
        loginSubmit(formData, result.tenants[0].id, formikHelpers);
      } else {
        // wrong ID in tenant
        dispatch(
          showWarningToast({
            message: 'login.noTenant',
            intlMessage: true,
          })
        );
        formikHelpers.resetForm();
        void formikHelpers.setFieldValue('auth_provider', formData.auth_provider);
      }
    } else {
      // no tenants associated -> can't login
      dispatch(
        showWarningToast({
          message: 'login.noTenant',
          intlMessage: true,
        })
      );
      formikHelpers.resetForm();
      void formikHelpers.setFieldValue('auth_provider', formData.auth_provider);
    }
  };

  const confirmTenant = (tenantId: string) => {
    loginSubmit(loginFormData, tenantId);
  };

  const loginSubmit = (
    values: LoginFormDataType,
    tenantId: string,
    formikHelpers?: FormikHelpers<LoginFormDataType>
  ) => {
    if (!values.auth_provider) return;

    const options: AxiosRequestConfig = {
      url: `${config.apis.login}`,
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      },
      data: { ...values, tenant_id: tenantId },
    };

    axios(options)
      .then((result) => {
        // call API user/me -> then goto landing
        if (result.data.change_password) {
          // Temporary password -> insert a new password
          setTokens(result.data);
          setTabIndex(3); // reset password if temporary
        } else {
          getUserData(result.data, async () => {
            await dispatch(getBootstrap());
            // NOTE: get bootstrap here is responsible of fetching the new bootstrap data after a login
            navigate('/home');
          });
        }
      })
      .catch(() => {
        // error message
        if (formikHelpers) {
          formikHelpers.resetForm();
          void formikHelpers.setFieldValue('auth_provider', values.auth_provider);
        }
        dispatch(
          showErrorToast({
            message: 'login.errorMessage',
            intlMessage: true,
          })
        );
      });
  };

  const sendResetSubmit = (
    values: SendPasswordFormDataType,
    formikHelpers: FormikHelpers<SendPasswordFormDataType>
  ) => {
    const data = new FormData();
    data.append('user', values.email);

    const options: AxiosRequestConfig = {
      url: `${config.apis.sendReset}`,
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      },
      data: data,
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.emailSent',
            intlMessage: true,
          })
        );
        // back to login form
        setTabIndex(0);
      })
      .catch((e) => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.errorMessage',
            intlMessage: true,
          })
        );
        // reset form
        formikHelpers.resetForm();
      });
  };

  const getUserData = (authTokens: any, callback: any) => {
    let headersToken: RawAxiosRequestHeaders = Object.assign(
      {},
      {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      }
    );
    headersToken['horus-token'] = authTokens.token;

    const options: AxiosRequestConfig = {
      url: `${config.apis.me}`,
      method: 'GET',
      headers: headersToken,
    };

    axios(options)
      .then((result) => {
        // set tokens and user data to redux
        dispatch(authUser({ accessToken: authTokens.token, refreshToken: authTokens.refresh_token }));
        dispatch(loginUser(result.data));
        // set localstorage as Redux
        let lsdata = Object.assign({}, result.data);
        lsdata['accessToken'] = authTokens.token;
        lsdata['refreshToken'] = authTokens.refresh_token;
        lsSet(lsdata);

        // callback
        callback();
      })
      .catch(() => {
        // error message
        dispatch(showErrorToast());
      });
  };

  const changePasswordSubmit = (
    values: ChangePasswordDataType,
    formikHelpers: FormikHelpers<ChangePasswordDataType>
  ) => {
    let headersToken: RawAxiosRequestHeaders = Object.assign(
      {},
      {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      }
    );
    headersToken['horus-token'] = tokens?.token!;

    const options: AxiosRequestConfig = {
      url: `${config.apis.changePassword}`,
      method: 'POST',
      headers: headersToken,
      data: { oldpassword: loginFormData?.password, newpassword: values.password },
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.passwordChanged',
            intlMessage: true,
          })
        );
        // back to login
        setTabIndex(0);
      })
      .catch(() => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.resetError',
            intlMessage: true,
          })
        );
        // reset form
        formikHelpers.resetForm();
      });
  };

  if (!bootstrap) {
    return null;
  }

  return (
    <div data-testid="login">
      <Stack fullWidth align="center">
        <Box sx={{ width: '280px', mb: 2 }}>
          <Logo imageUrl={bootstrap.logo} width={280} />
        </Box>
      </Stack>
      <TabPanel value={tabIndex} index={0}>
        <LoginForm onSubmit={manageTenants} onForgot={() => setTabIndex(1)} />
      </TabPanel>
      {bootstrap.forgot_password && (
        <TabPanel value={tabIndex} index={1}>
          <SendPasswordForm onSubmit={sendResetSubmit} onBack={() => setTabIndex(0)} />
        </TabPanel>
      )}
      <TabPanel value={tabIndex} index={2}>
        <TenantsListForm onSubmit={confirmTenant} onBack={() => setTabIndex(0)} tenantsList={tenantsList} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ChangePasswordForm onSubmit={changePasswordSubmit} onBack={() => setTabIndex(0)} />
      </TabPanel>
    </div>
  );
};

export default Login;
