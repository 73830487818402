import { Form, FormikHelpers } from 'formik';
import { object, string, ref } from 'yup';
import { useIntl } from 'react-intl';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Button } from '@athonet/ui/components/Input/Button';
import { Link } from '@athonet/ui/components/Navigation/Link';
import PasswordStrengthBox from 'components/Form/PasswordStrengthBox';
import { useMemo } from 'react';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFormik from 'components/Form/BaseFormik';

export interface ChangePasswordDataType {
  password: string;
  passwordConfirm: string;
}

type ChangePasswordFormProps = {
  onSubmit: (values: ChangePasswordDataType, formikHelpers: FormikHelpers<ChangePasswordDataType>) => void;
  onBack: () => void;
};

export default function ChangePasswordForm({ onSubmit, onBack }: ChangePasswordFormProps) {
  const { formatMessage } = useIntl();

  const schema = useMemo(
    () =>
      object().shape({
        password: string()
          .min(8)
          .required(formatMessage({ id: 'login.passwordError' }))
          .label(formatMessage({ id: 'login.newPasswordLabel' })),
        passwordConfirm: string()
          .oneOf([ref('password'), undefined], formatMessage({ id: 'login.change.matchPasswordError' }))
          .required(formatMessage({ id: 'login.passwordError' }))
          .label(formatMessage({ id: 'changePassword.confirmpasswordLabel' })), //login.change.passwordLabel
      }),
    [formatMessage]
  );

  const initialValues = useMemo(
    () => ({
      password: '',
      passwordConfirm: '',
    }),
    []
  );

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.change.title' })}
      description={formatMessage({ id: 'login.change.subtitle' })}
      data-testid="changePasswordForm"
    >
      <BaseFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ isSubmitting, values }) => (
          <>
            <Form noValidate autoComplete="off">
              <Stack fullWidth>
                <TextFieldField
                  size="medium"
                  name="password"
                  fullWidth
                  type="password"
                  showPasswordVisibility
                  placeholder={formatMessage({ id: 'changePassword.newpasswordPlaceholder' })} //login.passwordPlaceholder
                  helperText={formatMessage({ id: 'changePassword.rules' })} //login.change.rules
                />

                <TextFieldField
                  size="medium"
                  name="passwordConfirm"
                  fullWidth
                  type="password"
                  showPasswordVisibility
                  placeholder={formatMessage({ id: 'changePassword.confirmpasswordPlaceholder' })} //login.passwordPlaceholder
                />

                <PasswordStrengthBox password={values['password']} sx={{ pt: 2 }} />

                <Button
                  size="large"
                  data-testid="changePasswordForm-submitButton"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={formatMessage({ id: 'login.change.submitButton' })}
                />

                <Link data-testid="changePasswordForm-backLink" onClick={onBack}>
                  <Text align="center">{formatMessage({ id: 'login.change.backButton' })}</Text>
                </Link>
              </Stack>
            </Form>
          </>
        )}
      </BaseFormik>
    </AuthPanel>
  );
}
