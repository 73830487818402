import { Form, FormikHelpers } from 'formik';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Link } from '@athonet/ui/components/Navigation/Link';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFormik from 'components/Form/BaseFormik';
import { useMemo } from 'react';

export interface SendPasswordFormDataType {
  email: string;
}

export type SendPasswordFormProps = {
  onSubmit: (values: SendPasswordFormDataType, formikHelpers: FormikHelpers<SendPasswordFormDataType>) => void;
  onBack: () => void;
};

const SendPasswordForm = ({ onSubmit, onBack }: SendPasswordFormProps) => {
  const { formatMessage } = useIntl();

  const schema = useMemo(
    () =>
      object().shape({
        email: string()
          .email()
          .required(formatMessage({ id: 'login.emailError' }))
          .label(formatMessage({ id: 'login.enterpriseLabel' })),
      }),
    [formatMessage]
  );

  const initialValues = useMemo(() => ({ email: '' }), []);

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.reset.title' })}
      description={formatMessage({ id: 'login.reset.subtitle' })}
      data-testid="sendPasswordForm"
    >
      <BaseFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <Stack fullWidth spacing={2} sx={{ mt: 4 }}>
              <TextFieldField
                size="medium"
                name="email"
                fullWidth
                placeholder={formatMessage({ id: 'login.enterpriseUserPlaceholder' })}
              />

              <Button
                color="secondary"
                size="large"
                data-testid="sendPasswordForm-submitButton"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                text={formatMessage({ id: 'login.reset.submitButton' })}
              />

              <Link data-testid="sendPasswordForm-backLink" onClick={onBack}>
                <Text align="center">{formatMessage({ id: 'login.reset.backButton' })}</Text>
              </Link>
            </Stack>
          </Form>
        )}
      </BaseFormik>
    </AuthPanel>
  );
};

export default SendPasswordForm;
