import React, { useCallback, useEffect, useMemo } from 'react';
import FormikDialog from 'components/Form/FormikDialog';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { object, string } from 'yup';
import { useAppDispatch, useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import SelectField from 'components/Form/Field/SelectField';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import ManageTenantsList from './ManageTenantsList';
import { batch } from 'react-redux';
import { getTenantsByUser } from 'store/actions/users/getTenantsByUser';
import { selectAvailableEditingUserTenants } from 'store/selectors/user';
import { addTenantToUser } from 'store/actions/users/addUserTenant';
import { deleteUserTenant } from 'store/actions/users/deleteUserTenant';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AssignUserTenantDataType } from '../types';
import { FormikHelpers } from 'formik';
import { resetTenantsByUser } from 'store/reducers/users';
import { AppAxiosError } from 'utils/errorString';

interface ManageTenantsDialogProps {
  userId: string;
  onClose: () => void;
}

const ManageTenantsDialog: React.FC<ManageTenantsDialogProps> = ({ userId, onClose }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const availableTenants = useAppSelector((state) => selectAvailableEditingUserTenants(state));
  const roles = useAppSelector((state) => state.roles.list);

  useEffect(() => {
    batch(() => {
      void dispatch(getTenantsByUser({ userId }));
    });
  }, [dispatch, userId]);

  const parseErrorMessage = useCallback(
    (error?: AppAxiosError): string | undefined => {
      const errorCode = error?.response?.data?.error;
      switch (errorCode) {
        case 'invalid role':
          return formatMessage({ id: 'users.form.tenants.error.wrongrole' });
        case 'field_role_not_nullable':
          return formatMessage({ id: 'users.form.tenants.error.emptyrole' });
        case 'user already in tenant':
          return formatMessage({ id: 'users.form.tenants.error.conflict' });
        case 'node already associated':
          return formatMessage({ id: 'nodes.form.tenants.error.conflict' });
        case 'cannot deassociate user':
          return formatMessage({ id: 'users.form.tenants.error.deassociate' });
        case 'cannot deassociate user from tenant':
          return formatMessage({ id: 'users.form.tenants.error.deassociate' });
        case 'cannot deassociate admin user':
          return formatMessage({ id: 'users.form.tenants.admin.error.deassociate' });
        default:
          return 'Unknown Error';
      }
    },
    [formatMessage]
  );

  const handleOnSubmit = useCallback(
    async (
      { tenantId, roleName }: AssignUserTenantDataType,
      formikHelpers: FormikHelpers<AssignUserTenantDataType>
    ) => {
      const res = await dispatch(addTenantToUser({ userId, tenantId, roleName }));

      if (addTenantToUser.fulfilled.match(res)) {
        batch(() => {
          dispatch(showSuccessToast());
          void dispatch(getTenantsByUser({ userId }));
        });
        formikHelpers.resetForm();
      } else if (addTenantToUser.rejected.match(res)) {
        const message = parseErrorMessage(res.payload as AppAxiosError);
        dispatch(showErrorToast(message ? { message } : undefined));
      }
    },
    [dispatch, parseErrorMessage, userId]
  );

  const handleUnassignTenant = useCallback(
    async (tenantId: string) => {
      const res = await dispatch(deleteUserTenant({ userId, tenantId }));
      if (deleteUserTenant.fulfilled.match(res)) {
        batch(() => {
          dispatch(showSuccessToast());
          void dispatch(getTenantsByUser({ userId }));
        });
      } else if (deleteUserTenant.rejected.match(res)) {
        const message = parseErrorMessage(res.payload as AppAxiosError);
        dispatch(showErrorToast(message ? { message } : undefined));
      }
    },
    [dispatch, parseErrorMessage, userId]
  );

  const initialValues = useMemo(
    () => ({
      tenantId: '',
      roleName: '',
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        tenantId: string()
          .required()
          .label(formatMessage({ id: 'users.form.tenant.label' })),
        roleName: string()
          .required()
          .label(formatMessage({ id: 'users.form.roles.label' })),
      }),
    [formatMessage]
  );

  const handleOnClose = useCallback(() => {
    dispatch(resetTenantsByUser());
    onClose();
  }, [dispatch, onClose]);

  return (
    <FormikDialog
      onClosed={handleOnClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      cancelLabel={formatMessage({ id: 'common.form.close' })}
      submitLabel={formatMessage({ id: 'common.button.add' })}
      submitWithNoChanges={false}
    >
      <>
        <ManageTenantsList
          onUnassignClick={(tenantId: string) => {
            void handleUnassignTenant(tenantId);
          }}
        />

        <BaseFieldset sx={{ mt: 4 }} label={formatMessage({ id: 'users.form.addTenants' })}>
          <SelectField
            disabled={availableTenants.length === 0}
            name="tenantId"
            placeholder={formatMessage({ id: 'users.form.tenant.placeholder' })}
          >
            {availableTenants?.map(({ id, name }) => (
              <MenuItem value={`${id}`} key={`${id}`}>
                {name}
              </MenuItem>
            ))}
          </SelectField>

          <SelectField
            disabled={availableTenants.length === 0}
            name="roleName"
            placeholder={formatMessage({ id: 'users.form.roles.placeholder' })}
          >
            {roles?.map(({ id, name }) => (
              <MenuItem value={`${name}`} key={`${id}`}>
                {name}
              </MenuItem>
            ))}
          </SelectField>
        </BaseFieldset>
      </>
    </FormikDialog>
  );
};

export default ManageTenantsDialog;
