import { FiltersObj } from 'store/models/filters';
import { Tenant } from 'store/models/tenant';
import { DATA_LIFECYCLE, List, listIdle } from '.';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getTenantsWithSubtenants } from 'store/actions/tenants/getTenantsWithSubtenants';
import { getTenantsList } from 'store/actions/tenants/getTenantsList';

export type TenantsState = {
  filters: FiltersObj;
  sort: string;
  list: List<Tenant>;
  tenantsWithSubTenants: List<Tenant>;
};

const initialState: TenantsState = {
  filters: {},
  sort: '',
  list: listIdle<Tenant>(),
  tenantsWithSubTenants: listIdle<Tenant>(),
};

const tenantsSlice = createSlice({
  name: 'tenantsSlice',
  initialState,
  reducers: {
    clearTenants: (state: Draft<TenantsState>) => {
      state.list = listIdle();
    },
    setTenantsFilters: (state: Draft<TenantsState>, action: PayloadAction<FiltersObj>) => {
      state.filters = action.payload;
    },
    setTenantsSort: (state: Draft<TenantsState>, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    setTenantsPageNumber: (state: Draft<TenantsState>, action: PayloadAction<number>) => {
      state.list.data.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenantsList.pending, (state) => {
      state.list.state = DATA_LIFECYCLE.LOADING;
    });
    builder.addCase(getTenantsList.fulfilled, (state, action) => {
      state.list.data.data = action.payload.tenants;
      state.list.data.total = action.payload.total_items;
      state.list.state = DATA_LIFECYCLE.SUCCESS;
    });
    builder.addCase(getTenantsList.rejected, (state) => {
      state.list.state = DATA_LIFECYCLE.FAILURE;
    });

    builder.addCase(getTenantsWithSubtenants.pending, (state) => {
      state.tenantsWithSubTenants.state = DATA_LIFECYCLE.LOADING;
    });
    builder.addCase(getTenantsWithSubtenants.fulfilled, (state, action) => {
      state.tenantsWithSubTenants.data.data = action.payload.tenants;
      state.tenantsWithSubTenants.data.total = action.payload.total_items;
      state.tenantsWithSubTenants.state = DATA_LIFECYCLE.SUCCESS;
    });
    builder.addCase(getTenantsWithSubtenants.rejected, (state) => {
      state.tenantsWithSubTenants.state = DATA_LIFECYCLE.FAILURE;
    });
  },
});

export const { setTenantsPageNumber, clearTenants, setTenantsFilters, setTenantsSort } = tenantsSlice.actions;

const tenantsReducer = tenantsSlice.reducer;
export default tenantsReducer;
