import { CreateUsimCard5g } from 'components/Edit/5gProvisioning/UsimCards/Create';
import List, { ColumnShape, SelectedData } from 'components/List';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import {
  useCanUserBulkDeleteUsims5gSelector,
  useCanUserCreateUsimSelector,
  useCanUserUpdateUsimSelector,
  useUserSelector,
} from 'store/selectors/user';
import { checkPermissionsList, T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import { USIM5G_STATUS, Usim5g } from 'store/models/usim5g';
import {
  deleteUsim5g,
  deprovisionUsim5g,
  getUsims5g,
  provisionUsim5g,
  setUsim5gShouldFlushSelection,
} from 'store/actions/usims5g';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getUsimIdsWithPendingModifications,
  selectShouldFlushUsims5gSelection,
  selectUsimList,
} from 'store/selectors/5gProvisioning/usimCards';
import DropdownTools, { DropdownToolsOption } from 'components/DropdownTools';
import { EditUsimCard5gDialog } from 'components/Edit/5gProvisioning/UsimCards/Edit';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import UsimStatus from 'components/UsimStatus';
import { ListToolbarAction } from 'components/ListToolBar';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useNavigate } from 'react-router-dom';
import EditUsimsNodeProfileDialog from 'components/Edit/5gProvisioning/UsimCards/EditNetworkProfile/EditUsimsNetworkProfileDialog';
import EditUsimNodeProfileDialog from 'components/Edit/5gProvisioning/UsimCards/EditNetworkProfile/EditUsimNetworkProfileDialog';
import { ConfirmationDialogStateProps } from '@athonet/ui/store/atoms/overlay/confirmationDialog';
import MetadataEditorDialog from 'components/Edit/5gProvisioning/UsimCards/MetadataEditorDialog';
import UsimSupiLink from './UsimSupiLink';
import UsimPlainText from './UsimPlainText';
import { SxProps, Theme } from '@athonet/ui/theme';
import { SyncColors } from 'components/RemoteSync/utils';
import RemoteSyncLegend from 'components/RemoteSync/RemoteSyncLegend';
import { batch } from 'react-redux';

export default function UsimCards5g() {
  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const usimsList = useAppSelector((state) => selectUsimList(state));
  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const canUserCreateUsim = useCanUserCreateUsimSelector();
  const shouldFlushUsims5gSelector = useAppSelector((state) => selectShouldFlushUsims5gSelection(state));
  const canUserDeleteUsim = useCanUserBulkDeleteUsims5gSelector();
  const canUserUpdateUsim = useCanUserUpdateUsimSelector();
  const navigate = useNavigate();
  const pendingModificationIds: string[] = useAppSelector((state) => getUsimIdsWithPendingModifications(state));

  useEffect(() => {
    void dispatch(getUsims5g({}));
  }, [bootstrap?.pageLimit, dispatch]);

  const handleSortChange = useCallback(
    (orderQuery: string) => {
      void dispatch(getUsims5g({ sortBy: orderQuery }));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    void dispatch(getUsims5g({}));
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page: number) => {
      void dispatch(getUsims5g({ page }));
    },
    [dispatch]
  );

  const openEdit = useCallback(
    (rowData: Usim5g) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.edit.title' }),
        content: () => <EditUsimCard5gDialog usim5g={rowData} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  //const openUsimOperationNotPossible = useCallback(
  //  ({ operation, message }) => {
  //    dialogOpen({
  //      content: () => (
  //        <DialogContent>
  //          <Alert message={message} severity="warning" title={operation} />
  //          <Stack spacing={2} fullWidth sx={{ pt: 2 }} align="flex-end">
  //            <Button text={formatMessage({ id: 'common.form.close' })} onClick={dialogClose} />
  //          </Stack>
  //        </DialogContent>
  //      ),
  //      title: formatMessage({ id: 'common.operationNotPossible' }),
  //    });
  //  },
  //  [dialogClose, dialogOpen, formatMessage]
  //);

  const openActionPrepareForProvisioning = useCallback(
    ({ selectedRows, filters }: SelectedData<Usim5g>) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.prepare.title' }),
        content: () =>
          selectedRows?.length === 1 ? (
            <EditUsimNodeProfileDialog usim5g={selectedRows[0]} />
          ) : (
            <EditUsimsNodeProfileDialog filters={filters} selectedRows={selectedRows} />
          ),
      });
    },
    [dialogOpen, formatMessage]
  );

  const openPrepareForProvisioning = useCallback(
    (usim5g: Usim5g) => {
      dialogOpen({
        title: formatMessage({ id: 'usims.actions.prepare.title' }),
        content: () => <EditUsimNodeProfileDialog usim5g={usim5g} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const mapSelectedData = useCallback(
    (selectedData?: SelectedData<Usim5g>) => {
      let usimIds: Usim5g['id'][] = [];
      let usimSupi;
      let elementsCount = usimsList.data.total;

      if (selectedData?.selectedRows) {
        usimIds = selectedData.selectedRows.map((usim5g) => usim5g.id);
        elementsCount = selectedData.selectedRows.length;
        if (usimIds.length === 1) usimSupi = selectedData.selectedRows[0].supi;
      }

      return { usimIds, usimSupi, elementsCount };
    },
    [usimsList.data.total]
  );

  interface ConfirmDialogParams {
    operation: string;
    onConfirm: () => void;
    usimSupi?: string;
    elementsCount?: number;
    severity?: ConfirmationDialogStateProps['severity'];
  }

  const openUsimOperationConfirmDialog = useCallback(
    ({ operation, onConfirm, severity = 'warning', elementsCount = 1, usimSupi }: ConfirmDialogParams) => {
      confirmationDialogOpen({
        description: formatMessage({ id: `usims.actions.${operation}.confirm.content` }),
        onConfirm: async () => {
          void onConfirm();
        },
        severity,
        alertMessage: formatMessage(
          { id: `usims.actions.confirm.itemsAffected` },
          { elements: elementsCount, usim: usimSupi }
        ),
        title: formatMessage({ id: `usims.actions.${operation}.confirm.title` }, { elements: elementsCount }),
        continueButtonText: formatMessage(
          { id: `usims.actions.${operation}.confirm.continueButton` },
          { elements: elementsCount }
        ),
        dataTestid: `confirm-${operation}-usim${elementsCount > 1 ? '-bulk' : ''}`,
      });
    },
    [confirmationDialogOpen, formatMessage]
  );

  const openActionProvision = useCallback(
    (selectedData: SelectedData<Usim5g>) => {
      const { usimSupi, usimIds, elementsCount } = mapSelectedData(selectedData);
      openUsimOperationConfirmDialog({
        operation: 'provision',
        elementsCount,
        usimSupi,
        onConfirm: () => {
          void dispatch(provisionUsim5g({ usimIds, filters: selectedData.filters }));
        },
      });
    },
    [dispatch, mapSelectedData, openUsimOperationConfirmDialog]
  );

  const openProvision = useCallback(
    (rowData: Usim5g) => {
      openUsimOperationConfirmDialog({
        operation: 'provision',
        usimSupi: rowData.supi,
        onConfirm: () => {
          void dispatch(provisionUsim5g({ usimIds: [rowData.id] }));
        },
      });
    },
    [dispatch, openUsimOperationConfirmDialog]
  );

  const openActionDeprovision = useCallback(
    (selectedData: SelectedData<Usim5g>) => {
      const { usimSupi, usimIds, elementsCount } = mapSelectedData(selectedData);
      openUsimOperationConfirmDialog({
        operation: 'deprovision',
        elementsCount,
        usimSupi,
        onConfirm: () => {
          void dispatch(deprovisionUsim5g({ usimIds, filters: selectedData.filters }));
        },
      });
    },
    [dispatch, mapSelectedData, openUsimOperationConfirmDialog]
  );

  const openDeprovision = useCallback(
    (rowData: Usim5g) => {
      openUsimOperationConfirmDialog({
        operation: 'deprovision',
        usimSupi: rowData.supi,
        onConfirm: () => void dispatch(deprovisionUsim5g({ usimIds: [rowData.id] })),
      });
    },
    [dispatch, openUsimOperationConfirmDialog]
  );

  const openActionDelete: ListToolbarAction<Usim5g>['onClick'] = useCallback(
    (selectedData: SelectedData<Usim5g>) => {
      const { usimSupi, usimIds, elementsCount } = mapSelectedData(selectedData);
      openUsimOperationConfirmDialog({
        operation: 'delete',
        severity: 'danger',
        elementsCount,
        usimSupi,
        onConfirm: () => {
          batch(() => {
            dispatch(setUsim5gShouldFlushSelection(true));
            void dispatch(deleteUsim5g({ usimIds, filters: selectedData.filters }));
          });
        },
      });
    },
    [dispatch, mapSelectedData, openUsimOperationConfirmDialog]
  );

  const openDelete = useCallback(
    (rowData: Usim5g) => {
      openUsimOperationConfirmDialog({
        operation: 'delete',
        severity: 'danger',
        usimSupi: rowData.supi,
        onConfirm: () => {
          void dispatch(deleteUsim5g({ usimIds: [rowData.id] }));
        },
      });
    },
    [dispatch, openUsimOperationConfirmDialog]
  );

  const openUsim5gMetadataEditor = useCallback(
    (rowData: Usim5g) => {
      dialogOpen({
        title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${rowData.supi}`,
        content: () => <MetadataEditorDialog id={rowData.id} metadata={rowData.metadata} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const getTools = useCallback(
    (rowData: Usim5g) => {
      const options: DropdownToolsOption[] = checkPermissionsList(user?.permissions || [], [
        {
          iconName: 'Pencil',
          label: formatMessage({ id: 'usims.actions.edit.title' }),
          onClick: () => openEdit(rowData),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'edit-usim',
        },
        {
          iconName: 'Code-1',
          label: formatMessage({ id: 'usims.actions.metadata.title' }),
          onClick: () => openUsim5gMetadataEditor(rowData),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'edit-usim-metadata',
        },
        {
          iconName: 'Edit-Task',
          label: formatMessage({ id: 'usims.actions.prepare.title' }),
          onClick: () => openPrepareForProvisioning(rowData),
          show: Boolean(rowData.status !== USIM5G_STATUS.PROVISIONED),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'prepare-usim',
        },
        {
          iconName: 'Server-Upload',
          label: formatMessage({ id: 'usims.actions.provision.title' }),
          onClick: () => openProvision(rowData),
          disabled: !rowData.network_topology_id,
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'provision-usim',
        },
        {
          iconName: 'Delete-Server',
          label: formatMessage({ id: 'usims.actions.deprovision.title' }),
          onClick: () => openDeprovision(rowData),
          disabled: !rowData.network_topology_id,
          hide: Boolean(rowData.status !== USIM5G_STATUS.PROVISIONED),
          permissions: [U_PERMISSIONS.UPDATE_USIM],
          value: 'deprovision-usim',
        },
        {
          iconName: 'Trashcan',
          label: formatMessage({ id: 'usims.actions.delete.title' }),
          onClick: () => openDelete(rowData),
          permissions: [U_PERMISSIONS.DELETE_USIM],
          value: 'delete-usim',
        },
      ]);

      return <DropdownTools options={options} />;
    },
    [
      user?.permissions,
      formatMessage,
      openEdit,
      openUsim5gMetadataEditor,
      openPrepareForProvisioning,
      openProvision,
      openDeprovision,
      openDelete,
    ]
  );

  const getRowSx = useCallback(
    (id: any) => {
      const sxProps: SxProps<Theme> = {
        backgroundColor: pendingModificationIds.includes(id) ? SyncColors.modificationBg : undefined,
      };
      return sxProps;
    },
    [pendingModificationIds]
  );

  const columns: ColumnShape<Usim5g>[] = useMemo(() => {
    if (!bootstrap) {
      return [];
    }

    return checkPermissionsList(
      [user?.tenant_type],
      [
        {
          key: 'supi',
          title: formatMessage({ id: 'usims.table.supi' }),
          dataKey: 'supi',
          sortable: true,
          width: 180,
          maxWidth: 250,
          minWidth: 160,
          resizable: true,
          cellRenderer: ({ rowData, cellData: supi }) => {
            return <UsimSupiLink type={'body2'} usim5g={rowData} />;
          },
        },
        {
          key: 'name',
          title: formatMessage({ id: 'usims.table.name' }),
          dataKey: 'name',
          sortable: true,
          width: 160,
          maxWidth: 160,
          minWidth: 100,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable,
          cellRenderer: ({ rowData }) => {
            return (
              <UsimPlainText
                type={'body2'}
                provisionedValue={rowData.provisioned_values?.name}
                unprovisionedValue={rowData.name}
              />
            );
          },
        },
        {
          key: 'msisdn',
          title: formatMessage({ id: 'usims.table.msisdn' }),
          dataKey: 'msisdn',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
        },
        {
          key: 'status',
          title: formatMessage({ id: 'usims.id.details.provisioningStatus' }),
          dataKey: 'status',
          sortable: true,
          visible: true,
          width: 160,
          maxWidth: 180,
          minWidth: 120,
          cellRenderer: ({ cellData: status }) => {
            return <UsimStatus status={status} error={undefined} updating={undefined} />;
          },
        },
        {
          key: 'tenant_id',
          title: formatMessage({ id: 'usims.table.tenantName' }),
          dataKey: 'tenant.name',
          sortable: false,
          width: 150,
          headerClassName: 'table-cell-resizable', // for columns auto-resizable
          className: 'table-cell-resizable', // for columns auto-resizable
          permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
        },
        {
          key: 'network_topology_id',
          title: formatMessage({ id: 'usims.table.network' }),
          dataKey: 'network_topology_id',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ cellData, rowData: { network_topology_name } }) => {
            return (
              <Link
                onClick={() => {
                  navigate(`/networks/${cellData}`);
                }}
              >
                <Text type={'body2'}>{network_topology_name}</Text>
              </Link>
            );
          },
        },
        {
          key: 'profile_id',
          title: formatMessage({ id: 'usims.table.profile_id' }),
          dataKey: 'profile_id',
          sortable: true,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ rowData: { profile_name } }) => <>{profile_name}</>,
        },
        {
          key: 'tools',
          title: '',
          dataKey: 'tools',
          width: 60,
          maxWidth: 60,
          minWidth: 60,
          secret: true, // secret used to hide from columns management panel
          cellRenderer: ({ rowData }) => {
            return <>{getTools(rowData)}</>;
          },
        },
      ]
    );
  }, [bootstrap, formatMessage, getTools, navigate, user?.tenant_type]);

  const actionsData = useMemo(() => {
    let actions: ListToolbarAction<Usim5g>[] = [];

    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.prepare.title' }),
        onClick: openActionPrepareForProvisioning,
      });
    }
    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.provision.title' }),
        onClick: openActionProvision,
      });
    }
    if (canUserUpdateUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.deprovision.title' }),
        onClick: openActionDeprovision,
      });
    }
    if (bootstrap?.delete_usim && canUserDeleteUsim) {
      actions.push({
        label: formatMessage({ id: 'usims.actions.delete.title' }),
        onClick: openActionDelete,
      });
    }

    return actions.length ? actions : undefined;
  }, [
    bootstrap?.delete_usim,
    canUserDeleteUsim,
    canUserUpdateUsim,
    formatMessage,
    openActionDelete,
    openActionDeprovision,
    openActionPrepareForProvisioning,
    openActionProvision,
  ]);

  return (
    <List
      selectable
      columns={columns}
      totalRows={usimsList.data.total}
      data={usimsList.data.data}
      page={usimsList.data.page}
      filters={{}}
      actions={actionsData}
      onOrderChange={handleSortChange}
      {...(canUserCreateUsim && { createComponent: <CreateUsimCard5g /> })}
      toolbar={{ filters: false, actions: Boolean(actionsData?.length) }}
      loadingState={usimsList.state}
      onRefresh={handleRefresh}
      setFlushSelectionAction={setUsim5gShouldFlushSelection}
      shouldFlushSelection={shouldFlushUsims5gSelector}
      rowsPerPage={bootstrap?.pageLimit}
      onPageChange={handlePageChange}
      customRowSx={getRowSx}
      customFooterComponent={<RemoteSyncLegend modification />}
    />
  );
}
