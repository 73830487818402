import { selectActiveUsim } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useAppSelector } from 'store';
import { BasePanel } from 'components/Panel/BasePanel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';

export function TenantPanel() {
  const activeUsim = useAppSelector((state) => selectActiveUsim(state));
  const { formatMessage } = useIntl();

  return (
    <BasePanel folder title={formatMessage({ id: 'usims.table.tenantName' })} height={115} skeleton={!activeUsim}>
      <PanelContent>
        <Text>{activeUsim?.tenant.name}</Text>
      </PanelContent>
    </BasePanel>
  );
}
