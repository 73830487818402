import { useAppSelector } from 'store';
import { AdminConfigItem } from 'store/models/adminConfig';

export function useAdminConfigUserSelector() {
  const user = useAppSelector((state) => state.adminConfig.user);
  return user;
}

export function useAdminConfigDataSelector() {
  const adminConfigData = useAppSelector((state) => state.adminConfig.adminConfigData);
  return adminConfigData;
}

export function useAdminConfigCategoriesSelector() {
  return (
    useAdminConfigDataSelector()
      .data?.items?.reduce((arr: AdminConfigItem[], obj: AdminConfigItem) => {
        if (arr.length && arr.some((o) => o.category.toLowerCase() === obj.category.toLowerCase())) return arr;
        arr.push(obj);
        return arr;
      }, [])
      .sort((a: AdminConfigItem, b: AdminConfigItem) => {
        return a.category.localeCompare(b.category);
      }) || []
  );
}
