import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Radios } from '../../../../graphql/client/types';
import { NoMetrics } from 'components/NetworkId/NoMetrics/NoMetrics';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { useIntl } from 'react-intl';

type NetworkRadiosProps = {
  radios: Radios;
};

export function NetworkRadios({ radios }: NetworkRadiosProps) {
  const { formatMessage } = useIntl();

  if (radios.active === null) {
    return <NoMetrics />;
  }

  const currentRadios = (radios?.total || 0) - (radios?.active || 0);

  return (
    <PanelContent>
      <SquaredTile horizontal>
        <SquaredChildTile
          mainValue={radios?.active || 0}
          totalValue={radios?.total || 0}
          totalValueFontSizeRatio={0.5}
          valueDivider="/"
          leftAlign
          mainValueColor={
            (radios?.total || 0) > 0 && (radios?.active || 0) === 0
              ? 'error'
              : (radios?.active || 0) === (radios?.total || 0)
              ? 'success'
              : 'warning'
          }
        />
      </SquaredTile>
      {(radios?.active || 0) < (radios?.total || 0) ? (
        <Stack direction="row" sx={{ mt: 2 }}>
          <Icon name="Error-Mark" fontSize="medium" color="error" />
          <Text>
            {currentRadios}{' '}
            {currentRadios > 1
              ? formatMessage({ id: 'menu.networks.radiosPanel.radiosAreNotActivated' })
              : formatMessage({ id: 'menu.networks.radiosPanel.radioIsNotActivated' })}
          </Text>
        </Stack>
      ) : null}
    </PanelContent>
  );
}
