import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { User } from 'store/models/user';
import { ListData } from 'store/reducers';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast } from '../toast';
import { generateFiltersQuery } from 'store/models/filters';

export const getUsers = createAsyncThunk<
  ListData<User>,
  void,
  {
    rejectValue: null;
    state: State;
  }
>('users/getAll', async (_, { dispatch, rejectWithValue, getState }) => {
  const {
    bootstrap,
    users: {
      sort,
      filters,
      list: {
        data: { page },
      },
    },
  } = getState();

  try {
    const query = encodeURI(generateFiltersQuery(filters, '&'));

    const options: AxiosRequestConfig = {
      url: `${config.apis.getUsers
        .replace('{sort}', sort)
        .replace('{limit}', `${bootstrap?.pageLimit}`)
        .replace('{page}', `${page}`)
        .replace('{filters}', query)}`,
      method: 'GET',
    };

    const fetchedData = await dispatch(
      fetchData<{
        total_items: number;
        users: User[];
      }>(options)
    );

    return {
      data: fetchedData.users,
      total: fetchedData.total_items,
      page,
    };
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    return rejectWithValue(null);
  }
});
