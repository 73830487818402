import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import CreateUserDialog from './CreateUserDialog';

export default function CreateUserButton() {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();
  const bootstrap = useBootstrapSelector();

  const handleOpenCreate = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'users.newUser' }),
      content: () => <CreateUserDialog />,
    });
  }, [dialogOpen, formatMessage]);

  if (!bootstrap) return null;

  return (
    <Button
      onClick={handleOpenCreate}
      text={formatMessage({ id: 'users.newUser' })}
      data-testid="toolbar-new-button"
      startIcon="Add"
      variant="outlined"
    />
  );
}
