import { useEffect } from 'react';
import { useToastSelector } from 'store/selectors/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { hideToast } from 'store/actions/toast';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';

export default function Toasts() {
  const toast = useToastSelector();
  const { snackbarOpen, snackbarClose } = useOverlay();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (toast) {
      const { message, type, intlMessage } = toast;
      snackbarOpen({
        message: intlMessage ? formatMessage({ id: message }) : message,
        severity: type,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        onClose: () => {
          dispatch(hideToast());
        },
      });
    }
    if (!toast) {
      snackbarClose();
    }
  }, [dispatch, formatMessage, snackbarClose, snackbarOpen, toast]);

  return null;
}
