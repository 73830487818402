export interface ServiceProfile {
  id: string;
  name: string;
  //node_id: string;
  network_topology_id: string;
  slices: Slice[];
  highlighting: Highlighting;
  synchronized_at: string;
  to_be_deleted?: boolean;
}

interface Highlighting {
  provisioned_values: ProvisionableValues;
  unprovisioned_values: ProvisionableValues;
}

interface ProvisionableValues {
  slices: Slice[];
}

export interface Slice {
  id: string;
  name: string;
  differentiator: string; // differentiatorRegExp
  services: Service[];
  is_4g: boolean;
  to_be_deleted?: boolean;
}

export enum SERVICE_TYPE {
  DATA_TRAFFIC = 'DATA_TRAFFIC',
  VOICE_SERVICES = 'VOICE_SERVICE',
  MC_PTT = 'MC_PTT',
}

export interface Service {
  id: string;
  name: string; // apnRegExp
  type: SERVICE_TYPE;
  default: boolean;
  to_be_deleted?: boolean;
}

export const differentiatorRegExp = /^[a-f0-9]{6}$/;
