import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';

export function privateRouteLoader(loader?: LoaderFunction) {
  return async (loaderArgs: LoaderFunctionArgs) => {
    //TODOPS redirect
    //const token = localStorage.getItem(String(process.env.REACT_APP_LOCAL_STORAGE_AUTH_KEY));
    // if (!token) {
    //   return redirect('/signin');
    // }

    if (loader) {
      return loader(loaderArgs);
    }

    return null;
  };
}
