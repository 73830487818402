import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { DetailsGridItem } from 'components/BulkOperations/SingleOperation/DetailsGridItem';
import { ProgressGridItem } from 'components/BulkOperations/SingleOperation/ProgressGridItem';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isEntityFailure, isEntityLoading } from 'store/reducers';
import { useBulkOperationsEntity } from 'store/selectors/bulkOperations';
import { splitPascalCase } from 'utils/string';

export default function SingleOperation() {
  const bulkOperation = useBulkOperationsEntity();
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();

  const columns: TableColumn<{
    command: string | null;
    errors: string;
    params: string;
  }>[] = useMemo(
    () => [
      {
        key: 'command',
        label: 'Command',
        cellRender: ({ command }) => <>{splitPascalCase(command || '')}</>,
      },
      {
        key: 'errors',
        label: 'Errors',
        cellRender: ({ errors }) => <>{errors}</>,
      },
      {
        key: 'params',
        label: 'Params',
        cellRender: ({ params }) => <>{JSON.stringify(JSON.parse(params), null, 2)}</>,
      },
    ],
    []
  );

  return (
    <>
      <DialogContent>
        {isEntityFailure(bulkOperation) ? (
          <Alert
            severity="error"
            title={formatMessage({ id: 'common.error' })}
            message={formatMessage({ id: 'common.fetch.error' })}
          />
        ) : (
          <GridContainer sx={{ mt: -2 }}>
            <DetailsGridItem />
            <ProgressGridItem />
            {bulkOperation.data?.actions && bulkOperation.data.actions.length > 0 && (
              <GridItem size={{ xs: 12 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
                <Panel title="Actions Breakdown" folder>
                  <Table
                    loading={isEntityLoading(bulkOperation)}
                    asPanel
                    orderBy={'command'}
                    columns={columns}
                    data={bulkOperation.data.actions}
                    rowsCount={bulkOperation.data.actions.length}
                    page={0}
                    rowsPerPage={0}
                    rowKey={'command'}
                  />
                </Panel>
              </GridItem>
            )}
          </GridContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" text={formatMessage({ id: 'common.form.close' })} onClick={dialogClose} />
        </Box>
      </DialogActions>
    </>
  );
}
