import { useEffect, useMemo, useCallback, useRef } from 'react';
import { batch } from 'react-redux';
import { deleteUser } from 'store/actions/users';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Users';
import List, { ColumnShape } from 'components/List';
import CreateUserButton from 'components/Edit/Users/create/CreateUserButton';
import { EditUserDialog } from 'components/Edit/Users/edit/EditUserDialog';
import DropdownTools, { DropdownToolsOption } from 'components/DropdownTools';
import { U_PERMISSIONS, checkPermissionsList, checkPermissionToUse } from 'utils/permissionCodes';
import { useUserSelector } from 'store/selectors/user';
import { User } from 'store/models/user';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { AUTH_PROVIDER, PAGE_LIMIT } from 'store/models/environmentConfiguration';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { getFiltersByQueryString } from 'store/models/filters';
import isEmpty from 'lodash/isEmpty';
import { clearUsersList, setUsersSort, setUsersFilters, setUsersPageNumber } from 'store/reducers/users';
import { Avatar } from '@athonet/ui/components/Persona/Avatar';
import { getRoles } from 'store/actions/roles/getRoles';
import { getUsers } from 'store/actions/users/getUsers';
import ManageTenantsDialog from 'components/Edit/Users/manageTenants/ManageTenantsDialog';
import { useLocalizedConfig } from 'hooks/useLocalizedConfig';
import { useAppDispatch, useAppSelector } from 'store';
import { DateTime } from 'luxon';
import { getTenantsWithSubtenants } from 'store/actions/tenants/getTenantsWithSubtenants';

export default function Users() {
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const dispatch = useAppDispatch();
  const { confirmationDialogOpen, dialogOpen } = useOverlay();

  const initialSort = useRef<string>('fullname');
  const localizedConfig = useLocalizedConfig();
  const queryStringFilters = useMemo(() => getFiltersByQueryString(), []);
  const initialPageLimit = useRef<number>(PAGE_LIMIT);

  const {
    list: { state: loadingState, data },
    filters,
  } = useAppSelector((state) => state.users);

  useEffect(() => {
    void dispatch(getTenantsWithSubtenants());
  }, [dispatch]);

  useEffect(() => {
    void dispatch(getRoles());
    if (!isEmpty(filters)) return;
    if (!isEmpty(queryStringFilters)) dispatch(setUsersFilters(queryStringFilters)); //set filters only if not in the state
    void dispatch(getUsers());
    return () => {
      dispatch(clearUsersList()); // clear redux when leaves page for best performances
    };
  }, [dispatch, filters, queryStringFilters]);

  useEffect(() => {
    if (bootstrap?.pageLimit && bootstrap?.pageLimit !== initialPageLimit.current) {
      initialPageLimit.current = bootstrap?.pageLimit!;
      void dispatch(getUsers());
    }
  }, [dispatch, initialPageLimit, bootstrap?.pageLimit]);

  const handleRefresh = useCallback(() => {
    void dispatch(getUsers());
  }, [dispatch]);

  const handlePageChange = useCallback(
    (pageChanged: number) => {
      batch(() => {
        dispatch(setUsersPageNumber(pageChanged));
        void dispatch(getUsers());
      });
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (orderQuery: string) => {
      batch(() => {
        if (orderQuery && orderQuery !== initialSort.current) {
          dispatch(setUsersSort(orderQuery));
          initialSort.current = orderQuery!;
          void dispatch(getUsers());
        }
      });
    },
    [dispatch]
  );

  const handleDeleteUser = useCallback(
    async (id: string) => {
      try {
        await dispatch(deleteUser(id));
        dispatch(showSuccessToast());
      } catch (e) {
        dispatch(showErrorToast());
      }
    },
    [dispatch]
  );

  const handleOpenEdit = useCallback(
    (userId: User['id']) => {
      dialogOpen({
        title: formatMessage({ id: 'users.editUser' }),
        content: () => <EditUserDialog userId={userId} />,
        closeOnEsc: false,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openDelete = useCallback(
    ({ id, fullname }: User) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'users.deleteUser.confirm.title' }),
        description: formatMessage({ id: 'users.deleteUser.confirm' }),
        alertMessage: formatMessage({ id: 'users.itemsAffected' }, { element: fullname }),
        continueButtonText: formatMessage({ id: 'users.deleteUser.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => handleDeleteUser(id),
        severity: 'danger',
        dataTestid: 'confirm-delete-user',
      });
    },
    [confirmationDialogOpen, formatMessage, handleDeleteUser]
  );

  const openManageTenants = useCallback(
    (userId: string) => {
      dialogOpen({
        title: formatMessage({ id: 'users.form.manageTenant' }),
        content: () => <ManageTenantsDialog userId={userId} onClose={handleRefresh} />,
        closeOnEsc: false,
      });
    },
    [dialogOpen, formatMessage, handleRefresh]
  );

  const getDropdownTools = useCallback(
    (rowData: User) => {
      const options: DropdownToolsOption[] = checkPermissionsList(
        user?.permissions || [],
        [
          {
            iconName: 'Pencil',
            value: 'edit-user',
            label: formatMessage({ id: 'users.editUser' }),
            onClick: () => handleOpenEdit(rowData.id),
            disabled: rowData.id === user?.id,
            permissions: [U_PERMISSIONS.UPDATE_USER],
          },
          {
            iconName: 'Users',
            value: 'edit-user-tenant',
            label: formatMessage({ id: 'users.userTenants' }),
            onClick: () => openManageTenants(rowData.id),
            disabled: false,
            permissions: [U_PERMISSIONS.UPDATE_USER],
          },
          {
            iconName: 'Trashcan',
            value: 'delete-user',
            label: formatMessage({ id: 'users.deleteUser' }),
            onClick: () => openDelete(rowData),
            disabled: rowData.id === user?.id,
            permissions: [U_PERMISSIONS.DELETE_USER],
          },
        ],
        false
      );

      return <DropdownTools options={options} />;
    },
    [formatMessage, handleOpenEdit, openDelete, openManageTenants, user]
  );

  const columns: ColumnShape<User>[] = useMemo(
    () =>
      checkPermissionsList(
        [user?.tenant_type],
        [
          {
            key: 'image',
            title: '',
            dataKey: 'image',
            cellRenderer: ({ cellData: image }: { cellData: string }) => {
              return <Avatar alt={formatMessage({ id: 'users.table.avatar' })} src={image} />;
            },
            sortable: false,
            secret: true,
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          },
          {
            key: 'id',
            title: 'ID',
            dataKey: 'id',
            width: 200,
            maxWidth: 300,
            minWidth: 100,
            visible: false,
          },
          {
            key: 'fullname',
            title: formatMessage({ id: 'users.table.fullname' }),
            dataKey: 'fullname',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'name',
            title: formatMessage({ id: 'users.table.username' }),
            dataKey: 'name',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'email',
            title: formatMessage({ id: 'users.table.email' }),
            dataKey: 'email',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            minWidth: 100,
            visible: false,
            cellRenderer: ({ cellData, rowData }) => {
              if (rowData.realm === AUTH_PROVIDER.ENTERPRISE) {
                return cellData;
              }
              return '-';
            },
          },
          {
            key: 'realm',
            title: formatMessage({ id: 'users.table.realm' }),
            dataKey: 'realm',
            sortable: true,
            width: 100,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
            cellRenderer: ({ cellData }: { cellData: AUTH_PROVIDER }) => {
              return (
                <Chip
                  id="realm"
                  size="small"
                  color={cellData === AUTH_PROVIDER.ENTERPRISE ? 'default' : 'info'}
                  label={formatMessage({ id: `authProvider.${cellData}` })}
                />
              );
            },
          },
          {
            key: 'tenant',
            title: formatMessage({ id: 'users.table.tenant' }),
            dataKey: 'tenant',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
          },
          {
            key: 'created_at',
            title: formatMessage({ id: 'users.table.creation' }),
            dataKey: 'created_at',
            sortable: true,
            width: 140,
            maxWidth: 140,
            minWidth: 140,
            cellRenderer: ({ cellData }: { cellData: string }) => {
              return <>{DateTime.fromISO(cellData).toFormat(localizedConfig.fullDateFormat)}</>;
            },
          },
          {
            key: 'phone',
            title: formatMessage({ id: 'users.table.phone' }),
            dataKey: 'phone',
            sortable: true,
            width: 200,
            headerClassName: 'table-cell-resizable', // for columns auto-resizable
            className: 'table-cell-resizable', // for columns auto-resizable
            defaultSort: 'asc', // set the columns sorted as default
            minWidth: 100,
            visible: false,
          },
          {
            key: 'tools',
            title: '',
            dataKey: 'tools',
            width: 60,
            maxWidth: 60,
            minWidth: 60,
            secret: true, // secret used to hide from columns management panel
            cellRenderer: ({ rowData }: { rowData: User }) => {
              return getDropdownTools(rowData);
            },
          },
        ]
      ),
    [formatMessage, getDropdownTools, localizedConfig.fullDateFormat, user]
  );

  return (
    <List
      columns={columns}
      totalRows={data.total}
      data={data.data}
      filters={filters}
      onOrderChange={handleSortChange}
      loadingState={loadingState}
      {...(checkPermissionToUse(user, U_PERMISSIONS.CREATE_USER) && {
        createComponent: <CreateUserButton />,
      })}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={{
        actions: false,
      }}
      page={data.page}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
    />
  );
}
