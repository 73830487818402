import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Panel, PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { List, ListItemAction } from '@athonet/ui/components/Navigation/List';
import { ListItem, ListItemProps } from '@athonet/ui/components/Navigation/List/ListItem';
import { NoDataSearch } from '@athonet/ui/components/Data/NoData';
import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { Divider } from '@athonet/ui/components/Layout/Divider';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { EditServiceProfileDialog } from './EditServiceProfileDialog';
import { useCanUserUpdateNodeSelector } from 'store/selectors/user';
import theme from '@athonet/ui/theme';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getSelectedServiceProfileId,
  selectServiceProfileLoadingStatus,
  selectServiceProfiles,
  selectPendingCreationServiceProfileIds,
  selectPendingModificationServiceProfileIds,
  selectPendingDeletionServiceProfileIds,
} from 'store/selectors/serviceProfiles';
import { getServiceProfiles } from 'store/actions/serviceProfiles/getServiceProfiles';
import { setSelectedServiceProfileId } from 'store/reducers/serviceProfiles';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { SyncColors, SyncStatus } from 'components/RemoteSync/utils';
import RemoteSyncLegend from 'components/RemoteSync/RemoteSyncLegend';
import { SelectedServiceProfile } from './SelectedServiceProfile';
import { useParams } from 'react-router-dom';
import { NetworkPageParams } from 'Router';
import { ServiceProfile } from 'store/models/serviceProfile';
import { deleteServiceProfile } from 'store/actions/serviceProfiles/deleteServiceProfile';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { CreateButton } from 'components/Button/CreateButton';

export function ServiceProfiles() {
  const { network_id } = useParams<NetworkPageParams>();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { dialogOpen, confirmationDialogOpen } = useOverlay();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const selectedServiceProfileId = useAppSelector((state) => getSelectedServiceProfileId(state));
  const serviceProfiles = useAppSelector((state) => selectServiceProfiles(state));
  const isLoading = useAppSelector((state) => selectServiceProfileLoadingStatus(state));

  const handleListItemClick = useCallback((id: string) => dispatch(setSelectedServiceProfileId(id)), [dispatch]);

  const pendingCreateServiceProfileIds = useAppSelector((state) => selectPendingCreationServiceProfileIds(state));
  const pendingEditServiceProfileIds = useAppSelector((state) => selectPendingModificationServiceProfileIds(state));
  const pendingDeleteServiceProfileIds = useAppSelector((state) => selectPendingDeletionServiceProfileIds(state));

  useEffect(() => {
    if (serviceProfiles.length && serviceProfiles[0].id && !selectedServiceProfileId) {
      handleListItemClick(serviceProfiles[0].id);
    }
  }, [handleListItemClick, selectedServiceProfileId, serviceProfiles]);

  const fetchServiceProfiles = useCallback(() => {
    if (!network_id) return;
    void dispatch(getServiceProfiles({ networkId: network_id }));
  }, [dispatch, network_id]);

  useEffect(() => {
    fetchServiceProfiles();
  }, [fetchServiceProfiles]);

  const handleRefresh = useCallback(() => {
    fetchServiceProfiles();
  }, [fetchServiceProfiles]);

  const handleOpenNewServiceProfile = useCallback(() => {
    if (!network_id) return;
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.newServiceProfile' }),
      content: () => <EditServiceProfileDialog networkId={network_id} />,
    });
  }, [dialogOpen, formatMessage, network_id]);

  const handleDeleteServiceProfile = useCallback(
    (serviceProfile: ServiceProfile) => {
      network_id &&
        confirmationDialogOpen({
          description: formatMessage({ id: 'serviceProfiles.serviceProfiles.deleteServiceProfile.description' }),
          title: formatMessage({ id: 'serviceProfiles.serviceProfiles.deleteServiceProfile.title' }),
          alertMessage: formatMessage(
            { id: 'serviceProfiles.serviceProfiles.actions.confirm.itemsAffected' },
            { element: `'${serviceProfile?.name}'` }
          ),
          onConfirm: async () =>
            void dispatch(deleteServiceProfile({ networkId: network_id, serviceProfileId: serviceProfile.id })),
          severity: 'danger',
        });
    },
    [network_id, confirmationDialogOpen, formatMessage, dispatch]
  );

  const handleOpenEditServiceProfile = useCallback(
    (serviceProfile: ServiceProfile) => {
      if (!network_id) return;
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.editServiceProfile' }, { element: serviceProfile?.name }),
        content: () => <EditServiceProfileDialog serviceProfileId={serviceProfile.id} networkId={network_id} />,
      });
    },
    [dialogOpen, formatMessage, network_id]
  );

  const serviceProfileActions: ListItemAction<ServiceProfile>[] = useMemo(
    () => [
      {
        label: formatMessage({ id: 'serviceProfiles.changeName' }),
        onClick({ item }: ListItemProps<ServiceProfile>) {
          if (item) {
            handleOpenEditServiceProfile(item);
          }
        },
      },

      {
        label: formatMessage({ id: 'common.button.delete' }),
        onClick({ item }: ListItemProps<ServiceProfile>) {
          if (item) {
            handleDeleteServiceProfile(item);
          }
        },
      },
    ],
    [formatMessage, handleDeleteServiceProfile, handleOpenEditServiceProfile]
  );

  if (!network_id || !serviceProfiles) return null;

  return (
    <>
      <Stack direction="row" justify="flex-end" sx={{ mb: 1.325, mt: -0.625 }}>
        <Button
          variant="outlined"
          onClick={handleRefresh}
          text={formatMessage({ id: 'common.button.refresh' })}
          startIcon={'Refresh'}
          data-testid="toolbar-refresh-button"
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        />
        {canUserUpdateNode && (
          <CreateButton
            onClick={handleOpenNewServiceProfile}
            text={formatMessage({ id: 'serviceProfiles.list.new' })}
          />
        )}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          // border: '1px solid ' + theme.palette.divider,
          // borderRadius: '4px 0 0 4px',
          backgroundColor: theme.palette.common.white,
          flexDirection: 'column',
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <GridContainer spacing={0} sx={{ height: '100%' }}>
            <GridItem size={{ xs: 12, md: 4 }} sx={{ height: '100%' }}>
              <Panel
                title={formatMessage({ id: 'serviceProfiles.table.title' })}
                folder
                fullHeight
                sx={{
                  border: 'none',
                  borderRadius: '0',
                  borderRight: '1px solid ' + theme.palette.divider,
                  '.Athonet-MuiCardHeader-root': { py: 1.75 }, // needed to offset the height created by the "create slice" button on SelectedServiceProfile
                }}
              >
                <PanelContent
                  sx={{
                    m: 0,
                    p: 0,
                  }}
                >
                  <List
                    divided
                    sx={{
                      m: 0,
                      p: 0,
                    }}
                    listItemActions={serviceProfileActions}
                  >
                    {serviceProfiles.length ? (
                      serviceProfiles.map((serviceProfile, idx) => {
                        const isDeletion = pendingDeleteServiceProfileIds.includes(serviceProfile.id);
                        const isModification = pendingEditServiceProfileIds.includes(serviceProfile.id);
                        const isCreation = pendingCreateServiceProfileIds.includes(serviceProfile.id);

                        return (
                          <ListItem
                            item={serviceProfile}
                            sx={{
                              backgroundColor: isDeletion
                                ? SyncColors.deletionBg
                                : isCreation
                                ? SyncColors.creationBg
                                : isModification
                                ? SyncColors.modificationBg
                                : undefined,
                            }}
                            key={`sp_${idx}`}
                            selected={selectedServiceProfileId === serviceProfile.id}
                            onClick={() => handleListItemClick(serviceProfile.id)}
                            primaryText={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {serviceProfile.name}
                                <RemoteSyncTooltipIcon
                                  syncStatus={
                                    isDeletion
                                      ? SyncStatus.PendingDeletion
                                      : isCreation
                                      ? SyncStatus.PendingCreation
                                      : isModification
                                      ? SyncStatus.PendingModification
                                      : SyncStatus.None
                                  }
                                  text={formatMessage({ id: `common.highlighting.remoteSyncPending` })}
                                />
                              </Box>
                            }
                            secondaryText={formatMessage(
                              {
                                id: 'serviceProfiles.list.nSlices',
                              },
                              {
                                elements: serviceProfile.slices.length,
                              }
                            )}
                            endIcon="Right-2"
                          />
                        );
                      })
                    ) : isLoading ? (
                      <>
                        <Skeleton variant="rectangular" height={72} />
                        <Divider />
                        <Skeleton variant="rectangular" height={72} />
                        <Divider />
                        <Skeleton variant="rectangular" height={72} />
                        <Divider />
                      </>
                    ) : (
                      <NoDataSearch />
                    )}
                  </List>
                </PanelContent>
              </Panel>
            </GridItem>
            {selectedServiceProfileId ? (
              <GridItem size={{ xs: 12, md: 8 }} sx={{ height: '100%' }}>
                <SelectedServiceProfile serviceProfileId={selectedServiceProfileId} />
              </GridItem>
            ) : (
              <GridItem size={{ xs: 12, md: 8 }} sx={{ height: '100%', backgroundColor: theme.palette.common.white }}>
                <Panel
                  fullHeight
                  sx={{
                    border: 'none',
                  }}
                >
                  <NoDataSearch />
                </Panel>
              </GridItem>
            )}
          </GridContainer>
        </Box>
        {serviceProfiles.length > 0 && (
          <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
            <RemoteSyncLegend sx={{ pb: 1, pt: 1 }} creation deletion modification />
          </Box>
        )}
      </Box>
    </>
  );
}
