import React from 'react';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { useAppSelector } from 'store';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { selectSliceById, selectSliceDifferentiatorSyncStatus } from 'store/selectors/serviceProfiles';
import { SyncStatus } from 'components/RemoteSync/utils';
import { useIntl } from 'react-intl';

interface SliceChipProps {
  serviceProfileId: string;
  sliceId: string;
}

const SliceChip: React.FC<SliceChipProps> = (props) => {
  const { formatMessage } = useIntl();
  const { serviceProfileId, sliceId } = props;
  const slice = useAppSelector((state) => selectSliceById(state, serviceProfileId, sliceId));
  const { oldValue, syncStatus } = useAppSelector((state) =>
    selectSliceDifferentiatorSyncStatus(state, serviceProfileId, sliceId)
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Chip label={slice?.differentiator} id={slice?.differentiator ?? ''} />
      {syncStatus === SyncStatus.PendingModification && (
        <RemoteSyncTooltipIcon
          syncStatus={syncStatus}
          text={`${formatMessage({ id: 'common.highlighting.oldValue' })}: ${oldValue}`}
        />
      )}
    </Box>
  );
};

export default SliceChip;
