import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Link, useParams } from 'react-router-dom';
import { ControlPlaneStatus } from '../../../../graphql/client/types';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { useIntl } from 'react-intl';
import { useHasUserMasterTenantSelector } from 'store/selectors/user';
import { useCallback } from 'react';

type NetworkAccessManagementProps = {
  accessManagement: ControlPlaneStatus;
};

export function NetworkAccessManagement({ accessManagement }: NetworkAccessManagementProps) {
  const { network_id } = useParams();
  const { formatMessage } = useIntl();
  const hasUserMasterTenant = useHasUserMasterTenantSelector();

  const ErrorVmsText = useCallback(
    () => (
      <Text>
        {accessManagement.errorVmsCount}{' '}
        {Number(accessManagement.errorVmsCount) > 1
          ? formatMessage({ id: 'menu.networks.accessManagementPanel.virtualMachines' })
          : formatMessage({ id: 'menu.networks.accessManagementPanel.virtualMachine' })}{' '}
        {formatMessage({ id: 'menu.networks.accessManagementPanel.withProblems' })}
      </Text>
    ),
    [accessManagement.errorVmsCount, formatMessage]
  );

  return (
    <PanelContent>
      <SquaredTile horizontal>
        <SquaredChildTile
          mainValue={`${accessManagement.workingPercentage}%`}
          totalValue={formatMessage({ id: 'menu.networks.accessManagementPanel.available' })}
          totalValueFontSizeRatio={0.5}
          leftAlign
          mainValueColor={accessManagement.workingPercentage === 100 ? 'success' : 'error'}
        />
      </SquaredTile>
      {Number(accessManagement.errorVmsCount) > 0 && (
        <Stack direction="row" sx={{ mt: 2 }}>
          <Icon name="Error-Mark" fontSize="medium" color="error" />
          {hasUserMasterTenant ? (
            <Link to={`/networks/${network_id}/topology`} replace={true}>
              <ErrorVmsText />
            </Link>
          ) : (
            <ErrorVmsText />
          )}
        </Stack>
      )}
    </PanelContent>
  );
}
