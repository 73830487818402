import { createSelector } from '@reduxjs/toolkit';
import { SyncStatus } from 'components/RemoteSync/utils';
import flatten from 'lodash/flatten';
import { State } from 'store';
import { Service, ServiceProfile, Slice } from 'store/models/serviceProfile';
import { DATA_LIFECYCLE } from 'store/reducers';
import { serviceProfilesAdapter } from 'store/reducers/serviceProfiles';

export const { selectAll: selectServiceProfiles, selectById: selectServiceProfileById } =
  serviceProfilesAdapter.getSelectors<State>((state) => state.serviceProfiles.entities);

const selectServiceProfilesWithSlices = createSelector([selectServiceProfiles], (serviceProfiles) =>
  serviceProfiles.filter((serviceProfile) => serviceProfile.slices.length > 0)
);

export const getSelectedServiceProfileId = (state: State) => state.serviceProfiles.selectedId;

export const selectServiceProfileLoadingStatus = createSelector(
  [(state: State) => state.serviceProfiles.loadingStatus],
  (status) => status === DATA_LIFECYCLE.LOADING || status === DATA_LIFECYCLE.IDLE
);

export const selectPendingDeletionServiceProfileIds = createSelector(
  [selectServiceProfiles, selectServiceProfilesWithSlices],
  (serviceProfiles) => {
    return serviceProfiles.filter((serviceProfile) => serviceProfile.to_be_deleted === true).map((p) => p.id);
  }
);

export const selectPendingCreationServiceProfileIds = createSelector(
  [selectServiceProfilesWithSlices],
  (serviceProfiles) =>
    serviceProfiles
      .filter((sp) => getSliceSyncStatuses(sp).every((s) => s.syncStatus === SyncStatus.PendingCreation))
      .map((sp) => sp.id)
);

export const selectPendingModificationServiceProfileIds = createSelector(
  [selectServiceProfilesWithSlices, selectPendingCreationServiceProfileIds, selectPendingDeletionServiceProfileIds],
  (serviceProfiles, creationIds, deletionIds) => {
    return serviceProfiles
      .filter((serviceProfile) =>
        getSliceSyncStatuses(serviceProfile).some(
          (srv) =>
            srv.syncStatus === SyncStatus.PendingModification ||
            srv.syncStatus === SyncStatus.PendingCreation ||
            srv.syncStatus === SyncStatus.PendingDeletion
        )
      )
      .map((sp) => sp.id)
      .filter((id) => !creationIds.includes(id) && !deletionIds.includes(id));
  }
);

export const getPendingModificationSliceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getSliceSyncStatuses(serviceProfile)
    ?.filter((s) => s.syncStatus === SyncStatus.PendingModification)
    .map((s) => s.id);
});

export const getPendingCreationSliceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getSliceSyncStatuses(serviceProfile)
    ?.filter((s) => s.syncStatus === SyncStatus.PendingCreation)
    .map((s) => s.id);
});

export const getPendingDeletionSliceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getSliceSyncStatuses(serviceProfile)
    ?.filter((p) => p.syncStatus === SyncStatus.PendingDeletion)
    .map((s) => s.id);
});

export const getPendingCreationServiceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getServicesSyncStatuses(serviceProfile)
    ?.filter((p) => p.syncStatus === SyncStatus.PendingCreation)
    .map((s) => s.id);
});

export const getPendingModificationServiceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getServicesSyncStatuses(serviceProfile)
    ?.filter((p) => p.syncStatus === SyncStatus.PendingModification)
    .map((s) => s.id);
});

export const getPendingDeletionServiceIds = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return [];
  return getServicesSyncStatuses(serviceProfile)
    ?.filter((p) => p.syncStatus === SyncStatus.PendingDeletion)
    .map((s) => s.id);
});

//#region slices

export const selectSliceById = createSelector(
  [selectServiceProfileById, (state: State, serviceProfileId: string, sliceId: string) => sliceId],
  (serviceProfile, sliceId) => serviceProfile?.slices.find((s) => s.id === sliceId)
);

export const selectSliceNameSyncStatus = createSelector(
  [selectServiceProfileById, selectSliceById],
  (serviceProfile, slice) => getSlicePropertySyncStatus('name', serviceProfile, slice)
);

export const selectSliceDifferentiatorSyncStatus = createSelector(
  [selectServiceProfileById, selectSliceById],
  (serviceProfile, slice) => getSlicePropertySyncStatus('differentiator', serviceProfile, slice)
);

export const getDbExistingSlicesCount = createSelector([selectServiceProfileById], (serviceProfile) => {
  if (!serviceProfile) return 0;
  return serviceProfile.slices.filter((slice) => !Boolean(slice.to_be_deleted)).length;
});

//#endregion

//#region services

export const selectServiceById = createSelector(
  [selectSliceById, (state: State, serviceProfileId: string, sliceId: string, serviceId: string) => serviceId],
  (slice, serviceId) => slice?.services.find((s) => s.id === serviceId)
);

export const selectServiceSyncStatus = createSelector(
  [
    selectServiceProfileById,
    selectServiceById,
    (state: State, serviceProfileId: string, sliceId: string, serviceId: string) => sliceId,
  ],
  (serviceProfile, service, sliceId) => getServiceSyncStatus(sliceId, service, serviceProfile)?.syncStatus
);

export const selectServiceNameSyncStatus = createSelector(
  [
    selectServiceProfileById,
    selectServiceById,
    (state: State, serviceProfileId: string, sliceId: string, serviceId: string) => sliceId,
  ],
  (serviceProfile, service, sliceId) => getServicePropertySyncStatus('name', serviceProfile, sliceId, service)
);

export const selectServiceTypeSyncStatus = createSelector(
  [
    selectServiceProfileById,
    selectServiceById,
    (state: State, serviceProfileId: string, sliceId: string, serviceId: string) => sliceId,
  ],
  (serviceProfile, service, sliceId) => getServicePropertySyncStatus('type', serviceProfile, sliceId, service)
);

export const selectServiceIsDefaultStatus = createSelector(
  [
    selectServiceProfileById,
    selectServiceById,
    (state: State, serviceProfileId: string, sliceId: string, serviceId: string) => sliceId,
  ],
  (serviceProfile, service, sliceId) => getServicePropertySyncStatus('default', serviceProfile, sliceId, service)
);

export const getDbExistingServicesCount = createSelector(
  [selectServiceProfileById, (_1, _2, sliceId: string) => sliceId],
  (serviceProfile, sliceId) => {
    if (!serviceProfile) return 0;
    const slice = serviceProfile.slices.find((s) => s.id === sliceId);
    if (!slice) return 0;
    return slice.services.filter((service) => !Boolean(service.to_be_deleted)).length;
  }
);

//#endregion

//#region slices

export const getSliceSyncStatuses = (serviceProfile: ServiceProfile) => {
  return serviceProfile.slices.map((slice) => {
    return {
      id: slice.id,
      syncStatus: compareSlice(serviceProfile, slice),
    };
  });
};

const compareSlice = (serviceProfile: ServiceProfile, slice: Slice) => {
  const { provisionedSlice, unprovisionedSlice } = getSliceProvisionsProps(serviceProfile, slice);
  if (slice?.to_be_deleted) return SyncStatus.PendingDeletion;
  else if (!unprovisionedSlice) return SyncStatus.None;
  else if (!provisionedSlice) return SyncStatus.PendingCreation;
  else {
    if (
      (unprovisionedSlice.differentiator && unprovisionedSlice.differentiator !== provisionedSlice.differentiator) ||
      (unprovisionedSlice.name && unprovisionedSlice.name !== provisionedSlice.name)
    )
      return SyncStatus.PendingModification;

    const servicesStatus = getSliceServicesSyncStatuses(serviceProfile, slice);

    if (
      servicesStatus.some(
        (srv) =>
          srv.syncStatus === SyncStatus.PendingModification ||
          srv.syncStatus === SyncStatus.PendingCreation ||
          srv.syncStatus === SyncStatus.PendingDeletion
      )
    )
      return SyncStatus.PendingModification;
    return SyncStatus.None;
  }
};

const getSliceProvisionsProps = (serviceProfile: ServiceProfile, slice: Slice) => {
  const unprovisionedSlice = serviceProfile?.highlighting.unprovisioned_values?.slices?.find((p) => p.id === slice.id);
  const provisionedSlice = serviceProfile?.highlighting.provisioned_values?.slices?.find((p) => p.id === slice.id);
  return { provisionedSlice, unprovisionedSlice };
};

const getSlicePropertySyncStatus = (
  propertyName: keyof Slice,
  serviceProfile?: ServiceProfile,
  slice?: Slice
): SyncResults => {
  if (serviceProfile && slice) {
    const sliceStatus = compareSlice(serviceProfile, slice);
    if (
      sliceStatus === SyncStatus.PendingDeletion ||
      sliceStatus === SyncStatus.PendingCreation ||
      sliceStatus === SyncStatus.None
    )
      return { oldValue: undefined, syncStatus: sliceStatus };

    const { provisionedSlice, unprovisionedSlice } = getSliceProvisionsProps(serviceProfile, slice);

    if (unprovisionedSlice?.[propertyName] && unprovisionedSlice?.[propertyName] !== provisionedSlice?.[propertyName])
      return { oldValue: provisionedSlice?.[propertyName], syncStatus: SyncStatus.PendingModification };
    else if (unprovisionedSlice?.[propertyName] && !provisionedSlice?.[propertyName])
      return { oldValue: undefined, syncStatus: SyncStatus.PendingCreation };
  }
  return { oldValue: undefined, syncStatus: SyncStatus.None };
};

//#endregion

//#region services

export const getServicesSyncStatuses = (serviceProfile: ServiceProfile) => {
  const statuses = serviceProfile?.slices?.map((slice) => {
    return getSliceServicesSyncStatuses(serviceProfile, slice);
  });
  return flatten(statuses);
};

export const getSliceServicesSyncStatuses = (serviceProfile: ServiceProfile, slice: Slice) =>
  slice?.services?.map((service) => getServiceSyncStatus(slice.id, service, serviceProfile));

export const getServiceSyncStatus = (sliceId: string, service?: Service, serviceProfile?: ServiceProfile) => ({
  id: service?.id,
  syncStatus: compareService(sliceId, service, serviceProfile),
});

interface SyncResults {
  oldValue: any;
  syncStatus: SyncStatus;
}

const getServiceProvisionsProps = (sliceId: string, service?: Service, serviceProfile?: ServiceProfile) => {
  const unprovisionedService = serviceProfile?.highlighting?.unprovisioned_values?.slices
    ?.find((p) => p.id === sliceId)
    ?.services?.find((s) => s.id === service?.id);
  const provisionedService = serviceProfile?.highlighting?.provisioned_values?.slices
    ?.find((p) => p.id === sliceId)
    ?.services?.find((s) => s.id === service?.id);
  return { provisionedService, unprovisionedService };
};

const getServicePropertySyncStatus = (
  propertyName: keyof Service,
  serviceProfile?: ServiceProfile,
  sliceId?: string,
  service?: Service
): SyncResults => {
  if (serviceProfile && sliceId && service) {
    const serviceStatus = getServiceSyncStatus(sliceId, service, serviceProfile)?.syncStatus;
    if (
      serviceStatus === SyncStatus.PendingDeletion ||
      serviceStatus === SyncStatus.PendingCreation ||
      serviceStatus === SyncStatus.None
    )
      return { oldValue: undefined, syncStatus: serviceStatus };
    const { provisionedService, unprovisionedService } = getServiceProvisionsProps(sliceId, service, serviceProfile);
    if (
      unprovisionedService?.[propertyName] &&
      unprovisionedService?.[propertyName] !== provisionedService?.[propertyName]
    )
      return { oldValue: provisionedService?.[propertyName], syncStatus: SyncStatus.PendingModification };
  }
  return { oldValue: undefined, syncStatus: SyncStatus.None };
};

export const compareService = (sliceId: string, service?: Service, serviceProfile?: ServiceProfile) => {
  const { provisionedService, unprovisionedService } = getServiceProvisionsProps(sliceId, service, serviceProfile);
  if (service?.to_be_deleted) return SyncStatus.PendingDeletion;
  else if (!unprovisionedService) return SyncStatus.None;
  else if (!provisionedService) return SyncStatus.PendingCreation;
  else {
    if (
      (unprovisionedService.default && unprovisionedService.default !== provisionedService.default) ||
      (unprovisionedService.name && unprovisionedService.name !== provisionedService.name) ||
      (unprovisionedService.type && unprovisionedService.type !== provisionedService.type)
    )
      return SyncStatus.PendingModification;
  }
  return SyncStatus.None;
};

//#endregion
