import base from './config-base.json';

export const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
export const baseApiUrlDashboard = baseApiUrl + '/portal';

const LOCAL_STORAGE = 'Horus';
const LOCAL_STORAGE_ADMIN = `${LOCAL_STORAGE}-admin`;

const LOCAL_STORAGE_I18N = `${LOCAL_STORAGE}-i18n`;

const config = {
  ...base,
  localStorage: LOCAL_STORAGE,
  localStorageAdmin: LOCAL_STORAGE_ADMIN,
  localStorageI18N: LOCAL_STORAGE_I18N,
  sentry: {
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
  apis: {
    bootstrap: `${baseApiUrlDashboard}/api/configuration`,
    //checkToken: `${baseApiUrlDashboard}/api/user/check`, // NOTE: the checkToken action has been removed
    refreshToken: `${baseApiUrlDashboard}/api/user/refresh`,

    authenticate: `${baseApiUrlDashboard}/api/session/authenticate`,
    login: `${baseApiUrlDashboard}/api/session/login`,
    sendReset: `${baseApiUrlDashboard}/api/session/forgotpassword`,
    resetPassword: `${baseApiUrlDashboard}/api/session/resetpassword`,
    confirmUser: `${baseApiUrlDashboard}/api/session/confirm/{temporary-code}`,
    confirmUserEmail: `${baseApiUrlDashboard}/api/session/email-confirm/{temporary-code}`,

    me: `${baseApiUrlDashboard}/api/user/me`,
    changePassword: `${baseApiUrlDashboard}/api/user/changepassword`,

    getLandingBadges: `${baseApiUrlDashboard}/api/landing/badges`,

    getTenantParents: `${baseApiUrlDashboard}/api/tenant/tenantparents?o={sort}&l={limit}&p={page}{filters}`,
    getTenantWithSubtenants: `${baseApiUrlDashboard}/api/tenant/tenantwithsubtenants?o={sort}&l={limit}&p={page}{filters}`,
    getTenants: `${baseApiUrlDashboard}/api/tenant/tenants/?o={sort}&l={limit}&p={page}{filters}`,
    createChannelPartner: `${baseApiUrlDashboard}/api/tenant/createchannelpartner`,
    createNetworkManager: `${baseApiUrlDashboard}/api/tenant/createnetworkmanager`,
    deleteTenants: `${baseApiUrlDashboard}/api/tenant/tenant/{id}`,
    getTenant: `${baseApiUrlDashboard}/api/tenant/tenant/{id}`,
    updateTenant: `${baseApiUrlDashboard}/api/tenant/tenant/{id}`,
    updateTenantLogo: `${baseApiUrlDashboard}/api/tenant/{id}/logo`,
    deleteTenantLogo: `${baseApiUrlDashboard}/api/tenant/{id}/logo`,

    getUsers: `${baseApiUrlDashboard}/api/tenant/users/?o={sort}&l={limit}&p={page}{filters}`,
    getUserRoles: `${baseApiUrlDashboard}/api/tenant/roles`,
    createUser: `${baseApiUrlDashboard}/api/tenant/createuser`,
    deleteUser: `${baseApiUrlDashboard}/api/tenant/user/{id}`,
    getUser: `${baseApiUrlDashboard}/api/tenant/user/{id}`,
    updateUser: `${baseApiUrlDashboard}/api/tenant/user/{id}`,

    geolocal: 'https://nominatim.openstreetmap.org/search?q={address}&format=json&addressdetails=0', // TODO: remove

    addTenantToUser: `${baseApiUrlDashboard}/api/tenant/adduser`,
    deleteTenantFromUser: `${baseApiUrlDashboard}/api/tenant/removeuser`,
    getTenantsByUser: `${baseApiUrlDashboard}/api/tenant/user/{user_id}/tenant`,
    getPools: `${baseApiUrlDashboard}/api/pools?o={sort}&l={limit}&p={page}{filters}`,

    //getMetadataKeys: `${baseApiUrlDashboard}/api/metadata/keys?l={limit}&p={page}`,
    //getUsims: `${baseApiUrlDashboard}/api/usims/filter?o={sort}&l={limit}&p={page}{filters}`,
    //getUsim: `${baseApiUrlDashboard}/api/usims/{id}`,
    //postUsims: `${baseApiUrlDashboard}/api/usims/filter?o={sort}&l={limit}&p={page}`,
    //searchUsims: `${baseApiUrlDashboard}/api/usims/search`,
    //createUsim: `${baseApiUrlDashboard}/api/usims`,
    //createBulkUsim: `${baseApiUrlDashboard}/api/usims/upload`, // bulk
    //editUsim: `${baseApiUrlDashboard}/api/usims/{id}`, // bulk
    //deleteUsim: `${baseApiUrlDashboard}/api/usims/{id}`, // bulk
    //updateUsimMetadata: `${baseApiUrlDashboard}/api/usims/{id}/metadata`, // bulk
    //activateUsim: `${baseApiUrlDashboard}/api/usims/{id}/activate`, // bulk
    //deactivateUsim: `${baseApiUrlDashboard}/api/usims/{id}/deactivate`, // bulk
    //barUsim: `${baseApiUrlDashboard}/api/usims/{id}/bar`, // bulk
    //unbarUsim: `${baseApiUrlDashboard}/api/usims/{id}/unbar`, // bulk
    //deprovisionUsim: `${baseApiUrlDashboard}/api/usims/{id}/deprovision`, // bulk
    //changeProfileUsim: `${baseApiUrlDashboard}/api/usims/{id}/changeprofile`, // bulk
    //assignToTenantUsim: `${baseApiUrlDashboard}/api/usims/{id}/assigntotenant`, // bulk
    //assignToNodeUsim: `${baseApiUrlDashboard}/api/usims/{id}/assigntonode`, // bulk

    getUsims5g: `${baseApiUrlDashboard}/api/1/usims?o={sort}&l={limit}&p={page}`,
    createUsim5g: `${baseApiUrlDashboard}/api/1/usims`,
    createBulkUsim5g: `${baseApiUrlDashboard}/api/1/usims/upload`, // bulk
    editUsim5g: `${baseApiUrlDashboard}/api/1/usims/{supi}`,
    provisionUsim5g: `${baseApiUrlDashboard}/api/1/usims/provision`, // bulk
    deprovisionUsim5g: `${baseApiUrlDashboard}/api/1/usims/deprovision`, // bulk
    prepareUsim5g: `${baseApiUrlDashboard}/api/1/usims/prepare-provision`, // bulk
    deleteUsims5g: `${baseApiUrlDashboard}/api/1/usims`, // bulk
    updateUsims5gMetadata: `${baseApiUrlDashboard}/api/1/usims/metadata`, // bulk

    //deleteUsims: `${baseApiUrlDashboard}/api/usims/bulk{filters}`, // bulk
    //updateUsimsMetadata: `${baseApiUrlDashboard}/api/usims/bulk/metadata{filters}`, // bulk
    //activateUsims: `${baseApiUrlDashboard}/api/usims/bulk/activate{filters}`, // bulk
    //deactivateUsims: `${baseApiUrlDashboard}/api/usims/bulk/deactivate{filters}`, // bulk
    //barUsims: `${baseApiUrlDashboard}/api/usims/bulk/bar{filters}`, // bulk
    //unbarUsims: `${baseApiUrlDashboard}/api/usims/bulk/unbar{filters}`, // bulk
    //deprovisionUsims: `${baseApiUrlDashboard}/api/usims/bulk/deprovision{filters}`, // bulk
    //changeProfileUsims: `${baseApiUrlDashboard}/api/usims/bulk/changeprofile{filters}`, // bulk
    //assignToTenantUsims: `${baseApiUrlDashboard}/api/usims/bulk/assigntotenant{filters}`, // bulk
    //exportUsims: `${baseApiUrlDashboard}/api/usims/export{filters}`,

    getBulkOperations: `${baseApiUrlDashboard}/api/1/bulk-operations`,
    getBulkOperationById: `${baseApiUrlDashboard}/api/1/bulk-operations/{id}`,

    getGpgKeys: `${baseApiUrlDashboard}/api/gpg-keys?o={sort}&l={limit}&p={page}{filters}`,
    createGpgKey: `${baseApiUrlDashboard}/api/gpg-keys`,
    deleteGpgKey: `${baseApiUrlDashboard}/api/gpg-keys/{id}`,

    getRoles: `${baseApiUrlDashboard}/api/role_management/role`,
    getRole: `${baseApiUrlDashboard}/api/role_management/role/{id}`,
    createRole: `${baseApiUrlDashboard}/api/role_management/role`,
    updateRole: `${baseApiUrlDashboard}/api/role_management/role/{id}`,
    deleteRole: `${baseApiUrlDashboard}/api/role_management/role/{id}`,
    getPermissions: `${baseApiUrlDashboard}/api/role_management/permission`,

    getRegionalSubscriptionsProfiles: `${baseApiUrlDashboard}/api/regional-subscriptions-profiles?o={sort}&l={limit}&p={page}{filters}`,

    // getNodes: `${baseApiUrlDashboard}/api/node_management/node?o={sort}&l={limit}&p={page}{filters}&c={platform}`,
    // getNode: `${baseApiUrlDashboard}/api/node_management/node/{id}`,
    // updateNode: `${baseApiUrlDashboard}/api/node_management/node/{id}`,
    // assignTenantNode: `${baseApiUrlDashboard}/api/node_management/node/{id}/assigntenant`,
    // deassignTenantNode: `${baseApiUrlDashboard}/api/node_management/node/{id}/deassigntenant`,
    // createNode: `${baseApiUrlDashboard}/api/node_management/node`,
    // deleteNode: `${baseApiUrlDashboard}/api/node_management/node/{id}`,
    sendDataToRemoteUDR: `${baseApiUrlDashboard}/api/1/network/{network_id}/connect`,
    // getAvailableNodes: `${baseApiUrlDashboard}/api/node_management/nodes_available`,
    getNetworkTopologies: `${baseApiUrlDashboard}/api/1/network`,

    getPlmns: `${baseApiUrlDashboard}/api/1/network/{network_id}/plmns`,
    createPlmn: `${baseApiUrlDashboard}/api/1/network/{network_id}/plmns`,
    deletePlmn: `${baseApiUrlDashboard}/api/1/network/{network_id}/plmns/{id}`,

    getServiceProfiles: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles`,
    createServiceProfile: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles`,
    editServiceProfile: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}`,
    deleteServiceProfile: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}`,

    createSlice: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices`,
    editSlice: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices/{slice_id}`,
    editService: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services/{service_id}`,

    createService: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services`,
    deleteSlice: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices/{slice_id}`,
    deleteService: `${baseApiUrlDashboard}/api/1/network/{network_id}/service-profiles/{service_profile_id}/slices/{slice_id}/services/{service_id}`,

    getLandingPageUsimsInfo: `${baseApiUrlDashboard}/api/1/landing-page/usims-info`,
    getLandingPageTenantsInfo: `${baseApiUrlDashboard}/api/1/landing-page/tenants-info`,
  },
  adminConfigApis: {
    login: `${baseApiUrlDashboard}/api/admin-configs/login`,
    adminConfig: `${baseApiUrlDashboard}/api/admin-configs`,
    updateAdminConfig: `${baseApiUrlDashboard}/api/admin-configs/{id}`,
  },
} as const;

export default config;
