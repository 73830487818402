import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { batch } from 'react-redux';
import { getUsers } from 'store/actions/users/getUsers';
import { FiltersDrawer, FiltersDrawerItem, defaultStringFilterSchema } from '../Drawer';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { FiltersObj, getDefaultValue } from 'store/models/filters';
import { useUsersFiltersSelector } from 'store/selectors/users';
import { setUsersFilters } from 'store/reducers/users';
import { useAppDispatch } from 'store';

export default function Users() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const values = useUsersFiltersSelector();

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'fullname',
        label: formatMessage({ id: 'users.filters.fullname' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['fullname'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: defaultStringFilterSchema,
      },
      {
        name: 'email',
        label: formatMessage({ id: 'users.filters.email' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: values['email'],
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: defaultStringFilterSchema,
      },
    ],
    [formatMessage, values]
  );

  const handleSubmit = useCallback(
    (filtersValues: FiltersObj) => {
      batch(() => {
        dispatch(setUsersFilters(filtersValues));
        void dispatch(getUsers());
      });
    },
    [dispatch]
  );

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
