import { Location, MapBox } from '@athonet/ui/components/Data/MapBox';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { Network } from '../../../graphql/client/types';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { NetworksMapTooltip } from './NetworksMapTooltip';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { STATUS } from '@athonet/ui/components/Feedback/Status';

export function NetworksMap({ networks }: { networks: Network[] }) {
  const navigate = useNavigate();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();

  const handleNetworkClick = useCallback(
    (location: Partial<Location>) => {
      navigate(`/networks/${location.id}`);
    },
    [navigate]
  );

  const tooltipTranslations = useMemo(
    () => ({
      throughputTitle: formatMessage({ id: 'networks.table.throughput' }),
      uplinkTitle: formatMessage({ id: 'menu.networks.userThroughputPanel.uplink' }),
      downlinkTitle: formatMessage({ id: 'menu.networks.userThroughputPanel.downlink' }),
      noMetricsValue: formatMessage({ id: 'common.error.noMetricsShort' }),
    }),
    [formatMessage]
  );

  const hardwareLocations = useMemo(() => {
    const locations: Location[] = [];

    networks.forEach((network: Network) => {
      const networkTooltipHtml = renderToStaticMarkup(
        <NetworksMapTooltip network={network} translations={tooltipTranslations} />
      );

      const networkStatus =
        network?.status.accessManagement.workingPercentage < 100 ||
        network?.status.userTrafficManagement.workingPercentage < 100 ||
        ((network.radios?.total || 0) > 0 && (network.radios?.active || 0) === 0)
          ? STATUS.ERROR
          : (network.radios?.active || 0) === (network.radios?.total || 0)
          ? STATUS.SUCCESS
          : STATUS.WARNING;

      locations.push({
        id: String(network?.id),
        text: networkTooltipHtml,
        address: '',
        center: [String(network?.location?.long), String(network?.location?.lat)],
        status: networkStatus,
      });
    });
    return locations;
  }, [networks, tooltipTranslations]);

  return (
    <PanelContent>
      <Box>
        <MapBox
          id="networks-map"
          centerAddress={(hardwareLocations[0]?.center || bootstrap?.mapbox_center) ?? ''}
          accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? bootstrap?.mapbox_token ?? ''}
          stylesUrl={process.env.REACT_APP_MAPBOX_STYLES_URL ?? bootstrap?.mapbox_styles ?? ''}
          height={600}
          locations={hardwareLocations}
          zoom={5}
          clusteredMarkers={true}
          clusterMarkerUrl="/mapbox-cluster-marker-filled.png"
          projection="mercator"
          onMarkerClick={handleNetworkClick}
        />
      </Box>
    </PanelContent>
  );
}
