import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { boolean, object, string } from 'yup';
import { Slice, differentiatorRegExp } from 'store/models/serviceProfile';
import { createSlice, editSlice } from 'store/actions/serviceProfiles';
import { useAppDispatch } from 'store';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import FormikDialog from 'components/Form/FormikDialog';
import { Field, FieldProps } from 'formik';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Switch } from '@athonet/ui/components/Input/Switch';

type EditSliceFormDataType = Pick<Slice, 'name' | 'differentiator' | 'is_4g'>;

export function EditSliceDialog({
  serviceProfileId,
  slice,
  networkId,
}: {
  serviceProfileId: string;
  slice?: Slice;
  networkId: string;
}) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .required()
          .trim()
          .label(formatMessage({ id: 'serviceProfiles.slices.form.name.label' })),
        differentiator: string()
          .label(formatMessage({ id: 'serviceProfiles.slices.form.differentiator.label' }))
          .matches(
            differentiatorRegExp,
            formatMessage({ id: 'serviceProfiles.slices.form.differentiator.regexpError' })
          ),
        is_4g: boolean(),
      }),
    [formatMessage]
  );

  const initialValues: EditSliceFormDataType = useMemo(
    () => ({
      name: slice?.name || '',
      differentiator: slice?.differentiator || '',
      is_4g: slice?.is_4g || false,
    }),
    [slice]
  );

  const handleCreateOrEditSlice = useCallback(
    async ({ name, differentiator, is_4g }: EditSliceFormDataType) => {
      const errorRes = slice
        ? await dispatch(
            editSlice({
              networkId,
              serviceProfileId,
              sliceId: slice.id,
              data: { name, differentiator, is_4g },
            })
          )
        : await dispatch(
            createSlice({
              networkId,
              serviceProfileId,
              data: { name, differentiator, is_4g },
            })
          );

      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, networkId, serviceProfileId, slice]
  );

  return (
    <FormikDialog
      errorMessage={
        error
          ? slice
            ? formatMessage({ id: 'serviceProfile.slices.form.edit.error.title' }, { error })
            : formatMessage({ id: 'serviceProfile.slices.form.create.error.title' }, { error })
          : undefined
      }
      onSubmit={handleCreateOrEditSlice}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize={true}
      submitWithNoChanges={false}
    >
      {({ setFieldValue }) => {
        return (
          <BaseFieldset>
            <TextFieldField
              fullWidth
              name="name"
              placeholder={formatMessage({ id: 'serviceProfiles.slices.form.name.placeholder' })}
            />
            <TextFieldField
              name="differentiator"
              fullWidth
              placeholder={formatMessage({ id: 'serviceProfiles.slices.form.differentiator.placeholder' })}
            />
            <Field name="is_4g" key="is_4g">
              {({ field }: FieldProps<boolean>) => (
                <Stack fullWidth direction="row" align="center" justify="flex-start">
                  <Text>{formatMessage({ id: 'serviceProfiles.slices.form.is_4g' })}</Text>
                  <Switch
                    {...field}
                    defaultChecked={field.value}
                    onChange={(e) => void setFieldValue('is_4g', e.target.checked)}
                  />
                </Stack>
              )}
            </Field>
          </BaseFieldset>
        );
      }}
    </FormikDialog>
  );
}
