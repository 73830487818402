import { Thunk } from '.';
import { showErrorToast, showSuccessToast } from './toast';
import { fetchData } from './fetchData';
import config from 'config';
import { AxiosRequestConfig } from 'axios';
import { AppAxiosError, parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';
import { PLMN } from 'store/models/plmn';
import { getPlmns } from './plmns/getPlmns';
import { intl } from 'CreateIntlProvider';

type CreatePlmnRequest = { networkId: string; name: string; mcc: string; mnc: string };

export function createPlmn({ networkId, name, mcc, mnc }: CreatePlmnRequest): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createPlmn.replace('{network_id}', networkId),
        method: 'POST',
        data: {
          name,
          mcc,
          mnc,
        },
      };
      await dispatch(fetchData(options));
      await dispatch(getPlmns({ networkId }));
      dispatch(showSuccessToast());
      return;
    } catch (e) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

type DeletePlmnRequest = { networkId: string; plmnId: PLMN['id'] };

export function deletePlmn({ plmnId, networkId }: DeletePlmnRequest): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deletePlmn.replace('{network_id}', networkId).replace('{id}', plmnId),
      method: 'DELETE',
    };

    try {
      await dispatch(fetchData(options));
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      const parsedError = parseError(e as AppAxiosError);
      const message = `${intl?.formatMessage({ id: 'toastr.failure' })}${parsedError ? ': ' + parsedError : ''}`;
      dispatch(showErrorToast({ message }));
    }
  };
}
