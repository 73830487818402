import { useCallback, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import { User } from 'store/models/user';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { editUser } from 'store/actions/users';
import FormikDialog from 'components/Form/FormikDialog';
import { EditUserForm } from './EditUserForm';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { isEntityLoading } from 'store/reducers';
import { Progress } from '@athonet/ui/components/Feedback/Progress';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useIntl } from 'react-intl';
import { getUserById } from 'store/actions/users/getUserById';
import { EditUserDataType } from '../types';
import { useAppDispatch, useAppSelector } from 'store';

type EditProps = {
  userId: User['id'];
};

export function EditUserDialog({ userId }: EditProps) {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();
  const editedUser = useAppSelector((state) => state.users?.entity);
  const { formatMessage } = useIntl();

  const handleEditUser = useCallback(
    async (values: EditUserDataType) => {
      if (!editedUser.data) {
        return;
      }
      await dispatch(editUser(editedUser.data.id, values));
      dialogClose();
    },
    [dialogClose, dispatch, editedUser]
  );

  useEffect(() => {
    void dispatch(getUserById({ userId }));
  }, [dispatch, userId]);

  const schema = useMemo(
    () =>
      object().shape({
        fullName: string()
          .required()
          .label(formatMessage({ id: 'users.form.user.fullname' })),
        role: string()
          .required()
          .label(formatMessage({ id: 'users.form.user.role' })),
        phone: string().label(formatMessage({ id: 'users.form.user.phone' })),
        email: string()
          .email()
          .when('realm', {
            is: AUTH_PROVIDER.ENTERPRISE,
            then: (fieldSchema) => fieldSchema.required(),
            otherwise: (fieldSchema) => fieldSchema,
          })
          .label(formatMessage({ id: 'users.form.user.email' })),
      }),
    [formatMessage]
  );

  const initialValues = useMemo(() => ({ ...editedUser.data, fullName: editedUser.data?.fullname }), [editedUser.data]);

  if (isEntityLoading(editedUser)) {
    return (
      <Stack justify="center" align="center" fullWidth sx={{ height: 294 }}>
        <Progress type="circular" size={48} />
      </Stack>
    );
  }

  return editedUser.data ? (
    <FormikDialog
      alertPendingChanges={true}
      onSubmit={handleEditUser}
      initialValues={initialValues}
      validationSchema={schema}
    >
      <EditUserForm user={editedUser.data} />
    </FormikDialog>
  ) : null;
}
