import React, { useEffect } from 'react';
import { getTranslations } from 'utils/locale';
import { IntlShape, createIntl } from 'react-intl';
import { useLocaleSelector } from 'store/selectors/locale';

export let intl: IntlShape | undefined;

/**
 * The CreateIntlProvider component creates an internationalization provider using the selected locale
 * and translations. Useful when we need to use `formatMessage` outside a react component,
 * just import anywhere the `intl` instance.
 * @returns The component is returning an empty fragment (`<></>`).
 */

const CreateIntlProvider: React.FC = () => {
  const locale = useLocaleSelector();
  useEffect(() => {
    if (!locale) return;
    intl = createIntl({ locale: locale, messages: getTranslations(locale) });
  }, [locale]);

  return <></>;
};

export default CreateIntlProvider;
