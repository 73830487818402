import config from 'config';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export function useLocalizedConfig() {
  const { locale } = useIntl();
  const localizedConfig = useMemo(() => {
    const langLocale = locale.split('-')[0] as 'it' | 'en' | 'es' | 'de' | 'fr';
    return config[langLocale] || config.en;
  }, [locale]);
  return localizedConfig;
}
