import { useEffect, useCallback, useMemo, useRef } from 'react';
import { batch } from 'react-redux';
import { deleteTenant } from 'store/actions/tenants';
import { useIntl } from 'react-intl';
import Filters from 'components/Filters/Tenants';
import List from 'components/List';
import DropdownTools, { DropdownToolsOption } from 'components/DropdownTools';
import { U_PERMISSIONS, T_PERMISSIONS, checkPermissionsList } from 'utils/permissionCodes';
import { getCountryLabel } from 'utils/getStatesCountries';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Button } from '@athonet/ui/components/Input/Button';
import EditTenant from 'components/Edit/Tenant/EditTenant';
import { selectTenantFilters, selectTenants } from 'store/selectors/tenants';
import { useUserSelector, useCanUserCreateTenantSelector } from 'store/selectors/user';
import { Tenant } from 'store/models/tenant';
import UploadLogoDialog from 'components/Edit/Tenant/logo/UploadLogoDialog';
import { setTenantsFilters, clearTenants, setTenantsSort, setTenantsPageNumber } from 'store/reducers/tenants';
import { getTenantsList } from 'store/actions/tenants/getTenantsList';
import isEmpty from 'lodash/isEmpty';
import { getFiltersByQueryString } from 'store/models/filters';
import { PAGE_LIMIT } from 'store/models/environmentConfiguration';
import { useLocalizedConfig } from 'hooks/useLocalizedConfig';
import { useAppDispatch, useAppSelector } from 'store';
import { DateTime } from 'luxon';

export default function Tenants() {
  const bootstrap = useBootstrapSelector();
  const userdata = useUserSelector();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const {
    data: { data: tenants, page, total },
    state: loadingState,
  } = useAppSelector((state) => selectTenants(state));
  const filters = useAppSelector((state) => selectTenantFilters(state));

  const { confirmationDialogOpen, dialogOpen } = useOverlay();
  const canUserCreateTenant = useCanUserCreateTenantSelector();

  const localizedConfig = useLocalizedConfig();
  const queryStringFilters = useMemo(() => getFiltersByQueryString(), []);
  const initialPageLimit = useRef<number>(PAGE_LIMIT);
  const initialSort = useRef<string>('name');

  useEffect(() => {
    if (!isEmpty(filters)) return;
    if (!isEmpty(queryStringFilters)) dispatch(setTenantsFilters(queryStringFilters)); //set filters only if not in the state
    void dispatch(getTenantsList());
    return () => {
      dispatch(clearTenants()); //or reset? clear redux when leaves page for best performances
    };
  }, [dispatch, filters, queryStringFilters]);

  useEffect(() => {
    if (bootstrap?.pageLimit && bootstrap?.pageLimit !== initialPageLimit.current) {
      initialPageLimit.current = bootstrap?.pageLimit!;
      void dispatch(getTenantsList());
    }
  }, [dispatch, bootstrap?.pageLimit, initialPageLimit]);

  const handleRefresh = useCallback(() => {
    void dispatch(getTenantsList());
  }, [dispatch]);

  const handlePageChange = useCallback(
    (pageChanged: number) => {
      batch(() => {
        dispatch(setTenantsPageNumber(pageChanged));
        void dispatch(getTenantsList());
      });
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (orderQuery: string) => {
      batch(() => {
        if (orderQuery && orderQuery !== initialSort.current) {
          dispatch(setTenantsSort(orderQuery));
          initialSort.current = orderQuery!;
          void dispatch(getTenantsList());
        }
      });
    },
    [dispatch]
  );

  const openBranding = useCallback(
    ({ id, name }: Tenant) => {
      dialogOpen({
        title: `${formatMessage({ id: 'tenants.form.logoUpload.title' })} ${name}`,
        content: () => <UploadLogoDialog tenantId={id} />,
        closeOnBackdropClick: false,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openEdit = useCallback(
    (idEdit: string) => {
      dialogOpen({
        title: formatMessage({ id: 'tenants.editTenant' }),
        content: () => <EditTenant tenantId={idEdit} />,
        'data-testid': 'edit-form',
        id: idEdit,
      });
    },
    [dialogOpen, formatMessage]
  );

  const openDelete = useCallback(
    ({ id, name }: Tenant) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'tenants.deleteTenant.confirm' }),
        title: formatMessage({ id: 'tenants.deleteTenant.confirm.title' }),
        alertMessage: formatMessage({ id: 'tenants.itemsAffected' }, { element: name }),
        continueButtonText: formatMessage({ id: 'tenants.deleteTenant.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => void dispatch(deleteTenant(id)),
        severity: 'danger',
        dataTestid: 'confirm-delete-tenant',
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage]
  );

  const handleOpenCreate = useCallback(
    () =>
      dialogOpen({
        title: formatMessage({ id: 'tenants.newTenant' }),
        content: () => <EditTenant />,
        'data-testid': 'create-form',
      }),
    [dialogOpen, formatMessage]
  );

  const getTools = useCallback(
    (rowData: Tenant) => {
      const options: DropdownToolsOption[] = checkPermissionsList(
        userdata?.permissions || [],
        [
          {
            iconName: 'Pencil',
            value: 'edit-tenant',
            label: formatMessage({ id: 'tenants.editTenant' }),
            onClick: () => openEdit(rowData.id),
            permissions: [U_PERMISSIONS.UPDATE_TENANT],
          },
          ...(bootstrap && bootstrap.branding_enabled
            ? ([
                {
                  iconName: 'Building',
                  value: 'branding',
                  label: formatMessage({ id: 'user.menu.branding' }),
                  onClick: () => openBranding(rowData),
                },
              ] as DropdownToolsOption[])
            : ([] as DropdownToolsOption[])),
          {
            iconName: 'Trashcan',
            value: 'delete-tenant',
            label: formatMessage({ id: 'tenants.deleteTenant' }),
            onClick: () => openDelete(rowData),
            permissions: [U_PERMISSIONS.DELETE_TENANT],
          },
        ],
        false
      );

      return <DropdownTools options={options} />;
    },
    [bootstrap, formatMessage, openBranding, openDelete, openEdit, userdata?.permissions]
  );

  const columns = useMemo(() => {
    const initialCols = [
      {
        key: 'id',
        title: 'ID',
        dataKey: 'id',
        width: 200,
        maxWidth: 200,
        minWidth: 100,
        visible: false,
      },
      {
        key: 'type',
        title: formatMessage({ id: 'tenants.table.type' }),
        dataKey: 'type',
        cellRenderer: ({ cellData: type }: { cellData: string }) => {
          let label;
          switch (type) {
            case 'master':
              label = formatMessage({ id: 'common.master' });
              break;
            case 'channel_partner':
              label = formatMessage({ id: 'common.channelPartner' });
              break;
            case 'network_manager':
              label = formatMessage({ id: 'common.networkManager' });
              break;
            default:
              label = '';
              break;
          }
          return <Chip id={label} size="small" label={label} color="secondary" />;
        },
        sortable: true,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        permissions: [T_PERMISSIONS.MASTER],
        visible: false,
      },
      {
        key: 'name',
        title: formatMessage({ id: 'tenants.table.tenantName' }),
        dataKey: 'name',
        sortable: true,
        defaultSort: 'asc', // set the columns sorted as default
        width: 160,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
      },
      {
        key: 'parent',
        title: formatMessage({ id: 'tenants.table.parent' }),
        dataKey: 'parent_tenant_name',
        sortable: true,
        width: 160,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
        permissions: [T_PERMISSIONS.MASTER],
      },
      {
        key: 'address',
        title: formatMessage({ id: 'tenants.table.address' }),
        dataKey: 'address',
        sortable: true,
        width: 300,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
      },
      {
        key: 'country',
        title: formatMessage({ id: 'tenants.table.country' }),
        dataKey: 'country',
        sortable: true,
        width: 200,
        headerClassName: 'table-cell-resizable', // for columns auto-resizable
        className: 'table-cell-resizable', // for columns auto-resizable
        minWidth: 100,
        cellRenderer: ({ cellData: country }: { cellData: string }) => {
          return <>{getCountryLabel(country)}</>;
        },
      },
      {
        key: 'created_at',
        title: formatMessage({ id: 'tenants.table.creation' }),
        dataKey: 'created_at',
        sortable: true,
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        cellRenderer: ({ cellData }: { cellData: string }) => {
          return <>{DateTime.fromISO(cellData).toFormat(localizedConfig.fullDateFormat)}</>;
        },
      },
      {
        key: 'tools',
        title: '',
        dataKey: 'tools',
        width: 60,
        maxWidth: 60,
        minWidth: 60,
        secret: true, // secret used to hide from columns management panel
        cellRenderer: ({ rowData }: { rowData: Tenant }) => {
          return <>{getTools(rowData)}</>;
        },
      },
    ];
    return checkPermissionsList([userdata?.tenant_type], initialCols);
  }, [formatMessage, getTools, localizedConfig.fullDateFormat, userdata]);

  return (
    <List
      key="tenant-list"
      columns={columns}
      totalRows={total}
      data={tenants}
      page={page}
      filters={filters}
      onOrderChange={handleSortChange}
      loadingState={loadingState}
      {...(canUserCreateTenant && {
        createComponent: (
          <Button
            onClick={handleOpenCreate}
            text={formatMessage({ id: 'tenants.newTenant' })}
            data-testid="toolbar-new-button"
            startIcon="Add"
            variant="outlined"
          />
        ),
      })}
      filtersComponent={<Filters />}
      onPageChange={handlePageChange}
      toolbar={{ actions: false }}
      rowsPerPage={bootstrap?.pageLimit}
      onRefresh={handleRefresh}
    />
  );
}
