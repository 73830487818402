import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { lsGet } from 'utils/localStorage';
import { User } from 'store/models/user';
import { baseApiUrl } from 'config';

const httpLink = createHttpLink({
  uri: baseApiUrl + '/monitoring/api',
});

const errorLink = onError(({ forward, operation, graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // if (message === 'unauthorized') {
        //   window.location.href = '/signout';
        // }
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    } else {
      console.error(graphQLErrors);
    }
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  forward(operation);
});

const authLink = setContext((_, { headers }) => {
  const horusStorage = lsGet<User>();
  return {
    headers: {
      ...headers,
      'horus-token': horusStorage?.accessToken,
    },
  };
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          networkSummary: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
          userSummary: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  }),
  link: from([authLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-only',
    },
  },
});
