import React from 'react';
import { TextProps } from '@athonet/ui/components/Guidelines/Text';
import { Usim5g } from 'store/models/usim5g';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { useNavigate } from 'react-router-dom';
import UsimTooltipIcon from './UsimTooltipIcon';
import { Box } from '@athonet/ui/components/Surfaces/Box';

interface UsimSupiLinkProps {
  usim5g: Usim5g;
}

const UsimSupiLink: React.FC<TextProps & UsimSupiLinkProps> = (props) => {
  const { usim5g } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Link onClick={() => navigate(`/subscribers/${usim5g.supi}`)}>{usim5g.supi}</Link>
      <UsimTooltipIcon usim5g={usim5g} />
    </Box>
  );
};

export default UsimSupiLink;
