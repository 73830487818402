import React from 'react';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { useAppSelector } from 'store';
import { getPlmnSyncStatus, selectPlmnById } from 'store/selectors/plmns';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useIntl } from 'react-intl';

interface PlmnNameProps {
  id: string;
}

const PlmnName: React.FC<PlmnNameProps> = (props) => {
  const { id, ...textProps } = props;
  const { formatMessage } = useIntl();

  const syncStatus = useAppSelector((state) => getPlmnSyncStatus(state, id));
  const plmn = useAppSelector((state) => selectPlmnById(state, id));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <RemoteSyncText type={'body2'} syncStatus={syncStatus} text={plmn?.name} {...textProps} />
      <RemoteSyncTooltipIcon
        syncStatus={syncStatus}
        text={formatMessage({ id: 'common.highlighting.remoteSyncPending' })}
      />
    </Box>
  );
};

export default PlmnName;
