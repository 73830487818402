import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { BasePanel } from 'components/Panel/BasePanel';
import { useIntl } from 'react-intl';
import { isEntityLoading } from 'store/reducers';
import { useBulkOperationsEntity } from 'store/selectors/bulkOperations';

export function ProgressGridItem() {
  const bulkOperation = useBulkOperationsEntity();
  const { formatMessage } = useIntl();

  return (
    <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
      <BasePanel
        title={formatMessage({ id: 'singleBulkOperation.progress.title' })}
        folder
        skeleton={isEntityLoading(bulkOperation)}
        height={235}
      >
        <PanelContent>
          <GridContainer>
            {bulkOperation.data && (
              <>
                <KeyValueGridItem
                  label={formatMessage({ id: 'singleBulkOperation.progress.title' })}
                  value={`${Math.round(bulkOperation.data.progress ?? 0)}%`}
                />
                <GridItem size={{ xs: 12, sm: 6 }} sx={{ alignSelf: 'center' }}>
                  {bulkOperation.data.errors && (
                    <Chip
                      id="error-chip"
                      color="error"
                      label={formatMessage({ id: 'singleBulkOperation.completedActions.errors' })}
                    />
                  )}
                  {!bulkOperation.data.pending_actions && !bulkOperation.data.errors && (
                    <Chip
                      id="success-chip"
                      color="success"
                      label={formatMessage({ id: 'singleBulkOperation.completedActions.noErrors' })}
                    />
                  )}
                </GridItem>
                <KeyValueGridItem
                  value={bulkOperation.data.completed_actions}
                  label={formatMessage({ id: 'singleBulkOperation.completedActions.title' })}
                />
                <KeyValueGridItem
                  label={formatMessage({ id: 'singleBulkOperation.pendingActions.title' })}
                  value={bulkOperation.data.pending_actions}
                />
              </>
            )}
          </GridContainer>
        </PanelContent>
      </BasePanel>
    </GridItem>
  );
}
