import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { createTenant, editTenant, getParentTenants, getTenant } from 'store/actions/tenants';
import { Tenant, TENANT_TYPE } from 'store/models/tenant';
import { useCanUserUpdateTenantParentSelector, useUserCPTenantSelector, useUserSelector } from 'store/selectors/user';
import getStatesCountries from 'utils/getStatesCountries';
import { object, string } from 'yup';
import { useAppDispatch } from 'store';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import TextFieldField from 'components/Form/Field/TextFieldField';
import FormikDialog from 'components/Form/FormikDialog';

type EditTenantProps = {
  tenantId?: Tenant['id'];
};

type EditTenantFormDataType = {
  parent: AutocompleteItemProps | null;
  type: AutocompleteItemProps | null;
  name: Tenant['name'];
  address: Tenant['address'];
  country: AutocompleteItemProps | null;
};

const countryOptions = getStatesCountries();

export default function EditTenant({ tenantId }: EditTenantProps) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();
  const userdata = useUserSelector();
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [parentTenants, setParentTenants] = useState<Tenant[]>([]);
  const [parentOptions, setParentOptions] = useState<AutocompleteItemProps[]>([]);
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);
  const canUserUpdateTenantParent = useCanUserUpdateTenantParentSelector();
  const userCPTenant = useUserCPTenantSelector();

  useEffect(() => {
    async function populateTenant() {
      if (tenantId) {
        const fetchedTenant = await dispatch(getTenant(tenantId));
        setTenant(fetchedTenant);
      }
    }
    async function populateParentOptions() {
      setAreOptionsLoading(true);
      const fetchedParents: Tenant[] = await dispatch(getParentTenants());
      setParentTenants(fetchedParents);
      setParentOptions(
        fetchedParents.map((p) => ({
          label: p.name,
          value: p.id,
        }))
      );
      setAreOptionsLoading(false);
    }
    void populateParentOptions();
    void populateTenant();
  }, [dispatch, tenantId]);

  // SCHEMA: stateCountryShape, tenantOptionShape, autocompleteOptionShape
  const stateCountryShape = object()
    .shape({
      label: string(),
      value: string(),
      group: string(),
    })
    .nullable();

  const tenantOptionShape = object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .nullable();

  const autocompleteOptionShape = object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .nullable();

  const schema = useMemo(
    () =>
      object().shape({
        parent: tenantOptionShape.required().label(formatMessage({ id: `tenants.form.tenant.parent.label` })),
        type: autocompleteOptionShape.required().label(formatMessage({ id: `tenants.form.tenant.type.label` })),
        name: string()
          .required()
          .label(formatMessage({ id: `tenants.form.tenant.name.label` })),
        country: stateCountryShape.label(formatMessage({ id: `tenants.form.tenant.country.label` })),
        phone: string().label(formatMessage({ id: `users.form.user.phone.label` })),
      }),
    [autocompleteOptionShape, formatMessage, stateCountryShape, tenantOptionShape]
  );

  const typeOptions = useMemo(
    () => [
      {
        label: formatMessage({ id: 'tenants.type.channelPartners' }),
        value: TENANT_TYPE.CHANNEL_PARTNER as AutocompleteItemProps['value'],
      },
      {
        label: formatMessage({ id: 'tenants.type.networkManager' }),
        value: TENANT_TYPE.NETWORK_MANAGER as AutocompleteItemProps['value'],
      },
    ],
    [formatMessage]
  );

  const initialParentOption = useMemo(() => {
    if (tenant) {
      return parentOptions.find((p) => p.value === tenant.parent_id) || null;
    }
    if (userdata?.tenant_id && userCPTenant) {
      return parentOptions.find((p) => p.value === userCPTenant) || null;
    }
    return null;
  }, [parentOptions, tenant, userCPTenant, userdata?.tenant_id]);

  const initials: EditTenantFormDataType = useMemo(
    () =>
      tenant
        ? {
            parent: initialParentOption,
            type: typeOptions.find((option) => option.value === tenant.type) || null,
            name: tenant.name,
            address: tenant.address || '',
            country: countryOptions.find((country) => country.value === tenant.country) || null,
          }
        : {
            parent: initialParentOption,
            type: Boolean(userCPTenant) ? typeOptions[1] : null,
            name: '',
            address: '',
            country: null,
          },

    [initialParentOption, tenant, typeOptions, userCPTenant]
  );

  const handleSubmit = useCallback(
    ({ parent, type, country, ...values }: EditTenantFormDataType) => {
      const submitValues = {
        ...values,
        country: String(country?.value) || '',
        type: (String(type?.value) as TENANT_TYPE) || '',
        parent_id: String(parent?.value) || '',
      };

      if (tenantId) {
        void dispatch(editTenant({ values: submitValues, tenantId }));
      } else {
        void dispatch(createTenant(submitValues));
      }
      dialogClose();
    },
    [dialogClose, dispatch, tenantId]
  );

  const canTenantBeUpdated = useMemo(
    () => !tenantId || (tenant && tenant.type === TENANT_TYPE.NETWORK_MANAGER && canUserUpdateTenantParent),
    [canUserUpdateTenantParent, tenant, tenantId]
  );

  return (
    <FormikDialog initialValues={initials} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
      {({ values, setFieldValue }) => {
        return (
          <Stack fullWidth spacing={2} sx={{ pt: 2 }}>
            <AutocompleteField
              disabled={!canTenantBeUpdated}
              name="parent"
              placeholder={formatMessage({ id: `tenants.form.tenant.parent.placeholder` })}
              options={parentOptions}
              onChange={(_, v) => {
                if (!v) {
                  return;
                }
                const parentType = parentTenants.find((p) => p.id === v.value)?.type;
                if (parentType === TENANT_TYPE.CHANNEL_PARTNER) {
                  void setFieldValue('type', typeOptions[1]);
                }
                void setFieldValue('parent', v);
              }}
              loading={areOptionsLoading}
              multiple={false}
              freeSolo={false}
            />
            <Field name="type">
              {() => {
                // note: this field depends on parent selection in many ways
                const parentType = parentTenants.find((p) => {
                  if (typeof values['parent'] === 'string') {
                    return false;
                  }
                  return p.id === values['parent']?.value;
                })?.type;

                const options =
                  values['parent'] && parentType === TENANT_TYPE.CHANNEL_PARTNER ? [typeOptions[1]] : typeOptions;

                return (
                  <AutocompleteField
                    name="type"
                    disabled={Boolean(tenantId || !values['parent'])}
                    options={options}
                    placeholder={formatMessage({ id: `tenants.form.tenant.type.placeholder` })}
                    freeSolo={false}
                  />
                );
              }}
            </Field>
            <TextFieldField name="name" placeholder={formatMessage({ id: `tenants.form.tenant.name.placeholder` })} />
            <TextFieldField
              name="address"
              placeholder={formatMessage({ id: `tenants.form.tenant.address.placeholder` })}
            />
            <AutocompleteField
              name="country"
              placeholder={formatMessage({ id: `tenants.form.tenant.country.placeholder` })}
              options={countryOptions}
              multiple={false}
              freeSolo={false}
            />
          </Stack>
        );
      }}
    </FormikDialog>
  );
}
