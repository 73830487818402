import { NetworksMap } from 'components/Map/NetworkMap';
import { NetworkSummaryData } from 'graphql/loaders/network-summary';
import { AwaitPanel } from 'components/Panel/AwaitPanel';
import { useLoaderData } from 'react-router-dom';
import { useIntl } from 'react-intl';

export function RootMapRoute() {
  const { networkSummaryQuery } = useLoaderData() as NetworkSummaryData;
  const { formatMessage } = useIntl();

  return (
    <AwaitPanel
      folder
      resolve={networkSummaryQuery}
      title={formatMessage({ id: 'landing.networkDeploymentsPanel.title' })}
      height={480}
    >
      {(resolvedNetworkSummary) => {
        const network = resolvedNetworkSummary?.data?.me?.networkSummary?.networks || [];
        return <NetworksMap networks={network} />;
      }}
    </AwaitPanel>
  );
}
