import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { MetadataDictionary, Usim5g } from './../models/usim5g';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { FiltersObj, GetResultsOptions, GetResultsRes } from 'store/models/filters';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { AppAxiosError, parseError } from 'utils/errorString';
import { sentryLogError } from 'sentry';
import { getBulkOperations, showOperationScheduledToast } from './bulkOperations';

export enum USIMS_5G_ACTION_TYPE {
  SORT_SET = 'SORT_SET',
  LIST_LOADING = 'USIMS_5G_LIST_LOADING',
  LIST_SUCCESS = 'USIMS_5G_LIST_SUCCESS',
  LIST_FAILURE = 'USIMS_5G_LIST_FAILURE',
  USIMS_DETAIL_LOADING = 'USIMS_DETAIL_LOADING',
  USIMS_DETAIL_SUCCESS = 'USIMS_DETAIL_SUCCESS',
  USIMS_DETAIL_FAILURE = 'USIMS_DETAIL_FAILURE',
  RESET = 'USIMS_5G_RESET',
  USIM_SHOULD_FLUSH_SELECTION = 'USIM_SHOULD_FLUSH_SELECTION',
}

export function listLoading() {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_LOADING,
  };
}

export function listSuccess(payload: unknown) {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_SUCCESS,
    payload,
  };
}

export function listFailure() {
  return {
    type: USIMS_5G_ACTION_TYPE.LIST_FAILURE,
  };
}

export function reset() {
  return {
    type: USIMS_5G_ACTION_TYPE.RESET,
  };
}

export function getUsims5g({ page = 0, sortBy }: GetResultsOptions): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (sortBy) {
      dispatch({
        type: USIMS_5G_ACTION_TYPE.SORT_SET,
        payload: sortBy,
      });
    }

    dispatch({
      type: USIMS_5G_ACTION_TYPE.LIST_LOADING,
    });

    const {
      bootstrap,
      usims5g: { sort },
    } = getState();

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getUsims5g
          .replace('{sort}', sort)
          .replace('{limit}', `${bootstrap?.pageLimit}`)
          .replace('{page}', `${page}`),
        method: 'GET',
      };

      const result = { ...(await dispatch(fetchData<GetResultsRes<Usim5g>>(options))), page };

      dispatch({
        type: USIMS_5G_ACTION_TYPE.LIST_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast({ message: 'usims.table.error', intlMessage: true }));
      dispatch({
        type: USIMS_5G_ACTION_TYPE.LIST_FAILURE,
      });
    }
  };
}

type CreateUsim5gPayload = {
  name: string;
  tenant_id: string;
  supi: string;
  msisdn: string;
  key_type: number;
  k: Usim5g['k'];
  op: Usim5g['op'];
  opc: Usim5g['opc'];
  /* TODO: update when encryption is contemplated again */
  // | 'encrypt' | 'use_default_tk' | 'use_key'
};

// export type CreateUsim5gPayload = Pick<
//   Usim5gFields,
//   'name' | 'tenant' | 'supi' | 'msisdn' | 'key_type' | 'k' | 'op' | 'opc'
// >;

export function createUsim5g(data: CreateUsim5gPayload): Thunk<Promise<string | false>> {
  return async (dispatch) => {
    const newData: Partial<Usim5g> & { tenant_id: string } = {
      name: data.name.trim(),
      tenant_id: data.tenant_id,
      supi: data.supi,
      msisdn: data.msisdn,
      // encrypt: data.encrypt,
      ...(Boolean(data.k) && { k: data.k }),
      // ...(!data.use_default_tk && Boolean(data.use_key) && { use_key: data.use_key }),
      ...(data.key_type === 1 && Boolean(data.op) && { op: data.op }),
      ...(data.key_type === 2 && Boolean(data.opc) && { opc: data.opc }),
    };

    const options: AxiosRequestConfig = {
      url: config.apis.createUsim5g,
      method: 'POST',
      data: newData,
    };

    try {
      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));

      return false;
    } catch (e) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

// interface EditSingleUsim5gRequest {
//   data: EditUsim5gPayload;
//   usim5gSupi: Usim5g['supi'];
// }
// type EditUsim5gPayload = Pick<Usim5gFields, 'name' | 'key_type' | 'k' | 'op' | 'opc'>;

interface EditSingleUsim5gRequest {
  name: string;
  k: string; // Usim5g['k'];
  op: Usim5g['op'];
  opc: Usim5g['opc'];
  key_type?: number;
  usim5gSupi: Usim5g['supi'];
  /* TODO: update when encryption is contemplated again */
  // | 'encrypt' | 'use_default_tk' | 'use_key'
}

export function editSingleUsim5g({
  k,
  name,
  op,
  opc,
  key_type,
  usim5gSupi,
}: EditSingleUsim5gRequest): Thunk<Promise<string | void>> {
  return async (dispatch, getState) => {
    const {
      usims5g: { usim5gDetails },
    } = getState();

    const newData = {
      name: name?.trim(),
      /* TODO: update when encryption is contemplated again */
      // encrypt: data.encrypt,
      ...(k !== '' && { k }),
      // ...(!data.use_default_tk && data.use_key !== '' && { use_key: data.use_key }),
      // ...(data.use_default_tk && { use_key: '' }),
      // ...(data.encrypt === 0 && { use_key: '' }),
      ...(key_type === 1 && op !== '' && { op }),
      ...(key_type === 2 && opc !== '' && { opc }),
    };

    /* TODO: update when encryption is contemplated again */
    // if (data.key_type === 0) {
    //   newData.op = null;
    //   newData.opc = null;
    // }

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.editUsim5g.replace('{supi}', usim5gSupi),
        method: 'PUT',
        data: newData,
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));

      if (usim5gDetails.data) {
        await dispatch(getUsims5gBySupi(usim5gDetails.data.supi));
      }
      dispatch(showSuccessToast());
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

interface PrepareSingleUsim5gForProvisioningRequest {
  networkId: string;
  profileId: string;
  usim5gSupi: Usim5g['supi'];
}

export function prepareSingleUsim5gForProvisioning({
  networkId,
  profileId,
  usim5gSupi,
}: PrepareSingleUsim5gForProvisioningRequest): Thunk<Promise<string | void>> {
  return async (dispatch, getState) => {
    try {
      const {
        usims5g: { usim5gDetails },
      } = getState();

      if (!usim5gSupi) {
        throw new Error('invalid usim');
      }

      const options: AxiosRequestConfig = {
        url: config.apis.editUsim5g.replace('{supi}', usim5gSupi),
        method: 'PUT',
        data: { network_topology_id: networkId, profile_id: profileId },
      };

      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));

      if (usim5gDetails.data) {
        await dispatch(getUsims5gBySupi(usim5gDetails.data.supi));
      }

      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

interface PrepareUsim5gRequest {
  networkId: string;
  profileId: string;
  usimIds?: Usim5g['id'][] | null;
  filters?: FiltersObj;
}

export function bulkPrepareUsims5g({
  networkId,
  profileId,
  usimIds,
  filters,
}: PrepareUsim5gRequest): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.prepareUsim5g,
        method: 'POST',
        data: {
          network_topology_id: networkId,
          profile_id: profileId,
          usims: usimIds || null,
          //filters: TODOPS it will be implemented soon. right now usims: "null" is equal to filters: {}
        },
      };
      await dispatch(fetchData(options));
      await dispatch(getUsims5g({}));
      await dispatch(getBulkOperations());
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

interface ProvisionUsim5gRequest {
  usimIds?: Usim5g['id'][];
  filters?: FiltersObj;
}

export function provisionUsim5g({ usimIds, filters }: ProvisionUsim5gRequest): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.provisionUsim5g,
        method: 'POST',
        data: filters !== undefined ? { filters } : { filters: { usims: usimIds } },
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function deprovisionUsim5g({ usimIds, filters }: ProvisionUsim5gRequest): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deprovisionUsim5g,
        method: 'POST',
        data: filters !== undefined ? { filters } : { filters: { usims: usimIds } },
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function setUsim5gShouldFlushSelection(shouldFlushSelection: boolean) {
  return {
    type: USIMS_5G_ACTION_TYPE.USIM_SHOULD_FLUSH_SELECTION,
    payload: shouldFlushSelection,
  };
}

interface DeleteUsim5gRequest {
  usimIds?: Usim5g['id'][];
  filters?: FiltersObj;
}

export function deleteUsim5g({ usimIds, filters }: DeleteUsim5gRequest): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deleteUsims5g,
        method: 'DELETE',
        data: filters !== undefined ? { filters } : { filters: { usims: usimIds } },
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
    } catch (e: unknown) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function getUsims5gBySupi(supi: Usim5g['supi']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.apis.editUsim5g.replace('{supi}', encodeURIComponent(supi))}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
    };
    try {
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_LOADING });

      //TODOPS uncomment to mock slow apis await new Promise((r) => setTimeout(r, 3500));
      const usimsDetail = await dispatch(fetchData(options));
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_SUCCESS, payload: usimsDetail });
    } catch (e: any) {
      dispatch({ type: USIMS_5G_ACTION_TYPE.USIMS_DETAIL_FAILURE });
      const message = e.response?.data?.error;
      dispatch(showErrorToast(message ? { message } : undefined));
    }
  };
}

type CreateBulkUsim5gRequest = {
  upload: File;
  tenant_id: string;
};

export function createBulkUsim5g({ upload, tenant_id }: CreateBulkUsim5gRequest): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const formData = new FormData();

    formData.append('uploadfile', upload);
    formData.append('tenant_id', tenant_id);

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createBulkUsim5g,
        method: 'POST',
        transformRequest: (_, _1) => {
          return formData;
        },
        data: formData,
      };

      await dispatch(fetchData(options));
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));
      return;
    } catch (e: unknown) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

interface UpdateUsim5gMetadataRequest {
  filters: {
    usims: string[];
  };
  metadata: MetadataDictionary;
}

export function updateUsim5gMetadata(metadata: MetadataDictionary, usims: string[]): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      usims5g: { usim5gDetails },
    } = getState();

    const request: UpdateUsim5gMetadataRequest = {
      filters: { usims },
      metadata: metadata,
    };

    try {
      const options: AxiosRequestConfig = {
        url: config.apis.updateUsims5gMetadata,
        method: 'POST',
        data: request,
      };

      await dispatch(fetchData(options));
      await dispatch(showOperationScheduledToast());
      await dispatch(getBulkOperations());
      await dispatch(getUsims5g({}));

      if (usim5gDetails.data) {
        await dispatch(getUsims5gBySupi(usim5gDetails.data.supi));
      }
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}
