import { AppAxiosError, parseError } from 'utils/errorString';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { GetResultsRes } from 'store/models/filters';
import { Service, ServiceProfile } from 'store/models/serviceProfile';
import { sentryLogError } from 'sentry';
import { AutocompleteOption } from 'utils/forms';
import { getServiceProfiles } from './serviceProfiles/getServiceProfiles';

export function getServiceProfilesOptions(networkId: string): Thunk<Promise<AutocompleteOption[]>> {
  //TODOPS better store service profiles by node in the redux entities state
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getServiceProfiles.replace('{network_id}', networkId),
      };
      const result = await dispatch(fetchData<GetResultsRes<ServiceProfile>>(options));
      return result.items.map((item) => ({ label: item.name, value: item.id }));
    } catch (e) {
      sentryLogError(e);
      return [];
    }
  };
}

export function editServiceProfileFetcher(
  options: AxiosRequestConfig,
  networkId: string
): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    try {
      await dispatch(fetchData(options));
      await dispatch(getServiceProfiles({ networkId }));
      dispatch(showSuccessToast());
      return;
    } catch (e) {
      sentryLogError(e);

      const error = e as AppAxiosError;
      return parseError(error);
    }
  };
}

type CreateServiceProfilePayload = {
  networkId: string;
  data: { name: string; slices?: { name: string; differentiator: string; services: Omit<Service, 'id'>[] }[] };
};
export function createServiceProfile({
  networkId,
  data,
}: CreateServiceProfilePayload): Thunk<Promise<{ error?: string; id?: string }>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createServiceProfile.replace('{network_id}', networkId),
      method: 'POST',
      data: {
        name: data.name,
        slices: data.slices || [],
      },
    };
    try {
      const result = await dispatch(fetchData<{ id: string }>(options));
      await dispatch(getServiceProfiles({ networkId }));
      dispatch(showSuccessToast());
      return { id: result.id };
    } catch (e) {
      sentryLogError(e);
      const error = e as AppAxiosError;
      return { error: parseError(error) };
    }
  };
}

type CreateSlicePayload = {
  networkId: string;
  serviceProfileId: string;
  data: { name: string; differentiator: string; is_4g: boolean };
};
export function createSlice({ networkId, serviceProfileId, data }: CreateSlicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createSlice.replace('{network_id}', networkId).replace('{service_profile_id}', serviceProfileId),
      method: 'POST',
      data: { services: [], ...data },
    };

    return dispatch(editServiceProfileFetcher(options, networkId));
  };
}

type EditSlicePayload = {
  networkId: string;
  serviceProfileId: string;
  sliceId: string;
  data: { name: string; differentiator: string; is_4g: boolean };
};
export function editSlice({
  networkId,
  serviceProfileId,
  sliceId,
  data,
}: EditSlicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editSlice
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, networkId));
  };
}

type DeleteSlicePayload = {
  networkId: string;
  serviceProfileId: string;
  sliceId: string;
};
export function deleteSlice({ networkId, serviceProfileId, sliceId }: DeleteSlicePayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteSlice
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'DELETE',
    };

    const resError = await dispatch(editServiceProfileFetcher(options, networkId));
    if (resError) {
      dispatch(showErrorToast({ message: resError }));
    }
  };
}

type CreateServicePayload = {
  networkId: string;
  serviceProfileId: string;
  sliceId: string;
  data: Omit<Service, 'id'>;
};
export function createService({
  networkId,
  serviceProfileId,
  sliceId,
  data,
}: CreateServicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createService
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId),
      method: 'POST',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, networkId));
  };
}

type EditServicePayload = {
  networkId: string;
  serviceProfileId: string;
  sliceId: string;
  serviceId: string;
  data: Omit<Service, 'id'>;
};
export function editService({
  networkId,
  serviceProfileId,
  sliceId,
  serviceId,
  data,
}: EditServicePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editService
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId)
        .replace('{service_id}', serviceId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, networkId));
  };
}

type DeleteServicePayload = {
  networkId: string;
  serviceProfileId: string;
  sliceId: string;
  serviceId: string;
};
export function deleteService({
  networkId,
  serviceProfileId,
  sliceId,
  serviceId,
}: DeleteServicePayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.deleteService
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId)
        .replace('{slice_id}', sliceId)
        .replace('{service_id}', serviceId),
      method: 'DELETE',
    };

    const resError = await dispatch(editServiceProfileFetcher(options, networkId));
    if (resError) {
      dispatch(showErrorToast({ message: resError }));
    }
  };
}
