import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { fetchData } from '../fetchData';
import config from 'config';
import { State } from 'store';
import { AppAxiosError } from 'utils/errorString';

interface AddTenantToUserRequest {
  tenantId: string;
  roleName: string;
  userId: string;
}

export const addTenantToUser = createAsyncThunk<
  void,
  AddTenantToUserRequest,
  {
    rejectValue: AppAxiosError;
    state: State;
  }
>('users/addTenant', async ({ tenantId, roleName, userId }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: `${config.apis.addTenantToUser}`,
      method: 'POST',
      data: {
        tenant_id: tenantId,
        role: roleName,
        user_id: userId,
      },
    };

    const response = await dispatch(fetchData<void>(options));
    return response;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
