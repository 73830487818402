import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useIntl } from 'react-intl';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { User } from 'store/models/user';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';

export function EditUserForm({ user }: { user: User }) {
  const { formatMessage } = useIntl();

  return (
    <BaseFieldset label={formatMessage({ id: 'users.form.user.data' })}>
      <Stack direction="row" spacing={2}>
        <TextFieldField
          name="fullName"
          placeholder={formatMessage({ id: 'users.form.user.fullname.placeholder' })}
          sx={{ flexGrow: 1 }}
        />
        <TextFieldField
          name="phone"
          placeholder={formatMessage({ id: 'users.form.user.phone.placeholder' })}
          sx={{ flexGrow: 1 }}
        />
      </Stack>

      {user.realm === AUTH_PROVIDER.ENTERPRISE && (
        <TextFieldField
          name="email"
          placeholder={formatMessage({ id: 'users.form.user.email.placeholder' })}
          sx={{ flexGrow: 1 }}
        />
      )}
    </BaseFieldset>
  );
}
