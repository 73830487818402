import React from 'react';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { useAppSelector } from 'store';
import { getPlmnSyncStatus } from 'store/selectors/plmns';

export interface PlmnTextProps {
  id: string;
  text: string;
}

const PlmnText: React.FC<PlmnTextProps> = (props) => {
  const { id, text, ...textProps } = props;
  const syncStatus = useAppSelector((state) => getPlmnSyncStatus(state, id));

  return <RemoteSyncText type={'body2'} text={text} syncStatus={syncStatus} {...textProps} />;
};

export default PlmnText;
