import { useCallback, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { createUser } from 'store/actions/users';
import { selectCreateUserAuthProviders, useBootstrapSelector } from 'store/selectors/bootstrap';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { showSuccessToast } from 'store/actions/toast';
import FormikDialog from 'components/Form/FormikDialog';
import CreateUserForm from './CreateUserForm';
import { CreateUserDataType } from '../types';
import { useAppDispatch, useAppSelector } from 'store';

const CreateUserDialog = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const bootstrap = useBootstrapSelector();
  const { dialogClose } = useOverlay();
  const createUserAuthProviders = useAppSelector((state) => selectCreateUserAuthProviders(state));
  const [error, setError] = useState('');

  const handleSubmit = useCallback(
    async (values: CreateUserDataType) => {
      try {
        await dispatch(createUser(values));
        dialogClose();
        void dispatch(showSuccessToast());
      } catch (e: any) {
        if (e.response?.data.error?.unique_name) {
          setError(formatMessage({ id: `users.form.user.error.email.unique.${values.realm}` }));
        } else {
          setError(formatMessage({ id: 'users.form.user.error' }));
        }
      }
    },
    [dialogClose, dispatch, formatMessage]
  );

  const validationSchema = useMemo(() => {
    const pattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/;

    return object().shape({
      tenantId: string()
        .required()
        .label(formatMessage({ id: 'users.form.user.tenant.label' })),
      fullName: string()
        .required()
        .label(formatMessage({ id: 'users.form.user.fullname.label' })),
      roleName: string()
        .required()
        .label(formatMessage({ id: 'users.form.user.role.label' })),
      phone: string().label(formatMessage({ id: 'users.form.user.phone.label' })),
      realm: string().oneOf(Object.values(AUTH_PROVIDER)).required(),
      email: string()
        .when('realm', {
          is: AUTH_PROVIDER.ENTERPRISE,
          then: () => string().email().required(),
          otherwise: () => string().email(),
        })
        .label(formatMessage({ id: 'users.form.user.email.label' })),
      password: string()
        .when('realm', {
          is: AUTH_PROVIDER.ENTERPRISE,
          then: () =>
            string()
              .test('password', formatMessage({ id: 'changePassword.requiredNewpasswordError' }), (value) =>
                value ? pattern.test(value.toString()) : false
              )
              .required(formatMessage({ id: 'changePassword.requiredNewpasswordError' })),
          otherwise: () => string(),
        })
        .label(formatMessage({ id: 'users.form.user.password.label' })),
      name: string()
        .when('realm', {
          is: AUTH_PROVIDER.LDAP,
          then: () => string().required(formatMessage({ id: 'users.form.user.username.error' })),
          otherwise: () => string(),
        })
        .label(formatMessage({ id: 'users.form.user.username.label' })),
    });
  }, [formatMessage]);

  const initialValues: CreateUserDataType = useMemo(
    () => ({
      tenantId: '',
      fullName: '',
      roleName: '',
      phone: '',
      email: '',
      password: '',
      realm: createUserAuthProviders[0],
      name: '',
    }),
    [createUserAuthProviders]
  );

  if (!bootstrap) return null;

  // width: { xs: '600px' },
  return (
    <FormikDialog
      errorMessage={error ? formatMessage({ id: error }) : undefined}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <CreateUserForm />
    </FormikDialog>
  );
};

export default CreateUserDialog;
