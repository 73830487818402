import React from 'react';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { useAppSelector } from 'store';
import { selectServiceById, selectServiceTypeSyncStatus } from 'store/selectors/serviceProfiles';

interface ServiceTypeProps {
  id: string;
  serviceProfileId: string;
  sliceId: string;
}

const ServiceType: React.FC<ServiceTypeProps> = ({ id, sliceId, serviceProfileId }) => {
  const service = useAppSelector((state) => selectServiceById(state, serviceProfileId, sliceId, id));
  const { oldValue, syncStatus } = useAppSelector((state) =>
    selectServiceTypeSyncStatus(state, serviceProfileId, sliceId, id)
  );

  return (
    <RemoteSyncText type={'body2'} syncStatus={syncStatus} text={service?.type} tooltipText={oldValue?.toString()} />
  );
};

export default ServiceType;
