import { FiltersObj } from 'store/models/filters';
import { User } from 'store/models/user';
import { DATA_LIFECYCLE, Entity, List, Sort, entityIdle, listIdle } from '.';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getTenantsByUser } from 'store/actions/users/getTenantsByUser';
import { Owner } from 'store/models/tenant';
import { getUsers } from 'store/actions/users/getUsers';
import { getUserById } from 'store/actions/users/getUserById';

export type UsersState = {
  filters: FiltersObj;
  sort: Sort;
  list: List<User>;
  entity: Entity<User>;
  tenantsByUser: Owner[];
};

const initialState: UsersState = {
  filters: {},
  sort: '',
  list: listIdle<User>(),
  entity: entityIdle(),
  tenantsByUser: [],
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    clearUsersList: (state: Draft<UsersState>) => {
      state.list = listIdle();
    },
    setUsersFilters: (state: Draft<UsersState>, action: PayloadAction<FiltersObj>) => {
      state.filters = action.payload;
    },
    setUsersPageNumber: (state: Draft<UsersState>, action: PayloadAction<number>) => {
      state.list.data.page = action.payload;
    },
    setUsersSort: (state: Draft<UsersState>, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    resetTenantsByUser: (state: Draft<UsersState>) => {
      state.tenantsByUser = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenantsByUser.fulfilled, (state, action) => {
        state.tenantsByUser = action.payload;
      })
      .addCase(getUsers.pending, (state) => {
        state.list.state = DATA_LIFECYCLE.LOADING;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.list.state = DATA_LIFECYCLE.SUCCESS;
      })
      .addCase(getUsers.rejected, (state) => {
        state.list.state = DATA_LIFECYCLE.FAILURE;
      })
      .addCase(getUserById.pending, (state) => {
        state.entity.state = DATA_LIFECYCLE.LOADING;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.entity.data = action.payload;
        state.entity.state = DATA_LIFECYCLE.SUCCESS;
      })
      .addCase(getUserById.rejected, (state) => {
        state.entity.state = DATA_LIFECYCLE.FAILURE;
      });
  },
});

export const { setUsersPageNumber, resetTenantsByUser, setUsersSort, setUsersFilters, clearUsersList } =
  usersSlice.actions;

const usersReducer = usersSlice.reducer;
export default usersReducer;
