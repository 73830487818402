import { Role } from 'store/models/role';
import { createSlice } from '@reduxjs/toolkit';
import { getRoles } from 'store/actions/roles/getRoles';
import { DATA_LIFECYCLE } from '.';

export type RolesState = {
  list: Role[];
  status: DATA_LIFECYCLE;
};

const initialState: RolesState = {
  list: [],
  status: DATA_LIFECYCLE.IDLE,
};

const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.status = DATA_LIFECYCLE.LOADING;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = DATA_LIFECYCLE.SUCCESS;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.status = DATA_LIFECYCLE.FAILURE;
    });
  },
});

const rolesReducer = rolesSlice.reducer;
export default rolesReducer;
