import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  title: { id: 'unauthorized.title' },
  message: { id: 'unauthorized.message' },
});

const Unauthorized = () => (
  <Box sx={{ textAlign: 'center' }}>
    <Text type="h1">
      <FormattedMessage {...messages.title} />
    </Text>

    <Text>
      <FormattedMessage {...messages.message} />
    </Text>
  </Box>
);

export default Unauthorized;
