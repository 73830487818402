import { Tenant } from './tenant';

export enum BULK_OPERATION_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  PROGRESS = 'progress',
}

export type BulkOperation = {
  id: string;
  name: string;
  progress: number;
  succeded_actions: number;
  errored_actions: number;
  pending_actions: number;
  status: BULK_OPERATION_STATUS;
  total_actions: number;
  tenant: Tenant;
  operation_id: string | null;
  completed_at: string | null;
  created_at: string;
};

export type ScheduledBulkOperation = string;

export type SingleBulkOperation = {
  actions: [
    {
      command: string;
      errors: string;
      params: string;
    },
  ];
  completed_at: string | null;
  created_at: string;
  errors: boolean | null;
  id: string;
  name: string;
  operation_id: string | null;
  pending_actions: number;
  completed_actions: number;
  progress: number;
  status: string | null;
  tenant: {
    id: string | null;
    name: string | null;
  };
  total_actions: number;
};
