import { useIntl } from 'react-intl';
import AvatarEditor from 'react-avatar-editor';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { UploadLogoDialogProps } from './UploadLogoDialog';
import { Slider } from '@athonet/ui/components/Input/Slider';
import { PALETTE } from '@athonet/ui/theme';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import DeleteIconButtonWithConfirm from 'components/Button/DeleteIconButtonWithConfirm';
import { useAppDispatch } from 'store';
import { deleteTenantLogo, getTenant } from 'store/actions/tenants';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { Tenant } from 'store/models/tenant';

type UploadLogoBoxProps = {
  image: File | string | null;
  setImage: (file: File | null) => void;
  editor: RefObject<AvatarEditor>;
} & UploadLogoDialogProps;

export default function UploadLogoBox({ image, setImage, editor, tenantId }: UploadLogoBoxProps) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [zoom, setZoom] = useState(1);
  const [tenant, setTenant] = useState<Tenant | null>(null);

  const handleGetTenant = useCallback(async () => {
    const result: Tenant | null = await dispatch(getTenant(tenantId));
    setTenant(result);
    return result;
  }, [dispatch, tenantId]);

  const handleZoomSlider = useCallback((event: Event, value: number | Number[]) => {
    if (typeof value === 'number') {
      setZoom(value);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteTenantLogo(tenantId));
      void handleGetTenant();
      dispatch(showSuccessToast());
      setImage(null);
    } catch (e) {
      dispatch(showErrorToast());
    }
  }, [dispatch, handleGetTenant, setImage, tenantId]);

  useEffect(() => {
    void handleGetTenant();
  }, [dispatch, handleGetTenant]);

  return (
    <>
      <AvatarEditor
        ref={editor}
        image={image || tenant?.logo || ''}
        border={0}
        scale={zoom}
        width={322}
        height={62}
        style={{
          border: `16px solid ${PALETTE.background.default}`,
          borderRightWidth: `63px`,
          background: PALETTE.background.default,
          boxSizing: 'content-box',
        }}
      />
      <>
        {tenant?.logo && ( // Always returning parent logo as own logo at the moment, controllers always present */
          <>
            <Stack direction="row" justify="space-between" fullWidth>
              <Box sx={{ width: 160 }}>
                <Slider
                  min={0.5}
                  max={1.5}
                  step={0.1}
                  value={zoom}
                  onChange={handleZoomSlider}
                  label={formatMessage({ id: 'tenants.form.logoUpload.zoomLevel' })}
                />
              </Box>
              <DeleteIconButtonWithConfirm
                onDelete={() => {
                  void handleDelete();
                }}
              />
            </Stack>
          </>
        )}
      </>
    </>
  );
}
