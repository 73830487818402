import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { TextField, TextFieldProps } from '@athonet/ui/components/Input/TextField';

export const DEBOUNCE_MILLISECONDS = 150;

/**
 * The `useDebounce` function is a custom hook in TypeScript React that returns a debounced version of
 * a callback function. see https://www.developerway.com/posts/debouncing-in-react
 * @param {any} callback - The `callback` parameter is a function that you want to debounce. It is the
 * function that will be called after the debounce period has elapsed.
 * @returns The `useDebounce` function returns a debounced version of the provided callback function.
 */
const useDebounce = (callback: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = callback;
  }, [callback]);
  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };
    return debounce(func, DEBOUNCE_MILLISECONDS);
  }, []);
  return debouncedCallback;
};

/**
 * The `DebouncedTextField` component is a debounced version of the `TextField` component,
 * which delays the execution of the `onChange` event handler until a certain amount of time has passed
 * since the last input change.
 * */
const DebouncedTextField: React.FC<TextFieldProps> = (props) => {
  const [localEvent, setLocalEvent] = useState<React.ChangeEvent<HTMLInputElement> | null>({
    target: { value: '' },
  } as any);

  const { value, onChange, ...otherProps } = props;

  useEffect(() => {
    if (!isNil(value)) setLocalEvent({ target: { value } } as any);
  }, [value]);

  const debounceChange = useDebounce(() => {
    if (onChange) onChange(localEvent!);
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEvent(evt);
    debounceChange();
  };

  return <TextField {...otherProps} onChange={handleChange} value={localEvent?.target?.value} />;
};

export default DebouncedTextField;
