import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast } from '../toast';

export const sendDataToRemoteUDR = createAsyncThunk<
  unknown,
  { networkId: string },
  {
    rejectValue: null;
    state: State;
  }
>('networks/sendDataToRemoteUDR', async ({ networkId }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: config.apis.sendDataToRemoteUDR.replace('{network_id}', networkId),
      method: 'POST',
    };

    const result = await dispatch(fetchData(options));
    // await dispatch(showOperationScheduledToast());
    // await dispatch(getBulkOperations());
    // await dispatch(getNodeById(nodeId));

    return result;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    return rejectWithValue(null);
  }
});

// export function sendDataToRemoteUDR(nodeId: Node['id']): Thunk<Promise<void>> {
//     return async (dispatch) => {
//       try {
//         const options: AxiosRequestConfig = {
//           url: config.apis.sendDataToRemoteUDR.replace('{id}', nodeId),
//           method: 'POST',
//         };
//         await dispatch(fetchData(options));
//         await dispatch(showOperationScheduledToast());
//         await dispatch(getBulkOperations());
//         await dispatch(getNodeById(nodeId));
//       } catch (e) {
//         sentryLogError(e);
//         dispatch(showErrorToast());
//       }
//     };
//   }
