import { AxiosRequestConfig } from 'axios';
import { Thunk } from '..';
import config from 'config';
import { editServiceProfileFetcher } from '../serviceProfiles';

type EditServiceProfilePayload = {
  networkId: string;
  serviceProfileId: string;
  data: { name: string };
};
export function editServiceProfile({
  networkId,
  serviceProfileId,
  data,
}: EditServiceProfilePayload): Thunk<Promise<string | void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.editServiceProfile
        .replace('{network_id}', networkId)
        .replace('{service_profile_id}', serviceProfileId),
      method: 'PUT',
      data,
    };

    return dispatch(editServiceProfileFetcher(options, networkId));
  };
}
