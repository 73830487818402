import React from 'react';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useAppSelector } from 'store';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { selectServiceById, selectServiceIsDefaultStatus } from 'store/selectors/serviceProfiles';
import { SyncStatus } from 'components/RemoteSync/utils';
import { useIntl } from 'react-intl';

interface ServiceIsDefaultChipProps {
  id: string;
  serviceProfileId: string;
  sliceId: string;
}

const ServiceIsDefaultChip: React.FC<ServiceIsDefaultChipProps> = ({ id, sliceId, serviceProfileId }) => {
  const { formatMessage } = useIntl();
  const service = useAppSelector((state) => selectServiceById(state, serviceProfileId, sliceId, id));

  const { oldValue, syncStatus } = useAppSelector((state) =>
    selectServiceIsDefaultStatus(state, serviceProfileId, sliceId, id)
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {service?.default === true && <Chip color="secondary" label="Default" />}
      {syncStatus === SyncStatus.PendingModification && (
        <RemoteSyncTooltipIcon
          syncStatus={syncStatus}
          text={`${formatMessage({ id: 'common.highlighting.oldValue' })}: ${oldValue === true ? 'Default' : '-'}`}
        />
      )}
    </Box>
  );
};

export default ServiceIsDefaultChip;
