import { useNavigate, useParams } from 'react-router';
import config from 'config';
import axios, { AxiosRequestConfig } from 'axios';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { FormikHelpers } from 'formik';
import ResetPasswordForm, { ResetPasswordFormDataType } from '../../components/ResetPasswordForm';
import { useAppDispatch } from 'store';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetToken } = useParams() as any;

  const resetSubmit = (values: ResetPasswordFormDataType, formikHelpers: FormikHelpers<ResetPasswordFormDataType>) => {
    const data = new FormData();
    data.append('code', resetToken);
    data.append('newpassword', values.password);

    const options: AxiosRequestConfig = {
      url: `${config.apis.resetPassword}`,
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      },
      data: data,
    };

    axios(options)
      .then(() => {
        // success message
        dispatch(
          showSuccessToast({
            message: 'login.reset.passwordChanged',
            intlMessage: true,
          })
        );
        // go to landing page
        navigate('/login');
      })
      .catch(() => {
        // error message
        dispatch(
          showErrorToast({
            message: 'login.reset.resetError',
            intlMessage: true,
          })
        );
        // reset form
        formikHelpers.resetForm();
      });
  };

  const onBack = () => {
    navigate('/login');
  };

  return <ResetPasswordForm onSubmit={resetSubmit} onBack={onBack} />;
};

export default ResetPassword;
