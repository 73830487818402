import { Suspense, useEffect } from 'react';
import List, { ColumnShape } from 'components/List';
import { T_PERMISSIONS, checkPermissionsList } from 'utils/permissionCodes';
import { Await, useAsyncError, useLoaderData, useNavigate } from 'react-router-dom';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useAppDispatch, useAppSelector } from 'store';
import { DATA_LIFECYCLE } from 'store/reducers';
import { NetworkSummaryData } from 'graphql/loaders/network-summary';
import { Network } from 'graphql/client/types';
import { useIntl } from 'react-intl';
import { showErrorToast } from 'store/actions/toast';
import { STATUS, Status } from '@athonet/ui/components/Feedback/Status';
import { filesize } from 'filesize';
import TenantCell from 'components/TenantCell';
import { getTenantsWithSubtenants } from 'store/actions/tenants/getTenantsWithSubtenants';

function EmptyList({ columns, loadingState }: { columns: ColumnShape<any>[]; loadingState: DATA_LIFECYCLE }) {
  return <List data={[]} loadingState={loadingState} columns={columns} totalRows={5} toolbar={{ actions: false }} />;
}

function LoadingList({ columns }: { columns: ColumnShape<any>[] }) {
  return <EmptyList loadingState={DATA_LIFECYCLE.LOADING} columns={columns} />;
}

function ErrorList({ columns }: { columns: ColumnShape<any>[] }) {
  const dispatch = useAppDispatch();
  const error = useAsyncError();

  useEffect(() => {
    const textError = error ? error?.toString() : '';

    if (!textError) dispatch(showErrorToast());
    else
      dispatch(
        showErrorToast({
          message: error ? error?.toString() : '',
          manualClose: true,
        })
      );
  }, [dispatch, error]);

  return <EmptyList loadingState={DATA_LIFECYCLE.FAILURE} columns={columns} />;
}

export default function Networks() {
  const userdata = useAppSelector((state) => state.user);
  const { formatMessage } = useIntl();
  const { networkSummaryQuery } = useLoaderData() as NetworkSummaryData;
  // const { confirmationDialogOpen } = useOverlay();
  const navigate = useNavigate();
  //const { dialogOpen } = useOverlay();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getTenantsWithSubtenants());
  }, [dispatch]);

  // const handleOpenEdit = useCallback(
  //   (node: Node) => {
  //     dialogOpen({
  //       title: formatMessage({ id: 'nodes.editNode' }),
  //       content: () => (
  //         <EditNode
  //           node={node}
  //           initials={{
  //             display_name: node.display_name,
  //             address: node.address,
  //             country: node.country,
  //             lat: node.lat,
  //             long: node.long,
  //           }}
  //         />
  //       ),
  //     });
  //   },
  //   [dialogOpen, formatMessage]
  // );

  // const openDelete = ({ id: networkId, name }: Node) => {
  //   confirmationDialogOpen({
  //     title: formatMessage({ id: 'nodes.deleteNode.confirm.title' }),
  //     description: formatMessage({ id: 'nodes.deleteNode.confirm' }),
  //     alertMessage: formatMessage({ id: 'nodes.actions.itemsAffected' }, { element: name }),
  //     continueButtonText: formatMessage({ id: 'nodes.deleteNode.confirm.continueButton' }, { elements: 1 }),
  //     onConfirm: async () => await dispatch(deleteNode(networkId)),
  //     dataTestid: 'confirm-delete-node',
  //   });
  // };

  // const getTools = (rowData: Node) => {
  //   const options: DropdownToolsOption[] = checkPermissionsList(userdata?.permissions || [], [
  //     {
  //       iconName: 'Pencil',
  //       label: formatMessage({ id: 'nodes.editNode' }),
  //       onClick: () => handleOpenEdit(rowData),
  //       permissions: [U_PERMISSIONS.UPDATE_NODE],
  //       value: 'edit-node',
  //     },
  //     {
  //       iconName: 'Trashcan',
  //       label: formatMessage({ id: 'nodes.deleteNode' }),
  //       onClick: () => openDelete(rowData),
  //       permissions: [U_PERMISSIONS.DELETE_NODE],
  //       value: 'delete-node',
  //     },
  //   ]);
  //   return <DropdownTools options={options} />;
  // };

  const columns: ColumnShape<Network>[] = [
    {
      key: 'id',
      title: 'ID',
      dataKey: 'id',
      width: 200,
      maxWidth: 300,
      minWidth: 100,
      visible: false,
    },
    {
      key: 'name',
      title: formatMessage({ id: 'networks.table.name' }),
      dataKey: 'name',
      visible: true,
      sortable: true,
      cellRenderer: ({ cellData: name, rowData: { id: networkId } }) => {
        return (
          <Link
            onClick={() => {
              navigate(`/networks/${networkId}`);
            }}
          >
            <Text type={'body2'}>{name}</Text>
          </Link>
        );
      },
      width: 160, // TODO
    },
    {
      key: 'status',
      title: formatMessage({ id: 'networks.table.status' }),
      dataKey: 'status',
      cellRenderer: ({ cellData: status, rowData: { radios } }) => {
        const networkStatus =
          status.accessManagement.workingPercentage < 100 ||
          status.userTrafficManagement.workingPercentage < 100 ||
          ((radios?.total || 0) > 0 && (radios?.active || 0) === 0)
            ? STATUS.ERROR
            : (radios?.active || 0) === (radios?.total || 0)
            ? STATUS.SUCCESS
            : STATUS.WARNING;
        return <Status label="" status={networkStatus} />;
      },
      width: 160, // TODO
    },
    {
      key: 'throughput',
      title: formatMessage({ id: 'networks.table.throughput' }),
      dataKey: 'throughput',
      cellRenderer: ({ cellData: throughput }) => {
        return throughput.uplink === null && throughput.downlink === null ? (
          <>{formatMessage({ id: 'common.error.noMetrics' })}</>
        ) : (
          <>
            <Text type={'body2'}>
              {formatMessage({ id: 'menu.networks.userThroughputPanel.uplink' })}:{' '}
              {throughput.uplink
                ? String(filesize(throughput.uplink))
                : formatMessage({ id: 'common.error.noMetrics' })}
            </Text>
            <Text type={'body2'}>
              {formatMessage({ id: 'menu.networks.userThroughputPanel.downlink' })}:{' '}
              {throughput.downlink
                ? String(filesize(throughput.downlink))
                : formatMessage({ id: 'common.error.noMetrics' })}
            </Text>
          </>
        );
      },
      width: 160, // TODO
    },
    {
      key: 'usersSummary',
      title: formatMessage({ id: 'networks.table.activeUsers' }),
      dataKey: 'usersSummary',
      visible: true,
      sortable: true,
      cellRenderer: ({ cellData: usersSummary }) => {
        return usersSummary?.active === null ? (
          <>{formatMessage({ id: 'common.error.noMetrics' })}</>
        ) : (
          <Text type={'body2'}>
            {formatMessage({ id: 'networks.table.usersSummary.active' })}: {String(usersSummary?.active)}
          </Text>
        );
      },
      width: 160, // TODO
    },
    {
      key: 'tenantId',
      title: formatMessage({ id: 'networks.table.tenants' }),
      dataKey: 'tenantId',
      sortable: false,
      width: 160,
      minWidth: 100,
      permissions: [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER],
      cellRenderer: ({ cellData: tenantId }) => <TenantCell tenantId={tenantId} />,
      visible: true,
    },
    // {
    //   key: 'tools',
    //   title: '',
    //   dataKey: 'tools',
    //   width: 60,
    //   maxWidth: 60,
    //   minWidth: 60,
    //   //TODO not working?
    //   visible: false,
    //   secret: true, // secret used to hide from columns management panel
    //   cellRenderer: ({ rowData }) => {
    //     return <>{getTools(rowData)}</>;
    //   },
    // },
  ];

  const columnsPermitted = checkPermissionsList([userdata?.tenant_type], columns);

  return (
    <Suspense fallback={<LoadingList columns={columnsPermitted} />}>
      <Await resolve={networkSummaryQuery} errorElement={<ErrorList columns={columnsPermitted} />}>
        {(resolvedNetworkSummaryQuery) => {
          const networks = resolvedNetworkSummaryQuery?.data?.me?.networkSummary?.networks || [];
          return (
            <List
              key="node-list"
              columns={columnsPermitted}
              totalRows={networks.length}
              data={networks}
              // page={datalist.page}
              // filters={filters}
              // onOrderChange={onOrderChange}
              loadingState={DATA_LIFECYCLE.SUCCESS}
              // createComponent={checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_NODE) ? <Create /> : undefined}
              // filtersComponent={<Filters />}
              // onPageChange={handlePageChange}
              toolbar={{ actions: false }}
              // rowsPerPage={bootstrap?.pageLimit}
              // onRefresh={handleRefresh}
            />
          );
        }}
      </Await>
    </Suspense>
  );
}
