import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { BulkOperation, ScheduledBulkOperation, SingleBulkOperation } from 'store/models/bulkOperation';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { v4 as uuidv4 } from 'uuid';
import { showInfoToast } from './toast';
import { sentryLogError } from 'sentry';

export enum BULK_OPERATIONS_ACTION_TYPE {
  BULK_OPERATIONS_LOADING = 'BULK_OPERATIONS_LOADING',
  BULK_OPERATIONS_SUCCESS = 'BULK_OPERATIONS_SUCCESS',
  BULK_OPERATIONS_FAILURE = 'BULK_OPERATIONS_FAILURE',
  BULK_OPERATION_ENTITY_LOADING = 'BULK_OPERATION_ENTITY_LOADING',
  BULK_OPERATION_ENTITY_SUCCESS = 'BULK_OPERATION_ENTITY_SUCCESS',
  BULK_OPERATION_ENTITY_FAILURE = 'BULK_OPERATION_ENTITY_FAILURE',
  SET_SCHEDULED_BULK_OPERATION = 'SET_SCHEDULED_BULK_OPERATION',
  DELETE_SCHEDULED_BULK_OPERATION = 'DELETE_SCHEDULED_BULK_OPERATION',
}

export type GetBulkOperationsRes = {
  items: BulkOperation[];
  total_items: number;
};

export type BulkOperationsAction =
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_LOADING;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_SUCCESS;
      payload: GetBulkOperationsRes;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_FAILURE;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_LOADING;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_SUCCESS;
      payload: SingleBulkOperation;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_FAILURE;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.SET_SCHEDULED_BULK_OPERATION;
      payload: ScheduledBulkOperation;
    }
  | {
      type: BULK_OPERATIONS_ACTION_TYPE.DELETE_SCHEDULED_BULK_OPERATION;
      payload: ScheduledBulkOperation;
    };

export function getBulkOperations(): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_LOADING,
      });
      const options: AxiosRequestConfig = {
        url: `${config.apis.getBulkOperations}?o=-created_at&l=50`, // TODO: handle pagination
        method: 'GET',
      };
      const result = await dispatch(fetchData<GetBulkOperationsRes>(options));
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATIONS_FAILURE,
      });
    }
  };
}

export function getBulkOperationById(operationId: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_LOADING,
      });
      const options: AxiosRequestConfig = {
        url: config.apis.getBulkOperationById.replace('{id}', operationId),
      };
      const result = await dispatch(fetchData<SingleBulkOperation>(options));
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_SUCCESS,
        payload: result,
      });
    } catch (e) {
      sentryLogError(e);
      dispatch({
        type: BULK_OPERATIONS_ACTION_TYPE.BULK_OPERATION_ENTITY_FAILURE,
      });
    }
  };
}

export function showOperationScheduledToast(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(
      showInfoToast({
        message: 'bulkOperations.scheduled',
        intlMessage: true,
      })
    );
  };
}

export function setScheduledOperation(): Thunk<Promise<string>> {
  return async (dispatch) => {
    await dispatch(showOperationScheduledToast());
    const uuid = uuidv4();
    dispatch({
      type: BULK_OPERATIONS_ACTION_TYPE.SET_SCHEDULED_BULK_OPERATION,
      payload: uuid,
    });
    return uuid;
  };
}

export function deleteScheduledOperation(uuid: string) {
  return {
    type: BULK_OPERATIONS_ACTION_TYPE.DELETE_SCHEDULED_BULK_OPERATION,
    payload: uuid,
  };
}

// TODO create actions that polls an operation id to be dispatched from another action (same logic as hook)
