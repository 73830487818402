import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import FormikDialog from 'components/Form/FormikDialog';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import DebouncedTextField from 'components/Input/DebouncedTextfield';
import { Field, FieldProps } from 'formik';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { updateUsim5gMetadata } from 'store/actions/usims5g';
import { MetadataDictionary, MetadataKey, availableKeys } from 'store/models/usim5g';

interface Usim5gMetadataEditorProps {
  metadata: MetadataDictionary;
  id: string;
}

const MetadataEditorDialog: React.FC<Usim5gMetadataEditorProps> = ({ id, metadata }) => {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();

  const handleOnSubmit = useCallback(
    (values: MetadataDictionary) => {
      void dispatch(updateUsim5gMetadata(values, [id]));
      dialogClose();
    },
    [dialogClose, dispatch, id]
  );

  return (
    <FormikDialog onSubmit={handleOnSubmit} initialValues={metadata} submitWithNoChanges={false}>
      {({ setFieldValue }) => (
        <BaseFieldset>
          <Stack spacing={2}>
            {availableKeys.map((key: MetadataKey) => {
              return (
                <Field name={key} key={key} fast>
                  {({ field }: FieldProps<string>) => {
                    const isDisabled = field.value === undefined || field.value == null;
                    return (
                      <Stack direction="row" fullWidth align="flex-start" justify="flex-start">
                        <Box sx={{ flex: '1 1 auto' }}>
                          <DebouncedTextField
                            {...field}
                            label={isDisabled ? `${key}: ${formatMessage({ id: 'common.form.notSet' })}` : key}
                            size="small"
                            fullWidth
                            disabled={isDisabled}
                            value={field.value ?? ''}
                          />
                        </Box>
                        {isDisabled ? (
                          <IconButton
                            name="Add"
                            onClick={() => void setFieldValue(field.name, '')}
                            color="success"
                            fontSize="small"
                          />
                        ) : (
                          <IconButton
                            name="Trashcan"
                            onClick={() => void setFieldValue(field.name, null)}
                            color={'error'}
                            fontSize="small"
                          />
                        )}
                      </Stack>
                    );
                  }}
                </Field>
              );
            })}
          </Stack>
        </BaseFieldset>
      )}
    </FormikDialog>
  );
};

export default MetadataEditorDialog;
