import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { BasePanel } from 'components/Panel/BasePanel';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { isEntityLoading } from 'store/reducers';
import { useBulkOperationsEntity } from 'store/selectors/bulkOperations';
import { splitPascalCase } from 'utils/string';

export function DetailsGridItem() {
  const bulkOperation = useBulkOperationsEntity();
  const { formatMessage } = useIntl();

  return (
    <GridItem size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignContent: 'stretch' }}>
      <BasePanel
        title={formatMessage({ id: 'singleBulkOperation.details.title' })}
        folder
        skeleton={isEntityLoading(bulkOperation)}
        height={235}
      >
        <PanelContent>
          {bulkOperation.data && (
            <GridContainer>
              <KeyValueGridItem label="Name" value={splitPascalCase(bulkOperation.data.name)} fullWidth />
              <KeyValueGridItem label="Started At" value={bulkOperation.data.created_at} />
              {bulkOperation.data.completed_at && (
                <KeyValueGridItem
                  label="Completed At"
                  value={`${bulkOperation.data.completed_at} (Completed in ${moment
                    .duration(moment(bulkOperation.data.completed_at).diff(bulkOperation.data.created_at))
                    .humanize()})`}
                />
              )}
            </GridContainer>
          )}
        </PanelContent>
      </BasePanel>
    </GridItem>
  );
}
