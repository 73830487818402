import React from 'react';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { useAppSelector } from 'store';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { selectServiceById, selectServiceNameSyncStatus } from 'store/selectors/serviceProfiles';
import { useIntl } from 'react-intl';

interface ServiceNameTextProps {
  id: string;
  serviceProfileId: string;
  sliceId: string;
}

const ServiceName: React.FC<ServiceNameTextProps> = ({ id, sliceId, serviceProfileId }) => {
  const { formatMessage } = useIntl();
  const service = useAppSelector((state) => selectServiceById(state, serviceProfileId, sliceId, id));
  const { oldValue, syncStatus } = useAppSelector((state) =>
    selectServiceNameSyncStatus(state, serviceProfileId, sliceId, id)
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <RemoteSyncText type={'body2'} syncStatus={syncStatus} text={service?.name} tooltipText={oldValue?.toString()} />
      <RemoteSyncTooltipIcon
        syncStatus={syncStatus}
        text={formatMessage({ id: `common.highlighting.remoteSyncPending` })}
      />
    </Box>
  );
};

export default ServiceName;
