import React from 'react';
import useErrorProps from './useErrorProps';
import { TextFieldProps } from '@athonet/ui/components/Input/TextField';
import { FieldProps } from 'formik';
import DebouncedTextField from 'components/Input/DebouncedTextfield';
import useYupSchemaProps from './useYupSchemaProps';
import { useBaseFormikContext } from '../BaseFormik';

export type TextFieldComponentProps = FieldProps & TextFieldProps;

const TextFieldComponent: React.FC<TextFieldComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, isSubmitting },
    field,
    meta,
    onChange,
    helperText,
    ...textFieldProps
  } = props;

  const { validationSchema } = useBaseFormikContext();
  const yupProps = useYupSchemaProps(field.name, validationSchema);
  const errorProps = useErrorProps(errors[field.name], touched[field.name], helperText);

  return (
    <DebouncedTextField
      size="small"
      name={field.name}
      value={field.value}
      {...yupProps}
      {...errorProps}
      {...textFieldProps}
      onChange={(evt) => {
        void setFieldValue(field.name, evt.target.value, true);
        if (onChange) onChange(evt);
      }}
      disabled={isSubmitting || textFieldProps.disabled}
    />
  );
};

export default TextFieldComponent;
