import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import React from 'react';
import { PanelContentSkeleton } from 'components/Panel/PanelContentSkeleton';

export interface BasePanelProps {
  //TODOPS extends PanelProps
  title?: string;
  headerActionsComponent?: React.ReactNode;
  height?: number;
  folder?: boolean;
  fullHeight?: boolean;
}

interface BasePanelOwnProps {
  skeleton?: boolean;
  /**
   * provide custom skeleton component
   */
  skeletonComponent?: React.ReactNode;
}
/**
 * The `BasePanel` function is a React component that renders a panel with a title, header actions, and
 * content, including an optional skeleton loading state.
 */
export function BasePanel({
  title,
  headerActionsComponent,
  children,
  skeleton = false,
  skeletonComponent,
  height,
  fullHeight,
  folder = true, //TODOPS default = true? collapsible is not working
}: React.PropsWithChildren<BasePanelProps & BasePanelOwnProps>) {
  return (
    <Box sx={{ p: 0.5, width: '100%', minHeight: height ?? undefined }}>
      <Panel
        sx={{
          minHeight: height ?? undefined,
          '.Athonet-MuiCardHeader-content': {
            py: 1,
          },
        }}
        fullHeight={fullHeight}
        folder={folder}
        title={title?.toUpperCase()}
        headerActionsComponent={headerActionsComponent}
      >
        <>
          {skeleton && (skeletonComponent ? skeletonComponent : <PanelContentSkeleton />)}
          {!skeleton && children}
        </>
      </Panel>
    </Box>
  );
}
