import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DetailPageParams } from 'Router';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { getUsims5gBySupi, provisionUsim5g } from 'store/actions/usims5g';
import { selectActiveUsim } from 'store/selectors/5gProvisioning/usimCards';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { useCanUserUpdateUsimSelector } from '../../../store/selectors/user';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useAppDispatch, useAppSelector } from 'store';

export enum TAB {
  DETAILS = 'details',
}

export function UsimDetailsLayout() {
  const { id } = useParams<DetailPageParams>();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const activeUsim = useAppSelector((state) => selectActiveUsim(state));
  const canUserUpdateUsimEntity = useCanUserUpdateUsimSelector();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationPaths = location.pathname.split('/');
  const tab = locationPaths[locationPaths.length - 1];

  useEffect(() => {
    void dispatch(getUsims5gBySupi(id as string));
  }, [dispatch, id]);

  const handleSend = useCallback(() => {
    if (!activeUsim) {
      return;
    }
    void dispatch(provisionUsim5g({ usimIds: [activeUsim.id] }));
  }, [dispatch, activeUsim]);

  const handleTabChange = useCallback(
    (newTab: TAB) => {
      navigate(`/subscribers/${id}/${newTab}`, {
        replace: true,
      });
    },
    [id, navigate]
  );

  const usimDetailTabs = useMemo(
    () => [
      {
        value: TAB.DETAILS,
        label: formatMessage({ id: 'usim.tabs.details' }),
      },
    ],
    [formatMessage]
  );

  useEffect(() => {
    if (tab !== TAB.DETAILS) {
      navigate(`/subscribers/${id}/${TAB.DETAILS}`, {
        replace: true,
      });
    }
  }, [id, navigate, tab]);

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {activeUsim && (
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            <Button
              text={formatMessage({ id: 'common.button.networkSync' })}
              onClick={handleSend}
              disabled={!canUserUpdateUsimEntity}
            />
          </Stack>
        )}
      </Toolbar>
      <Box sx={{ mb: 2 }}>
        <Tabs
          value={tab}
          tabs={usimDetailTabs}
          onChange={(value) => {
            handleTabChange(value as TAB);
          }}
        />
      </Box>
      <Outlet />
    </>
  );
}
