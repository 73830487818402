import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Form } from 'formik';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getTenantsAutocompleteOptions } from 'store/actions/tenants';
import { object, string } from 'yup';
import { CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { CreateBulkUsim5gFormDataType } from '../BulkCreateUsimCard5gContent';
import { useAppDispatch } from 'store';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import BaseFormik from 'components/Form/BaseFormik';

interface StepExtendProps {
  isActive?: boolean;
  isCompleted?: boolean;
}

export function Step1({ onCompleteStep, initialValues, ...step }: CreateUsim5gStepProps & StepExtendProps) {
  const [options, setOptions] = useState<AutocompleteItemProps[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        tenant: object()
          .shape({ label: string().required(), value: string().required() })
          .required()
          .nullable()
          .label(formatMessage({ id: 'usims.form.usim.tenant_id.label' })),
      }),
    [formatMessage]
  );

  const handleOptionsLoading = useCallback(async () => {
    setOptionsLoading(true);
    setOptions([]);

    const searchResults = await dispatch(getTenantsAutocompleteOptions(''));

    setOptions(searchResults);
    setOptionsLoading(false);
    if (searchResults.length === 1 && step.isCompleted === false && step.isActive === true) {
      onCompleteStep({ tenant: searchResults[0] });
    }
  }, [dispatch, onCompleteStep, step.isActive, step.isCompleted]);

  useEffect(() => {
    void handleOptionsLoading();
  }, [handleOptionsLoading]);

  const handleSubmit = useCallback(
    (values: Partial<CreateBulkUsim5gFormDataType>) => {
      onCompleteStep(values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.ownership' })} {...step}>
      <BaseFormik
        initialValues={initialValues}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ errors }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <AutocompleteField
                  name="tenant"
                  options={options}
                  loading={optionsLoading}
                  placeholder={formatMessage({ id: 'usims.form.usim.tenant_id.placeholder' })}
                />

                <Button
                  variant="outlined"
                  text={formatMessage({ id: 'common.form.next' })}
                  type="submit"
                  disabled={Boolean(errors['tenant'])}
                />
              </Stack>
            </Form>
          );
        }}
      </BaseFormik>
    </Step>
  );
}
