import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import { Slice } from 'store/models/serviceProfile';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { SxProps, Theme } from '@athonet/ui/theme';
import { deleteSlice } from 'store/actions/serviceProfiles';
import { useAppDispatch, useAppSelector } from 'store';
import { showErrorToast } from 'store/actions/toast';
import {
  getDbExistingSlicesCount,
  getPendingCreationSliceIds,
  getPendingDeletionSliceIds,
  getPendingModificationSliceIds,
  selectServiceProfileById,
} from 'store/selectors/serviceProfiles';
import { EditSliceDialog } from './EditSliceDialog';
import { SyncColors } from 'components/RemoteSync/utils';
import { ServicesTable } from '../services/ServicesTable';
import SliceName from './SliceName';
import SliceDifferentiatorChip from './SliceDifferentiatorChip';
import SliceServicesCount from './SliceServicesCount';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { NetworkPageParams } from 'Router';
import { useParams } from 'react-router-dom';

export function SlicesTable({ serviceProfileId }: { serviceProfileId: string }) {
  const { formatMessage } = useIntl();
  const { dialogOpen, confirmationDialogOpen } = useOverlay();
  const dispatch = useAppDispatch();
  const { network_id } = useParams<NetworkPageParams>();

  const serviceProfile = useAppSelector((state) => selectServiceProfileById(state, serviceProfileId));
  const pendingCreationSliceIds = useAppSelector((state) => getPendingCreationSliceIds(state, serviceProfileId));
  const pendingEditSliceIds = useAppSelector((state) => getPendingModificationSliceIds(state, serviceProfileId));
  const pendingDeletionSliceIds = useAppSelector((state) => getPendingDeletionSliceIds(state, serviceProfileId));

  const dbExistingSlicesCount = useAppSelector((state) => getDbExistingSlicesCount(state, serviceProfileId));

  const slicesColumns: TableColumn<
    Slice & {
      tools?: boolean;
    }
  >[] = useMemo(
    () => [
      {
        key: 'name',
        label: formatMessage({ id: 'serviceProfiles.slices.table.name' }),
        cellRender: (rowData: Slice) => {
          return <SliceName serviceProfileId={serviceProfileId} sliceId={rowData?.id} />;
        },
      },
      {
        key: 'differentiator',
        label: formatMessage({ id: 'serviceProfiles.slices.table.differentiator' }),
        cellRender: (rowData: Slice) => {
          return <SliceDifferentiatorChip serviceProfileId={serviceProfileId} sliceId={rowData?.id} />;
        },
      },
      {
        key: 'services',
        label: formatMessage({ id: 'serviceProfiles.slices.table.services' }),
        cellRender: (rowData: Slice) => {
          return <SliceServicesCount serviceProfileId={serviceProfileId} sliceId={rowData?.id} />;
        },
      },
      {
        key: 'tools',
        label: '',
        maxWidth: '100',
        cellRender: (rowData: Slice) => {
          if (rowData.is_4g) {
            return (
              <Chip
                id={rowData.id}
                color="secondary"
                size="small"
                label={formatMessage({ id: 'serviceProfiles.slices.form.is_4g' })}
              />
            );
          }
          return <></>;
        },
      },
    ],
    [formatMessage, serviceProfileId]
  );

  const handleOpenEditSlice = useCallback(
    (slice: Slice) => {
      if (!network_id) return;
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.slices.editSlice' }),
        content: () => <EditSliceDialog serviceProfileId={serviceProfileId} slice={slice} networkId={network_id} />,
      });
    },
    [dialogOpen, formatMessage, serviceProfileId, network_id]
  );

  const handleOpenDeleteSlice = useCallback(
    (slice: Slice) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.description' }),
        title: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.title' }),
        alertMessage: formatMessage(
          { id: 'serviceProfiles.slices.actions.confirm.itemsAffected' },
          { element: slice.name }
        ),
        severity: 'danger',
        onConfirm: async () => {
          if (!network_id) dispatch(showErrorToast());
          else {
            return dispatch(
              deleteSlice({
                networkId: network_id,
                serviceProfileId,
                sliceId: slice.id,
              })
            );
          }
        },
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, network_id, serviceProfileId]
  );

  const rowDetail = useMemo(
    () => ({
      content: (slice: Slice) => {
        return (
          <>
            {serviceProfile?.id && (
              <ServicesTable
                sliceId={slice.id}
                sliceName={slice.name}
                serviceProfileId={serviceProfile?.id}
                services={slice.services}
              />
            )}
          </>
        );
      },
      disabled: () => {
        return false;
      },
    }),
    [serviceProfile?.id]
  );

  const handleSliceRowSx = useCallback(
    (id: any) => {
      const sxProps: SxProps<Theme> = {
        backgroundColor: pendingCreationSliceIds?.includes(id)
          ? SyncColors.creationBg
          : pendingEditSliceIds?.includes(id)
          ? SyncColors.modificationBg
          : pendingDeletionSliceIds?.includes(id)
          ? SyncColors.deletionBg
          : undefined,
      };
      return sxProps;
    },
    [pendingCreationSliceIds, pendingEditSliceIds, pendingDeletionSliceIds]
  );

  const slicesSortedByName = useMemo(() => {
    const sortedSlices = [...(serviceProfile?.slices ?? [])].sort((slice1, slice2) => {
      const name1 = slice1.name.toUpperCase();
      const name2 = slice2.name.toUpperCase();
      return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    });

    return sortedSlices;
  }, [serviceProfile?.slices]);

  return (
    <Table
      customRowSx={handleSliceRowSx}
      autoScale
      orderBy="name"
      columns={slicesColumns}
      data={slicesSortedByName}
      rowsCount={serviceProfile?.slices.length ?? 0}
      page={0}
      rowsPerPage={10}
      rowKey="id"
      rowDetail={rowDetail}
      rowActions={[
        {
          label: formatMessage({ id: 'serviceProfiles.slices.editSlice' }),
          onClick: handleOpenEditSlice,
        },
        {
          label: formatMessage({ id: 'serviceProfiles.slices.deleteSlice.title' }),
          onClick: (row) => handleOpenDeleteSlice(row),
          disabled: () => dbExistingSlicesCount === 1,
        },
      ]}
    />
  );
}
