import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import config from 'config';
import axios, { AxiosRequestConfig } from 'axios';
import ConfirmUserForm from 'components/ConfirmUserForm';

const ConfirmUser = () => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const { confirmToken } = useParams();

  useEffect(() => {
    if (!confirmToken) return;
    const options: AxiosRequestConfig = {
      url: `${config.apis.confirmUser.replace('{temporary-code}', confirmToken)}`,
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      },
    };

    axios(options)
      .then(() => {
        // success message
        setSuccess(true);
      })
      .catch(() => {
        // error message
        setSuccess(false);
      });
  }, [confirmToken]);

  return <ConfirmUserForm success={success} />;
};

export default ConfirmUser;
