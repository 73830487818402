import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Button } from '@athonet/ui/components/Input/Button';
import {
  FilterInput,
  FilterInputProps,
  FilterInputValue,
  FILTER_INPUT_TYPE,
} from '@athonet/ui/components/Input/FilterInput';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { DrawerActions } from '@athonet/ui/components/Overlay/Drawer/DrawerActions';
import { DrawerContent } from '@athonet/ui/components/Overlay/Drawer/DrawerContent';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { FiltersObj, setFiltersQueryString } from 'store/models/filters';
import { Schema, array, boolean, object, string } from 'yup';

type FilterDrawerCustomItemFields = {
  initial?: FilterInputValue;
  default: FilterInputValue;
  schema: Schema;
  onOptionsChange?: (query: string) => Promise<string[] | AutocompleteItemProps[]>;
  getInitialOptions?: () => Promise<AutocompleteItemProps[]>;
};

// Used for legacy js components
export type FiltersProps = {
  values: Record<string, FilterInputValue | undefined>;
  onSubmit?: (values: Record<string, FilterInputValue>) => void;
};

export type FiltersDrawerItem = FilterDrawerCustomItemFields &
  Omit<FilterInputProps, 'valueByFilterType' | 'onChange' | 'error' | 'loading' | 'onOptionsChange'>;

type FiltersDrawerProps = {
  items: FiltersDrawerItem[];
  onSubmit: (values: FiltersObj) => void;
};

export const defaultStringFilterSchema = object().shape({
  value: string().nullable(),
  activeFilterType: string().oneOf(Object.values(FILTER_INPUT_TYPE)),
  not: boolean(),
});

export const defaultSelectOptionFilterSchema = object().shape({
  value: object().nullable(),
  activeFilterType: string().oneOf(Object.values(FILTER_INPUT_TYPE)),
  not: boolean(),
});
export const defaultMultipleSelectOptionFilterSchema = object().shape({
  value: array().of(object().nullable()),
  activeFilterType: string().oneOf(Object.values(FILTER_INPUT_TYPE)),
  not: boolean(),
});

export function FiltersDrawer({ items, onSubmit }: FiltersDrawerProps) {
  const { drawerClose } = useOverlay();
  const { formatMessage } = useIntl();

  const [filtersOptions, setFiltersOptions] = useState<Record<string, string[]>>();
  // TODO: filters: FiltersObj
  const [filtersAutocompleteOptions, setFiltersAutocompleteOptions] =
    useState<Record<string, AutocompleteItemProps[]>>();
  const [filtersOptionsLoading, setFiltersOptionsLoading] = useState<string[]>([]);

  const handleFiltersOptionsChange = useCallback(
    async (
      query: string,
      field: string,
      activeFilter: FILTER_INPUT_TYPE,
      onChange: FilterDrawerCustomItemFields['onOptionsChange']
    ) => {
      if (!onChange) {
        return;
      }
      setFiltersOptionsLoading((prevState) => [...prevState, field]);

      if (activeFilter === FILTER_INPUT_TYPE.OPTIONS) {
        setFiltersAutocompleteOptions((prevState) => {
          return {
            ...prevState,
            [field]: [],
          };
        });
      } else {
        setFiltersOptions((prevState) => {
          return {
            ...prevState,
            [field]: [],
          };
        });
      }

      const searchResults = await onChange(query);

      if (searchResults.length) {
        if (activeFilter === FILTER_INPUT_TYPE.OPTIONS) {
          setFiltersAutocompleteOptions((prevState) => {
            return {
              ...prevState,
              [field]: searchResults as AutocompleteItemProps[],
            };
          });
        } else {
          setFiltersOptions((prevState) => {
            return {
              ...prevState,
              [field]: searchResults as string[],
            };
          });
        }
      }

      setFiltersOptionsLoading((prevState) => prevState.filter((fieldItem) => fieldItem !== field));
    },
    []
  );

  useEffect(() => {
    const promises = items
      .filter(({ getInitialOptions, onOptionsChange }) => getInitialOptions || onOptionsChange)
      .map(async ({ name, initial, default: defaultValue, getInitialOptions, onOptionsChange }) => {
        const filterType = initial?.activeFilterType || defaultValue.activeFilterType;
        return handleFiltersOptionsChange('', name, filterType, onOptionsChange || getInitialOptions);
      });
    void Promise.all(promises);
  }, [handleFiltersOptionsChange, items]);

  const initials = useMemo(() => {
    const values: FiltersObj = {};

    items.forEach((item) => {
      const v = item.initial || item.default;
      values[item.name] = v;
    });

    return values;
  }, [items]);

  const validationSchema = useMemo(() => {
    const shape: Record<string, Schema> = {};

    items.forEach((item) => {
      shape[item.name] = item.schema;
    });

    return object().shape(shape);
  }, [items]);

  const defaultValues = useMemo(() => {
    const values: FiltersObj = {};

    items.forEach((item) => {
      values[item.name] = item.default;
    });

    return values;
  }, [items]);

  const handleReset = useCallback(
    async (setValues: FormikHelpers<FiltersObj>['setValues'], submitForm: FormikHelpers<FiltersObj>['submitForm']) => {
      await setValues(defaultValues);
      void submitForm();
      drawerClose();
    },
    [defaultValues, drawerClose]
  );

  const handleSetFiltersQueryString = useCallback((values: FiltersObj) => {
    const queryStringValues: FiltersObj = {};

    for (const key in values) {
      const filter = values[key];
      if (filter) {
        if (filter.value === null) {
          continue;
        }

        if (filter.value !== null && Array.isArray(filter.value) && !filter.value.length) {
          continue;
        }

        if (filter.activeFilterType === FILTER_INPUT_TYPE.UPLOAD_FILE) {
          continue;
        }

        if (filter.activeFilterType === FILTER_INPUT_TYPE.RANGE && filter.value.every((v) => v === null)) {
          continue;
        }

        queryStringValues[key] = filter;
      }
    }

    setFiltersQueryString(queryStringValues);
  }, []);

  const handleSubmit = useCallback(
    (values: FiltersObj) => {
      handleSetFiltersQueryString(values);
      onSubmit(values);
      drawerClose();
    },
    [drawerClose, handleSetFiltersQueryString, onSubmit]
  );

  return (
    <Formik
      initialValues={initials}
      enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setValues, errors, submitForm, setFieldValue }) => {
        return (
          <Form
            noValidate
            autoComplete="off"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <DrawerContent>
              <Stack spacing={1.5}>
                {items.map(
                  ({
                    options,
                    autocompleteOptions,
                    onOptionsChange,
                    initial,
                    default: defaultValue,
                    schema,
                    name,
                    ...item
                  }) => {
                    return (
                      <FastField
                        name={name}
                        key={name}
                        {...(filtersOptionsLoading.includes(name) && { loading: true })}
                      >
                        {({ field: { multiple, value, ...fieldProps } }: FieldProps<FilterInputValue | undefined>) => {
                          if (!value) {
                            return null;
                          }

                          return (
                            <FilterInput
                              {...fieldProps}
                              {...item}
                              name={name}
                              valueByFilterType={value}
                              onChange={(newValue) => {
                                void setFieldValue(name, newValue);
                              }}
                              error={Boolean(errors[name])}
                              loading={filtersOptionsLoading.includes(name)}
                              onOptionsChange={(query: string) => {
                                void handleFiltersOptionsChange(query, name, value.activeFilterType, onOptionsChange);
                              }}
                              options={filtersOptions ? filtersOptions[name] : options}
                              autocompleteOptions={
                                filtersAutocompleteOptions && filtersAutocompleteOptions[name]
                                  ? filtersAutocompleteOptions[name]
                                  : autocompleteOptions
                              }
                            />
                          );
                        }}
                      </FastField>
                    );
                  }
                )}
              </Stack>
            </DrawerContent>
            <DrawerActions>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  data-testid="filters-drawer-cancel"
                  onClick={() => {
                    void handleReset(setValues, submitForm);
                  }}
                  text={formatMessage({ id: 'common.form.reset' })}
                />
                <Button
                  data-testid="filters-drawer-continue"
                  type="submit"
                  text={formatMessage({ id: 'common.form.continue' })}
                />
              </Stack>
            </DrawerActions>
          </Form>
        );
      }}
    </Formik>
  );
}
