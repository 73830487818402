import { createAsyncThunk } from '@reduxjs/toolkit';
import { State } from 'store';
import { fetchData } from '../fetchData';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { showErrorToast } from '../toast';
import { sentryLogError } from 'sentry';
import { GetTenantsRes } from './types';
import { generateFiltersQuery } from 'store/models/filters';

export const getTenantsList = createAsyncThunk<
  GetTenantsRes,
  void,
  {
    rejectValue: null;
    state: State;
  }
>('tenants/getTenantsList', async (_, { dispatch, rejectWithValue, getState }) => {
  try {
    const {
      bootstrap,
      tenants: {
        sort,
        filters,
        list: {
          data: { page },
        },
      },
    } = getState();

    const query = encodeURI(generateFiltersQuery(filters, '&'));

    const options: AxiosRequestConfig = {
      url: `${config.apis.getTenants
        .replace('{sort}', sort)
        .replace('{limit}', `${bootstrap?.pageLimit}`)
        .replace('{page}', `${page}`)
        .replace('{filters}', query)}`,
      method: 'GET',
    };

    const results = dispatch(fetchData<GetTenantsRes>(options));
    return results;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});
