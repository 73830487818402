import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export function useSetLabel(value: string | null) {
  const { formatMessage } = useIntl();
  const formattedValue = useMemo(() => {
    return value ? formatMessage({ id: 'common.form.set' }) : formatMessage({ id: 'common.form.notSet' });
  }, [formatMessage, value]);

  return formattedValue;
}
