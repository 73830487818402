import { AthonetProvider } from '@athonet/ui/components/AthonetProvider';
import { Provider } from 'react-redux';
import store from 'store';
import Bootstrap from 'containers/Bootstrap';
import Toasts from 'containers/Toasts';
import Router from 'Router';
import { ErrorBoundary } from 'sentry';
import CreateIntlProvider from 'CreateIntlProvider';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'graphql/apollo';
import '@athonet/ui/assets/css/athonet.css';

export default function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Bootstrap>
          <AthonetProvider>
            <ApolloProvider client={apolloClient}>
              <>
                <Toasts />
                <Router />
              </>
            </ApolloProvider>
          </AthonetProvider>
        </Bootstrap>
        <CreateIntlProvider />
      </Provider>
    </ErrorBoundary>
  );
}
