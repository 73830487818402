import { Chart } from '@athonet/ui/components/Data/Chart';
import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import theme from '@athonet/ui/theme';
import { Maybe } from '@graphql-tools/utils';
import { Box } from '@mui/system';
import { filesize } from 'filesize';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Throughput, SeriesPoint } from '../../../../graphql/client/types';
import { NoMetrics } from 'components/NetworkId/NoMetrics/NoMetrics';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { useIntl } from 'react-intl';

type NetworkThroughputProps = {
  throughput: Throughput;
};

type TooltipFormattedParams = {
  axisValueLabel: string;
  seriesName: string;
  marker: string;
  data: (number | '0')[];
}[];

export function NetworkThroughput({ throughput }: NetworkThroughputProps) {
  const { formatMessage } = useIntl();

  const parseSeriesPoints = useCallback((points: Maybe<Maybe<SeriesPoint>[]> | undefined) => {
    return (
      points?.map((point) => [
        DateTime.fromISO(`${point?.datetime}`.replace(' ', 'T')).toFormat('dd/MM - HH:mm:ss'),
        point?.bytes || '0',
      ]) || []
    );
  }, []);

  const seriesDownlinkData = useMemo(
    () => parseSeriesPoints(throughput?.downlinkTimeseries),
    [parseSeriesPoints, throughput?.downlinkTimeseries]
  );

  const seriesUplinkData = useMemo(
    () => parseSeriesPoints(throughput?.uplinkTimeseries),
    [parseSeriesPoints, throughput?.uplinkTimeseries]
  );

  const tooltipFormatter = useCallback((params: TooltipFormattedParams, unit: 'filesize' | 'number') => {
    return `${params[0].axisValueLabel}
    <hr style="margin: 4px 0; background-color: #DADADA; height: 1px; border: none" />    
    ${params
      .map((param) => {
        return `<div style="margin-top: 8px;">${param.marker} ${
          param.seriesName
        }: <h2 style="font-weight: 500; display: inline; font-size: 16px; line-height: 24px;"> ${
          unit === 'filesize' && param.data[1] !== '0'
            ? param.data[1] < 0
              ? `-${filesize(Math.abs(param.data[1]) as number)}`
              : filesize(Math.abs(param.data[1]) as number)
            : param.data[1].toLocaleString()
        }</h2></div>`;
      })
      .join('')}`;
  }, []);

  if (throughput?.downlink == null && throughput?.uplink === null) {
    return <NoMetrics iconName="Bar-Chart-2" />;
  }

  const downlink = formatMessage({ id: 'menu.networks.userThroughputPanel.downlink' });
  const uplink = formatMessage({ id: 'menu.networks.userThroughputPanel.uplink' });

  return (
    <PanelContent>
      <SquaredTile horizontal>
        <SquaredChildTile
          mainValue={String(filesize(throughput?.downlink || 0))}
          leftAlign
          subtitle={downlink}
          uppercaseTitle={false}
        />
        <SquaredChildTile
          mainValue={String(filesize(throughput?.uplink || 0))}
          leftAlign
          subtitle={uplink}
          uppercaseTitle={false}
        />
      </SquaredTile>
      <Box sx={{ width: '100%' }}>
        <Chart
          height={180}
          options={{
            series: [
              {
                name: 'Downlink', //TODO translate also here?
                data: seriesDownlinkData,
                color: theme.palette.series2.main,
                type: 'line',
                symbol: 'none',
              },
              {
                name: 'Uplink', //TODO translate also here?
                data: seriesUplinkData,
                color: theme.palette.series3.main,
                type: 'line',
                symbol: 'none',
              },
            ],
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value: string | number) => String(filesize(Number(value))),
              },
            },
            xAxis: {
              type: 'category',
              splitLine: {
                show: true,
              },
            },
            grid: {
              show: false,
            },
            legend: { show: true, data: ['Downlink', 'Uplink'], borderColor: 'red' }, //TODO translate also here?
            tooltip: {
              formatter: (params) => {
                return tooltipFormatter(params as unknown as TooltipFormattedParams, 'filesize');
              },
            },
          }}
        />
      </Box>
    </PanelContent>
  );
}
