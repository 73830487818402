import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../fetchData';
import { State } from 'store';
import { showErrorToast } from '../toast';
import { sentryLogError } from 'sentry';
import config from 'config';
import { AxiosRequestConfig } from 'axios';
import { User } from 'store/models/user';

export const getUserById = createAsyncThunk<
  User,
  { userId: string },
  {
    rejectValue: null;
    state: State;
  }
>('users/getById', async ({ userId }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: `${config.apis.getUser.replace('{id}', userId)}`,
      method: 'GET',
    };

    const result = await dispatch(fetchData<User>(options));
    return result;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});
