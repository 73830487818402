import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { useCallback } from 'react';
import { selectActiveUsim } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { EditUsimCard5gDialog } from 'components/Edit/5gProvisioning/UsimCards/Edit';
import UsimPlainText from '../UsimPlainText';
import { useAppSelector } from 'store';
import theme from '@athonet/ui/theme';
import UsimTooltipIcon from '../UsimTooltipIcon';
import { BasePanel } from 'components/Panel/BasePanel';
import UsimKText from '../UsimKText';
import UsimKeyTypeText from '../UsimKeyTypeText';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';

export function GeneralDataPanel() {
  const activeUsim = useAppSelector((state) => selectActiveUsim(state));
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const handleOpenEditGeneral = useCallback(() => {
    if (!activeUsim) {
      return;
    }

    dialogOpen({
      title: formatMessage({ id: 'usims.actions.edit.subtitle' }, { usim: activeUsim.supi }),
      content: () => <EditUsimCard5gDialog usim5g={activeUsim} />,
    });
  }, [dialogOpen, activeUsim, formatMessage]);

  return (
    <BasePanel
      folder
      height={335}
      skeleton={!activeUsim}
      title={formatMessage({ id: 'usims.id.details.generalData' })}
      headerActionsComponent={
        <>
          {activeUsim && <UsimTooltipIcon sx={{ fontSize: theme.spacing(3) }} usim5g={activeUsim} />}
          <IconButton name="Pencil" onClick={handleOpenEditGeneral} fontSize="small" />
        </>
      }
    >
      {activeUsim && (
        <>
          <PanelContent>
            <GridContainer>
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.name' })}
                valueComponent={
                  <UsimPlainText
                    provisionedValue={activeUsim.provisioned_values?.name}
                    unprovisionedValue={activeUsim.name}
                  />
                }
                fullWidth
              />
              <KeyValueGridItem label={formatMessage({ id: 'usims.table.supi' })} value={activeUsim.supi} />
              <KeyValueGridItem label={formatMessage({ id: 'usims.table.msisdn' })} value={activeUsim.msisdn} />
            </GridContainer>
          </PanelContent>
          <PanelContent title="AKA Authentication" titleColor="text.secondary">
            <GridContainer>
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.k' })}
                valueComponent={
                  <UsimKText provisionedValue={activeUsim.provisioned_values?.k} unprovisionedValue={activeUsim.k} />
                }
                compactWidth
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.op' })}
                valueComponent={
                  <UsimKeyTypeText
                    provisionedValue={activeUsim.provisioned_values?.op}
                    unprovisionedValue={activeUsim.op}
                  />
                }
                compactWidth
              />
              <KeyValueGridItem
                label={formatMessage({ id: 'usims.table.opc' })}
                valueComponent={
                  <UsimKeyTypeText
                    provisionedValue={activeUsim.provisioned_values?.opc}
                    unprovisionedValue={activeUsim.opc}
                  />
                }
                compactWidth
              />
            </GridContainer>
          </PanelContent>
        </>
      )}
    </BasePanel>
  );
}
