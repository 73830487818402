import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Panel } from '@athonet/ui/components/Surfaces/Panel';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { SxProps, Theme } from '@athonet/ui/theme';
import { SyncColors } from 'components/RemoteSync/utils';
import ServiceName from './ServiceName';
import ServiceType from './ServiceType';
import ServiceIsDefaultChip from './ServiceIsDefaultChip';
import { deleteService } from 'store/actions/serviceProfiles';
import { showErrorToast } from 'store/actions/toast';
import { useAppDispatch, useAppSelector } from 'store';
import { EditServiceDialog } from './EditServiceDialog';
import { Service } from 'store/models/serviceProfile';
import { Table, TableColumn } from '@athonet/ui/components/Data/Table';
import {
  getDbExistingServicesCount,
  getPendingCreationServiceIds,
  getPendingDeletionServiceIds,
  getPendingModificationServiceIds,
} from 'store/selectors/serviceProfiles';
import { useParams } from 'react-router-dom';
import { NetworkPageParams } from 'Router';

interface ServicesTableProps {
  sliceId: string;
  sliceName: string;
  serviceProfileId: string;
  services: Service[];
}

export function ServicesTable({ sliceId, sliceName, serviceProfileId, services }: ServicesTableProps) {
  const { formatMessage } = useIntl();
  const { dialogOpen, confirmationDialogOpen } = useOverlay();
  const dispatch = useAppDispatch();
  const { network_id } = useParams<NetworkPageParams>();

  const pendingCreationIds = useAppSelector((state) => getPendingCreationServiceIds(state, serviceProfileId));
  const pendingEditIds = useAppSelector((state) => getPendingModificationServiceIds(state, serviceProfileId));
  const pendingDeletionIds = useAppSelector((state) => getPendingDeletionServiceIds(state, serviceProfileId));
  const dbExistingServicesCount = useAppSelector((state) =>
    getDbExistingServicesCount(state, serviceProfileId, sliceId)
  );

  const handleServiceRowSx = useCallback(
    (id: any) => {
      const sxProps: SxProps<Theme> = {
        backgroundColor: pendingCreationIds?.includes(id)
          ? SyncColors.creationBg
          : pendingEditIds?.includes(id)
          ? SyncColors.modificationBg
          : pendingDeletionIds?.includes(id)
          ? SyncColors.deletionBg
          : undefined,
      };
      return sxProps;
    },
    [pendingCreationIds, pendingDeletionIds, pendingEditIds]
  );

  const handleOpenCreateService = useCallback(() => {
    if (!network_id) return;
    dialogOpen({
      title: formatMessage({ id: 'serviceProfiles.services.createService' }),
      content: () => <EditServiceDialog serviceProfileId={serviceProfileId} sliceId={sliceId} networkId={network_id} />,
    });
  }, [dialogOpen, formatMessage, serviceProfileId, sliceId, network_id]);

  const servicesColumns: TableColumn<Service>[] = useMemo(
    () => [
      {
        key: 'name',
        label: formatMessage({ id: 'serviceProfiles.services.table.name' }),
        cellRender: (rowData: Service) => {
          return <ServiceName id={rowData?.id} serviceProfileId={serviceProfileId} sliceId={sliceId} />;
        },
      },
      {
        key: 'type',
        label: formatMessage({ id: 'serviceProfiles.services.table.type' }),
        cellRender: (rowData: Service) => {
          return <ServiceType id={rowData?.id} serviceProfileId={serviceProfileId} sliceId={sliceId} />;
        },
      },
      {
        key: 'default',
        label: '',
        cellRender: (rowData: Service) => {
          return <ServiceIsDefaultChip id={rowData?.id} serviceProfileId={serviceProfileId} sliceId={sliceId} />;
        },
        maxWidth: '100',
      },
    ],
    [formatMessage, serviceProfileId, sliceId]
  );

  const handleOpenEditService = useCallback(
    (service: Service) => {
      if (!network_id) return;
      dialogOpen({
        title: formatMessage({ id: 'serviceProfiles.services.editService' }),
        content: () => (
          <EditServiceDialog
            serviceProfileId={serviceProfileId}
            sliceId={sliceId}
            service={service}
            networkId={network_id}
          />
        ),
      });
    },
    [dialogOpen, formatMessage, serviceProfileId, sliceId, network_id]
  );

  const handleOpenDeleteService = useCallback(
    (service: Service) => {
      confirmationDialogOpen({
        description: formatMessage({ id: 'serviceProfiles.services.deleteService.description' }),
        title: formatMessage({ id: 'serviceProfiles.services.deleteService.title' }),
        alertMessage: formatMessage(
          { id: 'serviceProfiles.services.actions.confirm.itemsAffected' },
          { element: `'${service.name}'` }
        ),
        severity: 'danger',
        onConfirm: async () => {
          if (!network_id) dispatch(showErrorToast());
          else {
            return dispatch(
              deleteService({
                networkId: network_id,
                serviceId: service.id,
                serviceProfileId,
                sliceId,
              })
            );
          }
        },
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage, network_id, serviceProfileId, sliceId]
  );

  const servicesSortedByName = useMemo(() => {
    const sortedServices = [...services].sort((service1, service2) => {
      const name1 = service1.name.toUpperCase();
      const name2 = service2.name.toUpperCase();
      return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    });
    return sortedServices;
  }, [services]);

  return (
    <Panel
      dense
      folder
      title={formatMessage({ id: 'serviceProfiles.services.table.title' }, { serviceProfile: sliceName })}
      headerActionsComponent={
        <Button
          text={formatMessage({ id: 'serviceProfiles.list.newService' })}
          variant="outlined"
          onClick={() => handleOpenCreateService()}
        />
      }
    >
      <Table
        customRowSx={handleServiceRowSx}
        orderBy="name"
        columns={servicesColumns}
        data={servicesSortedByName}
        rowsCount={services.length}
        page={0}
        rowsPerPage={10}
        rowKey="id"
        rowActions={[
          {
            label: formatMessage({ id: 'serviceProfiles.services.editService' }),
            onClick: (service) => handleOpenEditService(service),
          },
          {
            label: formatMessage({ id: 'serviceProfiles.services.deleteService.title' }),
            onClick: (service) => handleOpenDeleteService(service),
            disabled: (service) => dbExistingServicesCount === 1 || service.default,
          },
        ]}
      />
    </Panel>
  );
}
