import { useIntl } from 'react-intl';
import { selectCreateUserAuthProviders } from 'store/selectors/bootstrap';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Field, FieldProps, useFormikContext } from 'formik';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { CreateUserDataType } from '../types';
import SelectField from 'components/Form/Field/SelectField';
import TextFieldField from 'components/Form/Field/TextFieldField';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { selectTenantsWithSubtenants } from 'store/selectors/tenants';
import { useAppSelector } from 'store';

const CreateUserForm = () => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, isSubmitting } = useFormikContext<CreateUserDataType>();
  const createUserAuthProviders = useAppSelector((state) => selectCreateUserAuthProviders(state));
  const allTenants = useAppSelector((state) => selectTenantsWithSubtenants(state));
  const roles = useAppSelector((state) => state.roles.list);

  return (
    <>
      <BaseFieldset label={formatMessage({ id: 'users.form.user.data' })}>
        <SelectField fast name="tenantId" placeholder={formatMessage({ id: 'users.form.user.tenant.placeholder' })}>
          {allTenants?.data?.data.map(({ id, name }) => (
            <MenuItem value={`${id}`} key={`${id}`}>
              {name}
            </MenuItem>
          ))}
        </SelectField>

        <TextFieldField
          name="fullName"
          size="small"
          placeholder={formatMessage({ id: 'users.form.user.fullname.placeholder' })}
        />
        <SelectField
          name="roleName"
          size="small"
          placeholder={formatMessage({ id: 'users.form.user.role.placeholder' })}
        >
          {roles?.map(({ id, name }) => (
            <MenuItem value={`${name}`} key={`${id}`}>
              {name}
            </MenuItem>
          ))}
        </SelectField>
        <TextFieldField
          name="phone"
          size="small"
          placeholder={formatMessage({ id: 'users.form.user.phone.placeholder' })}
        />
      </BaseFieldset>
      <BaseFieldset
        label={formatMessage(
          { id: 'users.form.user.auth' },
          {
            value:
              createUserAuthProviders.length === 1
                ? formatMessage({ id: `authProvider.${createUserAuthProviders[0]}` })
                : '',
          }
        )}
        direction="column"
        spacing={2}
      >
        <Field name="realm">
          {({ field }: FieldProps<AUTH_PROVIDER>) =>
            createUserAuthProviders.length > 1 ? (
              <ToggleButtonGroup
                {...field}
                data-testid="field-realm"
                size="small"
                color={'secondary'}
                exclusive={true}
                value={values['realm']}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    void setFieldValue('realm', newValue);
                  }
                }}
              >
                {createUserAuthProviders.map((provider) => (
                  <ToggleButton
                    value={provider}
                    disabled={values['realm'] === provider || isSubmitting}
                    data-testid={`field-input-realm-${provider})}`}
                    key={provider}
                  >
                    {formatMessage({ id: `authProvider.${provider}` })}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            ) : (
              <></>
            )
          }
        </Field>
        {values['realm'] === AUTH_PROVIDER.ENTERPRISE && (
          <>
            <TextFieldField
              name="email"
              size="small"
              placeholder={formatMessage({ id: 'users.form.user.email.placeholder' })}
            />

            <TextFieldField
              name="password"
              size="small"
              placeholder={formatMessage({ id: 'users.form.user.password.placeholder' })}
              type="password"
              showPasswordVisibility
              helperText={formatMessage({ id: 'changePassword.rules' })}
            />
          </>
        )}
        {values['realm'] === AUTH_PROVIDER.LDAP && (
          <TextFieldField
            name="name"
            size="small"
            placeholder={formatMessage({ id: 'users.form.user.username.placeholder' })}
          />
        )}
      </BaseFieldset>
    </>
  );
};

export default CreateUserForm;
