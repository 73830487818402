import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim5g';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Button } from '@athonet/ui/components/Input/Button';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';

const DefaultNullableValue = ({ field, value }: { field: string; value: unknown }) =>
  value ? <Text>{`${value}`}</Text> : <Chip id={field} size="small" label="Empty" />;

export function Step4({
  values,
  milestoneCompleted,
  onCompleteStep,
  children,
  ...step
}: {
  values: Record<string, unknown>;
  milestoneCompleted: boolean;

  onCompleteStep: () => void;
  children: ReactNode;
}) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();

  const tenantComputedValue = useMemo(
    () =>
      typeof values['tenant'] === 'object' && values['tenant'] !== null && 'label' in values['tenant']
        ? values['tenant'].label
        : null,
    [values]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.creation' })} checkpoint {...step}>
      {milestoneCompleted ? (
        <>
          <Alert
            severity="success"
            title={formatMessage({ id: 'usims.create.success.title' })}
            message={formatMessage({ id: 'usims.create.success.message' })}
          />
          <Stack spacing={2} direction="row" justify="flex-end" sx={{ pt: 2 }}>
            <Button variant="text" text="Skip preparation" onClick={dialogClose} />
            <Button variant="outlined" text="Prepare for provisioning" onClick={onCompleteStep} />
          </Stack>
        </>
      ) : (
        <Stack spacing={2} align="flex-end">
          <GridContainer>
            <GridItem size={{ xs: 12 }}>
              <Text type="h5">Please confirm the following values</Text>
            </GridItem>

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.tenant' })}
              valueComponent={<DefaultNullableValue field="name" value={tenantComputedValue} />}
            />

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.name.label' })}
              valueComponent={<DefaultNullableValue field="name" value={values['name']} />}
            />

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.supi.label' })}
              valueComponent={<DefaultNullableValue field="supi" value={`${SUPI_PADDING}${values['supi']}`} />}
            />

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.msisdn.label' })}
              valueComponent={<DefaultNullableValue field="msisdn" value={`${MSISDN_PADDING}${values['msisdn']}`} />}
            />

            {/* /* TODO: update when encryption is contemplated again  */}
            {/* <KeyValue
          label={formatMessage({ id: 'usims.form.usim.encrypt' })}
          value={formatMessage({
            id: `usims.form.usim.encrypt.${values['encrypt']}`,
          })}
        /> */}

            <KeyValueGridItem
              fullWidth
              label={formatMessage({ id: 'usims.form.usim.k.label' })}
              valueComponent={<DefaultNullableValue field="k" value={values['k']} />}
            />

            {values['encrypt'] !== 0 && (
              <KeyValueGridItem
                fullWidth
                label={formatMessage({ id: 'usims.form.usim.use_key.label' })}
                valueComponent={
                  <DefaultNullableValue
                    field="use_key"
                    value={
                      values['use_default_tk']
                        ? formatMessage({ id: 'usims.form.usim.use_default_tk.true' })
                        : values['use_key']
                    }
                  />
                }
              />
            )}

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.form.usim.key_type.label' })}
              value={formatMessage({
                id: `usims.form.usim.key_type.${
                  values['key_type'] === 1 ? 'customOP' : values['key_type'] === 2 ? 'customOPC' : 'defaultHSSOP'
                }`,
              })}
            />

            {values['key_type'] === 1 && (
              <KeyValueGridItem
                fullWidth
                label={formatMessage({ id: 'usims.form.usim.op.label' })}
                valueComponent={<DefaultNullableValue field="op" value={values['op']} />}
              />
            )}

            {values['key_type'] === 2 && (
              <KeyValueGridItem
                fullWidth
                label={formatMessage({ id: 'usims.form.usim.opc.label' })}
                valueComponent={<DefaultNullableValue field="opc" value={values['opc']} />}
              />
            )}
          </GridContainer>
          {children}
        </Stack>
      )}
    </Step>
  );
}
