import { Skeleton } from '@athonet/ui/components/Feedback/Skeleton';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { SxProps, Theme } from '@athonet/ui/theme';

interface PanelContentSkeletonProps {
  sx?: SxProps<Theme>;
}

export function PanelContentSkeleton(props: PanelContentSkeletonProps) {
  return (
    <PanelContent sx={{ height: '100%', pt: 0, ...props.sx }}>
      <Skeleton animation="pulse" variant="rectangular" sx={{ height: '100%' }} />
    </PanelContent>
  );
}
