import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchData } from '../fetchData';
import { Role } from 'store/models/role';
import { State } from 'store';
import { showErrorToast } from '../toast';
import { sentryLogError } from 'sentry';
import config from 'config';

export const getRoles = createAsyncThunk<
  Role[],
  void,
  {
    rejectValue: null;
    state: State;
  }
>('roles/getRoles', async (_, { dispatch, rejectWithValue }) => {
  try {
    const fetchedRoles = await dispatch(fetchData<Role[]>({ url: config.apis.getRoles, method: 'GET' }));
    return fetchedRoles;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});
