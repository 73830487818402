import React, { PropsWithChildren } from 'react';
import { Box } from '@athonet/ui/components/Surfaces/Box';

const ConfirmBox: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '250px',
        padding: '20px 5vw 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
};

export default ConfirmBox;
