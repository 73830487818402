import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Link, useParams } from 'react-router-dom';
import { UserPlaneStatus } from '../../../../graphql/client/types';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { useIntl } from 'react-intl';
import { useHasUserMasterTenantSelector } from 'store/selectors/user';
import { useCallback } from 'react';

type NetworkUserTrafficManagementProps = {
  userTrafficManagement: UserPlaneStatus;
};

export function NetworkUserTrafficManagement({ userTrafficManagement }: NetworkUserTrafficManagementProps) {
  const { network_id } = useParams();
  const { formatMessage } = useIntl();
  const hasUserMasterTenant = useHasUserMasterTenantSelector();

  const ErrorVmsText = useCallback(
    () => (
      <Text>
        {userTrafficManagement.errorVmsCount}{' '}
        {Number(userTrafficManagement.errorVmsCount) > 1
          ? formatMessage({ id: 'menu.networks.userTrafficManagementPanel.virtualMachines' })
          : formatMessage({ id: 'menu.networks.userTrafficManagementPanel.virtualMachine' })}{' '}
        {formatMessage({ id: 'menu.networks.userTrafficManagementPanel.withProblems' })}
      </Text>
    ),
    [formatMessage, userTrafficManagement.errorVmsCount]
  );

  return (
    <PanelContent>
      <SquaredTile horizontal>
        <SquaredChildTile
          mainValue={`${userTrafficManagement.workingPercentage}%`}
          totalValue={formatMessage({ id: 'menu.networks.userTrafficManagementPanel.available' })}
          totalValueFontSizeRatio={0.5}
          leftAlign
          mainValueColor={userTrafficManagement.workingPercentage === 100 ? 'success' : 'error'}
        />
      </SquaredTile>
      {Number(userTrafficManagement.errorVmsCount) > 0 && (
        <Stack direction="row" sx={{ mt: 2 }}>
          <Icon name="Error-Mark" fontSize="medium" color="error" />
          {hasUserMasterTenant ? (
            <Link to={`/networks/${network_id}/topology`} replace={true}>
              <ErrorVmsText />
            </Link>
          ) : (
            <ErrorVmsText />
          )}
        </Stack>
      )}
    </PanelContent>
  );
}
