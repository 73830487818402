import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { boolean, object, string } from 'yup';
import { SERVICE_TYPE, Service } from 'store/models/serviceProfile';
import { Switch } from '@athonet/ui/components/Input/Switch';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { createService, editService } from 'store/actions/serviceProfiles';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { useAppDispatch } from 'store';
import TextFieldField from 'components/Form/Field/TextFieldField';
import SelectField from 'components/Form/Field/SelectField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import FormikDialog from 'components/Form/FormikDialog';

type EditServiceFormDataType = Pick<Service, 'name' | 'type' | 'default'>;

interface EditServiceDialogProps {
  service?: Service;
  sliceId: string;
  serviceProfileId: string;
  networkId: string;
}

export function EditServiceDialog({ serviceProfileId, sliceId, service, networkId }: EditServiceDialogProps) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const validationSchema = useMemo(() => {
    return object().shape({
      name: string()
        .trim()
        .required()
        .label(formatMessage({ id: 'serviceProfiles.services.form.name.label' })),
      type: string()
        .required()
        .oneOf(Object.values(SERVICE_TYPE))
        .label(formatMessage({ id: 'serviceProfiles.services.form.type.label' })),
      default: boolean().required(),
    });
  }, [formatMessage]);

  const initialValues: EditServiceFormDataType = useMemo(
    () => ({
      name: service?.name || '',
      type: service?.type || SERVICE_TYPE.DATA_TRAFFIC,
      default: service?.default || false,
    }),
    [service]
  );

  const handleCreateOrEditService = useCallback(
    async (values: EditServiceFormDataType) => {
      if (!networkId) {
        return;
      }

      const errorRes = service
        ? await dispatch(
            editService({
              networkId,
              serviceProfileId,
              sliceId,
              serviceId: service.id,
              data: values,
            })
          )
        : await dispatch(
            createService({
              networkId,
              serviceProfileId,
              sliceId,
              data: values,
            })
          );
      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, networkId, service, serviceProfileId, sliceId]
  );

  return (
    <FormikDialog
      errorMessage={
        error
          ? service
            ? formatMessage({ id: 'serviceProfiles.services.edit.error.title' }, { error })
            : formatMessage({ id: 'serviceProfiles.services.create.error.title' }, { error })
          : undefined
      }
      initialValues={initialValues}
      onSubmit={handleCreateOrEditService}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnChange
      submitWithNoChanges={false}
    >
      {({ setFieldValue }) => {
        return (
          <BaseFieldset>
            <TextFieldField
              name="name"
              fullWidth
              placeholder={formatMessage({ id: 'serviceProfiles.services.form.name.placeholder' })}
            />
            <SelectField name="type" fullWidth>
              <MenuItem value={SERVICE_TYPE.DATA_TRAFFIC}>
                {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.DATA_TRAFFIC}` })}
              </MenuItem>
              <MenuItem value={SERVICE_TYPE.VOICE_SERVICES}>
                {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.VOICE_SERVICES}` })}
              </MenuItem>
              <MenuItem value={SERVICE_TYPE.MC_PTT}>
                {formatMessage({ id: `serviceProfiles.services.form.type.${SERVICE_TYPE.MC_PTT}` })}
              </MenuItem>
            </SelectField>
            <Field name="default" key="default">
              {({ field }: FieldProps<boolean>) => (
                <Stack fullWidth direction="row" align="center" justify="flex-start">
                  <Text>{formatMessage({ id: 'serviceProfiles.services.form.default' })}</Text>
                  <Switch
                    {...field}
                    defaultChecked={field.value}
                    onChange={(e) => void setFieldValue('default', e.target.checked)}
                  />
                </Stack>
              )}
            </Field>
          </BaseFieldset>
        );
      }}
    </FormikDialog>
  );
}
