import { useAppSelector } from 'store';

export function useUsersSelector() {
  const users = useAppSelector((state) => state.users);
  return users;
}

export function useUsersListSelector() {
  const list = useAppSelector((state) => state.users.list);
  return list;
}

export function useUsersFiltersSelector() {
  const filters = useAppSelector((state) => state.users.filters);
  return filters;
}
