import { Form, FormikHelpers } from 'formik';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectDefaultAuthProvider, useBootstrapSelector } from 'store/selectors/bootstrap';
import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { fetchRequest } from 'store/actions/fetchData';
import { LoginTenantResponse } from 'containers/Login';
import { useAppDispatch, useAppSelector } from 'store';
import BaseFormik from 'components/Form/BaseFormik';
import TextFieldField from 'components/Form/Field/TextFieldField';

export interface LoginFormDataType {
  user: string;
  password: string;
  auth_provider?: AUTH_PROVIDER;
}

export type LoginFormProps = {
  onSubmit: (
    values: LoginTenantResponse,
    authData: LoginFormDataType,
    formikHelpers: FormikHelpers<LoginFormDataType>
  ) => void;
  onForgot: () => void;
};

export default function LoginForm({ onSubmit, onForgot }: LoginFormProps) {
  const { formatMessage } = useIntl();
  const bootstrap = useBootstrapSelector();
  const defaultAuthProvider = useAppSelector((state) => selectDefaultAuthProvider(state));
  const [signinMode, setSigninMode] = useState<AUTH_PROVIDER | undefined>(defaultAuthProvider);
  const { locale } = useIntl();
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const schema = object().shape({
    auth_provider: string().oneOf(Object.values(AUTH_PROVIDER)).required(),
    user: string().when('auth_provider', {
      is: (authProviderValue: AUTH_PROVIDER) => authProviderValue === AUTH_PROVIDER.LDAP,
      then: (fieldSchema) => fieldSchema.required(formatMessage({ id: 'login.ldapUsernameError' })),
      otherwise: (fieldSchema) =>
        fieldSchema
          .email(formatMessage({ id: 'login.emailError' }))
          .label(formatMessage({ id: 'login.enterpriseLabel' }))
          .required(),
    }),
    password: string().when('auth_provider', {
      is: (authProviderValue: AUTH_PROVIDER) => authProviderValue === AUTH_PROVIDER.LDAP,
      then: (fieldSchema) => fieldSchema.required(formatMessage({ id: 'login.ldapPasswordError' })),
      otherwise: (fieldSchema) =>
        fieldSchema
          .min(8, formatMessage({ id: 'login.enterprisePasswordError' }))
          .label(formatMessage({ id: 'login.passwordLabel' }))
          .required(),
    }),
  });

  useEffect(() => {
    if (defaultAuthProvider) {
      setSigninMode(defaultAuthProvider);
    }
  }, [defaultAuthProvider]);

  const handleSubmit = useCallback(
    async (values: LoginFormDataType, formikHelpers: FormikHelpers<LoginFormDataType>) => {
      if (!values.auth_provider) return;
      try {
        const options: AxiosRequestConfig = {
          url: config.apis.authenticate,
          method: 'POST',
          data: { ...values, lang: locale },
        };
        const result = await dispatch(fetchRequest<LoginTenantResponse>(options, false));

        onSubmit(result, values, formikHelpers);
      } catch (e: any) {
        const errorRes = e.response?.data?.error;
        switch (errorRes) {
          case 'no-user':
            setError('login.noUser');
            break;
          case 'unauthorized':
            setError(`login.errorMessage.${values.auth_provider}`);
            break;
          default:
            setError('login.errorMessage');
            break;
        }

        formikHelpers.resetForm();
        void formikHelpers.setFieldValue('user', values.user);
        void formikHelpers.setFieldValue('auth_provider', values.auth_provider);
      }
    },
    [dispatch, locale, onSubmit]
  );

  const initialValues: LoginFormDataType = useMemo(
    () => ({ user: '', password: '', auth_provider: signinMode }),
    [signinMode]
  );

  if (!bootstrap || !signinMode) return null;

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.title' })}
      description={formatMessage({ id: 'login.subtitle' })}
      data-testid="loginForm"
    >
      {error && (
        <Box sx={{ pb: 2 }}>
          <Alert severity="error" message={formatMessage({ id: error })} />{' '}
        </Box>
      )}
      <BaseFormik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
        {({ isSubmitting, resetForm, setFieldValue }) => (
          <>
            {Array.isArray(bootstrap.auth_providers) && bootstrap.auth_providers.length > 1 && (
              <Box sx={{ mb: 2 }} data-testid="auth-tabs">
                <Tabs
                  onChange={(v) => {
                    resetForm();
                    void setFieldValue('auth_provider', v);
                    setSigninMode(v as AUTH_PROVIDER);
                    setError('');
                  }}
                  value={signinMode}
                  tabs={bootstrap.auth_providers.map((provider) => ({
                    label: formatMessage({ id: `authProvider.${provider}` }),
                    value: provider,
                  }))}
                />
              </Box>
            )}
            <Form noValidate autoComplete="off">
              <Box sx={{ width: '100%', height: '88px' }}>
                <TextFieldField
                  name="user"
                  fullWidth
                  placeholder={formatMessage({ id: `login.${signinMode}UserPlaceholder` })}
                />
              </Box>

              <Box sx={{ width: '100%', height: '88px' }}>
                <TextFieldField
                  name="password"
                  fullWidth
                  type="password"
                  showPasswordVisibility
                  placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                />
              </Box>

              <Stack spacing={2}>
                <Button
                  size="large"
                  data-testid="loginForm-submitButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  text={formatMessage({ id: `login.${signinMode}SubmitButton` })}
                  color="secondary"
                  type="submit"
                />

                {signinMode === AUTH_PROVIDER.ENTERPRISE && bootstrap.forgot_password && (
                  <Link data-testid="loginForm-forgotLink" onClick={onForgot}>
                    <Text align="center">{formatMessage({ id: 'login.forgotPasswordLink' })}</Text>
                  </Link>
                )}
              </Stack>
            </Form>
          </>
        )}
      </BaseFormik>
    </AuthPanel>
  );
}
