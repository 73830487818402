//import { LegacyFiltersObj } from 'store/models/filters';

export enum PARAMS_TYPE {
  CONTAINS = 'contains',
  BOOLEAN = 'boolean',
  BRACKETS = 'brackets',
}

export type Separator = '?' | '&';

type FiltersOptions = {
  separator: Separator;
  specialParams?: {
    [key: string]: PARAMS_TYPE;
  };
};

export default function filtersQueryGenerator(
  filtersObj: Record<string, string>, //LegacyFiltersObj
  { separator, specialParams }: FiltersOptions = {
    separator: '?',
    specialParams: {},
  }
): string {
  const arr = Object.entries(filtersObj)
    .filter(([, value]) => {
      if (Array.isArray(value)) {
        return value.length;
      }
      return value !== '';
    })
    .map(([item, value]) => {
      // if (Array.isArray(value)) {
      //   const multiselectValues: unknown[] = [];
      //   value.forEach((val) => {
      //     if (typeof val === 'string' || typeof val === 'number') {
      //       multiselectValues.push(val);
      //     } else {
      //       multiselectValues.push(val.value);
      //     }
      //   });
      //   return `${item}=${`[${multiselectValues.join(',')}]`}`;
      // }

      if (specialParams) {
        if (specialParams[item] === PARAMS_TYPE.CONTAINS) {
          return `${item}=contains{{${value}}}`;
        }

        if (specialParams[item] === PARAMS_TYPE.BOOLEAN) {
          return `${item}=boolean{{${value}}}`;
        }

        const bracketsItems = Object.entries(specialParams)
          .filter(([, v]) => v === PARAMS_TYPE.BRACKETS)
          .map(([bracketItem]) => bracketItem);

        const matchingBrackets = bracketsItems.find((bracketField) => {
          return item.indexOf(bracketField) === 0;
        });

        if (matchingBrackets) {
          const bracketName = matchingBrackets.split('_')[0];
          const bracketValues = item.split('_').slice(1).join('_');
          return `${bracketName}[${bracketValues}]=${value}`;
        }
      }

      return `${item}=${value}`;
    });

  return arr.length ? `${separator}${arr.join('&')}` : '';
}
