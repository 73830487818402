import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Select } from '@athonet/ui/components/Input/Select';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { setLocale } from 'store/actions/locale';
import { useLocaleSelector } from 'store/selectors/locale';
import { localeOptions, Locale } from 'utils/locale';
import { useAppDispatch } from 'store';

export default function LanguageSwitch() {
  const locale = useLocaleSelector();
  const dispatch = useAppDispatch();

  if (localeOptions.length < 2) {
    return null;
  }

  return (
    <Box sx={{ width: 200, display: 'flex', alignContent: 'stretch' }}>
      <Select
        onChange={(e) => {
          dispatch(setLocale(e.target.value as Locale));
        }}
        size={'small'}
        value={locale}
        data-testid="language-switch"
      >
        {localeOptions.map((localeOption) => {
          return (
            <MenuItem value={localeOption.locale} key={localeOption.locale}>
              {localeOption.name}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
