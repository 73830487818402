import { getIn } from 'formik';
import { useMemo } from 'react';
import { ObjectSchema } from 'yup';

/**
 * The function `useYupSchemaProps` takes a validation schema and a name, and returns an object
 * with properties indicating if the field is required and its label.
 * @param validationSchema - The `validationSchema` parameter is an object that represents a Yup
 * validation schema. Yup is a JavaScript schema builder for value parsing and validation. It allows
 * you to define a set of validation rules for your data.
 * @param {string} name - The `name` parameter is a string that represents the name of a field in a
 * form.
 * @returns The function `useYupSchemaProps` returns an object with two properties: `required` and
 * `label`.
 */
const useYupSchemaProps = (name: string, validationSchema?: ObjectSchema<any>) => {
  const props = useMemo(() => {
    if (!validationSchema) return {};

    const schemaDescription = validationSchema.describe();
    const accessor = name.split('.').join('.fields.');
    const field = getIn(schemaDescription.fields, accessor);
    if (!field) {
      return false;
    }
    const required = field.tests.some((test: { name: string }) => test.name === 'required');
    return {
      required,
      label: field.label,
    };
  }, [name, validationSchema]);
  return props;
};

export default useYupSchemaProps;
