import React from 'react';
import { TextProps } from '@athonet/ui/components/Guidelines/Text';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { SyncStatus } from 'components/RemoteSync/utils';
import { isUnprovisioned } from 'store/selectors/5gProvisioning/usimCards';

interface UsimRemoteSyncTextProps {
  provisionedValue: string | null;
  unprovisionedValue: string | null;
  text?: string;
  tooltipText?: string;
}

const UsimRemoteSyncText: React.FC<TextProps & UsimRemoteSyncTextProps> = (props) => {
  const { provisionedValue, unprovisionedValue, text, tooltipText, ...textProps } = props;

  return (
    <RemoteSyncText
      syncStatus={
        isUnprovisioned(provisionedValue, unprovisionedValue) ? SyncStatus.PendingModification : SyncStatus.None
      }
      {...textProps}
      text={text}
      tooltipText={tooltipText}
    />
  );
};

export default UsimRemoteSyncText;
