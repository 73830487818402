import React from 'react';
import { FieldProps } from 'formik';
import useErrorProps from './useErrorProps';
import { Select, SelectProps } from '@athonet/ui/components/Input/Select';
import useYupSchemaProps from './useYupSchemaProps';
import { useBaseFormikContext } from '../BaseFormik';

export type SelectComponentProps = FieldProps &
  SelectProps<any> & {
    placeholder?: string;
  };

const SelectComponent: React.FC<SelectComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, isSubmitting },
    field,
    meta,
    children,
    onChange,
    ...selectProps
  } = props;

  const { validationSchema } = useBaseFormikContext();
  const errorProps = useErrorProps(errors[field.name], touched[field.name]);
  const yupProps = useYupSchemaProps(field.name, validationSchema);

  return (
    <Select
      size="small"
      name={field.name}
      value={field.value}
      {...yupProps}
      {...errorProps}
      {...selectProps}
      onChange={(e, c) => {
        void setFieldValue(field.name, e.target.value, true);
        if (onChange) onChange(e, c);
      }}
      disabled={isSubmitting || selectProps.disabled}
    >
      {children}
    </Select>
  );
};

export default SelectComponent;
