import { FormikErrors, FormikTouched } from 'formik';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { useMemo } from 'react';

/**
 * The function `useErrorProps` returns an object with properties `error` and `helperText` based on
 * the provided `error`, `touched` and `helperText` values.
 * @param {string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined} error - The `error`
 * parameter can be of type `string`, `string[]`, `FormikErrors<any>`, `FormikErrors<any>[]`, or
 * `undefined`. It represents the error message or messages associated with an input field.
 * @param {boolean | FormikTouched<any> | FormikTouched<any>[] | undefined} touched - The `touched`
 * parameter is a boolean value or an array of boolean values that indicate whether the corresponding
 * form fields have been touched or not. It is used to determine if an error message should be
 * displayed for a particular field.
 * @param {string} [helperText] - The `helperText` parameter is an optional string that provides
 * additional information or guidance related to the input field. This optional provided 'helperText' is
 * the text that will be displayed when there is no error.
 */
const useErrorProps = (
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined,
  helperText?: string
) => {
  const props = useMemo(() => {
    const hasError = Boolean(error);
    const hasTouched = Boolean(touched);

    return {
      error: hasError && hasTouched,
      ...(hasError &&
        hasTouched && {
          helperText: isString(error) ? error : isArray(error) ? error.join(', ') : undefined,
        }),
      ...(!hasError &&
        helperText && {
          helperText,
        }),
    };
  }, [error, helperText, touched]);
  return props;
};

export default useErrorProps;
