import React from 'react';
import { FieldProps } from 'formik';
import useErrorProps from './useErrorProps';
import useYupSchemaProps from './useYupSchemaProps';
import { Autocomplete, AutocompleteProps } from '@athonet/ui/components/Input/Autocomplete';
import { useBaseFormikContext } from '../BaseFormik';

export type AutocompleteComponentProps = FieldProps & AutocompleteProps<any, any, any, any>;

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = (props) => {
  const {
    form: { errors, touched, setFieldValue, isSubmitting },
    field,
    meta,
    onChange,
    helperText,
    ...autoCompleteProps
  } = props;

  const { validationSchema } = useBaseFormikContext();
  const yupProps = useYupSchemaProps(field.name, validationSchema);
  const errorProps = useErrorProps(errors[field.name], touched[field.name], helperText);

  return (
    <Autocomplete
      size="small"
      name={field.name}
      value={field.value}
      {...yupProps}
      {...errorProps}
      {...autoCompleteProps}
      disabled={isSubmitting || autoCompleteProps.disabled}
      onChange={(_, v, r, d) => {
        void setFieldValue(field.name, v, true);
        if (onChange) onChange(_, v, r, d);
      }}
    />
  );
};

export default AutocompleteComponent;
