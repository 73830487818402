import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import UploadLogoBox from './UploadLogoBox';
import AvatarEditor from 'react-avatar-editor';
import { FileUpload } from '@athonet/ui/components/Input/FileUpload';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { updateTenantLogo } from 'store/actions/tenants';
import { showErrorToast, showSuccessToast } from 'store/actions/toast';
import { useAppDispatch } from 'store';
import { AppAxiosError, parseError } from 'utils/errorString';

export type UploadLogoDialogProps = {
  tenantId: string;
};

export default function UploadLogoDialog({ tenantId }: UploadLogoDialogProps) {
  const { formatMessage } = useIntl();
  const [image, setImage] = useState<File | null>();
  const editor = useRef<AvatarEditor>(null);
  const { dialogClose } = useOverlay();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const parseErrorMessages = useCallback(
    (error: AppAxiosError) => {
      const errorCode = error.response?.data?.error;
      return errorCode === 'invalid image size'
        ? formatMessage({ id: 'users.form.upload.error.pixels' })
        : parseError(error);
    },
    [formatMessage]
  );

  const handleOnSubmit = useCallback(async () => {
    if (editor.current) {
      setLoading(true);
      const canvas = editor.current.getImageScaledToCanvas().toDataURL();
      const res = await fetch(canvas);
      const blob = await res.blob();
      try {
        await dispatch(updateTenantLogo(tenantId, blob));
        dispatch(showSuccessToast());
      } catch (e: any) {
        dispatch(
          showErrorToast({
            message: parseErrorMessages(e),
          })
        );
      }
      dialogClose();
      setLoading(false);
    }
  }, [dialogClose, dispatch, parseErrorMessages, tenantId]);

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <UploadLogoBox image={image || null} setImage={setImage} editor={editor} tenantId={tenantId} />
          <FileUpload
            accept={{
              image: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
            }}
            maxSize={1024 ** 2}
            label={formatMessage({ id: 'tenants.form.logoUpload.subtitle' })}
            onChange={(files) => {
              setImage(files[0]);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" text="Cancel" onClick={dialogClose} />
        <Button text="Confirm" onClick={() => void handleOnSubmit()} loading={loading} />
      </DialogActions>
    </>
  );
}
