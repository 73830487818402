import { useCallback, MouseEvent, useMemo } from 'react';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { IconProps } from '@athonet/ui/components/Guidelines/Icon';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';

export type DropdownToolsOption = {
  iconName: IconProps['name'];
  label: string;
  onClick: () => void;
  disabled?: boolean;
  hide?: boolean;
  value: string;
  permissions?: string[];
};

type DropdownToolsProps = {
  options: DropdownToolsOption[];
};

export default function DropdownTools({ options }: DropdownToolsProps) {
  const { menuOpen, menuClose } = useOverlay();

  const items = useMemo(
    () =>
      options
        .map((option) => ({
          ...option,
          onClick: () => {
            menuClose();
            option.onClick();
          },
        }))
        .filter((item) => !item.hide),
    [menuClose, options]
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      menuOpen({
        anchorEl: event.currentTarget,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        items,
      });
    },
    [items, menuOpen]
  );

  return (
    <IconButton
      onClick={handleClick}
      fontSize="small"
      disabled={!options.length}
      data-testid="dropdown-tools"
      name="Other"
      sx={{ transform: 'rotate(90deg)' }}
    />
  );
}
