import { useIntl } from 'react-intl';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Button } from '@athonet/ui/components/Input/Button';
import { Form } from 'formik';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { useCallback, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import { adminConfigLogin, getDataUser, hydrateAdminConfigUser } from 'store/actions/adminconfig/login';
import { isEntityFailure } from 'store/reducers';
import { useAdminConfigUserSelector } from 'store/selectors/adminConfig';
import { useNavigate } from 'react-router-dom';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useAppDispatch } from 'store';
import BaseFormik from 'components/Form/BaseFormik';
import TextFieldField from 'components/Form/Field/TextFieldField';

export interface AdminDataType {
  password: string;
}

export default function Login() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const adminConfigUser = useAdminConfigUserSelector();
  const navigate = useNavigate();

  const schema = object().shape({
    password: string()
      .required()
      .label(formatMessage({ id: 'login.passwordLabel' }))
      .trim(),
  });

  const handleSubmit = useCallback(
    async (values: { password: string }) => {
      void dispatch(adminConfigLogin(values));
    },
    [dispatch]
  );

  useEffect(() => {
    if (adminConfigUser.data) {
      navigate('/adminconfig');
    } else if (getDataUser()) {
      void dispatch(hydrateAdminConfigUser()); // TODO [minor]: can be moved into reducer
    }
  }, [adminConfigUser, dispatch, navigate]);

  const initialValues: AdminDataType = useMemo(() => ({ password: '' }), []);

  return (
    <>
      <Stack align="center" justify="center" fullHeight fullWidth>
        <Box sx={{ width: '280px', mb: 2 }}>
          <Logo width={280} />
        </Box>
        <AuthPanel
          title={formatMessage({ id: 'login.title' })}
          description={formatMessage({ id: 'login.subtitle' })}
          data-testid="adminconfigloginForm"
        >
          <Box sx={{ mb: 2 }}>
            {isEntityFailure(adminConfigUser) && (
              <Alert severity="error" message={formatMessage({ id: 'login.errorMessage' })} />
            )}
          </Box>
          <BaseFormik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ isSubmitting }) => (
              <>
                <Form noValidate autoComplete="off">
                  <Box sx={{ width: '100%', height: '88px' }}>
                    <TextFieldField
                      fullWidth
                      name="password"
                      type="password"
                      showPasswordVisibility
                      placeholder={formatMessage({ id: 'login.passwordPlaceholder' })}
                    />
                  </Box>

                  <Stack spacing={2}>
                    <Button
                      size="large"
                      data-testid="adminconfigloginForm-submitButton"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      text={formatMessage({ id: `login.enterpriseSubmitButton` })}
                      color="secondary"
                      type="submit"
                    />
                  </Stack>
                </Form>
              </>
            )}
          </BaseFormik>
        </AuthPanel>
      </Stack>
    </>
  );
}
