import React from 'react';
import RemoteSyncText from 'components/RemoteSync/RemoteSyncText';
import { useAppSelector } from 'store';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import {
  getPendingModificationSliceIds,
  selectSliceById,
  selectSliceNameSyncStatus,
} from 'store/selectors/serviceProfiles';
import { useIntl } from 'react-intl';
import { SyncStatus } from 'components/RemoteSync/utils';

interface SliceNameProps {
  serviceProfileId: string;
  sliceId: string;
}

const SliceName: React.FC<SliceNameProps> = (props) => {
  const { formatMessage } = useIntl();
  const { serviceProfileId, sliceId, ...textProps } = props;
  const slice = useAppSelector((state) => selectSliceById(state, serviceProfileId, sliceId));
  const pendingEditSliceIds = useAppSelector((state) => getPendingModificationSliceIds(state, serviceProfileId));

  const { oldValue, syncStatus } = useAppSelector((state) =>
    selectSliceNameSyncStatus(state, serviceProfileId, sliceId)
  );

  const tooltipStatus = pendingEditSliceIds.includes(sliceId) ? SyncStatus.PendingModification : syncStatus;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <RemoteSyncText
        type={'body2'}
        syncStatus={syncStatus}
        text={slice?.name}
        tooltipText={oldValue?.toString()}
        {...textProps}
      />
      <RemoteSyncTooltipIcon
        syncStatus={tooltipStatus}
        text={formatMessage({ id: `common.highlighting.remoteSyncPending` })}
      />
    </Box>
  );
};

export default SliceName;
