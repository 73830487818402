import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { FormikConfig, FormikProps } from 'formik';
import { DialogActions } from '@athonet/ui/components/Overlay/Dialog/DialogActions';
import { setNestedObjectValues } from 'formik';
import { CancelButton } from 'components/Button/CancelButton';
import { ConfirmButton } from 'components/Button/ConfirmButton';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import BaseFormik from 'components/Form/BaseFormik';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';

export interface FormikDialogProps {
  /**
   * An action to be executed on dialog close
   */
  onClosed?: () => void;
  cancelLabel?: string;
  submitLabel?: string;
  /**
   * Display an alert when exiting and form is dirty - by clicking cancel button or unloading page (default: `true`)
   */
  alertPendingChanges?: boolean;
  /**
   * Enable submit button when form is not dirty (default: `true`)
   */
  submitWithNoChanges?: boolean;
  /**
   * Provides an error message (form has got an error if is not empty or undefined)
   */
  errorMessage?: string;
}

const FormikDialog: React.FC<
  {
    onCancel?: () => void;
    onValidateSuccess?: () => void;
  } & FormikDialogProps &
    FormikConfig<any>
> = ({
  children,
  errorMessage,
  onCancel,
  onValidateSuccess,
  onClosed,
  cancelLabel,
  submitLabel: _submitLabel,
  alertPendingChanges = true,
  submitWithNoChanges = true,
  ...formikProps
}) => {
  const [dirty, setDirty] = useState<boolean | undefined>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean | undefined>(false);
  const formikRef = useRef<any>(null);
  const { dialogClose, confirmationSimpleDialogOpen } = useOverlay();
  const { formatMessage } = useIntl();

  const onUnload = useCallback(
    (evt: BeforeUnloadEvent) => {
      if (alertPendingChanges && dirty) {
        evt.preventDefault();
        return (evt.returnValue = formatMessage({ id: 'common.confirmPendingChanges' }));
      } else {
        if (onClosed) onClosed();
        return;
      }
    },
    [dirty, onClosed, alertPendingChanges, formatMessage]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', onUnload, { capture: true });
    };
  }, [onUnload]);

  const handleOnSubmitButtonClick = useCallback(() => {
    const validate = async () => {
      if (!formikRef?.current) return;
      const validationErrors = await formikRef.current.validateForm();
      const errorKeys = Object.keys(validationErrors);
      if (validationErrors && errorKeys.length > 0) {
        void formikRef.current.setTouched(setNestedObjectValues(validationErrors, true));
        const element = document.querySelector(`input[name='${errorKeys[0]}']`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (onValidateSuccess) onValidateSuccess();
        else formikRef.current?.handleSubmit();
      }
    };
    void validate();
  }, [onValidateSuccess]);

  const handleOnCancelButtonClick = useCallback(() => {
    if (onCancel) onCancel();
    else {
      const closingCallback = () => {
        if (onClosed) onClosed();
        dialogClose();
      };

      if (alertPendingChanges && dirty) {
        confirmationSimpleDialogOpen({
          description: formatMessage({ id: 'common.confirmPendingChanges' }),
          onConfirm: async () => closingCallback(),
        });
      } else closingCallback();
    }
  }, [onCancel, alertPendingChanges, dirty, onClosed, dialogClose, confirmationSimpleDialogOpen, formatMessage]);

  const hasError = !isNil(errorMessage) && !isEmpty(errorMessage);
  let submitLabel = _submitLabel;
  if (hasError) submitLabel = formatMessage({ id: 'common.form.retry' });

  return (
    <>
      <DialogContent>
        {hasError && (
          <Alert
            sx={{
              opacity: isSubmitting ? 0.5 : 1,
            }}
            severity="error"
            title={formatMessage({ id: 'common.error' })}
            message={errorMessage}
          />
        )}
        <BaseFormik
          innerRef={(formikActions: FormikProps<any>) => {
            setDirty(formikActions?.dirty);
            setIsSubmitting(formikActions?.isSubmitting);
            formikRef.current = formikActions;
          }}
          {...formikProps}
        >
          {children}
        </BaseFormik>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
          <CancelButton
            {...(cancelLabel ? { text: cancelLabel } : {})}
            onClick={handleOnCancelButtonClick}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
          <ConfirmButton
            {...(submitLabel ? { text: submitLabel } : {})}
            onClick={handleOnSubmitButtonClick}
            loading={isSubmitting}
            disabled={isSubmitting || (!submitWithNoChanges && !dirty)}
          />
        </Stack>
      </DialogActions>
    </>
  );
};

export default FormikDialog;
