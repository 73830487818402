import Masonry from '@athonet/ui/components/Masonry';
import { useLoaderData } from 'react-router-dom';
import {
  NetworkAccessManagementByIdQueryResult,
  NetworkRadiosByIdQueryResult,
  NetworkThroughputByIdQueryResult,
  NetworkUsersSummaryByIdQueryResult,
  NetworkUserTrafficManagementByIdQueryResult,
} from '../../graphql/client/network';
import { NetworkOverviewLoaderData } from 'graphql/loaders/network-overview';
import { NetworkThroughput } from './Details/Throughput';
import { NetworkUsersSummary } from './Details/UsersSummary';
import { NetworkRadios } from './Details/Radios';
import { NetworkAccessManagement } from './Details/AccessManagement';
import { NetworkUserTrafficManagement } from './Details/UserTrafficManagement';
import { AwaitPanel } from 'components/Panel/AwaitPanel';
import { useIntl } from 'react-intl';

export function NetworkDetails() {
  const { formatMessage } = useIntl();

  const {
    networkThroughputByIdQuery,
    networkRadiosByIdQuery,
    networkAccessManagementByIdQuery,
    networkUserTrafficManagementByIdQuery,
    networkUsersSummaryByIdQuery,
  } = useLoaderData() as NetworkOverviewLoaderData;

  return (
    <Masonry columns={{ xs: 1, md: 2 }} spacing={1}>
      <AwaitPanel
        folder
        resolve={networkUsersSummaryByIdQuery}
        title={formatMessage({ id: 'menu.networks.usersSummaryPanel.title' })}
        height={153}
      >
        {(resolvedNetworkUsersSummaryByIdQuery: NetworkUsersSummaryByIdQueryResult) => {
          const network = resolvedNetworkUsersSummaryByIdQuery?.data?.me?.networkSummary?.networks[0];
          return network ? <NetworkUsersSummary usersSummary={network.usersSummary} /> : null;
        }}
      </AwaitPanel>

      <AwaitPanel
        folder
        resolve={networkRadiosByIdQuery}
        title={formatMessage({ id: 'menu.networks.radiosPanel.title' })}
        height={139}
      >
        {(resolvedNetworkRadiosByIdQuery: NetworkRadiosByIdQueryResult) => {
          const network = resolvedNetworkRadiosByIdQuery?.data?.me?.networkSummary?.networks[0];
          return network ? <NetworkRadios radios={network.radios} /> : null;
        }}
      </AwaitPanel>

      <AwaitPanel
        folder
        resolve={networkThroughputByIdQuery}
        title={formatMessage({ id: 'menu.networks.userThroughputPanel.title' })}
        height={333}
      >
        {(resolvedNetworkThroughputByIdQuery: NetworkThroughputByIdQueryResult) => {
          const network = resolvedNetworkThroughputByIdQuery?.data?.me?.networkSummary?.networks[0];
          return network ? <NetworkThroughput throughput={network.throughput} /> : null; // TODO: the fact that we don't have a valid network to render must be handled top level
        }}
      </AwaitPanel>

      <AwaitPanel
        folder
        resolve={networkAccessManagementByIdQuery}
        title={formatMessage({ id: 'menu.networks.accessManagementPanel.title' })}
        height={139}
      >
        {(resolvedNetworkAccessManagementByIdQuery: NetworkAccessManagementByIdQueryResult) => {
          const network = resolvedNetworkAccessManagementByIdQuery?.data?.me?.networkSummary?.networks[0];
          return network ? <NetworkAccessManagement accessManagement={network.status.accessManagement} /> : null;
        }}
      </AwaitPanel>

      <AwaitPanel
        folder
        resolve={networkUserTrafficManagementByIdQuery}
        title={formatMessage({ id: 'menu.networks.userTrafficManagementPanel.title' })}
        height={125}
      >
        {(resolvedNetworkUserTrafficManagementByIdQuery: NetworkUserTrafficManagementByIdQueryResult) => {
          const network = resolvedNetworkUserTrafficManagementByIdQuery?.data?.me?.networkSummary?.networks[0];
          return network ? (
            <NetworkUserTrafficManagement userTrafficManagement={network.status.userTrafficManagement} />
          ) : null;
        }}
      </AwaitPanel>
    </Masonry>
  );
}
