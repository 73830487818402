import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { USIM5G_STATUS, Usim5g } from 'store/models/usim5g';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { prepareSingleUsim5gForProvisioning } from 'store/actions/usims5g';
import { useAppDispatch } from 'store';
import EditNetworkProfileDialogContent from './EditNetworkProfileDialogContent';

interface EditUsimNetworkProfileDialogProps {
  usim5g: Usim5g;
}

const EditUsimNetworkProfileDialog: React.FC<EditUsimNetworkProfileDialogProps> = ({ usim5g }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const handlePrepareForProvisioning = useCallback(
    async (networkId: string, profileId: string) =>
      await dispatch(
        prepareSingleUsim5gForProvisioning({
          networkId,
          profileId,
          usim5gSupi: usim5g.supi,
        })
      ),
    [dispatch, usim5g.supi]
  );

  const defaultNetwork = useMemo(
    () =>
      usim5g.status === USIM5G_STATUS.PROVISIONED && usim5g.network_topology_id
        ? usim5g.network_topology_id
        : undefined,
    [usim5g]
  );

  return (
    <EditNetworkProfileDialogContent
      onPrepareForProvisioning={handlePrepareForProvisioning}
      isBulkOperation={false}
      defaultNetwork={defaultNetwork}
    >
      <Text type="body2">{formatMessage({ id: 'usims.form.usim.prepare.subtitle' }, { element: usim5g.supi })}</Text>
    </EditNetworkProfileDialogContent>
  );
};

export default EditUsimNetworkProfileDialog;
