import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { CreatePlmnDialog } from './CreatePlmnDialog';
import { useCallback } from 'react';
import { CreateButton } from 'components/Button/CreateButton';

interface CreatePlmnButtonProps {
  networkId?: string;
}

export function CreatePlmnButton({ networkId }: CreatePlmnButtonProps) {
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const handleOpenCreateDialog = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'plmns.newPlmn' }),
      content: () => <CreatePlmnDialog networkId={networkId} />,
    });
  }, [dialogOpen, formatMessage, networkId]);

  return <CreateButton onClick={handleOpenCreateDialog} />;
}
