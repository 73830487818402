import { AUTH_PROVIDER } from './../models/environmentConfiguration';
import { createSelector } from '@reduxjs/toolkit';
import { State, useAppSelector } from 'store';

export const selectBootstrap = (state: State) => state.bootstrap;

export function useBootstrapSelector() {
  const bootstrap = useAppSelector((state) => state.bootstrap);

  return bootstrap;
}

const selectAuthProviders = (state: State) => state.bootstrap?.auth_providers;
const selectCreateUserWithEnterpriseAuth = (state: State) => state.bootstrap?.create_user_with_enterprise_auth;
const selectAthonetosSupportEnabled = (state: State) => state.bootstrap?.athonetos_support_enabled;
const selectAthuxSupportEnabled = (state: State) => state.bootstrap?.athux_support_enabled;

export const selectDefaultAuthProvider = createSelector([selectAuthProviders], (authProviders) => {
  if (!authProviders) {
    return undefined;
  }
  if (Array.isArray(authProviders)) {
    if (authProviders.length) {
      return authProviders[0];
    }
  } else {
    return authProviders;
  }
  return undefined;
});

export const selectCreateUserAuthProviders = createSelector(
  [selectAuthProviders, selectCreateUserWithEnterpriseAuth],
  (authProviders, createUserWithEnterpriseAuth) => {
    if (!authProviders) {
      return [];
    }
    if (Array.isArray(authProviders)) {
      if (createUserWithEnterpriseAuth === false) {
        return authProviders.filter((realm) => realm !== AUTH_PROVIDER.ENTERPRISE);
      }
      return authProviders;
    } else {
      return [authProviders];
    }
  }
);

export const selectPlatForm = createSelector(
  [selectAthonetosSupportEnabled, selectAthuxSupportEnabled],
  (athonetosSupportEnabled, athuxSupportEnabled) => {
    let platform = '';
    if (athonetosSupportEnabled && athuxSupportEnabled) {
      platform = '';
    } else {
      if (athonetosSupportEnabled) platform = 'athonetos';
      if (athuxSupportEnabled) platform = 'athux';
    }

    return platform;
  }
);
