import axios, { AxiosRequestConfig } from 'axios';
import config, { baseApiUrlDashboard } from 'config';
import { lsGet, lsSet } from 'utils/localStorage';
import { Thunk } from '.';
import { logoutUser, updateToken } from './login';
import getFetchHeaders from 'utils/fetchHeaders';
import { User } from 'store/models/user';
import { adminConfigLogoutUser } from './adminconfig/login';
import { getBootstrap } from './bootstrap';

type TokenRes = {
  expiration_lapse: number;
  token: string;
};

export function fetchRequest<R>(
  options: AxiosRequestConfig,
  polling: boolean = false,
  adminConfig: boolean = false
): Thunk<Promise<R>> {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    const res = await axios.request<R>({
      ...options,
      headers: {
        ...options.headers,
        ...getFetchHeaders(user, adminConfig),
      },
    });

    const location = res.headers['content-location'];
    if (res.status === 202 && (location || polling)) {
      let pollingOptions = options;
      if (location) {
        pollingOptions = { ...options, url: `${baseApiUrlDashboard}${location}`, method: 'GET' };
      }
      return dispatch(fetchRequest(pollingOptions, true));
    }

    return res.data;
  };
}

let refreshingTokenPromise: Promise<string> | null = null;
let refreshTokenTS = 0;

function newToken(): Thunk<Promise<string>> {
  return async (dispatch, getState) => {
    if (refreshingTokenPromise && new Date().getTime() - refreshTokenTS < 5000) {
      return refreshingTokenPromise;
    }

    async function newTokenFetchRequest() {
      refreshTokenTS = new Date().getTime();
      const state = getState();
      const user = state.user;
      const options: AxiosRequestConfig = {
        url: `${config.apis.refreshToken}`,
        method: 'GET',
        headers: {
          'horus-refresh': user?.refreshToken || '',
        },
      };

      const result = await dispatch(fetchRequest<TokenRes>(options));
      const token = result.token;
      dispatch(
        updateToken({
          accessToken: token,
        })
      );

      const horusStorage = lsGet<User>();
      let ls: {
        accessToken?: string;
      } = {};
      if (horusStorage) {
        ls = horusStorage;
      }
      ls.accessToken = token;
      lsSet(ls);

      await dispatch(getBootstrap());
      return token;
    }

    refreshingTokenPromise = newTokenFetchRequest();
    return refreshingTokenPromise;
  };
}

function refreshToken<R>(options: AxiosRequestConfig): Thunk<Promise<R>> {
  return async (dispatch) => {
    const token = await dispatch(newToken());
    return dispatch(
      fetchRequest<R>({
        ...options,
        headers: {
          ...options.headers,
          'horus-token': token,
        },
      })
    );
  };
}

// TODO: remove history from here, it's not even used
export function fetchData<R>(options: AxiosRequestConfig, history?: History): Thunk<Promise<R>> {
  return async (dispatch) => {
    try {
      const res = await dispatch(fetchRequest<R>(options, false));
      return res;
    } catch (e: any) {
      if (e.response?.status === 401) {
        try {
          const res = await dispatch(refreshToken<R>(options));
          return res;
        } catch (r) {
          if (!history) {
            console.debug(
              `fetchData: the second fetchData try failed, but you are not providing a history object in order to logout the user`
            );
          }
          dispatch(logoutUser());
        }
      }

      throw e;
    }
  };
}

export function fetchAdminConfigData<R>(options: AxiosRequestConfig, isLoggingIn: boolean = false): Thunk<Promise<R>> {
  return async (dispatch) => {
    try {
      const res = await dispatch(fetchRequest<R>(options, false, true));
      return res;
    } catch (e: any) {
      if (e.response?.status === 401 && !isLoggingIn) {
        dispatch(adminConfigLogoutUser());
      }

      throw e;
    }
  };
}
