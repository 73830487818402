import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';

import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { RootMapRoute } from './RootMapRoute';

export default function Landing() {
  return (
    <GridContainer sx={{ height: '100%' }}>
      <GridItem
        size={{
          xs: 12,
        }}
        sx={{ height: '100%' }}
        zeroMinWidth
      >
        <RootMapRoute />
      </GridItem>
    </GridContainer>
  );
}
