import { useEffect, useCallback } from 'react';
import { Security } from 'components/Security';
import { EditRoleDialog } from 'components/Edit/Security/EditRoleDialog';
import { U_PERMISSIONS, checkPermissionToUse } from 'utils/permissionCodes';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useIntl } from 'react-intl';
import { deleteRole } from 'store/actions/roles';
import { Button } from '@athonet/ui/components/Input/Button';
import { getRoles } from 'store/actions/roles/getRoles';
import { DATA_LIFECYCLE } from 'store/reducers';
import { Role } from 'store/models/role';
import { useAppDispatch, useAppSelector } from 'store';

export function Roles() {
  const userdata = useAppSelector((state) => state.user);
  const { list, status } = useAppSelector((state) => state.roles);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { confirmationDialogOpen, dialogOpen } = useOverlay();

  useEffect(() => {
    void dispatch(getRoles());
  }, [dispatch]);

  const handleOpenCreateRole = useCallback(() => {
    dialogOpen({
      title: formatMessage({ id: 'security.newRole' }),
      content: () => <EditRoleDialog />,
    });
  }, [dialogOpen, formatMessage]);

  const handleOpenEditRole = useCallback(
    (role: Role) => {
      dialogOpen({
        title: formatMessage({ id: 'security.editRole' }),
        content: () => <EditRoleDialog role={role} />,
      });
    },
    [dialogOpen, formatMessage]
  );

  const toggleDelete = useCallback(
    (role: Role) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'security.deleteRole.confirm.title' }),
        description: formatMessage({ id: 'security.deleteRole.confirm' }),
        alertMessage: formatMessage({ id: 'security.actions.itemsAffected' }, { element: role.name }),
        continueButtonText: formatMessage({ id: 'security.deleteRole.confirm.continueButton' }, { elements: 1 }),
        onConfirm: async () => dispatch(deleteRole(role.id)),
        severity: 'danger',
        dataTestid: 'confirm-delete-role',
      });
    },
    [confirmationDialogOpen, dispatch, formatMessage]
  );

  return (
    <>
      {checkPermissionToUse(userdata, U_PERMISSIONS.READ_ROLE) && (
        <Security
          createComponent={
            checkPermissionToUse(userdata, U_PERMISSIONS.CREATE_ROLE) && (
              <Button
                text={formatMessage({ id: 'common.new' })}
                variant="outlined"
                onClick={handleOpenCreateRole}
                data-testid="toolbar-new-button"
              />
            )
          }
          roles={list}
          disableEdit={!checkPermissionToUse(userdata, U_PERMISSIONS.UPDATE_ROLE)}
          disableDelete={!checkPermissionToUse(userdata, U_PERMISSIONS.DELETE_ROLE)}
          toggleEdit={handleOpenEditRole}
          toggleDelete={toggleDelete}
          message={
            status === DATA_LIFECYCLE.FAILURE
              ? formatMessage({ id: 'security.message.error' })
              : formatMessage({ id: 'security.message.void' })
          }
          loading={status === DATA_LIFECYCLE.LOADING}
        />
      )}
    </>
  );
}
