import { FilterInputValue, FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { Sort } from 'store/reducers';
import filtersQueryGenerator, { PARAMS_TYPE, Separator } from 'utils/filtersQueryGenerator';
//import { array, boolean, lazy, object, Schema, string } from 'yup';

//export type FilterValue = string | number | string[] | number[] | boolean;

// export type MultiSelectValue = {
//   key: string;
//   value: FilterValue;
// };

// export type FiltersProps = {
//   values: Record<string, FilterInputValue | undefined>;
//   onSubmit?: (values: Record<string, FilterInputValue>) => void;
// };

// export type LegacyFilterObjValue = FilterValue | MultiSelectValue[] | FilterInputValue;

// export type LegacyFiltersObj<F extends LegacyFilterObjValue = FilterValue | MultiSelectValue[]> = Record<string, F>;

export type FiltersObj = {
  [key: string]: FilterInputValue;
};

// type ImsiSchemaProps = {
//   activeFilterType: FILTER_INPUT_TYPE;
//   value: unknown;
//   not: boolean;
// };

// export function usimCodeSchema(field: string, regexp: RegExp, exactRegexp: RegExp) {
//   return lazy((value: ImsiSchemaProps | undefined): Schema<ImsiSchemaProps | undefined> => {
//     let arrayValueSchema = array();
//     let stringValueSchema = null;

//     switch (value?.activeFilterType) {
//       case FILTER_INPUT_TYPE.MATCH:
//         arrayValueSchema = array().of(
//           string().matches(exactRegexp, `Please include only valid ${field.toUpperCase()} formats`)
//         );
//         break;
//       case FILTER_INPUT_TYPE.UPLOAD_FILE:
//         arrayValueSchema = array();
//         break;
//       case FILTER_INPUT_TYPE.CONTAINS:
//       case FILTER_INPUT_TYPE.STARTS:
//       case FILTER_INPUT_TYPE.ENDS:
//         stringValueSchema = string()
//           .matches(regexp, `Please enter a valid ${field.toUpperCase()} or partial ${field.toUpperCase()} format`)
//           .nullable()
//           .notRequired();
//         break;
//       case FILTER_INPUT_TYPE.RANGE:
//         arrayValueSchema = array()
//           .min(2)
//           .test('range', `Please enter a valid range of ${field.toUpperCase()}s`, (v) => {
//             return (
//               typeof v === 'undefined' ||
//               (Array.isArray(v) &&
//                 v.length === 2 &&
//                 ((typeof v[0] === 'string' &&
//                   exactRegexp.test(v[0]) &&
//                   typeof v[1] === 'string' &&
//                   exactRegexp.test(v[1]) &&
//                   v[1] >= v[0]) ||
//                   (v[0] === null && v[1] === null)))
//             );
//           });
//         break;
//       default:
//         break;
//     }

//     return object().shape<ImsiSchemaProps>({
//       activeFilterType: string(),
//       not: boolean(),
//       value: stringValueSchema || arrayValueSchema,
//     });
//   });
// }

function parseStringFilterValueToQuery(filtersObj: FiltersObj) {
  const newFiltersObj: Record<string, string> = {};

  for (const key in filtersObj) {
    const filterValue = parseFilterValueToQuery(filtersObj[key]);
    if (typeof filterValue === 'string') {
      newFiltersObj[key] = filterValue;
    }
  }
  return newFiltersObj;
}

// export function generateUsimsFiltersQuery(filtersObj: FiltersObj, separator: Separator): string {
//   const newFiltersObj = parseStringFilterValueToQuery(filtersObj);
//   return filtersQueryGenerator(newFiltersObj, {
//     separator,
//     specialParams: {
//       error: PARAMS_TYPE.BOOLEAN,
//       metadata: PARAMS_TYPE.BRACKETS,
//     },
//   });
// }

export function generateGpgKeysFiltersQuery(filtersObj: FiltersObj, separator: Separator) {
  const newFiltersObj = parseStringFilterValueToQuery(filtersObj);

  return filtersQueryGenerator(newFiltersObj, {
    separator,
    specialParams: {
      private: PARAMS_TYPE.BOOLEAN,
    },
  });
}

export function generateFiltersQuery(filtersObj: FiltersObj, separator: Separator): string {
  const newFiltersObj = parseStringFilterValueToQuery(filtersObj);
  return filtersQueryGenerator(newFiltersObj, { separator });
}

// export function generateContainsFiltersQuery(
//   filtersObj: LegacyFiltersObj,
//   separator: Separator,
//   containsParams?: string[]
// ) {
//   let specialParams = {};
//   if (containsParams) {
//     containsParams.forEach((param) => {
//       specialParams = {
//         ...specialParams,
//         [param]: PARAMS_TYPE.CONTAINS,
//       };
//     });
//   }
//   return filtersQueryGenerator(filtersObj, {
//     separator,
//     specialParams,
//   });
// }

export function parseFilterString(filter: FILTER_INPUT_TYPE, notEnabled: boolean) {
  switch (filter) {
    case FILTER_INPUT_TYPE.RANGE:
      return 'range';
    case FILTER_INPUT_TYPE.CONTAINS:
      return notEnabled ? 'not_contains' : 'contains';
    case FILTER_INPUT_TYPE.STARTS:
      return 'starts';
    case FILTER_INPUT_TYPE.ENDS:
      return 'ends';
    case FILTER_INPUT_TYPE.MATCH:
      return notEnabled ? 'not' : 'match';
    case FILTER_INPUT_TYPE.UPLOAD_FILE:
    case FILTER_INPUT_TYPE.OPTIONS:
      return notEnabled ? 'not' : '';
  }
}

export function getDefaultValue(filterType: FILTER_INPUT_TYPE, isMultiple: boolean): FilterInputValue {
  switch (filterType) {
    case FILTER_INPUT_TYPE.UPLOAD_FILE:
      return { value: undefined, activeFilterType: FILTER_INPUT_TYPE.UPLOAD_FILE, not: false };
    case FILTER_INPUT_TYPE.OPTIONS:
      return { value: isMultiple ? [] : null, activeFilterType: FILTER_INPUT_TYPE.OPTIONS, not: false };
    case FILTER_INPUT_TYPE.MATCH:
    case FILTER_INPUT_TYPE.STARTS:
    case FILTER_INPUT_TYPE.CONTAINS:
    case FILTER_INPUT_TYPE.ENDS:
      return { value: isMultiple ? [] : null, activeFilterType: filterType, not: false };
    case FILTER_INPUT_TYPE.RANGE:
      return { value: [null, null], activeFilterType: FILTER_INPUT_TYPE.RANGE, not: false };
  }
}

// parseFilterValueToQuery receives FilterInputValue and returns the string that our backend expects to apply the filter
export function parseFilterValueToQuery(filterValue: FilterInputValue): string | File[] {
  const filterString = parseFilterString(filterValue.activeFilterType, filterValue.not);

  switch (filterValue.activeFilterType) {
    case FILTER_INPUT_TYPE.RANGE:
      if (Array.isArray(filterValue.value)) {
        if (filterValue.value.every((x) => x === null) || !filterValue.value.length) {
          return '';
        } else {
          return `${filterString}{{${filterValue.value[0] || ''}}}{{${filterValue.value[1] || ''}}}`;
        }
      } else {
        return '';
      }

    case FILTER_INPUT_TYPE.MATCH:
      if (Array.isArray(filterValue.value)) {
        return filterValue.value.length ? `${filterString}{{${filterValue.value.join(',')}}}` : '';
      }

      return filterValue.value ? `${filterString}{{${filterValue.value}}}` : '';
    case FILTER_INPUT_TYPE.CONTAINS:
    case FILTER_INPUT_TYPE.STARTS:
    case FILTER_INPUT_TYPE.ENDS:
      return filterValue.value ? `${filterString}{{${filterValue.value}}}` : '';
    case FILTER_INPUT_TYPE.UPLOAD_FILE:
      return filterValue.value || [];
    case FILTER_INPUT_TYPE.OPTIONS:
      if (Array.isArray(filterValue.value)) {
        if (filterValue.value.length) {
          return filterString
            ? `${filterString}{{${filterValue.value.map((item) => item.value).join(',')}}}`
            : `${filterValue.value.map((item) => item.value).join(',')}`;
        } else {
          return '';
        }
      }
      return filterString
        ? `${filterString}{{${filterValue?.value?.value?.toString()}}}`
        : filterValue?.value?.value?.toString() || '';
  }
}

export type GetResultsRes<D> = {
  items: D[];
  total_items: number;
  page: number;
};

export type GetResultsOptions = {
  page?: number;
  sortBy?: Sort;
  filterBy?: FiltersObj;
};
const FILTERS_QUERY_PARAM = 'filters';

export function setFiltersQueryString(queryStringValues: FiltersObj) {
  const filtersQueryString = encodeURIComponent(JSON.stringify(queryStringValues));
  const searchParams = new URLSearchParams(window.location.search);

  if (Object.keys(queryStringValues).length) {
    searchParams.set(FILTERS_QUERY_PARAM, filtersQueryString);
  } else {
    searchParams.delete(FILTERS_QUERY_PARAM);
  }
  const paramsString = searchParams.toString();
  window.history.pushState({}, '', `${window.location.pathname}${paramsString ? `?${paramsString}` : ''}`);
}

export function getFiltersByQueryString() {
  const searchParams = new URLSearchParams(window.location.search);
  const filtersQueryString = searchParams.get(FILTERS_QUERY_PARAM);
  if (!filtersQueryString) {
    return {};
  }
  return JSON.parse(decodeURIComponent(filtersQueryString));
}
