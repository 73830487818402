import { AUTH_PROVIDER } from 'store/models/environmentConfiguration';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { User } from 'store/models/user';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { showErrorToast, showSuccessToast } from './toast';
import { setUser } from './login';
import { sentryLogError } from 'sentry';
import { CreateUserDataType, EditMeType, EditUserDataType } from 'components/Edit/Users/types';
import { getUsers } from './users/getUsers';

export function createUser({
  tenantId,
  fullName,
  roleName,
  phone,
  email,
  password,
  realm,
  name,
}: CreateUserDataType): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: config.apis.createUser,
      method: 'POST',
      data: {
        tenant_id: tenantId,
        full_name: fullName,
        role: roleName,
        phone,
        realm,
        name: realm === AUTH_PROVIDER.ENTERPRISE ? email : name,
        ...(realm === AUTH_PROVIDER.ENTERPRISE && { email, password }),
      },
    };

    await dispatch(fetchData(options));
    await dispatch(getUsers());
  };
}

export function deleteUser(userId: User['id']): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.deleteUser.replace('{id}', userId)}`,
      method: 'DELETE',
    };
    await dispatch(fetchData(options));
    await dispatch(getUsers());
  };
}

export function editUser(
  userId: User['id'],
  { realm, fullName, phone, email }: EditUserDataType
): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: `${config.apis.updateUser.replace('{id}', userId)}`,
        method: 'PATCH',
        data: { full_name: fullName, phone, ...(realm !== AUTH_PROVIDER.LDAP && { email }) },
      };

      await dispatch(fetchData(options));
      dispatch(showSuccessToast());
      await dispatch(getUsers());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function getMe(): Thunk<Promise<void>> {
  return async (dispatch) => {
    const options: AxiosRequestConfig = {
      url: `${config.apis.me}`,
      method: 'GET',
    };
    const user = await dispatch(fetchData<User>(options));
    dispatch(setUser(user));
  };
}

export function editMe({ fullName, phone, email }: EditMeType): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    const user = getState().user;

    if (!user || !user.id) {
      dispatch(showErrorToast());
      return;
    }

    try {
      await dispatch(
        editUser(user.id, {
          fullName,
          phone,
          email,
        })
      );
      await dispatch(getMe());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function changePassword(data: { oldpassword: string; newpassword: string }): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.changePassword,
        method: 'POST',
        data,
      };
      await dispatch(fetchData(options));
      dispatch(
        showSuccessToast({
          message: 'changePassword.successNotification',
          intlMessage: true,
        })
      );
    } catch (e) {
      dispatch(
        showErrorToast({
          message: 'changePassword.errorNotification',
          intlMessage: true,
        })
      );
    }
  };
}
