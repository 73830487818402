import List, { ColumnShape } from 'components/List';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PLMN } from 'store/models/plmn';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { useCanUserUpdateNodeSelector, useUserSelector } from 'store/selectors/user';
import { checkPermissionsList } from 'utils/permissionCodes';
import { CreatePlmnButton } from './CreatePlmnButton';
import DropdownTools, { DropdownToolsOption } from 'components/DropdownTools';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { deletePlmn } from 'store/actions/plmns';
import { useAppDispatch, useAppSelector } from 'store';
import PlmnText from './PlmnText';
import {
  getPlmnIdsWithPendingDeletion,
  getPlmnIdsWithPendingCreation,
  getPlmnsLoadingStatus,
  selectAllPlmn,
} from 'store/selectors/plmns';
import { getPlmns } from 'store/actions/plmns/getPlmns';
import { SxProps, Theme } from '@athonet/ui/theme';
import PlmnName from './PlmnName';
import RemoteSyncLegend from 'components/RemoteSync/RemoteSyncLegend';
import { SyncColors } from 'components/RemoteSync/utils';
import { useParams } from 'react-router-dom';
import { NetworkPageParams } from 'Router';

export function PlmnsList() {
  const { network_id } = useParams<NetworkPageParams>();

  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const canUserUpdateNode = useCanUserUpdateNodeSelector();
  const { confirmationDialogOpen } = useOverlay();
  const plmns = useAppSelector((state) => selectAllPlmn(state));
  const loadingStatus = useAppSelector((state) => getPlmnsLoadingStatus(state));
  const pendingCreationIds = useAppSelector((state) => getPlmnIdsWithPendingCreation(state));
  const pendingDeletionIds = useAppSelector((state) => getPlmnIdsWithPendingDeletion(state));

  useEffect(() => {
    if (!network_id) {
      return;
    }
    void dispatch(getPlmns({ networkId: network_id }));
  }, [bootstrap, dispatch, network_id]);

  const handleRefresh = useCallback(() => {
    if (!network_id) {
      return;
    }
    void dispatch(getPlmns({ networkId: network_id }));
  }, [dispatch, network_id]);

  const handleDeletePlmn = useCallback(
    async (id: PLMN['id']) => {
      if (!network_id) {
        return;
      }
      await dispatch(deletePlmn({ plmnId: id, networkId: network_id }));

      void dispatch(getPlmns({ networkId: network_id }));
    },
    [dispatch, network_id]
  );

  const openDelete = useCallback(
    ({ id, name }: PLMN) => {
      confirmationDialogOpen({
        title: formatMessage({ id: 'plmns.list.actions.delete.confirmationPopup.title' }),
        description: formatMessage({ id: 'plmns.list.actions.delete.confirmationPopup.description' }),
        alertMessage: formatMessage({ id: 'plmns.actions.itemsAffected' }, { element: `'${name}'` }),
        continueButtonText: formatMessage({ id: 'plmns.list.actions.delete.confirmationPopup.confirmDeleteButton' }),
        onConfirm: async () => handleDeletePlmn(id),
        severity: 'danger',
        dataTestid: 'confirm-delete-Plmn',
      });
    },
    [confirmationDialogOpen, formatMessage, handleDeletePlmn]
  );

  const getRowSx = useCallback(
    (id: any) => {
      const sxProps: SxProps<Theme> = {
        backgroundColor: pendingCreationIds.includes(id)
          ? SyncColors.creationBg
          : pendingDeletionIds.includes(id)
          ? SyncColors.deletionBg
          : undefined,
      };
      return sxProps;
    },
    [pendingDeletionIds, pendingCreationIds]
  );

  const columns: ColumnShape<PLMN>[] = useMemo(() => {
    if (!bootstrap) {
      return [];
    }
    const getTools = (rowData: PLMN) => {
      const options: DropdownToolsOption[] = checkPermissionsList(user?.permissions || [], [
        {
          iconName: 'Trashcan',
          label: formatMessage({ id: 'plmns.deletePlmn' }),
          onClick: () => openDelete(rowData),
          value: 'delete-plmn',
          disabled: rowData.to_be_deleted === true,
        },
      ]);

      return <DropdownTools options={options} />;
    };
    return checkPermissionsList(
      [user?.tenant_type],
      [
        {
          key: 'name',
          title: formatMessage({ id: 'plmns.table.name' }),
          dataKey: 'name',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          visible: true,
          resizable: true,
          cellRenderer: ({ rowData }) => {
            return <PlmnName id={rowData?.id} />;
          },
        },
        {
          key: 'mcc',
          title: formatMessage({ id: 'plmns.table.mcc' }),
          dataKey: 'mcc',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ rowData }) => {
            return <PlmnText id={rowData?.id} text={rowData.mcc} />;
          },
        },
        {
          key: 'mnc',
          title: formatMessage({ id: 'plmns.table.mnc' }),
          dataKey: 'mnc',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: true,
          cellRenderer: ({ rowData }) => {
            return <PlmnText id={rowData?.id} text={rowData.mnc} />;
          },
        },
        {
          key: 'id',
          title: formatMessage({ id: 'plmns.table.id' }),
          dataKey: 'id',
          sortable: false,
          width: 130,
          maxWidth: 200,
          minWidth: 100,
          resizable: true,
          visible: false,
          cellRenderer: ({ rowData }) => {
            return <PlmnText id={rowData?.id} text={rowData.id} />;
          },
        },
        {
          key: 'tools',
          title: '',
          dataKey: 'tools',
          width: 60,
          maxWidth: 60,
          minWidth: 60,
          secret: true,
          cellRenderer: ({ rowData }) => {
            return getTools(rowData);
          },
        },
      ]
    );
  }, [bootstrap, formatMessage, openDelete, user?.permissions, user?.tenant_type]);

  return (
    <List
      customRowSx={getRowSx}
      key="plmn-list"
      columns={columns}
      data={plmns}
      {...(canUserUpdateNode && { createComponent: <CreatePlmnButton networkId={network_id} /> })}
      toolbar={{ actions: false }}
      loadingState={loadingStatus}
      onRefresh={handleRefresh}
      totalRows={plmns.length}
      customFooterComponent={<RemoteSyncLegend creation deletion />}
    />
  );
}
