import React, { useMemo } from 'react';
import { Form, FormikHelpers } from 'formik';
import { string, object, ref } from 'yup';
import { useIntl } from 'react-intl';
import PasswordStrengthBox from 'components/Form/PasswordStrengthBox';
import { Button } from '@athonet/ui/components/Input/Button';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Link } from '@athonet/ui/components/Navigation/Link';
import TextFieldField from 'components/Form/Field/TextFieldField';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import BaseFormik from 'components/Form/BaseFormik';

export interface ResetPasswordFormDataType {
  password: string;
  passwordConfirm: string;
}

interface ResetPasswordFormProps {
  onSubmit: (values: ResetPasswordFormDataType, formikHelpers: FormikHelpers<ResetPasswordFormDataType>) => void;
  onBack: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit, onBack }) => {
  const { formatMessage } = useIntl();

  const validationSchema = object().shape({
    password: string()
      .min(8)
      .required(formatMessage({ id: 'changePassword.requiredNewpasswordError' }))
      .label(formatMessage({ id: 'changePassword.newpasswordLabel' })),
    passwordConfirm: string()
      .oneOf([ref('password'), undefined], formatMessage({ id: 'login.reset.matchPasswordError' }))
      .required(formatMessage({ id: 'changePassword.requiredConfirmpasswordError' }))
      .label(formatMessage({ id: 'login.reset.passwordLabel' })),
  });

  const initialValues: ResetPasswordFormDataType = useMemo(() => ({ password: '', passwordConfirm: '' }), []);

  return (
    <AuthPanel
      title={formatMessage({ id: 'login.reset.title' })}
      description={formatMessage({ id: 'login.reset.subtitleConfirm' })}
      data-testid="resetPasswordForm"
    >
      <BaseFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ submitForm, values }) => (
          <Form noValidate autoComplete="off">
            <TextFieldField
              sx={{ pb: 2 }}
              name="password"
              size="medium"
              type={'password'}
              placeholder={formatMessage({ id: 'changePassword.newpasswordPlaceholder' })}
              helperText={formatMessage({ id: 'changePassword.rules' })}
              autoComplete="off"
              showPasswordVisibility
              fullWidth
            />
            <TextFieldField
              size="medium"
              name="passwordConfirm"
              type="password"
              placeholder={formatMessage({ id: 'changePassword.confirmpasswordLabel' })}
              autoComplete="off"
              showPasswordVisibility
              fullWidth
            />
            <PasswordStrengthBox password={values['password']} sx={{ pt: 2 }} />
            <Stack spacing={2} sx={{ pt: 1 }}>
              <Button
                size="large"
                data-testid="resetPasswordForm-submitButton"
                onClick={() => {
                  void submitForm();
                }}
                color="secondary"
                text={formatMessage({ id: 'login.reset.submitButton' })}
              />

              <Link data-testid="resetPasswordForm-backLink" onClick={onBack}>
                <Text align="center">{formatMessage({ id: 'login.reset.backButton' })}</Text>
              </Link>
            </Stack>
          </Form>
        )}
      </BaseFormik>
    </AuthPanel>
  );
};

export default ResetPasswordForm;
