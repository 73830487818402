import { Draft, EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PLMN } from 'store/models/plmn';
import { getPlmns } from 'store/actions/plmns/getPlmns';
import { DATA_LIFECYCLE } from '.';

export const plmnsAdapter = createEntityAdapter<PLMN>();

export type PlmnsState = {
  entities: EntityState<PLMN>;
  loadingStatus: DATA_LIFECYCLE;
};

const initialState: PlmnsState = {
  entities: plmnsAdapter.getInitialState(),
  loadingStatus: DATA_LIFECYCLE.IDLE,
};

const plmns = createSlice({
  name: 'plmns',
  initialState,
  reducers: {
    resetPlmns: (state: Draft<PlmnsState>) => {
      plmnsAdapter.removeAll(state.entities);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlmns.pending, (state, action) => {
      state.loadingStatus = DATA_LIFECYCLE.LOADING;
    });
    builder.addCase(getPlmns.fulfilled, (state, action) => {
      plmnsAdapter.setAll(state.entities, action.payload.items);
      state.loadingStatus = DATA_LIFECYCLE.SUCCESS;
    });
    builder.addCase(getPlmns.rejected, (state, action) => {
      state.loadingStatus = DATA_LIFECYCLE.FAILURE;
    });
  },
});

export const { resetPlmns } = plmns.actions;

const plmnsPageReducer = plmns.reducer;
export default plmnsPageReducer;
