import config from 'config';
import { Thunk } from '.';
import { fetchData } from './fetchData';
import { showErrorToast, showSuccessToast } from './toast';
import { AxiosRequestConfig } from 'axios';
import { sentryLogError } from 'sentry';
import { getRoles } from './roles/getRoles';

export function getPermissions(): Thunk<Promise<string[]>> {
  return async (dispatch) => {
    try {
      const permissions = await dispatch(fetchData<string[]>({ url: config.apis.getPermissions, method: 'GET' }));

      return permissions;
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
      return [];
    }
  };
}

export function deleteRole(roleId: string): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.deleteRole.replace('{id}', roleId),
        method: 'DELETE',
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

type EditRolePayload = { data: { role: string; permissions: string[] }; roleId: string };
export function editRole({ data, roleId }: EditRolePayload): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.updateRole.replace('{id}', roleId),
        method: 'PUT',
        data,
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}

export function createRole(data: { role: string; permissions: string[] }): Thunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.createRole,
        method: 'POST',
        data,
      };
      await dispatch(fetchData(options));
      await dispatch(getRoles());
      dispatch(showSuccessToast());
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
    }
  };
}
