import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { InputAdornment } from '@athonet/ui/components/Input/InputAdornment';
import { Form } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { imsiExactRegExp, msisdnRegExp, MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim5g';
import { object, string } from 'yup';
import { CreateUsim5gFormDataType, CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import BaseFormik from 'components/Form/BaseFormik';
import TextFieldField from 'components/Form/Field/TextFieldField';

export function Step2({ onCompleteStep, initialValues, ...step }: CreateUsim5gStepProps) {
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(() => {
    return object().shape({
      name: string()
        .required()
        .trim()
        .label(formatMessage({ id: `usims.form.usim.name.label` })),
      supi: string()
        .matches(imsiExactRegExp, formatMessage({ id: `usims.form.usim.supi.error` }))
        .required()
        .trim()
        .label(formatMessage({ id: `usims.form.usim.supi.label` })),
      msisdn: string()
        .matches(msisdnRegExp, formatMessage({ id: `usims.form.usim.msisdn.error` }))
        .required()
        .trim()
        .label(formatMessage({ id: `usims.form.usim.msisdn.label` })),
    });
  }, [formatMessage]);

  const handleSubmit = useCallback(
    (values: Partial<CreateUsim5gFormDataType>) => {
      onCompleteStep(values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.idData' })} {...step}>
      <BaseFormik
        initialValues={initialValues}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ errors, touched }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2} align="flex-end">
                <TextFieldField
                  name="name"
                  fullWidth
                  placeholder={formatMessage({ id: `usims.form.usim.name.placeholder` })}
                />

                <TextFieldField
                  name="supi"
                  fullWidth
                  placeholder={formatMessage({ id: `usims.form.usim.supi.placeholder` })}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{SUPI_PADDING}</InputAdornment>,
                  }}
                />
                <TextFieldField
                  name="msisdn"
                  fullWidth
                  placeholder={formatMessage({ id: `usims.form.usim.msisdn.placeholder` })}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{MSISDN_PADDING}</InputAdornment>,
                  }}
                />
                <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
              </Stack>
            </Form>
          );
        }}
      </BaseFormik>
    </Step>
  );
}
