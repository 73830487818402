import { Route, createRoutesFromElements, Navigate, RouterProvider } from 'react-router-dom';
import Layout, { FullHeightLayout } from 'containers/Layout';
import Login from 'containers/Login';
import ResetPassword from 'containers/ResetPassword';
import ConfirmUser from 'containers/ConfirmUser';
import ConfirmUserEmail from 'containers/ConfirmUserEmail';
import Landing from 'containers/Landing';
import Users from 'containers/Users';
import Tenants from 'containers/Tenants';
import ErrorPage from 'containers/Errors/PageError';
import Profile from 'containers/Profile';
import { Roles } from 'containers/Security';
import Unauthorized from 'containers/Unauthorized';
import OptionalAuthLayout from 'containers/OptionalAuthLayout';
import { AuthLayout } from 'containers/Layout/AuthLayout';
import AdminConfigLogin from 'containers/AdminConfig/Login';
import AdminConfigLayout from 'containers/AdminConfig/AdminConfigLayout';
import { T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import PrivateHOC from 'containers/PrivateHOC';
import AdminConfigView from 'containers/AdminConfig/AdminConfigView';
import UsimCards5g from 'containers/5gProvisioning/UsimCards';
import { UsimDetailsLayout } from 'containers/5gProvisioning/UsimCards/UsimDetailsLayout';
import { UsimDetails } from 'containers/5gProvisioning/UsimCards/UsimDetails';
import { sentryCreateBrowserRouter } from 'sentry';
import { PlmnsList } from 'components/NetworkId/Plmns/PlmnsList';
import { ServiceProfiles } from 'components/NetworkId/ServiceProfiles/ServiceProfiles';
import { privateRouteLoader } from 'graphql/loaders/auth';
import { NetworkTopology } from 'components/NetworkId/Topology/NetworkTopology';
import { networkTopologyLoader } from 'graphql/loaders/network-topology';
import { NetworkDetailsLayout } from 'containers/Networks/NetworkDetailsLayout';
import { NetworkDetails } from 'components/NetworkId/NetworkDetails';
import Networks from 'containers/Networks';
import { apolloClient } from 'graphql/apollo';
import { networkOverviewLoader } from 'graphql/loaders/network-overview';
import { networkSummaryLoader } from 'graphql/loaders/network-summary';

export type DetailPageParams<Tab extends string = ''> = {
  id: string;
  tab: Tab;
};

export type NetworkPageParams<Tab extends string = ''> = {
  network_id: string;
  tab: Tab;
};

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorPage />}>
      <Route index element={<Navigate to="home" replace />} />
      {/* Authentication */}
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} /> {/* TODO: */}
        <Route path="resetpassword/:resetToken" element={<ResetPassword />} />
        <Route path="confirm/:confirmToken" element={<ConfirmUser />} />
        <Route path="email-confirm/:confirmToken" element={<ConfirmUserEmail />} />
      </Route>
      {/* Admin Config */}
      <Route path="adminconfig">
        <Route element={<AdminConfigLayout />}>
          <Route index element={<AdminConfigView />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="login" element={<AdminConfigLogin />} />
        </Route>
      </Route>
      {/* Home / Landing */}
      <Route path="home" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_DASHBOARD_MODULE]} />}>
        <Route element={<FullHeightLayout />}>
          <Route index element={<Landing />} loader={privateRouteLoader(networkSummaryLoader(apolloClient))} />
        </Route>
      </Route>
      {/* Networks */}
      <Route path="networks">
        <Route
          element={
            <PrivateHOC
              tenantType={[T_PERMISSIONS.MASTER]}
              permissions={[U_PERMISSIONS.VIEW_NODE_MODULE]}
              featureFlags={['athux_support_enabled', 'athonetos_support_enabled']}
              useNonStrictFlagCheck={true}
            />
          }
        >
          <Route element={<FullHeightLayout />}>
            <Route index element={<Networks />} loader={privateRouteLoader(networkSummaryLoader(apolloClient))} />
          </Route>

          <Route path=":network_id">
            <Route index element={<Navigate to="details" replace />} />
            <Route element={<Layout />}>
              <Route element={<NetworkDetailsLayout />}>
                <Route
                  path="details"
                  element={<NetworkDetails />}
                  loader={privateRouteLoader(networkOverviewLoader(apolloClient))}
                />
              </Route>
            </Route>
            <Route element={<FullHeightLayout />}>
              <Route element={<NetworkDetailsLayout />}>
                <Route
                  path="topology"
                  element={<NetworkTopology />}
                  loader={privateRouteLoader(networkTopologyLoader(apolloClient))}
                />
              </Route>
            </Route>
            <Route element={<FullHeightLayout />}>
              <Route element={<NetworkDetailsLayout />}>
                <Route path="service-profiles" element={<ServiceProfiles />} />
                <Route path="plmns" element={<PlmnsList />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Subscribers */}
      <Route path="subscribers">
        <Route
          element={
            <PrivateHOC permissions={[U_PERMISSIONS.VIEW_USIM_MODULE]} featureFlags={['athonetos_support_enabled']} />
          }
        >
          <Route element={<FullHeightLayout />}>
            <Route index element={<UsimCards5g />} />
          </Route>

          <Route path=":id" element={<Layout />}>
            <Route index element={<Navigate to="details" replace />} />
            <Route>
              <Route element={<UsimDetailsLayout />}>
                <Route path="details" element={<UsimDetails />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Settings */}
      <Route path="settings">
        <Route element={<FullHeightLayout />}>
          <Route path="tenants" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_TENANT_MODULE]} />}>
            <Route index element={<Tenants />} />
          </Route>
          <Route path="users" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_USER_MODULE]} />}>
            <Route index element={<Users />} />
          </Route>
        </Route>
        <Route element={<Layout />}>
          <Route path="roles" element={<PrivateHOC permissions={[U_PERMISSIONS.VIEW_SECURITY_MODULE]} />}>
            <Route index element={<Roles />} />
          </Route>
        </Route>
      </Route>
      {/* Profile */}
      <Route path="profile" element={<PrivateHOC />}>
        <Route element={<Layout />}>
          <Route index element={<Profile />} />
        </Route>
      </Route>
      {/* Unauthorized */}
      <Route element={<OptionalAuthLayout />}>
        <Route path="unauthorized" element={<Unauthorized />} />
      </Route>
    </Route>
  )
);

export default function Router() {
  return <RouterProvider router={router} />;
}
