import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Form } from 'formik';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { CreateUsim5gStepProps, PrepareForProvisionFormDataType } from '../CreateUsimCard5gContent';
import { getServiceProfilesOptions } from 'store/actions/serviceProfiles';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import { useAppDispatch } from 'store';
import { AutocompleteOption } from 'utils/forms';
import BaseFormik from 'components/Form/BaseFormik';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Link } from '@athonet/ui/components/Navigation/Link';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { getNetworksOptions } from 'store/actions/networks';

export function Step5({
  onCompleteStep,
  initialValues,
  defaultNetwork,
  ...step
}: Omit<CreateUsim5gStepProps, 'onCompleteStep' | 'initialValues'> & {
  onCompleteStep: (values: PrepareForProvisionFormDataType) => void;
  initialValues: PrepareForProvisionFormDataType;
  defaultNetwork?: string;
}) {
  const [networksOptions, setNetworksOptions] = useState<AutocompleteOption[]>([]);
  const [serviceProfileOptions, setserviceProfileOptions] = useState<AutocompleteOption[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const validationSchema = useMemo(
    () =>
      object().shape({
        network_topology_id: object()
          .shape({ label: string().required(), value: string().required() })
          .required()
          .label(formatMessage({ id: 'usims.form.usim.network.label' })),
        profile_id: object()
          .shape({ label: string().required(), value: string().required() })
          .required()
          .label(formatMessage({ id: 'usims.form.usim.profile_id.label' })),
      }),
    [formatMessage]
  );

  useEffect(() => {
    async function getNetworks() {
      setOptionsLoading(true);
      setNetworksOptions([]);
      const searchResults = await dispatch(getNetworksOptions());
      setNetworksOptions(searchResults);
      setOptionsLoading(false);
    }
    void getNetworks();
  }, [dispatch]);

  const getSPOptions = useCallback(
    async (value: NonNullable<string | AutocompleteOption> | null) => {
      const network = value && typeof value === 'object' && 'value' in value ? String(value.value) : '';
      setOptionsLoading(true);
      setserviceProfileOptions([]);

      if (network === '') {
        setOptionsLoading(false);
        return;
      }
      const searchResults = await dispatch(getServiceProfilesOptions(network));
      setserviceProfileOptions(searchResults);
      setOptionsLoading(false);
    },
    [dispatch]
  );

  const initials: PrepareForProvisionFormDataType = useMemo(
    () =>
      defaultNetwork
        ? {
            ...initialValues,
            network_topology_id: networksOptions.find((option) => option.value === defaultNetwork) || null,
          }
        : initialValues,
    [defaultNetwork, initialValues, networksOptions]
  );

  useEffect(() => {
    void getSPOptions(initials['network_topology_id'] as NonNullable<string | AutocompleteOption> | null);
  }, [getSPOptions, initials]);

  const handleSubmit = useCallback(
    (values: PrepareForProvisionFormDataType) => {
      onCompleteStep(values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.prepare.title' })} {...step}>
      <BaseFormik
        initialValues={initials}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
                <AutocompleteField
                  name="network_topology_id"
                  options={networksOptions}
                  multiple={false}
                  onChange={(_, v) => {
                    void setFieldValue('profile_id', null);
                    void getSPOptions(v);
                  }}
                  loading={optionsLoading}
                  placeholder={formatMessage({ id: 'usims.form.usim.network.placeholder' })}
                  disabled={Boolean(defaultNetwork)}
                />

                <AutocompleteField
                  name="profile_id"
                  options={serviceProfileOptions}
                  loading={optionsLoading}
                  placeholder={formatMessage({ id: 'usims.form.usim.profile_id.placeholder' })}
                  disabled={!values['network_topology_id']}
                />
                {values['network_topology_id'] && !optionsLoading && serviceProfileOptions.length === 0 && (
                  <Alert
                    sx={{ width: '100%' }}
                    severity="warning"
                    action={
                      <Stack spacing={0} sx={{ mr: 4 }}>
                        <Text type="body2">{formatMessage({ id: 'usims.form.usim.network.noServiceError' })}</Text>
                        <Text type="body2">
                          <Link
                            sx={{ display: 'inline' }}
                            href={`/networks/${values['network_topology_id'].value}/service-profiles`}
                            targetBlank
                          >
                            {formatMessage({ id: 'usims.form.usim.network.createServiceProfile' })}
                          </Link>{' '}
                        </Text>
                        <Text type="body2">
                          {formatMessage({ id: 'usims.form.usim.network.chooseAnotherNetwork' })}
                        </Text>
                      </Stack>
                    }
                  />
                )}
                <Button
                  variant="outlined"
                  text={formatMessage({ id: 'common.form.next' })}
                  type="submit"
                  disabled={Boolean(errors['network_topology_id'] || errors['profile_id'])}
                />
              </Stack>
            </Form>
          );
        }}
      </BaseFormik>
    </Step>
  );
}
