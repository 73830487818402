import { AnyAction } from '@reduxjs/toolkit';
import {
  Entity,
  entityFailure,
  entityIdle,
  entityLoading,
  entitySuccess,
  List,
  listFailure,
  listIdle,
  listLoading,
  listSuccess,
  Sort,
} from '.';
import { USIMS_5G_ACTION_TYPE } from 'store/actions/usims5g';
import { Usim5g } from 'store/models/usim5g';

export type Usims5gState = {
  list: List<Usim5g>;
  sort: Sort;
  shouldflushSelection: boolean;
  usim5gDetails: Entity<Usim5g>;
};

const initialState: Usims5gState = {
  list: listIdle<Usim5g>(),
  sort: '',
  shouldflushSelection: false,
  usim5gDetails: entityIdle(),
};

export default function usims5gReducer(state = initialState, action: AnyAction): Usims5gState {
  switch (action.type) {
    case USIMS_5G_ACTION_TYPE.SORT_SET:
      return {
        ...state,
        sort: action.payload,
      };

    case USIMS_5G_ACTION_TYPE.USIM_SHOULD_FLUSH_SELECTION:
      return {
        ...state,
        shouldflushSelection: action.payload,
      };

    case USIMS_5G_ACTION_TYPE.LIST_LOADING:
      return {
        ...state,
        list: listLoading(state.list.data),
      };

    case USIMS_5G_ACTION_TYPE.LIST_SUCCESS:
      const { items, total_items, page } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ...listSuccess({
            data: items,
            total: total_items,
            page,
          }),
        },
      };

    case USIMS_5G_ACTION_TYPE.LIST_FAILURE:
      return {
        ...state,
        list: {
          ...listFailure(),
        },
      };

    case USIMS_5G_ACTION_TYPE.USIMS_DETAIL_LOADING:
      return {
        ...state,
        usim5gDetails: entityLoading(),
      };

    case USIMS_5G_ACTION_TYPE.USIMS_DETAIL_SUCCESS:
      return {
        ...state,
        usim5gDetails: entitySuccess<Usim5g>(action.payload),
      };

    case USIMS_5G_ACTION_TYPE.USIMS_DETAIL_FAILURE:
      return {
        ...state,
        usim5gDetails: entityFailure(),
      };

    case USIMS_5G_ACTION_TYPE.RESET:
      return initialState;

    default:
      return state;
  }
}
