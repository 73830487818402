import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { GetResultsRes } from 'store/models/filters';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast } from '../toast';
import { PLMN } from 'store/models/plmn';

export const getPlmns = createAsyncThunk<
  GetResultsRes<PLMN>,
  { networkId: string },
  {
    rejectValue: null;
    state: State;
  }
>('plmns/getAll', async ({ networkId }, { dispatch, rejectWithValue, getState }) => {
  try {
    const options: AxiosRequestConfig = {
      url: config.apis.getPlmns.replace('{network_id}', networkId),
      method: 'GET',
    };

    const result = await dispatch(fetchData<GetResultsRes<PLMN>>(options));
    return result;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    return rejectWithValue(null);
  }
});
