import { createSelector } from '@reduxjs/toolkit';
import { State } from 'store';

export const selectTenants = (state: State) => state.tenants.list;

export const selectTenantsWithSubtenants = (state: State) => state.tenants.tenantsWithSubTenants;

export const selectTenantFilters = (state: State) => state.tenants.filters;

export const getTenantById = createSelector(
  [selectTenantsWithSubtenants, (_, tenantId) => tenantId],
  (tenants, tenantId) => {
    return tenants.data.data.find((t) => t.id === tenantId);
  }
);
