import { fetchAdminConfigData } from '../../actions/fetchData';
import { AxiosRequestConfig } from 'axios';
import { Thunk } from '..';
import config from 'config';
import { ADMIN_CONFIG_TYPE } from '.';
import { lsSetAdminConfig, lsGetAdminConfig, lsRemoveAdminConfig } from 'utils/localStorage';
import { UserAdminConfig } from 'store/models/user';
import { AdminDataType } from 'containers/AdminConfig/Login';

export const getDataUser = () => {
  let adminConfigToken = '';
  const horusStorage = lsGetAdminConfig<UserAdminConfig>();
  if (horusStorage) {
    adminConfigToken = horusStorage.adminConfigToken;
  }
  return adminConfigToken;
};

export function adminConfigLogin(data: AdminDataType): Thunk<Promise<void>> {
  return async (dispatch) => {
    const url = `${config.adminConfigApis.login}`;
    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      data,
    };
    try {
      dispatch({ type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_LOADING });
      const adminConfigToken = await dispatch(fetchAdminConfigData(options, true));
      const userData = {
        adminConfigToken,
      };
      lsSetAdminConfig(userData);
      dispatch({
        type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_SUCCESS,
        payload: adminConfigToken,
      });
    } catch (e: any) {
      dispatch({ type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_FAILURE });
    }
  };
}

export function adminConfigLogoutUser() {
  lsRemoveAdminConfig();
  window.location.href = '/adminconfig/login';
  return {
    type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_RESET,
  };
}

export function hydrateAdminConfigUser(): Thunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: ADMIN_CONFIG_TYPE.ADMIN_CONFIG_AUTH_SUCCESS,
      payload: getDataUser(),
    });
  };
}
