import React, { useMemo } from 'react';
import { Usim5g } from 'store/models/usim5g';
import { getUnprovisionedProperties, getUsimSyncStatus } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import RemoteSyncTooltipIcon from 'components/RemoteSync/RemoteSyncTooltipIcon';
import { SxProps, Theme } from '@athonet/ui/theme';

interface UsimTooltipIconProps {
  usim5g: Usim5g;
  sx?: SxProps<Theme>;
}

const UsimTooltipIcon: React.FC<React.PropsWithChildren<UsimTooltipIconProps>> = (props) => {
  const { usim5g, sx } = props;
  const { formatMessage } = useIntl();
  const syncStatus = useMemo(() => getUsimSyncStatus(usim5g), [usim5g]);
  const unprovisionedProperties = useMemo(() => getUnprovisionedProperties(usim5g), [usim5g]);

  const text = `${formatMessage({
    id: `common.highlighting.remoteSyncPending`,
  })}: ${unprovisionedProperties.join(', ')} ${formatMessage(
    { id: `common.properties.highlighting` },
    { count: unprovisionedProperties.length }
  )}`;

  return <RemoteSyncTooltipIcon syncStatus={syncStatus} text={text} sx={sx} />;
};

export default UsimTooltipIcon;
