import bootstrapReducer, { BootstrapState } from './reducers/bootstrap';
import loginReducer, { LoginState } from './reducers/login';
import tenantsPageReducer, { TenantsState } from './reducers/tenants';
import usersPageReducer, { UsersState } from './reducers/users';
import toastReducer, { ToastState } from './reducers/toast';
import rolesReducer, { RolesState } from './reducers/roles';
import bulkOperationsReducer, { BulkOperationsState } from './reducers/bulkOperations';
import localeReducer, { LocaleState } from './reducers/locale';
import adminConfigReducer, { AdminConfigState } from './reducers/adminConfig';
import usims5gReducer, { Usims5gState } from './reducers/usims5g';
import plmnsPageReducer, { PlmnsState } from './reducers/plmns';
import serviceProfilesReducer, { ServiceProfileState } from './reducers/serviceProfiles';
import {
  Action,
  AnyAction,
  CombinedState,
  Dispatch,
  ThunkAction,
  ThunkDispatch,
  configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export interface State {
  bootstrap: BootstrapState;
  locale: LocaleState;
  user: LoginState;
  tenants: TenantsState;
  users: UsersState;
  plmns: PlmnsState;
  serviceProfiles: ServiceProfileState;
  usims5g: Usims5gState;
  roles: RolesState;
  toast: ToastState;
  bulkOperations: BulkOperationsState;
  adminConfig: AdminConfigState;
}

const rootReducer = {
  bootstrap: bootstrapReducer,
  locale: localeReducer,
  user: loginReducer,
  tenants: tenantsPageReducer,
  users: usersPageReducer,
  plmns: plmnsPageReducer,
  serviceProfiles: serviceProfilesReducer,
  usims5g: usims5gReducer,
  roles: rolesReducer,
  toast: toastReducer,
  bulkOperations: bulkOperationsReducer,
  adminConfig: adminConfigReducer,
};

const store = configureStore<State>({
  reducer: rootReducer,
});

export type AppDispatch = Dispatch<AnyAction> &
  ThunkDispatch<CombinedState<State>, null, AnyAction> &
  ThunkDispatch<CombinedState<State>, undefined, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export type AppThunk = ThunkAction<void, CombinedState<State>, unknown, Action<string>>;

export default store;
