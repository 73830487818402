import React from 'react';
import { Tooltip } from '@athonet/ui/components/Feedback/Tooltip';
import { Icon } from '@athonet/ui/components/Guidelines/Icon';
import theme, { SxProps, Theme } from '@athonet/ui/theme';
import { SyncStatus, getSyncStatusColor } from './utils';

interface RemoteSyncTooltipIconProps {
  syncStatus: SyncStatus;
  text: string;
  sx?: SxProps<Theme>;
}

const RemoteSyncTooltipIcon: React.FC<RemoteSyncTooltipIconProps> = (props) => {
  const { syncStatus, text, sx } = props;
  return (
    <>
      {syncStatus !== SyncStatus.None && (
        <Tooltip title={text}>
          <Icon
            sx={{
              fontSize: theme.spacing(2),
              ml: 0.5,
              color: getSyncStatusColor(syncStatus),
              ...sx,
            }}
            name={'Information'}
          />
        </Tooltip>
      )}
    </>
  );
};

export default RemoteSyncTooltipIcon;
