import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NetworkPageParams } from 'Router';
import { Toolbar } from '@athonet/ui/components/Surfaces/Toolbar';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { Tabs } from '@athonet/ui/components/Navigation/Tabs';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useAppDispatch } from 'store';
import { resetPlmns } from 'store/reducers/plmns';
import { resetServiceProfiles } from 'store/reducers/serviceProfiles';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { sendDataToRemoteUDR } from 'store/actions/networks/sendDataToRemoteUDR';
import { useHasUserMasterTenantSelector } from 'store/selectors/user';
import { getBulkOperations, showOperationScheduledToast } from 'store/actions/bulkOperations';
import { batch } from 'react-redux';
import { getServiceProfiles } from 'store/actions/serviceProfiles/getServiceProfiles';
import { getPlmns } from 'store/actions/plmns/getPlmns';

export enum TAB {
  DETAILS = 'details',
  TOPOLOGY = 'topology',
  SERVICE_PROFILES = 'service-profiles',
  PLMNS = 'plmns',
}

export function NetworkDetailsLayout() {
  const { network_id } = useParams<NetworkPageParams>();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  // const canUserDeleteNode = useCanUserDeleteNodeSelector();
  // const canUserUpdateNode = useCanUserUpdateNodeSelector();
  // const { confirmationSimpleDialogOpen } = useOverlay();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationPaths = location.pathname.split('/');
  const tab = locationPaths[locationPaths.length - 1];
  const hasUserMasterTenant = useHasUserMasterTenantSelector();

  useEffect(() => {
    return () => {
      dispatch(resetPlmns());
      dispatch(resetServiceProfiles());
    };
  }, [dispatch, network_id]);

  // const handleOpenDelete = useCallback(() => {
  //   if (!activeNode) {
  //     return;
  //   }
  //   confirmationSimpleDialogOpen({
  //     title: formatMessage({ id: 'nodes.deleteNode' }),
  //     description: formatMessage({ id: 'nodes.deleteNode.confirm' }),
  //     onConfirm: async () => dispatch(deleteNode(activeNode.id, navigate)),
  //   });
  // }, [activeNode, confirmationSimpleDialogOpen, formatMessage, dispatch, navigate]);

  const handleSend = useCallback(() => {
    if (!network_id) {
      return;
    }
    void batch(async () => {
      const res = await dispatch(sendDataToRemoteUDR({ networkId: network_id }));
      if (sendDataToRemoteUDR.fulfilled.match(res)) {
        void dispatch(showOperationScheduledToast());
        void dispatch(getBulkOperations());
        //TODOPS ugly code wait 1 second before auto-refresh serviceProfiles & plmns
        await new Promise((r) => setTimeout(r, 1000));
        void dispatch(getServiceProfiles({ networkId: network_id }));
        void dispatch(getPlmns({ networkId: network_id }));
      }
    });
  }, [dispatch, network_id]);

  const handleTabChange = useCallback(
    (newTab: TAB) => {
      navigate(`/networks/${network_id}/${newTab}`, {
        replace: true,
      });
    },
    [network_id, navigate]
  );

  const nodeDetailTabs = useMemo(
    () => [
      {
        value: TAB.DETAILS,
        label: formatMessage({ id: 'nodes.tabs.details' }),
      },
      ...(hasUserMasterTenant
        ? [
            {
              value: TAB.TOPOLOGY,
              label: 'Topology',
            },
          ]
        : []),
      {
        value: TAB.SERVICE_PROFILES,
        label: formatMessage({ id: 'nodes.tabs.serviceProfiles' }),
      },
      {
        value: TAB.PLMNS,
        label: formatMessage({ id: 'nodes.tabs.plmns' }),
      },
    ],
    [formatMessage, hasUserMasterTenant]
  );

  return (
    <>
      <Toolbar>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          text="Back"
          variant="text"
          color="secondary"
          startIcon="Left-7"
        />
        {network_id && (
          <Stack direction="row" sx={{ marginLeft: 'auto' }}>
            {/* <Button
              text={formatMessage({ id: 'common.button.delete' })}
              color="error"
              onClick={handleOpenDelete}
              disabled={!canUserDeleteNode}
              variant="outlined"
            /> */}

            <Button
              text={formatMessage({ id: 'common.button.networkSync' })}
              onClick={handleSend}
              //disabled={!canUserUpdateNode}
            />
          </Stack>
        )}
      </Toolbar>
      <Box sx={{ mb: 2 }}>
        <Tabs
          value={tab}
          tabs={nodeDetailTabs}
          onChange={(value) => {
            handleTabChange(value as TAB);
          }}
        />
      </Box>
      <Outlet />
    </>
  );
}
