import React from 'react';
import { TextProps } from '@athonet/ui/components/Guidelines/Text';
import { useIntl } from 'react-intl';
import UsimRemoteSyncText from './UsimRemoteSyncText';

interface UsimKTextProps {
  provisionedValue: string | null;
  unprovisionedValue: string | null;
}

const UsimKText: React.FC<TextProps & UsimKTextProps> = (props) => {
  const { formatMessage } = useIntl();

  const formatKLabel = (k: string | null) => {
    return `${!k ? `${formatMessage({ id: 'common.form.notSet' })} -` : ''} ${formatMessage({
      id: 'usims.form.usim.encrypt.0',
    })}`;
  };

  return (
    <UsimRemoteSyncText
      text={formatKLabel(props.unprovisionedValue)}
      tooltipText={formatKLabel(props.provisionedValue)}
      {...props}
    />
  );
};

export default UsimKText;
