import Masonry from '@athonet/ui/components/Masonry';
import { GeneralDataPanel } from './details/GeneralDataPanel';
import { StatusPanel } from './details/StatusPanel';
import { TenantPanel } from './details/TenantPanel';
import { MetadataPanel } from './details/MetadataPanel';

export function UsimDetails() {
  return (
    <Masonry columns={{ xs: 1, md: 2 }} spacing={1}>
      <GeneralDataPanel />
      <StatusPanel />
      <TenantPanel />
      <MetadataPanel />
    </Masonry>
  );
}
