import React from 'react';
import { TextProps } from '@athonet/ui/components/Guidelines/Text';
import UsimRemoteSyncText from './UsimRemoteSyncText';

interface UsimPlainTextProps {
  provisionedValue: string;
  unprovisionedValue: string;
}

const UsimPlainText: React.FC<TextProps & UsimPlainTextProps> = (props) => {
  return <UsimRemoteSyncText {...props} text={props.unprovisionedValue} tooltipText={props.provisionedValue} />;
};

export default UsimPlainText;
