import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import FormikDialog from 'components/Form/FormikDialog';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { createPlmn } from 'store/actions/plmns';
import { number, object, string } from 'yup';

type CreatePLMNFormDataType = {
  name: string;
  mcc: number;
  mnc: number;
};

interface CreatePlmnDialogProps {
  networkId?: string;
}

export function CreatePlmnDialog({ networkId }: CreatePlmnDialogProps) {
  const { dialogClose } = useOverlay();
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | null>(null);

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .trim()
          .required()
          .label(formatMessage({ id: `plmns.form.name.label` })),
        mcc: number()
          .max(999)
          .required()
          .label(formatMessage({ id: `plmns.form.mcc.label` })),
        mnc: number()
          .max(999)
          .required()
          .label(formatMessage({ id: `plmns.form.mnc.label` })),
      }),
    [formatMessage]
  );

  const initialValues: Partial<CreatePLMNFormDataType> = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  const handleCreatePlmn = useCallback(
    async ({ name, mnc, mcc }: CreatePLMNFormDataType) => {
      if (!networkId) {
        return;
      }
      const paddedMcc = String(mcc).padStart(3, '0');
      const paddedMnc = String(mnc).padStart(2, '0');
      const errorRes = await dispatch(createPlmn({ name, mcc: paddedMcc, mnc: paddedMnc, networkId }));
      if (errorRes) {
        setError(errorRes);
      } else {
        setError(null);
        dialogClose();
      }
    },
    [dialogClose, dispatch, networkId]
  );

  return (
    <FormikDialog
      errorMessage={error ? `${formatMessage({ id: `plmns.form.dialogError` })} (${error})` : undefined}
      validationSchema={validationSchema}
      onSubmit={handleCreatePlmn}
      initialValues={initialValues}
    >
      <BaseFieldset>
        <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
          <TextFieldField
            fast
            fullWidth
            name="name"
            placeholder={formatMessage({ id: `plmns.form.name.placeholder` })}
          />
          <TextFieldField fast fullWidth name="mcc" placeholder={formatMessage({ id: `plmns.form.mcc.placeholder` })} />
          <TextFieldField fast fullWidth name="mnc" placeholder={formatMessage({ id: `plmns.form.mnc.placeholder` })} />
        </Stack>
      </BaseFieldset>
    </FormikDialog>
  );
}
