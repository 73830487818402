import { NoDataSearch } from '@athonet/ui/components/Data/NoData';
import { Icon54 } from '@athonet/ui/components/Guidelines/Icon/catalog';
import { useIntl } from 'react-intl';

export type NoMetricsProps = {
  iconName?: Icon54;
  fontSize?: number;
  noDataText?: string;
};

export function NoMetrics({ iconName, fontSize, noDataText }: NoMetricsProps) {
  const { formatMessage } = useIntl();
  return (
    <NoDataSearch
      iconName={iconName}
      fontSize={fontSize}
      noDataText={noDataText ?? formatMessage({ id: 'common.error.noMetrics' })}
    />
  );
}
