import { Button } from '@athonet/ui/components/Input/Button';
import { SelectChangeEvent } from '@athonet/ui/components/Input/Select';
// import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
// import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Form, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { keysRegExp } from 'store/models/usim5g';
import { number, object, string } from 'yup';
import { CreateUsim5gFormDataType, CreateUsim5gStepProps } from '../CreateUsimCard5gContent';
import { Step } from '@athonet/ui/components/Navigation/Stepper/Step';
import BaseFormik from 'components/Form/BaseFormik';
import TextFieldField from 'components/Form/Field/TextFieldField';
import SelectField from 'components/Form/Field/SelectField';

export function Step3({ onCompleteStep, initialValues, ...step }: CreateUsim5gStepProps) {
  const { formatMessage } = useIntl();

  const handleKeyTypeChange = useCallback(
    (
      e: SelectChangeEvent<number>,
      setFieldValue: FormikHelpers<Partial<CreateUsim5gFormDataType>>['setFieldValue']
    ) => {
      const v = e.target.value;
      void setFieldValue('key_type', v);
      if (v === 0) {
        void setFieldValue('op', '');
        void setFieldValue('opc', '');
      }
      // OP
      if (v === 1) {
        void setFieldValue('opc', '');
      }
      // OPC
      if (v === 2) {
        void setFieldValue('op', '');
      }
    },
    []
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      k: string()
        .matches(keysRegExp, formatMessage({ id: `usims.form.usim.k.error` }))
        .required()
        .label(formatMessage({ id: `usims.form.usim.k.label` }))
        .trim(),
      /* TODO: update when encryption is contemplated again */
      key_type: number()
        .min(1)
        .max(2)
        .label(formatMessage({ id: `usims.form.usim.key_type.label` })),
      /* TODO: update when encryption is contemplated again */
      encrypt: number()
        .min(0)
        .max(0)
        .label(formatMessage({ id: `usims.form.usim.encrypt.label` })),
      // use_key: string().when(['encrypt', 'use_default_tk'], {
      //   is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk,
      //   then: (schema) => schema.matches(tkRegExp),
      //   otherwise: (schema) => schema.nullable().matches(tkRegExp),
      // }),
      op: string().when('key_type', {
        is: 1,
        then: (schema) =>
          schema
            .matches(keysRegExp)
            .required()
            .label(formatMessage({ id: `usims.form.usim.op.label` }))
            .trim(),
        otherwise: (schema) =>
          schema
            .nullable()
            .matches(keysRegExp)
            .label(formatMessage({ id: `usims.form.usim.op.label` }))
            .trim(),
      }),
      opc: string().when(['key_type'], {
        is: 2,
        then: (schema) =>
          schema
            .matches(keysRegExp, formatMessage({ id: `usims.form.usim.op.error` }))
            .required()
            .label(formatMessage({ id: `usims.form.usim.opc.label` }))
            .trim(),
        otherwise: (schema) =>
          schema
            .nullable()
            .matches(keysRegExp, formatMessage({ id: `usims.form.usim.op.error` }))
            .label(formatMessage({ id: `usims.form.usim.opc.label` }))
            .trim(),
      }),
    });
  }, [formatMessage]);

  const handleSubmit = useCallback(
    (values: Partial<CreateUsim5gFormDataType>) => {
      onCompleteStep(values);
    },
    [onCompleteStep]
  );

  return (
    <Step label={formatMessage({ id: 'usims.form.usim.keys' })} {...step}>
      <BaseFormik
        initialValues={initialValues}
        enableReinitialize={true} // IMPORTANT! reload form if initial data change (used for edit form) only needed when need to reinitialize all initial values
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form
              noValidate
              autoComplete="off"
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Stack spacing={2} align="flex-end">
                <TextFieldField
                  fullWidth
                  name="k"
                  placeholder={formatMessage({ id: `usims.form.usim.k.placeholder` })}
                />
                <SelectField
                  name="encrypt"
                  placeholder={formatMessage({ id: `usims.form.usim.encrypt.placeholder` })}
                  /* TODO: update when encryption is contemplated again */
                  // label={formatMessage({ id: 'usims.form.usim.encrypt' })}
                  label="K type: Plain"
                  multiple={false}
                  /* TODO: update when encryption is contemplated again */
                  disabled
                >
                  {/* <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem> */}
                  <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
                </SelectField>

                {/* /* TODO: update when encryption is contemplated again */}
                {/* {values['encrypt'] === 1 && (
                <Field name="use_default_tk" key="use_default_tk">
                  {({ field }: FieldProps<string>) => (
                    <ToggleButtonGroup
                      sx={{ alignSelf: 'flex-start' }}
                      key="use_default_tk"
                      size="small"
                      color={'secondary'}
                      exclusive={true}
                      onChange={(v) => {
                        if (v !== null) {
                          setFieldValue('use_default_tk', v);
                        }
                      }}
                      data-testid={'field-use_default_tk'}
                      value={values['use_default_tk']}
                    >
                      <ToggleButton disabled={values['use_default_tk'] === true} value={true}>
                        {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.true' })}
                      </ToggleButton>
                      <ToggleButton disabled={values['use_default_tk'] === false} value={false}>
                        {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.false' })}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Field>
              )}
              {values['encrypt'] === 1 && !values['use_default_tk'] && (
                <Field name="use_key" key="use_key">
                  {({ field }: FieldProps<string>) => (
                    <TextField fullWidth size="small" {...field} {...getInputTextsError('use_key', errors, touched)} />
                  )}
                </Field>
              )} */}

                <SelectField
                  name="key_type"
                  label={formatMessage({ id: `usims.form.usim.key_type` })}
                  value={values['key_type']}
                  placeholder={formatMessage({ id: `usims.form.usim.key_type.placeholder` })}
                  onChange={(e) => handleKeyTypeChange(e, setFieldValue)}
                >
                  {/* /* TODO: update when encryption is contemplated again  */}
                  {/* <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.key_type.defaultOP_5g' })}</MenuItem> */}
                  <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.key_type.customOP' })}</MenuItem>
                  <MenuItem value={2}>{formatMessage({ id: 'usims.form.usim.key_type.customOPC' })}</MenuItem>
                </SelectField>

                {values['key_type'] === 1 && (
                  <TextFieldField
                    fullWidth
                    name="op"
                    placeholder={formatMessage({ id: `usims.form.usim.op.placeholder` })}
                  />
                )}
                {values['key_type'] === 2 && (
                  <TextFieldField
                    fullWidth
                    name="opc"
                    placeholder={formatMessage({ id: `usims.form.usim.opc.placeholder` })}
                  />
                )}
                <Button type="submit" variant="outlined" text={formatMessage({ id: 'common.form.next' })} />
              </Stack>
            </Form>
          );
        }}
      </BaseFormik>
    </Step>
  );
}
