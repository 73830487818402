import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import { useCallback } from 'react';
import { selectActiveUsim } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import { useCanUserUpdateUsimSelector } from 'store/selectors/user';
import MetadataEditorDialog from 'components/Edit/5gProvisioning/UsimCards/MetadataEditorDialog';
import { useAppSelector } from 'store';
import { BasePanel } from 'components/Panel/BasePanel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel';

export function MetadataPanel() {
  const activeUsim = useAppSelector((state) => selectActiveUsim(state));

  const canUserUpdateUsim = useCanUserUpdateUsimSelector();
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const openMetadataEditor = useCallback(() => {
    if (!activeUsim) {
      return;
    }
    dialogOpen({
      title: `${formatMessage({ id: 'usims.metadata.title' })} - USIM ${activeUsim.supi}`,
      content: () => <MetadataEditorDialog metadata={activeUsim?.metadata} id={activeUsim?.id} />,
    });
  }, [dialogOpen, formatMessage, activeUsim]);

  return (
    <BasePanel
      folder
      height={203}
      skeleton={!activeUsim}
      title={formatMessage({ id: 'usims.metadata' })}
      headerActionsComponent={
        <IconButton name="Pencil" onClick={() => openMetadataEditor()} fontSize="small" disabled={!canUserUpdateUsim} />
      }
    >
      <PanelContent>
        {activeUsim && (
          <GridContainer>
            {activeUsim && Object.entries(activeUsim.metadata).length === 0 ? (
              <GridItem size={{ xs: 12, sm: 6 }}>{formatMessage({ id: 'usims.id.details.noMetadata' })}</GridItem>
            ) : (
              activeUsim &&
              Object.entries(activeUsim.metadata).map(([key, value]) => (
                <KeyValueGridItem key={key} label={key} value={value} />
              ))
            )}
          </GridContainer>
        )}
      </PanelContent>
    </BasePanel>
  );
}
