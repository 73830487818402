export const imsiExactRegExp = /^[0-9]{10,15}?$/;
export const msisdnRegExp = /^[0-9]{5,15}?$/;
export const tkRegExp = /^[a-zA-Z0-9]+?$/;
export const keysRegExp = /^[0-9a-fA-F]{32}?$/;
export const SUPI_PADDING = 'imsi-';
export const MSISDN_PADDING = '+';
// export const usimOperatorCountryNotAvailableRexExp = /(UTRAN|GERAN)/i;
// export const iccidRegExp = /^[0-9]{1,20}?$/;
// export const iccidExactRegExp = /^[0-9]{19,20}?$/;
// export const imsiRegExp = /^[0-9]{1,15}?$/;

export enum USIM5G_STATUS {
  DEPROVISIONED = 'deprovisioned',
  PROVISIONED = 'provisioned',
}

//TODOPS for now they are hardcoded
export const availableKeys = ['business_unit', 'owner', 'project'];
export type MetadataKey = (typeof availableKeys)[number];

export type MetadataDictionary = {
  [index in MetadataKey]: string;
};

export type Usim5g = {
  id: string;
  created_at: string;
  encrypt: number;
  supi: string; // imsi regexp
  msisdn: string; // 1-15 digits regexp
  k: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  name: string;
  op: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  opc: string | null; // Regexp  ~r/^[a-fA-F0-9]{32}$/
  tenant: {
    //id: string;
    name: string;
  };
  updated_at: string;
  use_key: string | null;
  network_topology_id: string | null;
  status: USIM5G_STATUS | null;
  network_topology_name: string | null;
  profile_id: string | null;
  profile_name: string | null;
  metadata: MetadataDictionary;
  provisioned_values: ProvisionableValues;
  unprovisioned_values: ProvisionableValues;

  //tenant_id: string;
  //updating: boolean;
  //site_id?: any;
  //imsi: string;
  //msisdn_status?: any;
  //batch_id?: any;
  //synchronize_error?: any;
  //regional_subscriptions_profile_id?: any;
  //private_identity?: any;
  //error: boolean;
  //imsi2?: any;
  //type: string;
  //is_dual_imsi: string;
  //core_status: string;
  //synchronized_at: string;
  //iccid?: any;
  //order_id?: any;
};

export interface ProvisionableValues {
  k: string;
  name: string;
  op: string;
  opc: string;
}
