import { createSelector } from '@reduxjs/toolkit';
import { State } from 'store';
import { Usim5g } from 'store/models/usim5g';
import { intl } from 'CreateIntlProvider';
import { SyncStatus } from 'components/RemoteSync/utils';

export const selectUsimList = (state: State) => state.usims5g.list;

const selectAllUsims = (state: State) => state.usims5g.list.data.data;

export const selectActiveUsim = (state: State) => state.usims5g.usim5gDetails.data;

export const selectShouldFlushUsims5gSelection = (state: State) => state.usims5g.shouldflushSelection;

export const getUsimIdsWithPendingModifications = createSelector([selectAllUsims], (plmns) => {
  return plmns.filter((usim) => hasPendingModifications(usim)).map((p) => p.id);
});

export const isUnprovisioned = (provisionedValue: string | null, unprovisionedValue: string | null): boolean => {
  if (provisionedValue === undefined || unprovisionedValue === undefined) return false;
  else if (provisionedValue !== unprovisionedValue) return true;
  return false;
};

const hasPendingModifications = (usim5g?: Usim5g) => getUnprovisionedProperties(usim5g).length > 0;

export const getUnprovisionedProperties = (usim5g?: Usim5g) => {
  if (!usim5g) return [];
  const props: string[] = [];

  const k = isUnprovisioned(usim5g.provisioned_values?.k, usim5g?.unprovisioned_values?.k);
  const name = isUnprovisioned(usim5g.provisioned_values?.name, usim5g?.unprovisioned_values?.name);
  const opc = isUnprovisioned(usim5g.provisioned_values?.opc, usim5g?.opc);
  const op = isUnprovisioned(usim5g.provisioned_values?.op, usim5g?.op);

  if (k) props.push(`'${intl?.formatMessage({ id: 'usims.table.k' })}'`);
  if (opc) props.push(`'${intl?.formatMessage({ id: 'usims.table.opc' })}'`);
  if (op) props.push(`'${intl?.formatMessage({ id: 'usims.table.op' })}'`);
  if (name) props.push(`'${intl?.formatMessage({ id: 'usims.table.name' })}'`);

  return props;
};

export const getUsimSyncStatus = (usim?: Usim5g) =>
  hasPendingModifications(usim) ? SyncStatus.PendingModification : SyncStatus.None;
