import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import { useCallback, useState } from 'react';
import { Step1 } from './Steps/Step1';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { createBulkUsim5g } from 'store/actions/usims5g';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { BulkStep2 } from './Steps/BulkStep2';
import { BulkStep3 } from './Steps/BulkStep3';
import { useAppDispatch } from 'store';

export type CreateBulkUsim5gFormDataType = {
  tenant: AutocompleteItemProps | null;
  upload: File | null;
};

export type CreateUsim5gStepProps = {
  onCompleteStep: (values: Partial<CreateBulkUsim5gFormDataType>) => void;
  initialValues: CreateBulkUsim5gFormDataType;
  active?: boolean;
};

const CREATE_BULK_USIM_STEPS = 3;
const MILESTONES_ARRAY = [2];

export function BulkCreateUsimCard5gContent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { formatMessage } = useIntl();

  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: CREATE_BULK_USIM_STEPS,
    milestones: MILESTONES_ARRAY,
  });
  const dispatch = useAppDispatch();

  const [createValues, setCreateValues] = useState<CreateBulkUsim5gFormDataType>({
    tenant: null,
    upload: null,
  });

  const onCompleteCreateStep = useCallback(
    (values: Partial<CreateBulkUsim5gFormDataType>) => {
      setCreateValues((prevState) => ({ ...prevState, ...values }));
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handleBulkCreateUsim = useCallback(async () => {
    const newTenantId =
      createValues['tenant'] && 'value' in createValues['tenant'] && 'label' in createValues['tenant']
        ? String(createValues['tenant'].value)
        : null;

    if (newTenantId && createValues.upload) {
      setLoading(true);
      const errorRes = await dispatch(
        createBulkUsim5g({
          upload: createValues.upload,
          tenant_id: newTenantId,
        })
      );
      setLoading(false);

      if (errorRes) {
        setError(errorRes);
      } else {
        setError(null);
        handleCompleteMilestone(2);
      }
    }
  }, [createValues, dispatch, handleCompleteMilestone]);

  return (
    <Stepper
      orientation="vertical"
      completed={completed}
      nonLinear
      activeStep={active}
      onStepClick={handleGoToStep}
      skipped={skipped}
      milestonesCompleted={milestonesCompleted}
    >
      <Step1 onCompleteStep={onCompleteCreateStep} initialValues={{ tenant: createValues['tenant'] }} />
      <BulkStep2 initialValues={{ upload: createValues['upload'] }} onCompleteStep={onCompleteCreateStep} />
      <BulkStep3 values={createValues} milestoneCompleted={milestonesCompleted.has(2)}>
        {error && (
          <Box sx={{ width: '100%' }}>
            <Alert
              severity="error"
              title={formatMessage({ id: 'common.error' })}
              message={formatMessage({ id: 'usims.create.bulk.error.title' }, { error })}
            />
          </Box>
        )}
        <Button
          text={error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })}
          loading={loading}
          onClick={() => void handleBulkCreateUsim()}
        />
      </BulkStep3>
    </Stepper>
  );
}
