import React, { useCallback, useMemo } from 'react';
import PasswordStrengthBox from 'components/Form/PasswordStrengthBox';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { changePassword } from 'store/actions/users';
import { useAppDispatch } from 'store';
import { object, ref, string } from 'yup';
import FormikDialog from 'components/Form/FormikDialog';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';

type ChangePasswordFormDataType = {
  oldpassword: string;
  newpassword: string;
  confirmpassword: string;
};

const ChangePasswordDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();
  const { formatMessage } = useIntl();

  const handleOnSubmit = useCallback(
    async (values: ChangePasswordFormDataType, formikHelpers: FormikHelpers<ChangePasswordFormDataType>) => {
      try {
        await dispatch(changePassword(values));
        formikHelpers.resetForm();
        dialogClose();
      } catch (err) {
        // TODO handle errors in some way (changePassword action is being catched)
      }
    },
    [dialogClose, dispatch]
  );

  const validationSchema = useMemo(() => {
    const pattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/;

    return object().shape({
      oldpassword: string()
        .required(formatMessage({ id: 'changePassword.requiredOldpasswordError' }))
        .label(formatMessage({ id: 'changePassword.oldpasswordLabel' })),
      newpassword: string()
        .required(formatMessage({ id: 'changePassword.requiredNewpasswordError' }))
        .test('newpassword', formatMessage({ id: 'changePassword.requiredNewpasswordError' }), (value) =>
          value ? pattern.test(value.toString()) : false
        )
        .label(formatMessage({ id: 'changePassword.newpasswordLabel' })),
      confirmpassword: string()
        .oneOf([ref('newpassword'), undefined], formatMessage({ id: 'changePassword.matchPasswordError' }))
        .required(formatMessage({ id: 'changePassword.requiredConfirmpasswordError' }))
        .label(formatMessage({ id: 'changePassword.confirmpasswordLabel' })),
    });
  }, [formatMessage]);

  const initialValues: ChangePasswordFormDataType = useMemo(
    () => ({
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
    }),
    []
  );

  return (
    <FormikDialog initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
      {({ values }) => (
        <BaseFieldset label={''}>
          <GridItem size={{ xs: 12 }}>
            <TextFieldField
              name="oldpassword"
              type="password"
              autoComplete="off"
              showPasswordVisibility
              placeholder={formatMessage({ id: 'changePassword.oldpasswordPlaceholder' })}
              fullWidth
            />
          </GridItem>
          <GridItem size={{ xs: 12 }}>
            <TextFieldField
              name="newpassword"
              type="password"
              autoComplete="off"
              showPasswordVisibility
              placeholder={formatMessage({ id: 'changePassword.newpasswordPlaceholder' })}
              helperText={formatMessage({ id: 'changePassword.rules' })}
              fullWidth
            />
          </GridItem>
          <GridItem size={{ xs: 12 }}>
            <TextFieldField
              name="confirmpassword"
              type="password"
              autoComplete="off"
              showPasswordVisibility
              placeholder={formatMessage({ id: 'changePassword.confirmpasswordPlaceholder' })}
              fullWidth
            />
          </GridItem>
          <GridItem size={{ xs: 12 }} sx={{ pt: 2 }}>
            <PasswordStrengthBox password={values.newpassword} />
          </GridItem>
        </BaseFieldset>
      )}
    </FormikDialog>
  );
};

export default ChangePasswordDialog;
