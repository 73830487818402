import { Formik, FormikConfig } from 'formik';
import React, { createContext, useContext } from 'react';
import { ObjectSchema, object } from 'yup';

interface BaseFormikProps {
  validationSchema?: ObjectSchema<any>;
}

interface BaseFormikContextType {
  validationSchema?: ObjectSchema<any>;
}

const BaseFormikContext = createContext<BaseFormikContextType>({
  validationSchema: object(),
});

/**
 * Custom useContext hook useful for propagating Formik validationSchema, not available otherwise (known bug fixed on v3)
 */
export const useBaseFormikContext = () => useContext(BaseFormikContext);

/**
 * The `BaseFormik` function is a wrapper component that provides additional context to the original
 * `Formik` component. It adds the validationSchema, not available otherwise (known bug fixed on v3) */
const BaseFormik: React.FC<BaseFormikProps & FormikConfig<any>> = (props) => {
  // const BaseFormik = (props: BaseFormikProps & FormikConfig<any>): React.JSX.Element => {

  const { validationSchema, children, ...formikProps } = props;

  const contextValue = {
    validationSchema,
  };

  return (
    <BaseFormikContext.Provider value={contextValue}>
      <Formik {...formikProps} validationSchema={validationSchema}>
        {children}
      </Formik>
    </BaseFormikContext.Provider>
  );
};

export default BaseFormik;
