import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import { useCallback, useMemo, useState } from 'react';
import { Step1 } from './Steps/Step1';
import { Step4 } from './Steps/Step4';
import { Button } from '@athonet/ui/components/Input/Button';
import { useIntl } from 'react-intl';
import { Alert } from '@athonet/ui/components/Feedback/Alert';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { createUsim5g, prepareSingleUsim5gForProvisioning } from 'store/actions/usims5g';
import { AutocompleteItemProps } from '@athonet/ui/components/Input/Autocomplete';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { MSISDN_PADDING, SUPI_PADDING } from 'store/models/usim5g';
import { Step5 } from './Steps/Step5';
import { Step6 } from './Steps/Step6';
import { useAppDispatch } from 'store';

export type CreateUsim5gFormDataType = {
  name: string;
  tenant: AutocompleteItemProps | null;
  supi: string;
  msisdn: string;
  k: string;
  /* TODO: update when encryption is contemplated again */
  // encrypt: number;
  // use_default_tk: false;
  // use_key: string;
  key_type: number;
  op: string;
  opc: string;
};

export type PrepareForProvisionFormDataType = {
  network_topology_id: AutocompleteItemProps | null;
  profile_id: AutocompleteItemProps | null;
};

export type CreateUsim5gStepProps = {
  onCompleteStep: (values: Record<string, unknown>) => void;
  initialValues: Partial<CreateUsim5gFormDataType>;
  active?: boolean;
};

const CREATE_USIM_STEPS = 6;
const MILESTONES_ARRAY = [3, 5];

export function CreateUsimCard5gContent() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { formatMessage } = useIntl();

  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: CREATE_USIM_STEPS,
    milestones: MILESTONES_ARRAY,
  });
  const dispatch = useAppDispatch();

  const [createValues, setCreateValues] = useState<CreateUsim5gFormDataType>({
    name: '',
    tenant: null,
    supi: '',
    msisdn: '',
    k: '',
    /* TODO: update when encryption is contemplated again */
    // encrypt: 0,
    // use_default_tk: false,
    // use_key: '',
    key_type: 1,
    op: '',
    opc: '',
  });

  const newUsim5gSupi = useMemo(() => `${SUPI_PADDING}${createValues.supi}`, [createValues.supi]);
  const newUsim5gMsisdn = useMemo(() => `${MSISDN_PADDING}${createValues.msisdn}`, [createValues.msisdn]);

  const [prepareForProvisionValues, setPrepareForProvisionValues] = useState<PrepareForProvisionFormDataType>({
    network_topology_id: null,
    profile_id: null,
  });

  const checkNameError = useCallback(
    (values: Partial<CreateUsim5gFormDataType>) => {
      if (values.name !== createValues.name && error === 'IMSI already exists') {
        setError(null);
      }
    },
    [createValues, error]
  );

  const onCompleteCreateStep = useCallback(
    (values: Partial<CreateUsim5gFormDataType>) => {
      checkNameError(values);
      setCreateValues((prevState) => ({ ...prevState, ...values }));
      handleGoToNextIncompleteStep();
    },
    [checkNameError, handleGoToNextIncompleteStep]
  );

  const handleCreateUsim = useCallback(async () => {
    const newTenant =
      createValues['tenant'] && 'value' in createValues['tenant'] && 'label' in createValues['tenant']
        ? {
            id: String(createValues['tenant'].value),
            name: String(createValues['tenant'].label),
          }
        : null;

    if (newTenant) {
      setLoading(true);
      const errorRes = await dispatch(
        createUsim5g({
          ...createValues,
          tenant_id: newTenant?.id,
          msisdn: newUsim5gMsisdn,
          supi: newUsim5gSupi,
        })
      );
      setLoading(false);

      if (errorRes) {
        setError(errorRes);
      } else {
        setError(null);
        handleCompleteMilestone(3);
      }
    }
  }, [createValues, dispatch, handleCompleteMilestone, newUsim5gMsisdn, newUsim5gSupi]);

  const onCompleteStep5 = useCallback(
    (values: PrepareForProvisionFormDataType) => {
      setPrepareForProvisionValues(values);
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handlePrepareForProvisioning = useCallback(async () => {
    const newNetworkId =
      prepareForProvisionValues['network_topology_id'] &&
      'value' in prepareForProvisionValues['network_topology_id'] &&
      'label' in prepareForProvisionValues['network_topology_id']
        ? String(prepareForProvisionValues['network_topology_id'].value)
        : null;

    const newServiceProfileId =
      prepareForProvisionValues['profile_id'] &&
      'value' in prepareForProvisionValues['profile_id'] &&
      'label' in prepareForProvisionValues['profile_id']
        ? String(prepareForProvisionValues['profile_id'].value)
        : null;

    if (newNetworkId && newServiceProfileId) {
      setLoading(true);
      const errorRes = await dispatch(
        prepareSingleUsim5gForProvisioning({
          networkId: newNetworkId,
          profileId: newServiceProfileId,
          usim5gSupi: newUsim5gSupi,
        })
      );
      setLoading(false);
      if (!errorRes) {
        setError(null);
        handleCompleteMilestone(MILESTONES_ARRAY[1]);
      } else {
        setError(errorRes);
      }
    }
  }, [dispatch, handleCompleteMilestone, newUsim5gSupi, prepareForProvisionValues]);

  const step1Values = useMemo(() => ({ tenant: createValues.tenant }), [createValues.tenant]);

  const step2Values = useMemo(
    () => ({ name: createValues.name, supi: createValues.supi, msisdn: createValues.msisdn }),
    [createValues.name, createValues.supi, createValues.msisdn]
  );

  const step3Values = useMemo(
    () => ({
      k: createValues.k,
      key_type: createValues.key_type,
      op: createValues.op,
      opc: createValues.opc,
      /* TODO: update when encryption is contemplated again */
      //  encrypt
      //  use_default_tk
      //  use_key
    }),
    [createValues.k, createValues.key_type, createValues.op, createValues.opc]
  );

  return (
    <Stepper
      orientation="vertical"
      completed={completed}
      nonLinear
      activeStep={active}
      onStepClick={handleGoToStep}
      skipped={skipped}
      milestonesCompleted={milestonesCompleted}
    >
      <Step1 onCompleteStep={onCompleteCreateStep} initialValues={step1Values} />
      <Step2 onCompleteStep={onCompleteCreateStep} initialValues={step2Values} />
      <Step3 onCompleteStep={onCompleteCreateStep} initialValues={step3Values} />
      <Step4
        values={createValues}
        milestoneCompleted={milestonesCompleted.has(3)}
        onCompleteStep={handleGoToNextIncompleteStep}
      >
        {error && (
          <Box sx={{ width: '100%' }}>
            <Alert
              severity="error"
              title={formatMessage({ id: 'common.error' })}
              message={formatMessage({ id: 'usims.create.error.title' }, { error })}
            />
          </Box>
        )}
        <Button
          text={error ? formatMessage({ id: 'common.form.retry' }) : formatMessage({ id: 'common.form.continue' })}
          loading={loading}
          onClick={() => void handleCreateUsim()}
        />
      </Step4>
      <Step5 onCompleteStep={onCompleteStep5} initialValues={prepareForProvisionValues} />
      <Step6
        milestoneCompleted={milestonesCompleted.has(MILESTONES_ARRAY[1])}
        values={prepareForProvisionValues}
        error={error}
        onCompleteStep={() => void handlePrepareForProvisioning()}
        loading={loading}
      />
    </Stepper>
  );
}
