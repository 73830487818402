import { STATUS } from '@athonet/ui/components/Feedback/Status';
import theme from '@athonet/ui/theme';
import { filesize } from 'filesize';
import { Network } from 'graphql/client/types';
import { useMemo } from 'react';

export function NetworksMapTooltip({
  network,
  translations: { throughputTitle, uplinkTitle, downlinkTitle, noMetricsValue },
}: {
  network: Network;
  translations: { throughputTitle: string; uplinkTitle: string; downlinkTitle: string; noMetricsValue: string };
}) {
  // rendering pure HTML because mapbox does not render a lib component

  const networktatus =
    network?.status.accessManagement.workingPercentage < 100 ||
    network?.status.userTrafficManagement.workingPercentage < 100 ||
    ((network.radios?.total || 0) > 0 && (network.radios?.active || 0) === 0)
      ? STATUS.ERROR
      : (network.radios?.active || 0) === (network.radios?.total || 0)
      ? STATUS.SUCCESS
      : STATUS.WARNING;

  const statusColor = useMemo(() => {
    switch (networktatus) {
      case STATUS.SUCCESS:
        return theme.palette.success.main;
      case STATUS.WARNING:
        return theme.palette.warning.main;
      case STATUS.ERROR:
        return theme.palette.error.main;
      default:
        return theme.palette.grey['400'];
    }
  }, [networktatus]);

  const [uplinkValue, downlinkValue] = useMemo(
    () => [
      network?.throughput.uplink ? String(filesize(network?.throughput.uplink)) : noMetricsValue,
      network?.throughput.downlink ? String(filesize(network?.throughput.downlink)) : noMetricsValue,
    ],
    [network?.throughput.downlink, network?.throughput.uplink, noMetricsValue]
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <span>{network?.name}</span>
        <div
          style={{
            backgroundColor: statusColor,
            borderRadius: '100px',
            width: '12px',
            height: '12px',
            flex: '0 0 auto',
            marginTop: '4px',
            marginLeft: '5px',
          }}
        />
      </div>
      <hr />
      <div style={{ clear: 'both' }}></div>

      <div>
        <span>{throughputTitle}:</span>
      </div>
      <div style={{ marginLeft: '12px' }}>
        {uplinkTitle}: {uplinkValue}
      </div>
      <div style={{ marginLeft: '12px' }}>
        {downlinkTitle}: {downlinkValue}
      </div>
    </>
  );
}
