//import { PanelContent } from '@athonet/ui/components/Surfaces/Panel';
import { NetworkGraph, NetworkGraphElements, NodeElement } from '@athonet/ui/components/Data/NetworkGraph';
import { useCallback, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { GridItem } from '@athonet/ui/components/Layout/Grid/GridItem';
//import { PanelContentSkeleton } from '../../Panel/PanelContentSkeleton';
import { NetworkTopologyByIdQueryResult } from '../../../graphql/client/network';
import { NetworkTopologyLoaderData } from 'graphql/loaders/network-topology';
//import { BasePanel } from 'components/Panel/BasePanel';
import { useIntl } from 'react-intl';
//import { ErrorContent } from 'components/Panel/ErrorContent';
import { AwaitPanel } from 'components/Panel/AwaitPanel';
export function NetworkTopology() {
  const { networkTopologyByIdQuery } = useLoaderData() as NetworkTopologyLoaderData;

  const topologyRefId = useRef<string | null>(null);
  const topologyRef = useRef<NetworkGraphElements | null>(null);
  const { formatMessage } = useIntl();

  const handleNWElementLink = useCallback((vm: NodeElement) => {
    if (vm.url) {
      window.open(`http://${vm.url}`, '_blank');
    }
  }, []);

  return (
    <GridContainer>
      <GridItem
        size={{
          xs: 12,
        }}
        zeroMinWidth
      >
        <AwaitPanel
          resolve={networkTopologyByIdQuery}
          fullHeight
          folder
          title={formatMessage({ id: 'menu.networks.topologyPanel.title' })}
        >
          {(networkTopologyByIdQueryResult: NetworkTopologyByIdQueryResult) => {
            const network = networkTopologyByIdQueryResult.data?.me?.networkSummary?.networks[0];

            if (!topologyRef.current || topologyRefId.current !== network?.name) {
              topologyRef.current = {
                network: String(network?.name),
                vms: network?.vms.length
                  ? network?.vms.map((vm) => {
                      const server = network?.hardware?.find((h) => h?.id === vm?.info?.hwId)?.name || '';
                      const site = network?.site?.find((s) => s?.id === vm?.info?.siteId)?.name || '';
                      return {
                        name: `${vm?.hostname}`,
                        id: `${vm?.hostname}`,
                        product: `${vm?.info?.product}`,
                        release: `${vm?.info?.swRelease}`,
                        server,
                        url: `${vm?.oamIp}`,
                        error: Boolean(vm.nfs.find((nf) => !nf?.onlineStatus)),
                        tooltipElements: vm?.nfs.map((nf) => {
                          return { name: `${nf?.name}`, error: Boolean(!nf?.onlineStatus) };
                        }),
                        ...(vm?.info?.siteId && { site }),
                        ...(vm?.info?.udrClusterId && { cluster: vm.info.udrClusterId }),
                      };
                    })
                  : [],
              };
            }

            topologyRefId.current = String(network?.name);

            return (
              <NetworkGraph
                elements={topologyRef.current}
                height={480}
                key={topologyRef.current.network}
                onClick={handleNWElementLink}
              />
            );
          }}
        </AwaitPanel>
      </GridItem>
    </GridContainer>
  );
}
