import React, { useCallback } from 'react';
import { Usim5g } from 'store/models/usim5g';
import { bulkPrepareUsims5g } from 'store/actions/usims5g';
import EditNetworkProfileDialogContent from './EditNetworkProfileDialogContent';
import { useAppDispatch } from 'store';
import { SelectedData } from 'components/List';

const EditUsimsNetworkProfileDialog: React.FC<SelectedData<Usim5g>> = ({ filters, selectedRows }) => {
  const dispatch = useAppDispatch();

  const handlePrepareForProvisioning = useCallback(
    async (networkId: string, profileId: string) =>
      await dispatch(
        bulkPrepareUsims5g({
          networkId,
          profileId,
          usimIds: selectedRows !== undefined ? selectedRows.map((s) => s.id) : null,
          //TODOPS it will be implemented soon. right usimIds: "null" is equal to filters: {}
          filters,
        })
      ),
    [dispatch, filters, selectedRows]
  );

  return (
    <EditNetworkProfileDialogContent onPrepareForProvisioning={handlePrepareForProvisioning} isBulkOperation={true} />
  );
};

export default EditUsimsNetworkProfileDialog;
