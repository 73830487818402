import { useAppSelector } from 'store';

export function useBulkOperations() {
  const bulkOperations = useAppSelector((state) => state.bulkOperations);

  return bulkOperations;
}

export function useInProgressBulkOperationCounter() {
  const bulkOperations = useBulkOperations();

  return (
    bulkOperations.scheduledOperations.length +
    (bulkOperations.list.data?.filter((op) => {
      return op.progress < 100;
    }).length || 0)
  );
}

export function useBulkOperationsList() {
  const bulkOperations = useBulkOperations();
  return bulkOperations.list;
}

export function useBulkOperationsEntity() {
  const bulkOperations = useBulkOperations();
  return bulkOperations.entity;
}
