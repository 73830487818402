import { detectLocale } from './locale';

import data_de from 'datalists/de/usStates.json';
import data_fr from 'datalists/fr/usStates.json';
import data_it from 'datalists/it/usStates.json';
import data_en from 'datalists/en/usStates.json';
import data_es from 'datalists/es/usStates.json';

interface UsStates {
  [language: string]: {
    code: string;
    name: string;
    group: string;
  }[];
}

const translations: UsStates = {
  en: data_en,
  it: data_it,
  de: data_de,
  fr: data_fr,
  es: data_es,
};

const [index] = detectLocale().split('-');

export const get = () => translations[index] || translations.en;

export default get;
