import React from 'react';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { useIntl } from 'react-intl';
import { SyncColors } from './utils';
import theme, { SxProps, Theme } from '@athonet/ui/theme';

interface RemoteSyncOptionProps {
  backgroundColor: string;
}

const RemoteSyncOption: React.FC<React.PropsWithChildren<RemoteSyncOptionProps>> = (props) => {
  const { children, backgroundColor } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, ml: 0.5 }}>
      <Box
        sx={{
          border: '1px solid ' + theme.palette.divider,
          backgroundColor,
          width: theme.spacing(1.5),
          height: theme.spacing(1.5),
          mr: 1,
        }}
      ></Box>
      <Text type="caption" noWrap>
        {children}
      </Text>
    </Box>
  );
};

interface RemoteSyncLegendProps {
  modification?: boolean;
  creation?: boolean;
  deletion?: boolean;
  sx?: SxProps<Theme>;
}

const RemoteSyncLegend: React.FC<RemoteSyncLegendProps> = ({ modification, creation, deletion, sx }) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Text sx={{ ml: 2 }} type="caption" noWrap>
        {formatMessage({ id: 'common.highlighting.remoteSyncStatuses' })}:
      </Text>

      {modification && (
        <RemoteSyncOption backgroundColor={SyncColors.modification}>
          {formatMessage({ id: 'common.highlighting.editSyncPending' })}{' '}
        </RemoteSyncOption>
      )}

      {creation && (
        <RemoteSyncOption backgroundColor={SyncColors.creation}>
          {formatMessage({ id: 'common.highlighting.additiveSyncPending' })}
        </RemoteSyncOption>
      )}

      {deletion && (
        <RemoteSyncOption backgroundColor={SyncColors.deletion}>
          {formatMessage({ id: 'common.highlighting.deleteSyncPending' })}
        </RemoteSyncOption>
      )}
    </Box>
  );
};

export default RemoteSyncLegend;
