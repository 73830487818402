import { SelectChangeEvent } from '@athonet/ui/components/Input/Select';
import { ToggleButton } from '@athonet/ui/components/Input/ToggleButton';
import { ToggleButtonGroup } from '@athonet/ui/components/Input/ToggleButton/ToggleButtonGroup';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Field, FieldProps, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Usim5g } from 'store/models/usim5g';
import { object, string, number, boolean } from 'yup';
import { keysRegExp } from 'store/models/usim5g';
import { editSingleUsim5g } from 'store/actions/usims5g';
import { useAppDispatch } from 'store';
import FormikDialog from 'components/Form/FormikDialog';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import TextFieldField from 'components/Form/Field/TextFieldField';
import SelectField from 'components/Form/Field/SelectField';

//type EditUsimCard5gFormDataType = Omit<Usim5gFields, 'single' | 'upload' | 'metadata_delete' | 'tenant_id'>;

type EditUsimCard5gFormDataType = {
  name: Usim5g['name'];
  k: string;
  op: Usim5g['op'];
  opc: Usim5g['opc'];
  encrypt: Usim5g['encrypt'];
  key_type: number;
  use_default_tk: boolean;
  key_override: boolean;
};

export function EditUsimCard5gDialog({ usim5g }: { usim5g: Usim5g }) {
  const { formatMessage } = useIntl();
  const { dialogClose, snackbarClose } = useOverlay();
  const [error, setError] = useState<null | string>(null);
  const dispatch = useAppDispatch();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .required()
          .trim()
          .label(formatMessage({ id: `usims.form.usim.name.label` })),
        k: string()
          .matches(keysRegExp)
          .trim()
          .label(formatMessage({ id: `usims.form.usim.k.label` })),
        /* TODO: update when encryption is contemplated again */
        key_type: number()
          .min(1)
          .max(2)
          .label(formatMessage({ id: `usims.form.usim.key_type.label` })),
        /* TODO: update when encryption is contemplated again */
        encrypt: number()
          .min(0)
          .max(0)
          .label(formatMessage({ id: `usims.form.usim.encrypt.label` })),
        // use_key: string().when(['encrypt', 'use_default_tk'], {
        //   is: (encryptValue, use_default_tk) => encryptValue === 1 && !use_default_tk,
        //   then: string().matches(tkRegExp),
        //   otherwise: string().nullable().matches(tkRegExp),
        // }),
        op: string()
          .when(['key_type', 'key_override'], {
            is: (key_type: number, key_override: boolean) => key_type === 1 && (!usim5g['op'] || key_override),
            then: (fieldSchema) => fieldSchema.matches(keysRegExp).required(),
            otherwise: (fieldSchema) => fieldSchema.nullable().matches(keysRegExp),
          })
          .label(formatMessage({ id: `usims.form.usim.op.label` })),
        opc: string()
          .when(['key_type', 'key_override'], {
            is: (key_type: number, key_override: boolean) => key_type === 2 && (!usim5g['opc'] || key_override),
            then: (fieldSchema) => fieldSchema.matches(keysRegExp).required(),
            otherwise: (fieldSchema) => fieldSchema.nullable().matches(keysRegExp),
          })
          .label(formatMessage({ id: `usims.form.usim.opc.label` })),
        use_default_tk: boolean(),
        key_override: boolean(),
      }),
    [formatMessage, usim5g]
  );

  const initialValues: EditUsimCard5gFormDataType = useMemo(
    () => ({
      name: usim5g.name,
      encrypt: usim5g.encrypt,
      op: '',
      opc: '',
      k: '',
      key_type: !usim5g.op && !usim5g.opc ? 0 : usim5g.op && !usim5g.opc ? 1 : 2,
      key_override: false,
      use_default_tk: Boolean(!usim5g['use_key']),
    }),
    [usim5g]
  );

  const handleEditUsim = useCallback(
    /* TODO: update when encryption is contemplated again */
    async ({ name, k, key_type, op, opc /*encrypt, use_default_tk, use_key*/ }: EditUsimCard5gFormDataType) => {
      const resultError = await dispatch(
        editSingleUsim5g({
          name,
          k,
          key_type,
          op,
          opc,
          usim5gSupi: usim5g.supi,
        })
      );
      if (resultError) {
        setError(typeof resultError === 'string' ? resultError : formatMessage({ id: 'common.unknownError' }));
        snackbarClose();
      } else {
        setError(null);
        dialogClose();
      }
    },
    [dialogClose, dispatch, formatMessage, snackbarClose, usim5g.supi]
  );

  const handleKeyTypeChange = useCallback(
    (
      e: SelectChangeEvent<number>,
      setFieldValue: FormikHelpers<Partial<EditUsimCard5gFormDataType>>['setFieldValue']
    ) => {
      const v = e.target.value;
      void setFieldValue('key_type', v);
      if (v === 0) {
        void setFieldValue('op', '');
        void setFieldValue('opc', '');
      }
      // OP
      if (v === 1) {
        void setFieldValue('opc', '');
      }
      // OPC
      if (v === 2) {
        void setFieldValue('op', '');
      }
    },
    []
  );

  return (
    <FormikDialog
      errorMessage={
        error ? formatMessage({ id: 'usims.actions.edit.errorMessage.single' }, { message: error }) : undefined
      }
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleEditUsim}
      validationSchema={validationSchema}
      submitWithNoChanges={false}
    >
      {({ values, setFieldValue }) => (
        <BaseFieldset>
          <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
            <TextFieldField
              name="name"
              placeholder={formatMessage({ id: `usims.form.usim.name.placeholder` })}
              fullWidth
            />
            <TextFieldField name="k" placeholder={formatMessage({ id: `usims.form.usim.k.placeholder` })} fullWidth />
            <SelectField
              name="encrypt"
              placeholder={formatMessage({ id: `usims.form.usim.encrypt.placeholder` })}
              label={formatMessage({ id: 'usims.form.usim.encrypt.label' })}
              onChange={(e) => void setFieldValue('encrypt', e.target.value)}
              multiple={false}
              /* TODO: update when encryption is contemplated again */
              disabled
            >
              <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.encrypt.0' })}</MenuItem>
              <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.encrypt.1' })}</MenuItem>
            </SelectField>
            {values['encrypt'] === 1 && (
              <Field name="use_default_tk" key="use_default_tk">
                {({ field }: FieldProps<string>) => (
                  <ToggleButtonGroup
                    {...field}
                    sx={{ alignSelf: 'flex-start' }}
                    key="use_default_tk"
                    size="small"
                    color={'secondary'}
                    exclusive={true}
                    onChange={(v) => {
                      if (v !== null) {
                        void setFieldValue('use_default_tk', v);
                      }
                    }}
                    data-testid={'field-use_default_tk'}
                  >
                    <ToggleButton disabled={values['use_default_tk'] === true} value={true}>
                      {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.true' })}
                    </ToggleButton>
                    <ToggleButton disabled={values['use_default_tk'] === false} value={false}>
                      {formatMessage({ id: 'usims.form.usim.use_default_tk_5g.false' })}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Field>
            )}
            {/* TODO: update when encryption is contemplated again */}
            {/* {values['encrypt'] === 1 && !values['use_default_tk'] && (
                    <Field name="use_key" key="use_key">
                      {({ field }: FieldProps<string>) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...field}
                          {...getInputTextsError('use_key', errors, touched)}
                        />
                      )}
                    </Field>
                  )} */}
            <SelectField
              name="key_type"
              label={formatMessage({ id: `usims.form.usim.key_type.label` })}
              placeholder={formatMessage({ id: `usims.form.usim.key_type.placeholder` })}
              value={values['key_type']}
              onChange={(e) => handleKeyTypeChange(e, setFieldValue)}
            >
              {/* /* TODO: update when encryption is contemplated again */}
              {/* <MenuItem value={0}>{formatMessage({ id: 'usims.form.usim.key_type.defaultOP_5g' })}</MenuItem> */}
              <MenuItem value={1}>{formatMessage({ id: 'usims.form.usim.key_type.customOP' })}</MenuItem>
              <MenuItem value={2}>{formatMessage({ id: 'usims.form.usim.key_type.customOPC' })}</MenuItem>
            </SelectField>
            {Boolean((values['key_type'] === 1 && usim5g['op']) || (values['key_type'] === 2 && usim5g['opc'])) && (
              <Field name="key_override" key="key_override">
                {({ field }: FieldProps<boolean>) => (
                  <ToggleButtonGroup
                    size="small"
                    color={'secondary'}
                    exclusive={true}
                    {...field}
                    onChange={(value) => {
                      void setFieldValue('key_override', value);
                      if (!value) {
                        void setFieldValue('op', '');
                        void setFieldValue('opc', '');
                      }
                    }}
                  >
                    <ToggleButton value={false}>
                      {formatMessage({ id: 'usims.form.usim.key_override.no' })}
                    </ToggleButton>
                    <ToggleButton value={true}>
                      {formatMessage({ id: 'usims.form.usim.key_override.yes' })}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Field>
            )}
            {values['key_type'] === 1 && (values['key_override'] === true || !usim5g['op']) && (
              <TextFieldField
                name="op"
                fullWidth
                placeholder={formatMessage({ id: `usims.form.usim.op.placeholder` })}
              />
            )}
            {values['key_type'] === 2 && (values['key_override'] === true || !usim5g['opc']) && (
              <TextFieldField
                name="opc"
                fullWidth
                placeholder={formatMessage({ id: `usims.form.usim.opc.placeholder` })}
              />
            )}
          </Stack>
        </BaseFieldset>
      )}
    </FormikDialog>
  );
}
