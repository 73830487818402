import { createSelector } from '@reduxjs/toolkit';
import { State, useAppSelector } from 'store';
import { checkPermissionToUse, checkTenantType, T_PERMISSIONS, U_PERMISSIONS } from 'utils/permissionCodes';
import { selectTenantsWithSubtenants } from './tenants';

export function useUserSelector() {
  const user = useAppSelector((state) => state.user);

  return user;
}

export function useCanUserReadUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.READ_USIM);
}

export function useCanUserCreateUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_USER);
}

export function useCanUserUpdateUsimSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM);
}

export function useCanUserUpdateUsimProfileSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USIM_PROFILE);
}

export function useHasUserMasterTenantSelector() {
  const user = useUserSelector();

  return checkTenantType(user?.tenant_type, [T_PERMISSIONS.MASTER]);
}

export function useHasUserCPMasterTenantSelector() {
  const user = useUserSelector();

  return checkTenantType(user?.tenant_type, [T_PERMISSIONS.MASTER, T_PERMISSIONS.CHANNEL_PARTNER]);
}

export function useUserCPTenantSelector() {
  const user = useUserSelector();

  if (checkTenantType(user?.tenant_type, [T_PERMISSIONS.CHANNEL_PARTNER])) {
    return user?.tenant_id;
  } else {
    return null;
  }
}

export function useCanUserBulkDeleteUsims5gSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_USIM);
}

export function useCanUserUpdateTenantParentSelector() {
  const user = useUserSelector();

  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_TENANT_PARENT);
}

export function useLoggableTenantsSelector() {
  const user = useUserSelector();

  return user?.loggable_tenants
    ? user.loggable_tenants.map(({ id, name: tenantName, roles }) => ({
        tenant: tenantName,
        status: id === user.tenant_id,
        roles,
      }))
    : [];
}

export function useCanUserUpdateUserSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_USER);
}

export function useCanUserCreateTenantSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_TENANT);
}

export function useCanUserReadNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.READ_NODE);
}

export function useCanUserUpdateNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.UPDATE_NODE);
}

export function useCanUserDeleteNodeSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.DELETE_NODE);
}

export function useCanUserCreateNetworkElementSelector() {
  const user = useUserSelector();
  return checkPermissionToUse(user, U_PERMISSIONS.CREATE_NETWORK_ELEMENT);
}

export const selectEditingUserTenants = (state: State) => state.users.tenantsByUser;

const selectEditingUserTenantsIds = createSelector([selectEditingUserTenants], (userTenants) => {
  return userTenants.map((t) => t.id);
});

export const selectEditingUserTenantsWithRoles = createSelector([selectEditingUserTenants], (userTenants) => {
  return userTenants.map((t) => {
    const opt = {
      id: t.id,
      name: t.name,
      roles: t.roles?.join(', '),
    };
    return opt;
  });
});

export const selectEditingUserTenantsWithRolesIds = createSelector(
  [selectEditingUserTenantsWithRoles],
  (userTenants) => {
    return userTenants.map((t) => t.id);
  }
);

export const selectAvailableEditingUserTenants = createSelector(
  [selectTenantsWithSubtenants, selectEditingUserTenantsIds],
  (tenants, userTenants) => {
    return tenants.data.data
      .filter((t) => !userTenants.includes(t.id))
      .map((t) => ({
        id: t.id,
        name: t.name,
      }));
  }
);
