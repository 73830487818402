import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { State } from 'store';
import { ServiceProfile } from 'store/models/serviceProfile';
import { fetchData } from '../fetchData';
import { sentryLogError } from 'sentry';
import { showErrorToast } from '../toast';
import { GetResultsRes } from 'store/models/filters';

export const getServiceProfiles = createAsyncThunk<
  GetResultsRes<ServiceProfile>,
  {
    networkId: string;
  },
  {
    rejectValue: null;
    state: State;
  }
>('serviceProfiles/getAll', async ({ networkId }, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: config.apis.getServiceProfiles.replace('{network_id}', networkId),
    };

    const fetchedData = await dispatch(fetchData<GetResultsRes<ServiceProfile>>(options));
    return fetchedData;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast({ message: 'common.fetch.error', intlMessage: true }));
    return rejectWithValue(null);
  }
});
