import React from 'react';
import { TextProps } from '@athonet/ui/components/Guidelines/Text';
import { useSetLabel } from 'hooks/useSetLabel';
import UsimRemoteSyncText from './UsimRemoteSyncText';

interface UsimKeyTypeTextProps {
  provisionedValue: string | null;
  unprovisionedValue: string | null;
}

const UsimKeyTypeText: React.FC<TextProps & UsimKeyTypeTextProps> = (props) => {
  const text = useSetLabel(props.unprovisionedValue);
  const tooltipText = useSetLabel(props.provisionedValue);

  return <UsimRemoteSyncText text={text} tooltipText={tooltipText} {...props} />;
};

export default UsimKeyTypeText;
