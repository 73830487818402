import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Select } from '@athonet/ui/components/Input/Select';
import { Button } from '@athonet/ui/components/Input/Button';
import { MenuItem } from '@athonet/ui/components/Overlay/Menu/MenuItem';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Owner, TENANT_TYPE } from 'store/models/tenant';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Chip } from '@athonet/ui/components/Data/ChipsArray/Chip';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { Tooltip } from '@athonet/ui/components/Feedback/Tooltip';
import { AuthPanel } from '@athonet/ui/components/Surfaces/AuthPanel';
import { Link } from '@athonet/ui/components/Navigation/Link';

type TenantsListFormDataType = {
  tenantId: Owner['id'];
};

type TenantsListFormProps = {
  tenantsList: Owner[];
  onSubmit: (tenantId: TenantsListFormDataType['tenantId']) => void;
  onBack: () => void;
};

export default function TenantsListForm({ onSubmit, onBack, tenantsList }: TenantsListFormProps) {
  const { formatMessage } = useIntl();

  const handleSubmit = useCallback(
    ({ tenantId }: TenantsListFormDataType) => {
      return onSubmit(tenantId);
    },
    [onSubmit]
  );

  const getTenantChipTextByType = useCallback(
    (tenant: Owner): { label: string; initials: string } => {
      switch (tenant.type) {
        case TENANT_TYPE.MASTER:
          return {
            label: formatMessage({ id: 'common.master' }),
            initials: formatMessage({ id: 'common.master' }),
          };
        case TENANT_TYPE.CHANNEL_PARTNER:
          return { label: formatMessage({ id: 'common.channelPartner' }), initials: 'CP' };

        case TENANT_TYPE.NETWORK_MANAGER:
          return { label: formatMessage({ id: 'common.networkManager' }), initials: 'NM' };

        default:
          return { label: '', initials: '' };
      }
    },
    [formatMessage]
  );

  const tenantsOptions: Owner[] = useMemo(() => {
    const tenantCompare = (tenant: Owner) => {
      if (tenant.type === TENANT_TYPE.MASTER) {
        return -1;
      } else if (tenant.type === TENANT_TYPE.NETWORK_MANAGER) {
        return 1;
      } else {
        return 0;
      }
    };

    tenantsList.sort((a, b) => tenantCompare(a) - tenantCompare(b));
    return tenantsList;
  }, [tenantsList]);

  const initialValues: TenantsListFormDataType = useMemo(() => {
    return {
      tenantId: tenantsOptions[0].id,
    };
  }, [tenantsOptions]);

  if (!tenantsList) {
    return null;
  }

  return (
    <AuthPanel title={formatMessage({ id: 'login.tenants.title' })} data-testid="tenantsListForm">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <>
            <Form noValidate>
              <Stack spacing={5}>
                <Box sx={{ mt: 2 }}>
                  <Field name="tenantId">
                    {({ field }: FieldProps<string>) => {
                      return (
                        <Select
                          {...field}
                          data-testid="tenantsListForm-tenants-select"
                          label={formatMessage({ id: 'login.tenants.table.name' })}
                          size={'medium'}
                          disabled={isSubmitting}
                          renderValue={(tenantId: string) => {
                            const tenant = tenantsOptions.find(({ id }) => id === tenantId) as Owner;
                            const { label } = getTenantChipTextByType(tenant);
                            return (
                              <Stack align="center" direction="row">
                                <Chip id={label} size="small" label={label} color="secondary" />
                                <span>{tenant.name}</span>
                              </Stack>
                            );
                          }}
                        >
                          {tenantsOptions?.map((tenant) => {
                            const { label, initials } = getTenantChipTextByType(tenant);
                            return (
                              <MenuItem value={tenant.id} key={tenant.id} iconVariant="solid">
                                <Stack direction="row">
                                  <Tooltip title={label} sx={{ zIndex: 100000000 }}>
                                    <div>
                                      <Chip id={tenant.name} size="small" label={initials} color="secondary" />
                                    </div>
                                  </Tooltip>
                                  <Text>{tenant.name}</Text>
                                </Stack>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  </Field>
                </Box>

                <Stack spacing={2}>
                  <Button
                    size="large"
                    data-testid="tenantsListForm-submitButton"
                    text={formatMessage({ id: 'login.submitButton' })}
                    fullWidth
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />

                  <Link data-testid="tenantsListForm-loginLink" onClick={onBack}>
                    <Text align="center">{formatMessage({ id: 'login.tenants.backButton' })}</Text>
                  </Link>
                </Stack>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </AuthPanel>
  );
}
