import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { createServiceProfile } from 'store/actions/serviceProfiles';
import { ServiceProfile } from 'store/models/serviceProfile';
import { useAppDispatch, useAppSelector } from 'store';
import TextFieldField from 'components/Form/Field/TextFieldField';
import FormikDialog from 'components/Form/FormikDialog';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { selectServiceProfileById } from 'store/selectors/serviceProfiles';
import { editServiceProfile } from 'store/actions/serviceProfiles/editServiceProfile';
import { setSelectedServiceProfileId } from 'store/reducers/serviceProfiles';

type EditServiceProfileFormDataType = Pick<ServiceProfile, 'name'>;

interface EditServiceProfileDialogProps {
  serviceProfileId?: string;
  networkId: string;
}

export function EditServiceProfileDialog({ serviceProfileId, networkId }: EditServiceProfileDialogProps) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);

  const serviceProfile = useAppSelector((state) =>
    serviceProfileId ? selectServiceProfileById(state, serviceProfileId) : undefined
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .trim()
          .required()
          .label(formatMessage({ id: 'serviceProfiles.form.name.label' })),
      }),
    [formatMessage]
  );

  const initialValues: EditServiceProfileFormDataType = useMemo(
    () => ({
      name: serviceProfile?.name || '',
    }),
    [serviceProfile?.name]
  );

  const handleEditServiceProfile = useCallback(
    async (values: EditServiceProfileFormDataType) => {
      if (!serviceProfile) return;
      const errorRes = await dispatch(
        editServiceProfile({ serviceProfileId: serviceProfile.id, data: values, networkId })
      );

      if (!errorRes) {
        dialogClose();
      } else {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, networkId, serviceProfile]
  );

  const handleCreateServiceProfile = useCallback(
    async (values: EditServiceProfileFormDataType) => {
      const { error: errorRes, id: idRes } = await dispatch(createServiceProfile({ data: values, networkId }));

      if (!errorRes && idRes) {
        dialogClose();
        dispatch(setSelectedServiceProfileId(idRes));
      } else if (errorRes) {
        setError(errorRes);
      }
    },
    [dialogClose, dispatch, networkId]
  );

  const handleSubmit = useCallback(
    async (values: EditServiceProfileFormDataType) => {
      return serviceProfile ? await handleEditServiceProfile(values) : await handleCreateServiceProfile(values);
    },
    [handleCreateServiceProfile, handleEditServiceProfile, serviceProfile]
  );

  return (
    <FormikDialog
      errorMessage={
        error
          ? serviceProfile
            ? formatMessage({ id: 'serviceProfiles.edit.error.title' }, { error })
            : formatMessage({ id: 'serviceProfiles.create.error.title' }, { error })
          : undefined
      }
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      submitWithNoChanges={false}
    >
      <BaseFieldset>
        <TextFieldField
          name="name"
          fullWidth
          placeholder={formatMessage({ id: 'serviceProfiles.form.name.placeholder' })}
        />
      </BaseFieldset>
    </FormikDialog>
  );
}
