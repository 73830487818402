import { useCallback, useMemo } from 'react';
import { checkPermissionsList, U_PERMISSIONS } from 'utils/permissionCodes';
import { useIntl } from 'react-intl';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { NavigationDrawer, NavigationDrawerProps } from '@athonet/ui/components/Navigation/Drawer';
import { matchPath, useLocation } from 'react-router';
import { Logo } from '@athonet/ui/components/Branding/Logo';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { Box } from '@athonet/ui/components/Surfaces/Box';
import { Text } from '@athonet/ui/components/Guidelines/Text';
import { NavigationItemProps } from '@athonet/ui/components/Navigation/Drawer/NavigationItem';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';

export default function Navigation() {
  const userdata = useAppSelector((state) => state.user);
  const bootstrap = useBootstrapSelector();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems: NavigationDrawerProps['items'] = useMemo(() => {
    if (!userdata || !bootstrap) {
      return [];
    }

    return checkPermissionsList([userdata.tenant_type].concat(userdata.permissions), [
      {
        value: '/home',
        label: formatMessage({ id: 'menu.home' }),
        iconName: 'Grid-1',
        permissions: [U_PERMISSIONS.VIEW_DASHBOARD_MODULE],
      },
      // Networks
      ...(bootstrap.athux_support_enabled || bootstrap.athonetos_support_enabled
        ? [
            {
              value: '/networks',
              label: formatMessage({ id: 'menu.networks' }),
              iconName: 'Network-Settings',
              permissions: [U_PERMISSIONS.VIEW_NODE_MODULE, U_PERMISSIONS.VIEW_HSS_MODULE],
            },
          ]
        : []),
      ...(bootstrap.athonetos_support_enabled
        ? [
            {
              label: formatMessage({ id: 'menu.5g-provisioning.root' }),
              value: '/subscribers',
              iconName: 'Serer-Connections',
              // TODO: Update to 5g permissions once they are added
              permissions: [U_PERMISSIONS.VIEW_USIM_MODULE],
            },
          ]
        : []),
    ]) as NavigationDrawerProps['items'];
  }, [bootstrap, formatMessage, userdata]);

  const handleNavigationItemClick = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const getMatchingItemValue = useCallback(
    (item: NavigationItemProps) => {
      const itemMatch = Boolean(
        matchPath(
          {
            path: item.value,
            end: false,
          },
          location.pathname
        )
      );
      if (Boolean(itemMatch)) {
        return item.value;
      }
      return;
    },
    [location.pathname]
  );

  const activeNavigationValue = useMemo(() => {
    let activeValue: string | undefined;
    navigationItems.forEach((item) => {
      activeValue = getMatchingItemValue(item) || activeValue;
      item.subItems?.forEach((subItem) => {
        activeValue = getMatchingItemValue(subItem) || activeValue;
      });
    });
    return activeValue;
  }, [getMatchingItemValue, navigationItems]);

  if (!bootstrap) {
    return null;
  }

  return (
    <NavigationDrawer
      items={navigationItems}
      onClick={handleNavigationItemClick}
      activeValue={activeNavigationValue}
      headerComponent={
        <Stack justify="center" align="flex-start" sx={{ pt: 2 }}>
          <Logo imageUrl={bootstrap.logo} width={208} />
        </Stack>
      }
      footerComponent={
        <Box sx={{ pb: 1 }}>
          <Text type="caption">v{process.env.REACT_APP_VERSION}</Text>
        </Box>
      }
    />
  );
}
