import { ServiceProfile } from 'store/models/serviceProfile';
import { Draft, EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getServiceProfiles } from 'store/actions/serviceProfiles/getServiceProfiles';
import { DATA_LIFECYCLE } from '.';

export const serviceProfilesAdapter = createEntityAdapter<ServiceProfile>();

export type ServiceProfileState = {
  entities: EntityState<ServiceProfile>;
  selectedId?: string;
  loadingStatus: DATA_LIFECYCLE;
};

const initialState: ServiceProfileState = {
  entities: serviceProfilesAdapter.getInitialState(),
  loadingStatus: DATA_LIFECYCLE.IDLE,
};

const serviceProfiles = createSlice({
  name: 'serviceProfiles',
  initialState,
  reducers: {
    setSelectedServiceProfileId: (state: Draft<ServiceProfileState>, action: PayloadAction<string>) => {
      state.selectedId = action.payload;
    },
    resetServiceProfiles: (state: Draft<ServiceProfileState>) => {
      serviceProfilesAdapter.removeAll(state.entities);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceProfiles.pending, (state, action) => {
      state.loadingStatus = DATA_LIFECYCLE.LOADING;
    });
    builder.addCase(getServiceProfiles.fulfilled, (state, action) => {
      serviceProfilesAdapter.setAll(state.entities, action.payload.items);
      state.loadingStatus = DATA_LIFECYCLE.SUCCESS;
    });
    builder.addCase(getServiceProfiles.rejected, (state, action) => {
      state.loadingStatus = DATA_LIFECYCLE.FAILURE;
    });
  },
});

export const { resetServiceProfiles, setSelectedServiceProfileId } = serviceProfiles.actions;

const serviceProfileReducer = serviceProfiles.reducer;
export default serviceProfileReducer;
