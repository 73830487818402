import { useIntl } from 'react-intl';
import { FILTER_INPUT_TYPE } from '@athonet/ui/components/Input/FilterInput';
import { FiltersObj, getDefaultValue } from 'store/models/filters';
import { useCallback, useMemo } from 'react';
import {
  FiltersDrawer,
  FiltersDrawerItem,
  defaultSelectOptionFilterSchema,
  defaultStringFilterSchema,
} from '../Drawer';
import { batch } from 'react-redux';
import getStatesCountries, { getStateContryGroupBy } from 'utils/getStatesCountries';
import { selectTenantFilters } from 'store/selectors/tenants';
import { getTenantsList } from 'store/actions/tenants/getTenantsList';
import { setTenantsFilters } from 'store/reducers/tenants';
import { useAppDispatch, useAppSelector } from 'store';

export default function Tenants() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => selectTenantFilters(state));

  const filterItems = useMemo(
    (): FiltersDrawerItem[] => [
      {
        name: 'type',
        label: formatMessage({ id: 'tenants.filters.type' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        initial: filters ? filters['type'] : undefined,
        schema: defaultSelectOptionFilterSchema,
        autocompleteOptions: [
          { label: formatMessage({ id: 'tenants.type.channelPartners' }), value: 'channel_partner' },
          { label: formatMessage({ id: 'tenants.type.networkManager' }), value: 'network_manager' },
        ],
        // permissions: [T_PERMISSIONS.MASTER],
      },
      {
        name: 'name',
        label: formatMessage({ id: 'tenants.filters.name' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: filters ? filters['name'] : undefined,
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: defaultStringFilterSchema,
      },
      {
        name: 'address',
        label: formatMessage({ id: 'tenants.filters.address' }),
        filterTypes: [FILTER_INPUT_TYPE.CONTAINS],
        initial: filters ? filters['address'] : undefined,
        default: getDefaultValue(FILTER_INPUT_TYPE.CONTAINS, false),
        schema: defaultStringFilterSchema,
      },
      {
        name: 'country',
        label: formatMessage({ id: 'tenants.filters.tenant.country' }),
        filterTypes: [FILTER_INPUT_TYPE.OPTIONS],
        autocompleteOptions: getStatesCountries(),
        initial: filters ? filters['country'] : undefined,
        default: getDefaultValue(FILTER_INPUT_TYPE.OPTIONS, false),
        schema: defaultSelectOptionFilterSchema,
        groupBy: getStateContryGroupBy,
      },
    ],
    [formatMessage, filters]
  );

  const handleSubmit = useCallback(
    (filtersValues: FiltersObj) => {
      batch(() => {
        dispatch(setTenantsFilters(filtersValues));
        void dispatch(getTenantsList());
      });
    },
    [dispatch]
  );

  return <FiltersDrawer onSubmit={handleSubmit} items={filterItems} />;
}
