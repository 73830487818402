import { useUserSelector } from 'store/selectors/user';
import { object, string } from 'yup';
import { useIntl } from 'react-intl';
import { editMe } from 'store/actions/users';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useAppDispatch } from 'store';
import FormikDialog from 'components/Form/FormikDialog';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import { phoneRegExp } from 'store/models/user';

type EditUserProfileFormDataType = {
  fullName: string;
  phone: string;
  email: string;
};

export default function EditUserDialog() {
  const { formatMessage } = useIntl();
  const user = useUserSelector();
  const dispatch = useAppDispatch();
  const { dialogClose } = useOverlay();

  const handleEditUser = async (values: EditUserProfileFormDataType) => {
    void dispatch(editMe(values));
    dialogClose();
  };

  // TODO ADD PHONE GOOGLE VALIDATION LIBRARY
  const schema = object().shape({
    fullName: string()
      .required()
      .label(formatMessage({ id: `users.form.user.fullname.label` }))
      .trim(),
    phone: string()
      .label(formatMessage({ id: `users.form.user.phone.label` }))
      .matches(phoneRegExp, formatMessage({ id: `users.form.user.phone.format.error` })),
    email: string()
      .email()
      .required()
      .label(formatMessage({ id: `users.form.user.email.label` }))
      .trim(),
  });

  if (!user?.fullname) {
    return null;
  }

  const initialsValues = { fullName: user.fullname, phone: user.phone, email: user.email };

  return (
    <FormikDialog initialValues={initialsValues} onSubmit={handleEditUser} validationSchema={schema} enableReinitialize>
      <BaseFieldset>
        <Stack fullWidth spacing={2} sx={{ pt: 2 }}>
          <TextFieldField name="fullName" placeholder={formatMessage({ id: `users.form.user.fullname.placeholder` })} />
          <TextFieldField name="phone" placeholder={formatMessage({ id: `users.form.user.phone.placeholder` })} />
          <TextFieldField name="email" placeholder={formatMessage({ id: `users.form.user.email.placeholder` })} />
        </Stack>
      </BaseFieldset>
    </FormikDialog>
  );
}
