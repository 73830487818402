import { TENANT_TYPE } from 'store/models/tenant';
import { AUTH_PROVIDER } from './environmentConfiguration';

export const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

type LoggableTenant = {
  id: string;
  name: string;
  roles: string[];
  type: TENANT_TYPE;
};

export type User = {
  id: string;
  fullname: string;
  name: string;
  email: string;
  phone: string;
  lang: string;
  picture: string;
  accessToken: string;
  refreshToken: string;
  permissions: string[];
  loggable_tenants: LoggableTenant[];
  roles: string;
  tenant_id: string;
  tenant_name: string;
  tenant_type: string;
  image: string;
  tenant: string;
  created_at: string;
  realm: AUTH_PROVIDER;
};

export type UserAdminConfig = {
  adminConfigToken: string;
};
