import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { GridContainer } from '@athonet/ui/components/Layout/Grid/GridContainer';
import { useCallback } from 'react';
import { selectActiveUsim } from 'store/selectors/5gProvisioning/usimCards';
import { useIntl } from 'react-intl';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import KeyValueGridItem from 'components/Details/KeyValueGridItem';
import UsimStatus from 'components/UsimStatus';
import EditUsimNodeProfileDialog from 'components/Edit/5gProvisioning/UsimCards/EditNetworkProfile/EditUsimNetworkProfileDialog';
import { useAppSelector } from 'store';
import { BasePanel } from 'components/Panel/BasePanel';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';

export function StatusPanel() {
  const activeUsim = useAppSelector((state) => selectActiveUsim(state));
  const { formatMessage } = useIntl();
  const { dialogOpen } = useOverlay();

  const openProvisioningStatusEditor = useCallback(() => {
    if (!activeUsim) {
      return;
    }
    dialogOpen({
      title: formatMessage({ id: 'usims.actions.prepare.title' }),
      content: () => <EditUsimNodeProfileDialog usim5g={activeUsim} />,
    });
  }, [dialogOpen, formatMessage, activeUsim]);

  return (
    <BasePanel
      folder
      title={formatMessage({ id: 'usims.table.status' })}
      headerActionsComponent={
        <IconButton name="Pencil" onClick={() => openProvisioningStatusEditor()} fontSize="small" />
      }
      height={205}
      skeleton={!activeUsim}
    >
      {activeUsim && (
        <PanelContent>
          <GridContainer>
            <KeyValueGridItem
              fullWidth
              label={formatMessage({ id: 'usims.id.details.provisioningStatus' })}
              valueComponent={<UsimStatus status={activeUsim.status || ''} error={undefined} updating={undefined} />}
            />
            <KeyValueGridItem
              label={formatMessage({ id: 'usims.table.network' })}
              value={activeUsim.network_topology_name}
            />

            <KeyValueGridItem
              label={formatMessage({ id: 'usims.table.profileName' })}
              value={activeUsim.profile_name}
            />
          </GridContainer>
        </PanelContent>
      )}
    </BasePanel>
  );
}
