import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserSelector } from 'store/selectors/user';
import { checkUserPermissions } from 'store/actions/login';
import { useBootstrapSelector } from 'store/selectors/bootstrap';
import { EnvironmentConfiguration } from 'store/models/environmentConfiguration';
import { useAppDispatch } from 'store';

type EnvironmentConfigurationKeys = keyof EnvironmentConfiguration;

type PrivateHOCProps = {
  permissions?: string[];
  tenantType?: string[];
  featureFlags?: EnvironmentConfigurationKeys[];
  useNonStrictFlagCheck?: boolean;
};

export default function PrivateHOC({ permissions, tenantType, featureFlags, useNonStrictFlagCheck }: PrivateHOCProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useUserSelector();
  const bootstrap = useBootstrapSelector();

  useEffect(() => {
    void dispatch(checkUserPermissions(navigate, permissions, tenantType));
  }, [dispatch, navigate, permissions, tenantType]);

  if (!bootstrap || !user?.accessToken) {
    return null;
  }

  if (featureFlags) {
    const areFlagsValid = useNonStrictFlagCheck
      ? featureFlags.some((flag) => bootstrap[flag])
      : featureFlags.every((flag) => bootstrap[flag]);
    if (!areFlagsValid) throw new Error(`FF: ${featureFlags.toString()}`);
  }

  return <Outlet />;
}
