import theme, { alpha, darken } from '@athonet/ui/theme';

export namespace SyncColors {
  const creationColor = theme.palette.series5.main;
  const deletionColor = theme.palette.series10.main;
  const modificationColor = theme.palette.series8.main;

  export const modification = darken(modificationColor, 0.1);
  export const modificationBg = alpha(modificationColor, 0.15);

  export const creation = darken(creationColor, 0.3);
  export const creationBg = alpha(creationColor, 0.15);

  export const deletion = darken(deletionColor, 0.1);
  export const deletionBg = alpha(deletionColor, 0.15);
}

export enum SyncStatus {
  PendingCreation = 'Creation',
  PendingModification = 'Modification',
  PendingDeletion = 'Deletion',
  None = 'None',
}

export const getSyncStatusColor = (syncStatus: SyncStatus) => {
  let color;

  switch (syncStatus) {
    case SyncStatus.PendingCreation:
      color = SyncColors.creation;
      break;
    case SyncStatus.PendingModification:
      color = SyncColors.modification;
      break;
    case SyncStatus.PendingDeletion:
      color = SyncColors.deletion;
      break;
  }
  return color;
};
