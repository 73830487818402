import { AxiosRequestConfig } from 'axios';
import { AutocompleteOption } from 'utils/forms';
import { Thunk } from '.';
import config from 'config';
import { sentryLogError } from 'sentry';
import { showErrorToast } from './toast';
import { fetchData } from './fetchData';
import { GetResultsRes } from 'store/models/filters';
import { Network } from 'store/models/networks';

export function getNetworksOptions(): Thunk<Promise<AutocompleteOption[]>> {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        url: config.apis.getNetworkTopologies
          .replace('{sort}', 'id')
          .replace('{limit}', '')
          .replace('{page}', '0')
          .replace('{filters}', ''),
      };

      const { items } = await dispatch(fetchData<GetResultsRes<Network>>(options));

      return items.map(({ name, id }) => ({ label: name, value: id }));
    } catch (e) {
      sentryLogError(e);
      dispatch(showErrorToast());
      return [];
    }
  };
}
