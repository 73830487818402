import React, { Suspense, useEffect } from 'react';
import { PanelContentSkeleton } from 'components/Panel/PanelContentSkeleton';
import { Await, useAsyncError } from 'react-router-dom';
import { BasePanel, BasePanelProps } from './BasePanel';
import { NoDataSearch } from '@athonet/ui/components/Data/NoData';
import { useAppDispatch } from 'store';
import { showErrorToast } from 'store/actions/toast';

interface AwaitResolveRenderFunction {
  //TODOPS should be exported by react-router
  (data: Awaited<any>): React.ReactNode;
}

interface AwaitPanelProps extends BasePanelProps {
  resolve?: any;
  children: React.ReactNode | AwaitResolveRenderFunction;
}

function NoDataAsyncError() {
  const dispatch = useAppDispatch();
  const error = useAsyncError();

  useEffect(() => {
    //TODOPS centralize?
    const textError = error ? error?.toString() : '';

    if (!textError) dispatch(showErrorToast());
    else
      dispatch(
        showErrorToast({
          message: error ? error?.toString() : '',
          manualClose: true,
        })
      );
  }, [dispatch, error]);

  return <NoDataSearch />;
}

export function AwaitPanel(props: AwaitPanelProps) {
  const { resolve, children, ...panelProps } = props;

  return (
    <BasePanel {...panelProps}>
      <Suspense fallback={<PanelContentSkeleton />}>
        <Await resolve={resolve} errorElement={<NoDataAsyncError />}>
          {children}
        </Await>
      </Suspense>
    </BasePanel>
  );
}
