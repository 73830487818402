import { Stepper } from '@athonet/ui/components/Navigation/Stepper';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useStepper } from '@athonet/ui/hooks/useStepper';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Step5 } from '../Create/Steps/Step5';
import { Step6 } from '../Create/Steps/Step6';
import { DialogContent } from '@athonet/ui/components/Overlay/Dialog/DialogContent';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { PrepareForProvisionFormDataType } from '../Create/CreateUsimCard5gContent';

const PREPARE_PROVISION_STEPS = 2;
const MILESTONES_ARRAY = [1];

interface EditNetworkProfileDialogContentProps {
  onPrepareForProvisioning: (networkId: string, profileId: string) => Promise<string | void>;
  isBulkOperation: boolean;
  defaultNetwork?: string;
}

const EditNetworkProfileDialogContent: React.FC<PropsWithChildren<EditNetworkProfileDialogContentProps>> = ({
  children,
  onPrepareForProvisioning,
  isBulkOperation,
  defaultNetwork,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { dialogClose } = useOverlay();

  const {
    handleGoToStep,
    active,
    skipped,
    completed,
    handleGoToNextIncompleteStep,
    handleCompleteMilestone,
    milestonesCompleted,
  } = useStepper({
    steps: PREPARE_PROVISION_STEPS,
    milestones: MILESTONES_ARRAY,
  });

  const [prepareForProvisionValues, setPrepareForProvisionValues] = useState<PrepareForProvisionFormDataType>({
    network_topology_id: null,
    profile_id: null,
  });

  const onCompleteStep5 = useCallback(
    (values: PrepareForProvisionFormDataType) => {
      setPrepareForProvisionValues(values);
      handleGoToNextIncompleteStep();
    },
    [handleGoToNextIncompleteStep]
  );

  const handlePrepareForProvisioning = useCallback(async () => {
    const newNetwork =
      prepareForProvisionValues['network_topology_id'] &&
      'value' in prepareForProvisionValues['network_topology_id'] &&
      'label' in prepareForProvisionValues['network_topology_id']
        ? String(prepareForProvisionValues['network_topology_id'].value)
        : null;

    const newServiceProfile =
      prepareForProvisionValues['profile_id'] &&
      'value' in prepareForProvisionValues['profile_id'] &&
      'label' in prepareForProvisionValues['profile_id']
        ? String(prepareForProvisionValues['profile_id'].value)
        : null;

    if (newNetwork && newServiceProfile) {
      setLoading(true);
      const errorRes = await onPrepareForProvisioning(newNetwork, newServiceProfile);
      setLoading(false);
      if (!errorRes) {
        setError(null);
        handleCompleteMilestone(MILESTONES_ARRAY[0]);
      } else {
        setError(errorRes);
      }
    }
  }, [onPrepareForProvisioning, handleCompleteMilestone, prepareForProvisionValues]);

  return (
    <>
      <IconButton
        name="Close"
        fontSize="small"
        onClick={dialogClose}
        sx={{ position: 'absolute', right: '16px', top: '8px', zIndex: 100 }}
      />
      <DialogContent>
        {children}
        <Stepper
          orientation="vertical"
          completed={completed}
          nonLinear
          activeStep={active}
          onStepClick={handleGoToStep}
          skipped={skipped}
          milestonesCompleted={milestonesCompleted}
        >
          <Step5
            onCompleteStep={onCompleteStep5}
            initialValues={prepareForProvisionValues}
            defaultNetwork={defaultNetwork}
          />
          <Step6
            milestoneCompleted={milestonesCompleted.has(MILESTONES_ARRAY[0])}
            values={prepareForProvisionValues}
            error={error}
            onCompleteStep={() => void handlePrepareForProvisioning()}
            loading={loading}
            isBulkOperation={isBulkOperation}
          />
        </Stepper>
      </DialogContent>
    </>
  );
};

export default EditNetworkProfileDialogContent;
