import config from 'config';
import { Locale } from './locale';

const localStorageName = config.localStorage;
const localStorageAdmin = config.localStorageAdmin;

export function lsRemove() {
  localStorage.removeItem(localStorageName);
}

export function lsGet<T>(): T | null {
  const localStore = localStorage.getItem(localStorageName);
  if (localStore) {
    return JSON.parse(localStore);
  }
  return null;
}

export function lsSet(data: Record<string, unknown>) {
  localStorage.setItem(localStorageName, JSON.stringify(data));
}

export function lsSetLocale(locale: Locale) {
  localStorage.setItem(config.localStorageI18N, locale);
}

export function lsGetLocale() {
  const storedLocale = localStorage.getItem(config.localStorageI18N);
  return storedLocale ? (storedLocale as Locale) : null;
}

export function lsSetAdminConfig(data: Record<string, unknown>) {
  localStorage.setItem(localStorageAdmin, JSON.stringify(data));
}

export function lsGetAdminConfig<T>(): T | null {
  const storedAdmin = localStorage.getItem(localStorageAdmin);
  if (storedAdmin) {
    return JSON.parse(storedAdmin);
  }
  return null;
}

export function lsRemoveAdminConfig() {
  localStorage.removeItem(localStorageAdmin);
}
