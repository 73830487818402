import React from 'react';
import { useAppSelector } from 'store';
import { selectSliceById } from 'store/selectors/serviceProfiles';

interface SliceServiceCountProps {
  serviceProfileId: string;
  sliceId: string;
}

const SliceServicesCount: React.FC<SliceServiceCountProps> = (props) => {
  const { serviceProfileId, sliceId } = props;
  const slice = useAppSelector((state) => selectSliceById(state, serviceProfileId, sliceId));
  // const { oldValue, syncStatus } = useAppSelector((state) =>
  //   selectSliceServicesCountSyncStatus(state, serviceProfileId, sliceId)
  // );

  // return (
  //   <RemoteSyncText
  //     type={'body2'}
  //     syncStatus={syncStatus}
  //     text={slice?.services.length?.toString()}
  //     tooltipText={oldValue?.toString()}
  //     {...textProps}
  //   />
  // );

  return <>{slice?.services.length}</>;
};

export default SliceServicesCount;
