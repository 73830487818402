import { createAsyncThunk } from '@reduxjs/toolkit';
import { State } from 'store';
import { fetchData } from '../fetchData';
import { AxiosRequestConfig } from 'axios';
import config from 'config';
import { showErrorToast } from '../toast';
import { sentryLogError } from 'sentry';
import { GetTenantsRes } from './types';

export const getTenantsWithSubtenants = createAsyncThunk<
  GetTenantsRes,
  void,
  {
    rejectValue: null;
    state: State;
  }
>('tenants/getTenantsWithSubtenants', async (_, { dispatch, rejectWithValue }) => {
  try {
    const options: AxiosRequestConfig = {
      url: config.apis.getTenantWithSubtenants
        .replace('{sort}', 'name')
        .replace('{limit}', '1000')
        .replace('{page}', '0')
        .replace('{filters}', ''),
      method: 'GET',
    };
    const response = await dispatch(fetchData<GetTenantsRes>(options));
    return response;
  } catch (e) {
    sentryLogError(e);
    dispatch(showErrorToast());
    return rejectWithValue(null);
  }
});
