import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@athonet/ui/components/Input/Button';
import { IconButton } from '@athonet/ui/components/Input/IconButton';
import { Box } from '@athonet/ui/components/Surfaces/Box';

type DeleteIconButtonWithConfirmProps = {
  onDelete: () => void;
};

export default function DeleteIconButtonWithConfirm({ onDelete }: DeleteIconButtonWithConfirmProps) {
  const { formatMessage } = useIntl();
  const [showConfirmationButton, setShowButton] = useState<boolean>(false);

  const confirmDelete = useCallback(() => setShowButton(true), []);
  const cancelDelete = useCallback(() => setShowButton(false), []);

  return (
    <Box>
      {showConfirmationButton === true ? (
        <>
          <Button color="error" size="small" onClick={onDelete} text={formatMessage({ id: 'delete.form.textShort' })} />
          <IconButton name="Close" aria-label="delete" onClick={cancelDelete} />
        </>
      ) : (
        <IconButton name="Trashcan" aria-label="delete" onClick={confirmDelete} />
      )}
    </Box>
  );
}
