import React from 'react';
import { FastField, Field } from 'formik';

export interface BaseFieldProps {
  component?: any;
  /** use FastField instead of a Field */
  fast?: boolean;
}

const BaseField: React.FC<BaseFieldProps> = (props) => {
  const { component, fast, ...otherProps } = props;

  return (
    <>
      {fast && <FastField {...otherProps} component={component} />}
      {!fast && <Field {...otherProps} component={component} />}
    </>
  );
};

export default BaseField;
