import { SquaredChildTile } from '@athonet/ui/components/Data/Tile/SquaredChildTile';
import { SquaredTile } from '@athonet/ui/components/Data/Tile/SquaredTile';
import { UsersSummary } from '../../../../graphql/client/types';
import { NoMetrics } from 'components/NetworkId/NoMetrics/NoMetrics';
import { PanelContent } from '@athonet/ui/components/Surfaces/Panel/PanelContent';
import { useIntl } from 'react-intl';

type NetworkUsersSummaryProps = {
  usersSummary: UsersSummary;
};

export function NetworkUsersSummary({ usersSummary }: NetworkUsersSummaryProps) {
  const { formatMessage } = useIntl();

  if (usersSummary.provisioned === null || usersSummary.active === null) {
    return <NoMetrics />;
  }

  return (
    <PanelContent>
      <SquaredTile horizontal>
        <SquaredChildTile
          mainValue={usersSummary?.provisioned || 0}
          leftAlign
          subtitle={formatMessage({ id: 'menu.networks.usersSummaryPanel.provisioned' })}
          uppercaseTitle={false}
        />
        <SquaredChildTile
          mainValue={usersSummary?.active || 0}
          leftAlign
          subtitle={formatMessage({ id: 'menu.networks.usersSummaryPanel.active' })}
          uppercaseTitle={false}
        />
      </SquaredTile>
    </PanelContent>
  );
}
