import * as Types from './types';

import { gql } from '@apollo/client';
import { ThroughputFragmentFragmentDoc, LocationFragmentFragmentDoc, HardwareFragmentFragmentDoc, AccessManagementAlertsFragmentFragmentDoc, UserTrafficManagementAlertsFragmentFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NetworkSummaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NetworkSummaryQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', total: number, error: number, throughput: { __typename?: 'Throughput', downlink?: number | null, uplink?: number | null, downlinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null, uplinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null }, usersSummary: { __typename?: 'UsersSummary', active?: number | null }, networks: Array<{ __typename?: 'Network', id: string, name: string, tenantId: string, location: { __typename?: 'Location', lat: string, long: string }, status: { __typename?: 'Status', accessManagement: { __typename?: 'ControlPlaneStatus', workingPercentage: number }, userTrafficManagement: { __typename?: 'UserPlaneStatus', workingPercentage: number } }, radios: { __typename?: 'Radios', active?: number | null, total: number }, hardware: Array<{ __typename?: 'Hardware', id: string, name: string, location: { __typename?: 'Location', lat: string, long: string } }>, throughput: { __typename?: 'Throughput', downlink?: number | null, uplink?: number | null, downlinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null, uplinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null }, usersSummary: { __typename?: 'UsersSummary', active?: number | null } }> } } };

export type NetworkNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NetworkNamesQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, name: string }> } } };


export const NetworkSummaryDocument = gql`
    query networkSummary {
  me {
    id
    networkSummary {
      total
      error
      throughput {
        ...ThroughputFragment
      }
      usersSummary {
        active
      }
      networks {
        id
        name
        tenantId
        location {
          ...LocationFragment
        }
        status {
          accessManagement {
            workingPercentage
          }
          userTrafficManagement {
            workingPercentage
          }
        }
        radios {
          active
          total
        }
        hardware {
          ...HardwareFragment
          location {
            ...LocationFragment
          }
        }
        throughput {
          ...ThroughputFragment
        }
        usersSummary {
          active
        }
      }
    }
  }
}
    ${ThroughputFragmentFragmentDoc}
${LocationFragmentFragmentDoc}
${HardwareFragmentFragmentDoc}`;

/**
 * __useNetworkSummaryQuery__
 *
 * To run a query within a React component, call `useNetworkSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNetworkSummaryQuery(baseOptions?: Apollo.QueryHookOptions<NetworkSummaryQuery, NetworkSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkSummaryQuery, NetworkSummaryQueryVariables>(NetworkSummaryDocument, options);
      }
export function useNetworkSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkSummaryQuery, NetworkSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkSummaryQuery, NetworkSummaryQueryVariables>(NetworkSummaryDocument, options);
        }
export type NetworkSummaryQueryHookResult = ReturnType<typeof useNetworkSummaryQuery>;
export type NetworkSummaryLazyQueryHookResult = ReturnType<typeof useNetworkSummaryLazyQuery>;
export type NetworkSummaryQueryResult = Apollo.QueryResult<NetworkSummaryQuery, NetworkSummaryQueryVariables>;
export const NetworkNamesDocument = gql`
    query networkNames {
  me {
    id
    networkSummary {
      networks {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useNetworkNamesQuery__
 *
 * To run a query within a React component, call `useNetworkNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNetworkNamesQuery(baseOptions?: Apollo.QueryHookOptions<NetworkNamesQuery, NetworkNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkNamesQuery, NetworkNamesQueryVariables>(NetworkNamesDocument, options);
      }
export function useNetworkNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkNamesQuery, NetworkNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkNamesQuery, NetworkNamesQueryVariables>(NetworkNamesDocument, options);
        }
export type NetworkNamesQueryHookResult = ReturnType<typeof useNetworkNamesQuery>;
export type NetworkNamesLazyQueryHookResult = ReturnType<typeof useNetworkNamesLazyQuery>;
export type NetworkNamesQueryResult = Apollo.QueryResult<NetworkNamesQuery, NetworkNamesQueryVariables>;