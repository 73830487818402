import * as Types from './types';

import { gql } from '@apollo/client';
import { HardwareFragmentFragmentDoc, ThroughputFragmentFragmentDoc, AccessManagementAlertsFragmentFragmentDoc, UserTrafficManagementAlertsFragmentFragmentDoc, LocationFragmentFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NetworkOverviewByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkOverviewByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, name: string }> } } };

export type NetworkTopologyByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkTopologyByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, name: string, site: Array<{ __typename?: 'Site', id: string, name: string }>, hardware: Array<{ __typename?: 'Hardware', id: string, name: string }>, vms: Array<{ __typename?: 'Vm', id: string, hostname: string, oamIp: string, nfs: Array<{ __typename?: 'NetworkFunction', id: string, name: string, onlineStatus?: boolean | null }>, info: { __typename?: 'VmInfo', hwId: string, product: string, swRelease: string, siteId: string, udrClusterId?: string | null } }> }> } } };

export type NetworkThroughputByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkThroughputByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, throughput: { __typename?: 'Throughput', downlink?: number | null, uplink?: number | null, downlinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null, uplinkTimeseries?: Array<{ __typename?: 'SeriesPoint', bytes?: number | null, datetime?: string | null }> | null } }> } } };

export type NetworkAlertsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkAlertsByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, alerts: { __typename?: 'Alerts', accessManagement: { __typename?: 'AccessManagementAlerts', error?: number | null, warning?: number | null }, userTrafficManagement: { __typename?: 'UserTrafficManagementAlerts', error?: number | null, warning?: number | null } } }> } } };

export type NetworkLicensesByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkLicensesByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, licenses: { __typename?: 'Licenses', active?: number | null, expiring?: number | null } }> } } };

export type NetworkRadiosByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkRadiosByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, radios: { __typename?: 'Radios', active?: number | null, total: number } }> } } };

export type NetworkHardwareByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkHardwareByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, hardware: Array<{ __typename?: 'Hardware', id: string, name: string, location: { __typename?: 'Location', lat: string, long: string } }>, vms: Array<{ __typename?: 'Vm', id: string, hostname: string, info: { __typename?: 'VmInfo', hwName: string, hwId: string, product: string, swRelease: string, siteId: string } }> }> } } };

export type NetworkAccessManagementByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkAccessManagementByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, status: { __typename?: 'Status', accessManagement: { __typename?: 'ControlPlaneStatus', errorVmsCount: number, workingPercentage: number } } }> } } };

export type NetworkUserTrafficManagementByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkUserTrafficManagementByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, status: { __typename?: 'Status', userTrafficManagement: { __typename?: 'UserPlaneStatus', errorVmsCount: number, workingPercentage: number } } }> } } };

export type NetworkUsersSummaryByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type NetworkUsersSummaryByIdQuery = { __typename?: 'RootQueryType', me: { __typename?: 'User', id: string, networkSummary: { __typename?: 'NetworkSummary', networks: Array<{ __typename?: 'Network', id: string, usersSummary: { __typename?: 'UsersSummary', active?: number | null, provisioned?: number | null } }> } } };


export const NetworkOverviewByIdDocument = gql`
    query networkOverviewById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useNetworkOverviewByIdQuery__
 *
 * To run a query within a React component, call `useNetworkOverviewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkOverviewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkOverviewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkOverviewByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkOverviewByIdQuery, NetworkOverviewByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkOverviewByIdQuery, NetworkOverviewByIdQueryVariables>(NetworkOverviewByIdDocument, options);
      }
export function useNetworkOverviewByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkOverviewByIdQuery, NetworkOverviewByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkOverviewByIdQuery, NetworkOverviewByIdQueryVariables>(NetworkOverviewByIdDocument, options);
        }
export type NetworkOverviewByIdQueryHookResult = ReturnType<typeof useNetworkOverviewByIdQuery>;
export type NetworkOverviewByIdLazyQueryHookResult = ReturnType<typeof useNetworkOverviewByIdLazyQuery>;
export type NetworkOverviewByIdQueryResult = Apollo.QueryResult<NetworkOverviewByIdQuery, NetworkOverviewByIdQueryVariables>;
export const NetworkTopologyByIdDocument = gql`
    query networkTopologyById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        site {
          id
          name
        }
        name
        hardware {
          ...HardwareFragment
        }
        vms {
          id
          hostname
          nfs {
            id
            name
            onlineStatus
          }
          oamIp
          info {
            hwId
            product
            swRelease
            siteId
            udrClusterId
          }
        }
      }
    }
  }
}
    ${HardwareFragmentFragmentDoc}`;

/**
 * __useNetworkTopologyByIdQuery__
 *
 * To run a query within a React component, call `useNetworkTopologyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkTopologyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkTopologyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkTopologyByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkTopologyByIdQuery, NetworkTopologyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkTopologyByIdQuery, NetworkTopologyByIdQueryVariables>(NetworkTopologyByIdDocument, options);
      }
export function useNetworkTopologyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkTopologyByIdQuery, NetworkTopologyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkTopologyByIdQuery, NetworkTopologyByIdQueryVariables>(NetworkTopologyByIdDocument, options);
        }
export type NetworkTopologyByIdQueryHookResult = ReturnType<typeof useNetworkTopologyByIdQuery>;
export type NetworkTopologyByIdLazyQueryHookResult = ReturnType<typeof useNetworkTopologyByIdLazyQuery>;
export type NetworkTopologyByIdQueryResult = Apollo.QueryResult<NetworkTopologyByIdQuery, NetworkTopologyByIdQueryVariables>;
export const NetworkThroughputByIdDocument = gql`
    query networkThroughputById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        throughput {
          ...ThroughputFragment
        }
      }
    }
  }
}
    ${ThroughputFragmentFragmentDoc}`;

/**
 * __useNetworkThroughputByIdQuery__
 *
 * To run a query within a React component, call `useNetworkThroughputByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkThroughputByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkThroughputByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkThroughputByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkThroughputByIdQuery, NetworkThroughputByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkThroughputByIdQuery, NetworkThroughputByIdQueryVariables>(NetworkThroughputByIdDocument, options);
      }
export function useNetworkThroughputByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkThroughputByIdQuery, NetworkThroughputByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkThroughputByIdQuery, NetworkThroughputByIdQueryVariables>(NetworkThroughputByIdDocument, options);
        }
export type NetworkThroughputByIdQueryHookResult = ReturnType<typeof useNetworkThroughputByIdQuery>;
export type NetworkThroughputByIdLazyQueryHookResult = ReturnType<typeof useNetworkThroughputByIdLazyQuery>;
export type NetworkThroughputByIdQueryResult = Apollo.QueryResult<NetworkThroughputByIdQuery, NetworkThroughputByIdQueryVariables>;
export const NetworkAlertsByIdDocument = gql`
    query networkAlertsById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        alerts {
          accessManagement {
            ...AccessManagementAlertsFragment
          }
          userTrafficManagement {
            ...UserTrafficManagementAlertsFragment
          }
        }
      }
    }
  }
}
    ${AccessManagementAlertsFragmentFragmentDoc}
${UserTrafficManagementAlertsFragmentFragmentDoc}`;

/**
 * __useNetworkAlertsByIdQuery__
 *
 * To run a query within a React component, call `useNetworkAlertsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkAlertsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkAlertsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkAlertsByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkAlertsByIdQuery, NetworkAlertsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkAlertsByIdQuery, NetworkAlertsByIdQueryVariables>(NetworkAlertsByIdDocument, options);
      }
export function useNetworkAlertsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkAlertsByIdQuery, NetworkAlertsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkAlertsByIdQuery, NetworkAlertsByIdQueryVariables>(NetworkAlertsByIdDocument, options);
        }
export type NetworkAlertsByIdQueryHookResult = ReturnType<typeof useNetworkAlertsByIdQuery>;
export type NetworkAlertsByIdLazyQueryHookResult = ReturnType<typeof useNetworkAlertsByIdLazyQuery>;
export type NetworkAlertsByIdQueryResult = Apollo.QueryResult<NetworkAlertsByIdQuery, NetworkAlertsByIdQueryVariables>;
export const NetworkLicensesByIdDocument = gql`
    query networkLicensesById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        licenses {
          active
          expiring
        }
      }
    }
  }
}
    `;

/**
 * __useNetworkLicensesByIdQuery__
 *
 * To run a query within a React component, call `useNetworkLicensesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkLicensesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkLicensesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkLicensesByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkLicensesByIdQuery, NetworkLicensesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkLicensesByIdQuery, NetworkLicensesByIdQueryVariables>(NetworkLicensesByIdDocument, options);
      }
export function useNetworkLicensesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkLicensesByIdQuery, NetworkLicensesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkLicensesByIdQuery, NetworkLicensesByIdQueryVariables>(NetworkLicensesByIdDocument, options);
        }
export type NetworkLicensesByIdQueryHookResult = ReturnType<typeof useNetworkLicensesByIdQuery>;
export type NetworkLicensesByIdLazyQueryHookResult = ReturnType<typeof useNetworkLicensesByIdLazyQuery>;
export type NetworkLicensesByIdQueryResult = Apollo.QueryResult<NetworkLicensesByIdQuery, NetworkLicensesByIdQueryVariables>;
export const NetworkRadiosByIdDocument = gql`
    query networkRadiosById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        radios {
          active
          total
        }
      }
    }
  }
}
    `;

/**
 * __useNetworkRadiosByIdQuery__
 *
 * To run a query within a React component, call `useNetworkRadiosByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkRadiosByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkRadiosByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkRadiosByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkRadiosByIdQuery, NetworkRadiosByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkRadiosByIdQuery, NetworkRadiosByIdQueryVariables>(NetworkRadiosByIdDocument, options);
      }
export function useNetworkRadiosByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkRadiosByIdQuery, NetworkRadiosByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkRadiosByIdQuery, NetworkRadiosByIdQueryVariables>(NetworkRadiosByIdDocument, options);
        }
export type NetworkRadiosByIdQueryHookResult = ReturnType<typeof useNetworkRadiosByIdQuery>;
export type NetworkRadiosByIdLazyQueryHookResult = ReturnType<typeof useNetworkRadiosByIdLazyQuery>;
export type NetworkRadiosByIdQueryResult = Apollo.QueryResult<NetworkRadiosByIdQuery, NetworkRadiosByIdQueryVariables>;
export const NetworkHardwareByIdDocument = gql`
    query networkHardwareById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        hardware {
          id
          name
          location {
            ...LocationFragment
          }
        }
        vms {
          id
          hostname
          info {
            hwName
            hwId
            product
            swRelease
            siteId
          }
        }
      }
    }
  }
}
    ${LocationFragmentFragmentDoc}`;

/**
 * __useNetworkHardwareByIdQuery__
 *
 * To run a query within a React component, call `useNetworkHardwareByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkHardwareByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkHardwareByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkHardwareByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkHardwareByIdQuery, NetworkHardwareByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkHardwareByIdQuery, NetworkHardwareByIdQueryVariables>(NetworkHardwareByIdDocument, options);
      }
export function useNetworkHardwareByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkHardwareByIdQuery, NetworkHardwareByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkHardwareByIdQuery, NetworkHardwareByIdQueryVariables>(NetworkHardwareByIdDocument, options);
        }
export type NetworkHardwareByIdQueryHookResult = ReturnType<typeof useNetworkHardwareByIdQuery>;
export type NetworkHardwareByIdLazyQueryHookResult = ReturnType<typeof useNetworkHardwareByIdLazyQuery>;
export type NetworkHardwareByIdQueryResult = Apollo.QueryResult<NetworkHardwareByIdQuery, NetworkHardwareByIdQueryVariables>;
export const NetworkAccessManagementByIdDocument = gql`
    query networkAccessManagementById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        status {
          accessManagement {
            errorVmsCount
            workingPercentage
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNetworkAccessManagementByIdQuery__
 *
 * To run a query within a React component, call `useNetworkAccessManagementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkAccessManagementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkAccessManagementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkAccessManagementByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkAccessManagementByIdQuery, NetworkAccessManagementByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkAccessManagementByIdQuery, NetworkAccessManagementByIdQueryVariables>(NetworkAccessManagementByIdDocument, options);
      }
export function useNetworkAccessManagementByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkAccessManagementByIdQuery, NetworkAccessManagementByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkAccessManagementByIdQuery, NetworkAccessManagementByIdQueryVariables>(NetworkAccessManagementByIdDocument, options);
        }
export type NetworkAccessManagementByIdQueryHookResult = ReturnType<typeof useNetworkAccessManagementByIdQuery>;
export type NetworkAccessManagementByIdLazyQueryHookResult = ReturnType<typeof useNetworkAccessManagementByIdLazyQuery>;
export type NetworkAccessManagementByIdQueryResult = Apollo.QueryResult<NetworkAccessManagementByIdQuery, NetworkAccessManagementByIdQueryVariables>;
export const NetworkUserTrafficManagementByIdDocument = gql`
    query networkUserTrafficManagementById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        status {
          userTrafficManagement {
            errorVmsCount
            workingPercentage
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNetworkUserTrafficManagementByIdQuery__
 *
 * To run a query within a React component, call `useNetworkUserTrafficManagementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkUserTrafficManagementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkUserTrafficManagementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkUserTrafficManagementByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkUserTrafficManagementByIdQuery, NetworkUserTrafficManagementByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkUserTrafficManagementByIdQuery, NetworkUserTrafficManagementByIdQueryVariables>(NetworkUserTrafficManagementByIdDocument, options);
      }
export function useNetworkUserTrafficManagementByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkUserTrafficManagementByIdQuery, NetworkUserTrafficManagementByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkUserTrafficManagementByIdQuery, NetworkUserTrafficManagementByIdQueryVariables>(NetworkUserTrafficManagementByIdDocument, options);
        }
export type NetworkUserTrafficManagementByIdQueryHookResult = ReturnType<typeof useNetworkUserTrafficManagementByIdQuery>;
export type NetworkUserTrafficManagementByIdLazyQueryHookResult = ReturnType<typeof useNetworkUserTrafficManagementByIdLazyQuery>;
export type NetworkUserTrafficManagementByIdQueryResult = Apollo.QueryResult<NetworkUserTrafficManagementByIdQuery, NetworkUserTrafficManagementByIdQueryVariables>;
export const NetworkUsersSummaryByIdDocument = gql`
    query networkUsersSummaryById($id: ID!) {
  me {
    id
    networkSummary {
      networks(id: $id) {
        id
        usersSummary {
          active
          provisioned
        }
      }
    }
  }
}
    `;

/**
 * __useNetworkUsersSummaryByIdQuery__
 *
 * To run a query within a React component, call `useNetworkUsersSummaryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkUsersSummaryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkUsersSummaryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkUsersSummaryByIdQuery(baseOptions: Apollo.QueryHookOptions<NetworkUsersSummaryByIdQuery, NetworkUsersSummaryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkUsersSummaryByIdQuery, NetworkUsersSummaryByIdQueryVariables>(NetworkUsersSummaryByIdDocument, options);
      }
export function useNetworkUsersSummaryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkUsersSummaryByIdQuery, NetworkUsersSummaryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkUsersSummaryByIdQuery, NetworkUsersSummaryByIdQueryVariables>(NetworkUsersSummaryByIdDocument, options);
        }
export type NetworkUsersSummaryByIdQueryHookResult = ReturnType<typeof useNetworkUsersSummaryByIdQuery>;
export type NetworkUsersSummaryByIdLazyQueryHookResult = ReturnType<typeof useNetworkUsersSummaryByIdLazyQuery>;
export type NetworkUsersSummaryByIdQueryResult = Apollo.QueryResult<NetworkUsersSummaryByIdQuery, NetworkUsersSummaryByIdQueryVariables>;