import { Button } from '@athonet/ui/components/Input/Button';
import { Stack } from '@athonet/ui/components/Layout/Stack';
import { useOverlay } from '@athonet/ui/hooks/useOverlay';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { array, object, string } from 'yup';
import { Role } from 'store/models/role';
import { createRole, editRole, getPermissions } from 'store/actions/roles';
import { useAppDispatch } from 'store';
import { AutocompleteOption } from 'utils/forms';
import TextFieldField from 'components/Form/Field/TextFieldField';
import BaseFieldset from 'components/Form/Fieldset/BaseFieldset';
import AutocompleteField from 'components/Form/Field/AutocompleteField';
import FormikDialog from 'components/Form/FormikDialog';

type EditRoleFormDataType = {
  role: string;
  permissions: AutocompleteOption[];
};

interface EditRoleProps {
  role?: Role;
}

export function EditRoleDialog({ role }: EditRoleProps) {
  const { formatMessage } = useIntl();
  const { dialogClose } = useOverlay();
  const dispatch = useAppDispatch();
  const [permissions, setPermissions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    async function mapPermissions() {
      const fetchedPermissions = await dispatch(getPermissions());
      setPermissions(
        fetchedPermissions.map((item: string) => ({ label: formatMessage({ id: `permissions.${item}` }), value: item }))
      );
    }
    void mapPermissions();
  }, [dispatch, formatMessage]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        role: string()
          .trim()
          .required()
          .label(formatMessage({ id: 'security.form.role.label' })),
        permissions: array()
          .required()
          .label(formatMessage({ id: 'security.form.permissions.label' })),
      }),
    [formatMessage]
  );

  const initials: EditRoleFormDataType = useMemo(
    () =>
      role
        ? {
            role: role.name,
            permissions: permissions.filter(({ value }) => role.permissions.find(({ code }) => code === value)),
          }
        : {
            role: '',
            permissions: [],
          },
    [permissions, role]
  );

  const handleEditRole = useCallback(
    async (values: EditRoleFormDataType) => {
      const permissionsValues = values.permissions.map(({ value }) => value);

      role
        ? await dispatch(editRole({ data: { role: values.role, permissions: permissionsValues }, roleId: role.id }))
        : await dispatch(createRole({ role: values.role, permissions: permissionsValues }));

      dialogClose();
    },
    [dialogClose, dispatch, role]
  );

  return (
    <FormikDialog
      initialValues={initials}
      onSubmit={handleEditRole}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ setFieldValue }) => (
        <BaseFieldset>
          <Stack spacing={2} align="flex-end" sx={{ pt: 2 }}>
            <TextFieldField
              name="role"
              fullWidth
              placeholder={formatMessage({ id: 'security.form.role.placeholder' })}
            />
            <>
              <AutocompleteField
                name="permissions"
                options={permissions}
                multiple
                placeholder={formatMessage({ id: 'security.form.permissions.placeholder' })}
              />
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => void setFieldValue('permissions', permissions)}
                data-testid="multiselect-selectall"
                text={formatMessage({ id: 'common.button.selectAll' })}
                sx={{ marginRight: 0 }}
              />
            </>
          </Stack>
        </BaseFieldset>
      )}
    </FormikDialog>
  );
}
