import { createSelector } from '@reduxjs/toolkit';
import { SyncStatus } from 'components/RemoteSync/utils';
import isEmpty from 'lodash/isEmpty';
import { State } from 'store';
import { PLMN } from 'store/models/plmn';
import { plmnsAdapter } from 'store/reducers/plmns';

export const { selectAll: selectAllPlmn, selectById: selectPlmnById } = plmnsAdapter.getSelectors<State>(
  (state) => state.plmns.entities
);

export const getPlmnsLoadingStatus = (state: State) => state.plmns.loadingStatus;

const isPendingCreation = (plmn: PLMN) => !isEmpty(plmn.highlighting?.unprovisioned_values);
const isPendingDeletion = (plmn: PLMN) => plmn.to_be_deleted === true;

export const getPlmnSyncStatus = createSelector(
  [(state: State, id: string) => selectPlmnById(state, id), (state: State, id: string) => id],
  (plmn) => {
    if (!plmn) return SyncStatus.None;
    return isPendingDeletion(plmn)
      ? SyncStatus.PendingDeletion
      : isPendingCreation(plmn)
      ? SyncStatus.PendingCreation
      : SyncStatus.None;
  }
);

export const getPlmnIdsWithPendingDeletion = createSelector([selectAllPlmn], (plmns) => {
  const plmnIds = plmns
    .filter((plmn) => {
      return isPendingDeletion(plmn);
    })
    .map((p) => p.id);
  return plmnIds;
});

export const getPlmnIdsWithPendingCreation = createSelector(
  [selectAllPlmn, getPlmnIdsWithPendingDeletion],
  (plmns, deletionIds) => {
    const plmnIds = plmns
      .filter((plmn) => {
        return isPendingCreation(plmn);
      })
      .map((p) => p.id)
      .filter((id) => !deletionIds.includes(id));
    return plmnIds;
  }
);
